import { Component, ElementRef, OnInit, ViewChild,AfterViewInit, HostListener, NO_ERRORS_SCHEMA } from '@angular/core';
import { AiGeneratorService, AuthService } from 'src/app/_services/index';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/debounceTime'
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { SpinnerWithQuotesComponent } from 'src/app/architect/spinner-with-quotes/spinner-with-quotes.component';
import { ArchiExpiredGptModalComponent } from 'src/app/all/archi-expired-gpt-modal/archi-expired-gpt-modal.component';
import { ChatgptRegenerateModalComponent } from 'src/app/architect/chatgpt-regenerate-modal/chatgpt-regenerate-modal.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

import { select, Store } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';

declare var $:any;

@Component({
    standalone: true,

    selector: 'app-archi-aglo-gpt',
    imports:[
      
      HeaderComponent,
      ArchiExpiredModalComponent,
      SpinnerWithQuotesComponent,
      ArchiExpiredGptModalComponent,
      ChatgptRegenerateModalComponent,
      NgSharedModule
    ],
    providers: [],
    templateUrl: './archi-aglo-gpt.component.html',
    styleUrls: ['./archi-aglo-gpt.component.scss'],
})
export class ArchiAgloGPTComponent implements OnInit,AfterViewInit {
  @ViewChild('inputArea') myInput : ElementRef;
  public textInput:string;
  public resultat: string;user;
  public loadingData:boolean=false;
  public divVisibility:boolean=false;
  public score: any;
  public color: string;
  public textComplet: any;
  public textInputSize: number;
  public totalText: string;
  private subscriptions: Array<Subscription> = [];
  public errorMessage: boolean;
  public projectLicence: string='none';
  public currentUser 
  constructor(
    private aiService : AiGeneratorService,
    private clipboard :Clipboard,
    private messageService: MessageService,
    private store: Store,
    private authService:AuthService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    this.noMultiAccountsAllowed();
    this.getUserById();
    if(!environment.onPremise)window.analytics.page('pageview_gpt');

    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
  }
  
  ngAfterViewInit() : void {
   // this.doubounceInput(this.myInput);
  }
  
  public generateCCTP(event=undefined){
    let licencesPro = ['plus','plus-trim','plus-annuel']
    if(event){
      event.preventDefault(); /// to prevent the text from goinf to new line, on enter key pressed
    }
    if(this.textInput){
      if(licencesPro.includes(this.currentUser.licence) && this.projectLicence =='none'){
        $("#user-on-pro-license-modal").modal('show');
        return
      }else if(this.currentUser.licence=='without' && this.projectLicence =='none'){
        $("#user-on-without-license-modal").modal('show');
        return;
      }else if(this.user.dateEndTrial && this.user.gptCount<1){
        $("#gpt-expired-free").modal('show');
        return
      }
      this.errorMessage=false;
      this.loadingData=true;
      this.divVisibility=false;
      this.subscriptions.push( 
        this.aiService.genererCttpUsingAI({poste:this.textInput,gptCount:(this.user||{}).gptCount})
        .subscribe(
          (res)=>{
            this.loadingData = false; 
            this.divVisibility = true;
            //replace all /n and /r with <br/> 
            this.resultat = res.data.replace(/\n/g,'<br/>').replace(/\r/g,'<br/>');

            this.user.gptCount = (this.user||{}).gptCount>0 ?this.user.gptCount-1:0;
            this.setScoreColor(Math.floor(res.score*100) );
            if(!environment.onPremise)window.analytics.track('generate_cctp',{properties:{acValue: 'generate_cctp','requete':res.data,'fiabilite':Math.floor(res.score*100)}});
          },
          (err)=>{
            console.log(err)
            $("#chatgpt-regenerate-modal").modal("show");
            this.loadingData=false; 
            this.divVisibility=false;
          }
        )
      );
    }
    else{
      this.errorMessage=true;
    } 
  } 
  public regenerateCCTP(){
    $("#chatgpt-regenerate-modal").modal("hide");
    this.generateCCTP();
  }
  private setScoreColor(score){
    this.score = score;
    if(score > 85){
      this.color ='green';
    }
    else if(score < 75){
      this.color ='red';
    }
    else{
      this.color ='orange';
    }
  }
  private doubounceInput(input: ElementRef){
    fromEvent(input.nativeElement,'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((event:KeyboardEvent) => {
          let value = input.nativeElement.value;
          let nonSearchableKeyCodes = [8,9, 13];
          if(value!=='' && !nonSearchableKeyCodes.includes(event.keyCode)){
              this.searchTextCompletion(value);
          }else{
            this.GoToTheEndLine(event);
          }
        })
      )
      .subscribe();
  }
  private searchTextCompletion(value:string){
    this.subscriptions.push(
      this.aiService.textCompletion({poste:value}).subscribe(
        (res)=>{
            this.textComplet = res.replaceAll('Réponse :'&&'\n','');
            var text = this.textComplet.split('.')
            this.textInputSize = this.textInput.length;
            this.textInput +=text[0];//this.textComplet;
            this.myInput.nativeElement.focus();
            setTimeout(()=>{
              this.myInput.nativeElement.setSelectionRange(this.textInputSize, this.textInput.length);
            }, 0);         
        },
        (err)=>{
          console.log(err.message)
        }
      )
    )
  }
  private getUserById(){
    this.subscriptions.push(
      this.authService.getUser(this.currentUser.id).subscribe(
        (res)=>{
           this.user = res;
        },
        (err)=>{
          console.log(err.message)
        }
      )
    )
  }
  public GoToTheEndLine(event){
    event.preventDefault();
    setTimeout(()=>{
      this.myInput.nativeElement.setSelectionRange(this.textInput.length, this.textInput.length+1);
    }, 0); 
  }

  public changeErrorMessage(){
    if(this.myInput){
      this.errorMessage=false;
    }
    else{
    this.errorMessage=true;
    }
  }

  public copyToClipboardWithParameter(value: HTMLElement): void {
    const text: string = value.textContent || '';
    const successful = this.clipboard.copy(text);
    if (successful) {
      this.messageService.add({ key: 'toast', severity: 'success', summary: '', detail: 'Le texte a été copié', life: 5000 });
      if(!environment.onPremise)window.analytics.track('copied_descriptif',{properties:{acValue: 'copied_descriptif','fiabilite':this.score}});
    }
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
