<!-- <div class="ag-btn-primary-sm option-buttons" style="z-index:100; margin-left: 0px;position: absolute;width: auto;padding: 9px 12px 24px;background-color: #0A1747;" type="button" data-toggle="modal" data-target="#collab-modal" [ngStyle]="{'right': (currentUser.licence=='base')? '280px':'150px','top': (currentUser.licence=='base')? '83px':'5px'}"> -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>
  <div *ngIf="currentUser.licence!='without'" class="ag-btn-primary-sm option-buttons" style="z-index:100; margin-left: 0px;position: absolute;width: auto;padding: 9px 12px 24px;background-color: #0A1747;" type="button" data-toggle="modal" data-target="#collab-modal" [ngStyle]="{'right': (currentUser.licence=='base')? '280px':'150px','top':'5px'}">
    <i class="fa fa-share" style="height: 10px;position: relative;top: 1px;"></i>Partager Ma bibli.
  </div>
  <div  class="row background-page" [ngStyle]="{'height':'calc(100vh - 50px)'}">
  <div *ngIf="!isInRoot" class="row" style="height: 7%;">
    <div *ngIf="pathArray.length > 1 || isFromUrl" class='s_round' (click)="goToPath(pathArray.length -2)" [ngStyle]="{'top':'57px'}">
      <div class='b_round'>
      </div>
      <div class="s_arrow"></div>
    </div>
    <div *ngIf="!loadingPath" class="path-container">
      <strong>Dossiers : </strong> <span class="click-it" *ngFor="let path of pathArray,let i = index" (click)="goToPath(i)"> {{path.name}} / </span>
    </div>
  </div>
  <div *ngIf="!isInRoot" class="col-md-3 sticky-sommaire">
    <div class="row floating-card sommaire-container">
      <div class="lot-container">
        <app-spinner *ngIf="loadingLots"></app-spinner>
        <div *ngIf="!loadingLots" class="lot-pill scroller-1">
          <div>
            <div class="sommaire-pill">
              <i *ngIf="(lot && !lot.icon)|| !lot" class="fa fa-folder-open handle" aria-hidden="true"></i>
              <img *ngIf="lot && lot.icon" class="handle" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15" style="position: relative;bottom:2px;">
  
              <span class="lot-sommaire" >{{pathArray[pathArray.length-1].name}}</span>
            </div>
          </div>

          <ng-template [ngIf]="!isInRoot && lots && lots.length>0">
            <div [dragulaModel]="lots" (dragulaModelChange)="changeLotOrder($event)" dragula="LOTS" *ngIf="showDossier">
              <div *ngFor="let lot of lots;let loti = index;trackBy:trackById" [attr.id]="loti" style="margin-left: 20px;">    <!-- (click)="loadPostsFromLot(lot.id)"-->
                <div class="sommaire-pill" [ngClass]="{'selected': lot.id , 'not-consulted' : false}" (click)="getLot(lot._id)">
                  <i *ngIf="lot && !lot.icon" class="fa fa-folder handle" aria-hidden="true"></i>
                  <img *ngIf="lot && lot.icon" class="handle" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
                  <span class="lot-sommaire" [pTooltip]="lot.title" >{{lot.title}}</span>
                </div>
                <div class="delete-sommaire-lot delete">
                  <div class="poste-menu">
                    <div class="menubtn dropdown">
                      <div *ngIf="!authService.currentEnvironment &&lot.archiId==archiId || isAdministrator" class="more-dropdown no-open" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <i class="fa fa-ellipsis-v no-open" ></i>
                      </div>
                      <div *ngIf="authService.currentEnvironment && lot.archiId==archiId && userData?.isAdminArchitect  || isAdministrator" class="more-dropdown no-open" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <i class="fa fa-ellipsis-v no-open" ></i>
                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                        <li (click)="$event.stopPropagation();deleteLot(lot)">
                          <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i>
                          <span class="menu-text">Supprimer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          <div *ngIf='!loadingLots && !isInRoot && lots && (lots.length==0)' class="empty-lot-text">
            Ce dossier n'a aucun sous dossier
          </div>
        </div>
    </div>
      <div class="bottom-options" *ngIf="!authService.currentEnvironment">
          <div *ngIf="((!isInRoot || firstTimeDbArchi) && lot && lot.archiId==archiId) || (isEmpty && !isInRoot) || (!isInRoot && dbNum==0)" class="sommaire-pill add-post-button option-buttons" type="button" (click)="openSidePostEvent('add lot',{})">
            Ajouter un dossier &nbsp;	 <i class="fa fa-plus"></i>
          </div>
      </div>
      <div class="bottom-options" *ngIf="authService.currentEnvironment">
        <div *ngIf="((!isInRoot || firstTimeDbArchi) && lot && lot.archiId==archiId && isAdminArchitect) || (isEmpty && !isInRoot) || (!isInRoot && dbNum==0 && isAdminArchitect) || isAdministrator" class="sommaire-pill add-post-button option-buttons" type="button" (click)="openSidePostEvent('add lot',{})">
          Ajouter un dossier &nbsp;	 <i class="fa fa-plus"></i>
        </div>
    </div>
    </div>
  </div>
  <div *ngIf="!isInRoot" class="col-md-9 sticky-sommaire">
    <app-spinner *ngIf="loadingLots && !isEmpty"></app-spinner>
    <div *ngIf="isEmpty && !dbNum && !currentUser.isAdmin">
      <img src="assets/archi/empty_db.svg" style=" width: 24%;margin: auto;display: block;margin-top: 30px;">
      <div style="text-align:center;display: flex;flex-direction: column;">
        <h2 >Votre bibliothèque est vide</h2>
        <a style="font-size: 25px;font-weight: 300;" (click)="openSidePostEvent('add lot',{})">Ajoutez un dossier <i class="fa fa-plus"></i></a>
      </div>
    </div>
    <div *ngIf="(isFirstFolder && !searching && !isEmpty) || (isFirstFolder && !searching && dbNum)">
      <img src="assets/archi/undraw_my_answer_iw6k.svg" style=" width: 24%;margin: auto;display: block;margin-top: 30px;">
      <h2 style="text-align:center;">Choisissez un dossier dans l'arborescence de gauche</h2>
    </div>
    <div class="row floating-card sticky-sommaire" *ngIf="currentUser.isAdmin ||(!loadingLots && !isEmpty && !isFirstFolder) || (dbNum && !loadingLots && !isFirstFolder)" style="height: 95%;">
      <div class="col-md-12" data-step="6" data-intro="Ceci est un Lot" style="height: 100%;">
        <!--********************Lot Head Pill ***********************-->
          <div class="row" style="height: 100%;">
            <div style="display: flex;">
              <div class="pill-col-lot" (click)="openSidePostEvent('modify lot',{})">
                <div *ngIf="lot" class="title-lot">
                  <div class="title-icon">
                  <i *ngIf="lot && !lot.icon" class="fa fa-folder-open grab-it" aria-hidden="true"></i>
                  <img *ngIf="lot && lot.icon" class="grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="20">
                  </div>
                  {{lot.title}}
                </div>
              </div>
              <div style="float: right;margin-left: auto;">
                <i class="fa fa-search search-icon"></i><input class="search-bar" type="text" placeholder="Recherche..." name="search" (keyup)="search($event,true)" id="search-input"/>
              </div>
            </div>
  
          <div style="margin-top: 14px;">
            <table class="marged-table">
              <!--********************Table***********************-->
              <tr>
                <td class="title"></td>
                <td class="title">Désignation du poste</td>
                <!-- <td class="title centre">Quantité</td> -->
                <td class="title centre">U</td>
                <td class="title centre">PU HT</td>
                <!-- <td class="title centre">Prix total</td> -->
                <!-- <td class="title centre">EC</td> -->
                <!-- <td class="title centre">Date création</td> -->
                <td class="title"></td>
              </tr>
              <tbody class="post-menu scroller-1" [ngStyle]="{'height':'calc(95vh - 260px)' }">
                <ng-template [ngIf]="!loadingPosts && !searching">
                  <div style="margin-top: 130px;"><app-spinner></app-spinner></div>
                </ng-template>
                <ng-template [ngIf]="loadingPosts && !searching">
                  <ng-template [ngIf]="posts.length == 0 && pageNum!=0">
                    <tr>
                      <h3>Cette page est vide !</h3>
                    </tr>
                  </ng-template>
                  <ng-template [ngIf]="posts.length == 0 && pageNum==0 && lot.archiId==archiId">
                  <tr>
                    <h3>Ce dossier est vide. <a (click)="openSidePostEvent('add post',{})">Ajoutez un premier poste</a></h3>
                  </tr>
                  </ng-template>
                  <ng-template [ngIf]="posts.length == 0 && pageNum==0 && lot.archiId!=archiId">
                    <tr>
                      <h3>Ce dossier est vide.</h3>
                    </tr>
                  </ng-template>
                  <div [dragulaModel]="posts" (dragulaModelChange)="changePostOrder($event)" dragula="POSTS" *ngIf="showTable && lot">
                    <!-- (click)="openSidePostEvent($event.target.id,post)" -->
                    <tr *ngFor="let post of posts;let posti = index;trackBy:trackById" [attr.id]="posti" class="click-post">
                      <td style="position: relative;" (click)="openSidePostEvent($event.target.id,post)">
                          <input *ngIf="!authService.currentEnvironment&&lot && lot.archiId==archiId|| isAdministrator" type="checkbox" id="delete-post" (click)="addSelected(post)" [checked]="this.selectedValues.indexOf(post._id)!=-1">
                          <input *ngIf="authService.currentEnvironment&&lot && lot.archiId==archiId && isAdminArchitect || isAdministrator" type="checkbox" id="delete-post" (click)="addSelected(post)" [checked]="this.selectedValues.indexOf(post._id)!=-1">
                        </td>
                      <td (click)="openSidePostEvent($event.target.id,post)">
                        <div class="scrollable">
                          <i *ngIf="post.cctp" class="fa fa-file-text-o" style="position: absolute;top: calc(50% - 9px)"></i><p class="post-title" [ngStyle]="post.dateExpiration==null  ? { color:'black' } : post.dateExpiration==-2 ? { color:'blue' } : { color:'red' }">{{post.title}}</p>
                        </div>
                      </td>
                      <!-- <td class="centre">
                        <p class="post-unity">{{post.quantity}}</p>
                      </td > -->
                      <td class="centre" (click)="openSidePostEvent($event.target.id,post)">
                        <p class="post-unity">{{post.unity}}</p>
                      </td >
                      <td class="centre" (click)="openSidePostEvent($event.target.id,post)">
                        <p class="post-puht">{{post.puht | formatPrice}} €/u</p>
                      </td>
                      <td style="text-align: end;">                      
                        
                        <div class="dropdown">
                          <div class="more-dropdown" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i class="fa fa-ellipsis-v click-delete" style="margin:0px 20px 10px 0px;padding: 10px;"></i>
                          </div>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li id="delete" (click)="$event.stopPropagation();deletePost(post._id)">
                              <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i>
                              <span class="menu-text">Supprimer</span>
                            </li>
                            <li id="delete" (click)="$event.stopPropagation();displayMovePost(post._id)">
                              <i class="fa fa-share-square-o click-delete-sommaire" aria-hidden="true"></i>
                              <span class="menu-text">Déplacer vers...</span>
                            </li>
                            <li id="delete" (click)="$event.stopPropagation();duplicatePost(post)">
                              <i class="fa fa-clone click-delete-sommaire" aria-hidden="true"></i>
                              <span class="menu-text">Dupliquer</span>
                            </li>
                          </ul>
                        </div>
  
                        <!-- <i *ngIf="lot && lot.archiId==archiId" id="delete" class="fa fa-ellipsis-v click-delete" style="margin:0px 26px 10px 0px;padding: 4px;" aria-hidden="true" (click)='showDropdown(posti)' aria-haspopup="true"></i>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu3" [id]="'action-dropdown-'+posti" style="top: 25px;width: 130px;left:-51px">
                          <li id="delete" (click)="$event.stopPropagation();deletePost(post._id)">
                            <i class="fa fa-trash click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text">Supprimer</span>
                          </li>
                          <li id="delete" (click)="$event.stopPropagation();displayMovePost(post._id)">
                            <i class="fa fa-share-square-o click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text">Déplacer vers...</span>
                          </li>
                          <li id="delete" (click)="$event.stopPropagation();duplicatePost(post)">
                            <i class="fa fa-clone click-delete-sommaire" aria-hidden="true"></i>
                            <span class="menu-text">Dupliquer</span>
                          </li>
                        </ul> -->
  
                        <!-- <i *ngIf="lot && lot.archiId==archiId" class="fa fa-trash click-delete" id="delete" aria-hidden="true" (click)="deletePost(post._id)" style="margin:0px 30px 10px 0px"></i> -->
                        <i *ngIf="lot && lot.archiId!=archiId && !isAdmin && isAdminArchitect" class="fa fa-share click-delete" id="delete" aria-hidden="true" (click)="setPosteToAdd(post)" style="margin:0px 25px 6px 54px"></i>
                      </td>
                    </tr>
                  </div>
                </ng-template>
                <ng-template [ngIf]="searching">
                  <tr *ngFor="let post of postsSearch;let posti = index;trackBy:trackById" [attr.id]="posti" class="click-post" (click)="openSidePostEvent($event.target.id,post)">
                    <td style="position: relative;">
                        <input *ngIf="lot && lot.archiId==archiId" type="checkbox" id="delete-post" (click)="addSelected(post)" [checked]="this.selectedValues.indexOf(post._id)!=-1">
                    </td>
                    <td>
                      <div class="scrollable">
                        <i *ngIf="post.cctp" class="fa fa-file-text-o" style="position: absolute;top: calc(50% - 9px);"></i><p class="post-title">{{post.title}}</p>
                      </div>
                    </td>
                    <td class="centre">
                      <p class="post-unity">{{post.unity}}</p>
                    </td >
                    <td class="centre">
                      <p class="post-puht">{{post.puht}} €/u</p>
                    </td>
                    <td style="text-align: end;">
                      <i *ngIf="lot && lot.archiId!=archiId" class="fa fa-share click-delete" id="delete" aria-hidden="true" (click)="setPosteToAdd(post)" style="margin:0px 30px 10px 0px"></i>
                      <i *ngIf="lot && lot.archiId==archiId" class="fa fa-trash click-delete" id="delete" aria-hidden="true" (click)="deletePost(post._id)" style="margin:0px 30px 10px 0px"></i>
                    </td>
                  </tr>
                </ng-template>
                <ng-template [ngIf]="searching && !loadingPostsSearch && postsSearch.length == 0">
                  <tr>
                    <h3>Aucun résultat.</h3>
                  </tr>
                </ng-template>
              </tbody>
              <!-- <hr > -->
              <tfoot>
                <div *ngIf="!authService.currentEnvironment && lot && lot.archiId==archiId || authService.currentEnvironment && lot && lot.archiId==archiId && isAdminArchitect || isAdministrator" class="add-button-container">
                  <button class="sommaire-pill bottom-button" (click)="openSidePostEvent('add post',{})" style="margin-left: 0px;width: 48%;">Ajouter un poste &nbsp; <i class="fa fa-plus"></i></button>
                  <button class="sommaire-pill bottom-button dropdown-toggle" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Importer un poste &nbsp; <i class="fa fa-cloud-download"></i></button>
                  <ul class="dropdown-menu dropdown-import">
                    <li (click)='displaySideCstbE()' style="cursor: pointer;">
                      <span class="menu-text" >Importer depuis Excel</span>
                    </li>
                    <li (click)="displaySideDbF()" style="cursor: pointer;">
                      <span class="menu-text">Importer depuis les bibliothèques &nbsp;</span> <i class="fa fa-cloud"></i>
                    </li>
                    <li (click)='displaySideCstbF()' style="cursor: pointer;">
                      <span class="menu-text" >Importer depuis </span> <img src="assets/dpgf/cstb.jpg" width="20%">
                    </li>
                  </ul>
                  <i *ngIf="selectedValues.length && lot && lot.archiId==archiId" class="fa fa-trash fa-2x trash-icon" style="position:absolute;left: 61%;top: 5px;" (click)="deleteListPost()"></i>
                  <i *ngIf="selectedValues.length && lot && lot.archiId==archiId" class="fa fa-share-square-o trash-icon" style="position:absolute;left: 67%;top: 7px;font-size: 2em;" (click)="moveManyPosts()"></i>
                  <i *ngIf="selectedValues.length && lot && lot.archiId==archiId" class="fa fa-clone trash-icon" style="position:absolute;left: 73%;top: 8px;font-size: 1.7em;" (click)="duplicateMultiPost()"></i>
                </div>
                <div class="pagination" style="margin-bottom: 2px;margin-top: 3px;">
                  <a>&laquo;</a>
                  <a (click)="getPosts(lot._id,i)" *ngFor="let p of numOfPages;let i = index" [ngClass]="(i==pageNum)?'active':''">{{p+1}}</a>
                  <a>&raquo;</a>
                </div>
              </tfoot>
            </table>
          </div>
      </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="isInRoot" class="main-page">
    <p class="main-title">Bibliothèques</p>
    <div class="db-container scroller-1">
      <div class="spinner-holder-root" *ngIf="loadingLots">
        <app-spinner></app-spinner>
      </div>
      <div *ngIf="!loadingLots" class="sub-db">
        <div class="shared-folders">
          <div class="one-db" *ngFor="let db of rootLots;let i = index" (click)="setFirstLot(i)">
            <i class="fa fa-folder sub-icon" aria-hidden="true" style="font-size: 7rem;"></i>
            <p class="sub-title">{{(i==0)? ((isAdmin)?isAdmin:'Ma bibliothèque'):db.archiName}}</p>
          </div>
          <div *ngIf="!authService.currentEnvironment" class="reef-container">
            <div>
            <div style="padding-bottom:16px;padding-top: 20px;">
              <img src="/assets/archi/reef.svg" style="padding-right: 10px;"/>
              <span class="title">Accès au REEF</span>
            </div>
            <p class="sub-title">Accédez au REEF depuis AGLO afin d’aller plus loin dans l'expertise de vos pièces écrites</p>
            <button class="login-reef" (click)="goToReef()">Me connecter</button>
          </div>
  
            <!-- <div *ngIf="!userData?.hasReef" class="flex">
              <img src="/assets/archi/reef.svg" style="margin-right:6px;margin-left: 24px;"/>
              <button class="ag-btn-secondary" style="height: 28px;padding-top: 4px;" (click)='redirectReef()'>Voir l’offre</button>
            </div>
            <div *ngIf="userData?.hasReef" class="flex">
              <img src="/assets/archi/reef.svg" style="margin-right:6px;margin-left: 24px;"/>
              <button class="ag-btn-secondary" style="height: 28px;padding-top: 4px;" (click)='goToReef()'><i class="fa fa-external-link"></i>Y accéder</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Collaborate Modal -->
  <div id="collab-modal" class="modal fade" role="dialog">
    <div class="modal-dialog" style="width: 47%;">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <p class="modal-title">Mes partages</p>
        </div>
        <div class="modal-body">
          <app-archi-collab-modal [lot]=(lot) [collabs]=(collabs)></app-archi-collab-modal>
        </div>
        <div class="modal-footer" style="display:none"></div>
      </div>
    </div>
  </div>
  <!-- upgrade Modal -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>
  <p-sidebar [(visible)]="displaySidePost" position="right" autoZIndex="false" [style]="{'overflow': 'hidden','width':'70rem'}" styleClass="p-sidebar-lg">
    <app-archi-post-slider [architectID]="archiId" [post]=(post) [lots]=(lots) [lot]=(lot) [adding]=(adding) [userData]=(userData) [firstTime]=(firstTime) [pathArray]=(pathArray) [searching]=(searching) [isPost]=(isPost) (newPostEvent)="newPostEvent($event)" (updateLotEvent)="updateLotEvent($event)" (newLotEvent)="newLotEvent($event)" (updatePostEvent)="updatePostEvent($event)" (closeThePostSlider)="closeSidePost($event)"></app-archi-post-slider>
  </p-sidebar>
  <p-sidebar [(visible)]="displaySideCstb" position="right" autoZIndex="false" (onHide)="closeSideCstb()" [style]="{width:'70rem'}">
    <div class="side-header">
      <div class="row" style="display: flex;">
        <h2 class="center">
          <i class="click-it fa fa-times" aria-hidden="true" (click)="closeSideCstb()"></i>
          CSTB
        </h2>
        <button *ngIf="!loadingCstb && !searchTerm" type="button" class="ag-btn-primary-sm import-btn" (click)="importCstbPostes()" name="button" style="width: 100px;margin-left: auto;background-color:#0c1c46;">
          Importer
        </button>
        <button *ngIf="!loadingCstb && searchTerm" type="button" class="ag-btn-primary-sm import-btn" (click)="importCstbPostese()" name="button" style="width: 100px;margin-left: auto;background-color:#0c1c46;">
          Importer
        </button>
        <app-spinner class="spinner-import" *ngIf="loadingCstb"></app-spinner>
      </div>
    </div>
    <div class="cstb-content">
      <div *ngIf="licence=='premium' || licence=='premium-annuel' || licence=='premiumn' || licence=='premiumn-trim' || licence=='premiumn-annuel'">
        <input class="input-search" [(ngModel)]="searchTerm" (keyup)='searchPost()'>
        <i class="fa fa-search search-icon-cstb" (click)='searchPost()'></i>
      </div>
      <div *ngIf="searchTerm">
        <p class="result-title">Resultats de <b>{{searchTerm}}</b> :</p>
        <div class="post-container" *ngFor="let post of results;let i=index">
          <input type='checkbox' (click)='selectedPost(post)'>
          <p class="post">{{post.sugg}}</p>
        </div>
      </div>
      <app-cstb-navigation *ngIf="!searchTerm" manySelect="true" (selectedCstbEvent)="selectPosteCstb($event)"></app-cstb-navigation>
    </div>
  </p-sidebar>
  <div class="modal fade modal-dpgf" id="import-post" >
    <div class="modal-dialog">
      <div class="modal-container" style="height:fit-content">
        <!-- <div class="head">
        </div> -->
        <div class="row">
          <div class="col-md-11">
            <h3 class="modal-header" style="padding: 0px 0px 10px 0px;">Exporter un poste vers ma bibliothèque</h3>
          </div>
          <a data-dismiss="modal" class="col-md-1 close"><i class="fa fa-times" aria-hidden="true"></i></a>
        </div>
        <app-archi-db-post *ngIf="showMaBiblioModal" [isDPGF]='false' [firstTime]="true" [post]="postArchiDb" [isDCE]="true"></app-archi-db-post>
      </div>
    </div>
  </div>
  <div class="modal fade" id="upload-post" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Importer des postes depuis excel</h4>
        </div>
        <app-archi-post-upload [lot]="lot" (uploadedPosts)='uploadedPosts()'></app-archi-post-upload>
      </div>
  
    </div>
  </div>
  <div *ngIf="lot" class="modal fade" id="move-modal" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Choisissez le lot pour déplacer les postes</h4>
        </div>
        <div *ngIf="!movingPost" style="margin: 24px;padding-bottom: 24px;">
          <app-archi-db-post [firstTime]="true" [isDCE]="false" [hideMyDb]="true" (chosenLotEvent)='setChosenLot($event)'></app-archi-db-post>
        </div>
        <div *ngIf="movingPost" style="margin-top: 24px;margin-bottom: 24px;">
          <app-spinner ></app-spinner>
        </div>
      </div>
    </div>
  </div>
  <p-sidebar [(visible)]="displaySideDb" position="right" (onHide)="closeSideDb()" [style]="{'overflow-y': 'auto','overflow-x':'hidden'}" styleClass="p-sidebar-lg">
    <div class="side-header">
      <div class="row">
        <h2 class="center">
          <i class="click-it fa fa-times" aria-hidden="true" (click)="displaySideDb=false"></i>
          Bibliothèques
          <button *ngIf="!loadingCstb" type="button" class="ag-btn-primary-sm btn-valdiate" style="float: right;margin-left: auto;width: 11%;position: relative;bottom: 8px;" name="button" (click)="importArchiDbPostes()">
            Importer
          </button>
          <app-spinner class="spinner-import" *ngIf="loadingCstb" style="position: relative;bottom: 8px;height: 37px;"></app-spinner>
        </h2>
      </div>
    </div>
    <div *ngIf="!reloadArchiNavigation" class="cstb-content">
      <app-archi-db-navigation [firstTime]="true" [archiDbLot]="lot"></app-archi-db-navigation>
    </div>
  </p-sidebar>
  
  <p-confirmDialog key="lot"></p-confirmDialog>
  <p-confirmDialog key="post"></p-confirmDialog>
  <p-confirmDialog key="deletePosts"></p-confirmDialog>
  <p-confirmDialog key="duplicatePosts"></p-confirmDialog>
  
  
  <app-max-library-posts-modal [showLibraryText]="true"></app-max-library-posts-modal>