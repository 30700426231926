import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-on-pro-license-post-project-modal',
  standalone: true,
  imports: [],
  templateUrl: './user-on-pro-license-post-project-modal.component.html',
  styleUrl: './user-on-pro-license-post-project-modal.component.scss'
})
export class UserOnProLicensePostProjectModalComponent {

  constructor(
    private router: Router
  ){

  }

  public redirectToLicensesPage(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }

}
