import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Subscription } from 'rxjs';
// Services Imports
import { CeProjectService,AuthService,ErrorService,CeProjectInvitesService } from '../../../_services/index';
import {ConfirmationService} from 'primeng/api';
import { DpgfPostesService, ArchitectService, AttributService } from '../../../_services/index';
import { DragulaService } from 'ng2-dragula';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { CeProjectArtisanComponent } from 'src/app/dpgf/dce-artisan-management/ce-project-artisan/ce-project-artisan.component';
import { CeProjectArtisanLotComponent } from 'src/app/dpgf/dce-artisan-management/ce-project-artisan-lot/ce-project-artisan-lot.component';
import { RdvCalendarComponent } from 'src/app/all/rdv-calendar/rdv-calendar.component';
import { CeProjectAddArtisanComponent } from 'src/app/dpgf/dce-artisan-management/ce-project-add-artisan/ce-project-add-artisan.component';
import { BoosterMyConsultationPopupComponent } from 'src/app/dpgf/dce-artisan-management/booster-my-consultation-popup/booster-my-consultation-popup.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { EnterprisesInviteColumnsComponent } from 'src/app/architect/entreprises/entreprises-tab/enterprises-invite-columns/enterprises-invite-columns.component';
import { ChooseAgencyModalComponent } from 'src/app/architect/entreprises/choose-agency-modal/choose-agency-modal.component';
import { UpperInfoBarComponent } from 'src/app/architect/entreprises/upper-info-bar/upper-info-bar.component';

// New
import { SendDceToMultiplePopupComponent } from 'src/app/dpgf/dce-artisan-management/send-dce-to-multiple-popup/send-dce-to-multiple-popup.component';
import { SendMailToMultipleArtisansComponent } from 'src/app/dpgf/dce-artisan-management/send-mail-to-multiple-artisans/send-mail-to-multiple-artisans.component';
import { CreateArtisanFacticeComponent } from 'src/app/dpgf/dce-artisan-management/create-artisan-factice/create-artisan-factice.component';

// Helpers
import { Helpers } from 'src/app/_services/helpers';

import { IdentifySegement } from 'src/app/all/segment/identify';

import { environment } from 'src/environments/environment';
import { NewArchitectArtisansPopupComponent } from 'src/app/dpgf/dce-artisan-management/new-architect-artisans-popup/new-architect-artisans-popup.component';

declare var $;
declare var only;

@Component({
    selector: 'app-archi-ce-project-artisan',
    imports:[      
      HeaderComponent,
      CeProjectNavComponent,
      SpinnerComponent,
      CeProjectArtisanComponent,
      CeProjectArtisanLotComponent,
      RdvCalendarComponent,
      CeProjectAddArtisanComponent,
      BoosterMyConsultationPopupComponent,
      NeedSubscriptionPopup2Component,
      EnterprisesInviteColumnsComponent,
      ChooseAgencyModalComponent,
      UpperInfoBarComponent,
      SendDceToMultiplePopupComponent,
      SendMailToMultipleArtisansComponent,
      CreateArtisanFacticeComponent,
      NewArchitectArtisansPopupComponent,
      NgSharedModule
    ],
    templateUrl: './archi-ce-project-artisan.component.html',
    styleUrls: ['./archi-ce-project-artisan.component.scss'],
    standalone: true,

})
export class ArchiCeProjectArtisanComponent implements OnInit {
  @ViewChild("artisanPopup") artisanPopup : NewArchitectArtisansPopupComponent;
  @Output()refresh = new EventEmitter<boolean>();
  public addType ='';
  public choosenLot: any;
  public quickLinks:any=[];
  public invites : Array<any> = [];
  public artisans:any = [];isProjectDemo= false;
  public popUpArtisan:any = {artisan : {}};
  public lots:any = [];
  public collabs:any = [];
  public allArtisans = {
    "a_consulter": [],
    "en_attente": [],
    "en_cours": [],
    "offre_remise": [],
    "ne_chiffre_pas": [],
  };
  public artisansFiltered: boolean = false;
  
  public architectId:string = "";
  public projectId:string = "";
  public projectTitle:string = "";

  // NEW CONCEPT
  public allTags: Array<any> =[];
  public sendDceToMultiArtisans:any = [];
  public sendMailToMultiArtisans:any = [];
  public sendDceToMultiType:string = '';
  public dpgfLots: any = [];
  public manyDceLoading:boolean = false;
  public sumittingRequest:boolean = false;
  public resetValues:boolean = false;
  public consultedEnterprisesCount: number = 0;

  public currentRelationsPage: any = 1;
  public relationsList: any = [];
  public relationsListNumberOfPages: any = 1;
  public showSendDCEIcon:boolean = false;
  public showUpdateDCEIcon:boolean = false;
  public showSendMailIcon:boolean = false;
  // END NEW CONCEPT

  // architect emails templates
  public forwarderName:string = "";
  public architectEmailTemplates: any = [];

  
  public dpgfId:string = "";
  // public view:boolean = false;
  public view:string = "enterprises"; // enterprises, lots
  public canAdd:boolean = true;
  public dateDce;
  public loadingData:boolean = true;
  public loadingDPGF:boolean = true;
  public setLoading:boolean = false;
  public rdvDispo:any = [];
  public project:any = {};
  public dpgfLot:any = [];
  public dpgf:any = {};
  private subscriptions: Array<Subscription> = [];
  public  openArchitectArtisanPopUP: boolean=false;
  public numberOfPricingLots: any;
  constructor(private title: Title,
              private router: Router,
              private ceProjectService: CeProjectService,
              private ceProjectInvitesService : CeProjectInvitesService,
              private authService : AuthService,
              private DpgfPostesService : DpgfPostesService,
              private architectService: ArchitectService,
              private attributService: AttributService,
              private route: ActivatedRoute,
             // private confirmationService: ConfirmationService,
              private ErrorService: ErrorService,
              private dragulaService: DragulaService,
              private segmentIdentify: IdentifySegement,
              private cd: ChangeDetectorRef,
              ) {
  }

  ngOnInit() {
    this.noMultiAccountsAllowed();
    this.getCurrentRouteParams();

    this.allTags = this.attributService.getRelationTags();
    
    this.getRdvDispo();
    this.searchEnterprises(); // load relations list
    this.title.setTitle("Aglo -  Mes Entreprises" );
    // segment page event
    if(!environment.onPremise)window.analytics.page('pageview_entreprise');
  }
  private getCurrentRouteParams(){
    this.route.params.forEach((params: Params) => {
      if (params['projectId']) {
        this.projectId =params['projectId']
      }
    })
  }

  public updateInvitation(event){
    this.artisans.forEach((invitation)=>{
      if(invitation._id==event.invitationId){
        invitation=event.invitation;
      }
    })
  }
  //Get PRoject Data
  public getRdvDispo() {
    this.ceProjectService.getProject(this.projectId)
    .subscribe(
        data => {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.canAdd = currentUser.archiId == data.architect._id || currentUser.isAdmin;
          this.architectId = currentUser.archiId;

          this.rdvDispo = data.rdvDispo || [];
          if(data.dateDce)this.dateDce = new Date(data.dateDce);
          this.project = data;
          this.projectTitle = data["title"]; 
          if((data.dpgf||{})._id)this.getDpgf(data.dpgf._id);
          else {
            this.loadingData = false;
            // this.getInvite()
          }

          this.loadAuthArchitectEmailsTemplates();
        },
        error => {
            console.log("error",error)
            this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
        })
  }
  public getDpgf(dpgfId){
    this.DpgfPostesService.getDpgf(dpgfId)
    .subscribe(
        data => {
          this.loadingDPGF = false;
          this.dpgf = data;
          let dpgfLot =  data.data.map(lot=>{
            return {
              id:lot.id,
              name:lot.title, 
              indexLot : lot.indexLot, 
              requested:0,
              icon : (lot.icon||""),
              totalArchi: lot.totalArchi
              
            }
          }).filter(lot => lot.name != "")
          dpgfLot.unshift({id:"general",name:"Tout corps d'états",requested:0, icon : "facades.png"});
          this.dpgfLot =  dpgfLot;
          this.getInvite()
        },
        error => {
            console.log("error",error)
            this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
        })
  }
  public refreshAll(){
    this.refresh.emit(true);
  }
  public getInvite(){
    this.loadingData = true;
    this.openArchitectArtisanPopUP=false;
    this.subscriptions.push(
      this.ceProjectInvitesService.getCeInviteListOfCeProject(String(this.projectId))
          .subscribe(
              data => {
                this.collabs = data.collaborateurs;
                this.artisans = data.ceProjectInvite || [];
                this.isProjectDemo = data.isProjectDemo || false
                this.loadingData = false;
                this.filterArtisan();
                this.openArchitectArtisanPopUP=true;
                this.filterLot();
                this.loadingData = false;
              },
              error => {
                  console.log("error",error)
                  this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
              })
            )
  }
  public filterArtisan(){
    this.allArtisans = {
      "a_consulter": [],
      "en_attente": [],
      "en_cours": [],
      "offre_remise": [],
      "ne_chiffre_pas": [],
    };
    const helpers = new Helpers();

    const dpgfVersion = this.dpgf.currentVersion;

    for(var i = 0 ; i < this.artisans.length ; i++){
      const { pricingStatus, status, isFactice, dpgfPricing } = this.artisans[i];

      // fill columns data
      const newProjectInviteStatus = helpers.getCeProjetInviteStatus(status, pricingStatus);
      if(this.allArtisans[newProjectInviteStatus]){
        this.allArtisans[newProjectInviteStatus].push(this.artisans[i]); 
      }

      let needDCEUpdate = false;
      if(dpgfPricing){
        const { currentVersion } = dpgfPricing;
        needDCEUpdate = currentVersion != dpgfVersion ? needDCEUpdate = true : false;
      }

      // show : send DCE, Update DCE, SEND MAIL icons
      if(pricingStatus == "no-pricing"){
        this.showSendDCEIcon = true;
      }
      if(needDCEUpdate && ["pricing-sent", "pricing-done"].includes(pricingStatus)){
        this.showUpdateDCEIcon = true;
      }
      if(!isFactice && ["pricing-sent", "pricing-done"].includes(pricingStatus)){
        this.showSendMailIcon = true;
      }

    }

    this.SetConsultedEnterprisesCount();
    this.artisansFiltered = true;
  }

  private SetConsultedEnterprisesCount(){
    const { a_consulter, en_attente, en_cours, offre_remise, ne_chiffre_pas } = this.allArtisans;

    const totalCount = a_consulter.length + en_attente.length + en_cours.length + offre_remise.length + ne_chiffre_pas.length;

    this.consultedEnterprisesCount = totalCount;
  }
  public filterLot(){
    let lots = JSON.parse(JSON.stringify(this.dpgfLot));
    lots.map(lot=>{
      let invites = this.artisans.filter(artisan=>{
        let artisanLots = artisan.lots || [];
        let filterdArtisanLots = artisanLots.filter(artisanLot=>{return artisanLot.id == "general" || artisanLot.id == lot.id});
        return (filterdArtisanLots.length != 0) || (artisanLots.length == 0)
      });
      lot.invites = invites.map(arti=> {
        let invite = only(arti ,"_id artisan accepted dpgfSent pricingStatus rdvTaken status dpgfPricing ceProject artisanName lots signedLots artisan architect isFactice createdAt deliveryDate refusalDate sentPricingDate dateDceSent facticeArtisanInfo");
        invite.styleClass = this.getClass(invite);
        return invite
      });

      return lot;

    })
    this.lots = lots;
  }
  public getClass(invite) {
    let styleClass = "";
    if(invite.pricingStatus == "pricing-sent")styleClass = "orange-pill";
    if(invite.pricingStatus == "no-pricing")styleClass = "red-pill";
    if(invite.pricingStatus == "pricing-done")styleClass = "green-pill";
    if(invite.status == "signe")styleClass = "yellow-pill";

    return styleClass;
  }
  //Setters
  public setProjectData(){
    this.setLoading = true;
    let projectData = {"rdvDispo":this.rdvDispo, dateDce : this.dateDce};
    this.subscriptions.push(
      this.ceProjectService.updateRdv(this.projectId,projectData)
        .subscribe(
            () => {
                this.setLoading = false;
                $("#manage-dispos").modal('toggle');
            },
            error => {
                this.setLoading = false;
                this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                console.log("error",error)
            })
          );
  }
  public setNewDate(projectData){
    if(this.authService.licence == "without" && this.project.licence=='none'){
      // $('#upgrade-modal').modal('show');
      $("#user-on-without-license-modal").modal('show');
	    return;
    }
    
    this.subscriptions.push(
      this.ceProjectService.update(this.projectId,projectData)
        .subscribe(
            () => {
            },
            error => {
                this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                console.log("error",error)
            })
          );
  }
  //Navigation
  public gotoDash(){
    this.router.navigate(['/architect/ce-project/'+this.projectId]);
  }
  public changeView(newView){
    this.view = newView;
    if(!environment.onPremise && this.view == "lots")window.analytics.track('tabview_lots',{properties:{acValue: 'tabview_lots'}});
  }

  public setAddType(lot, type){
    this.choosenLot = lot;
    this.addType = type;
    $("#add-artisan").modal('toggle');
  }
  
  public closeModal(){
    $("#add-artisan").modal('toggle');
  }
  public closeBoosterModal(){
    $("#booster-my-consultation-modal").modal('toggle');
  }
  openBoostEvent(){
    if(!environment.onPremise)window.analytics.track('boost_consultations',{properties:{acValue: 'boost_consultations'}});
  }
  public openCreateChiffrageModal(){
    if(this.authService.licence == "without" && this.project.licence=='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    $('#factice-artisan-popup').modal('toggle');
  }
  public openAddEnterpriseModal(){
    if(this.authService.licence == "without" && this.project.licence=='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    $("#add-artisan").modal("toggle");
  }  
  public openSendDceToMultipleArtisansModal(){
    if(this.authService.licence == "without" && this.project.licence=='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    this.sendDceToMultiArtisans = this.allArtisans.a_consulter;
    this.sendDceToMultiType = "create";
    $('#send-mutiple-dce-popup').modal('toggle');
  }
  public openUpdateDceForMultipleArtisansModal(){
    if(this.authService.licence == "without" && this.project.licence=='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    const { en_attente, en_cours, offre_remise } = this.allArtisans;
    
    // en_cours or offre_remise that don't have the last indice of DPGF
    const dpgfVersion = this.dpgf.currentVersion;

    this.sendDceToMultiArtisans = [...en_attente, ...en_cours, ...offre_remise];
    this.sendDceToMultiArtisans = this.sendDceToMultiArtisans.filter(invite => invite.dpgfPricing.currentVersion !== dpgfVersion);

    this.sendDceToMultiType = "update";
    $('#send-mutiple-dce-popup').modal('toggle');
  }
  public openSendMailToMultipleArtisansModal(){
    if(this.authService.licence == "without" && this.project.licence=='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    const { en_attente, en_cours, offre_remise, ne_chiffre_pas } = this.allArtisans;

    this.sendMailToMultiArtisans = [...en_attente, ...en_cours, ...offre_remise, ...ne_chiffre_pas];
    // remove factice artisans
    this.sendMailToMultiArtisans = this.sendMailToMultiArtisans.filter(invite => !invite.isFactice);
    $('#send-mutiple-mail-to-atisans-popup').modal('toggle');
  }
  public sendDceToManyArtisans(body:any): void{
    this.manyDceLoading = true;
    this.subscriptions.push(
      this.DpgfPostesService.sendMultiDCE(this.dpgf._id, body)
        .subscribe(
          data => {
          if(!environment.onPremise)window.analytics.track('dce_sent_grouped',{properties:{acValue: 'dce_sent_grouped'}});
            $('#send-mutiple-dce-popup').modal('hide');
            this.manyDceLoading = false;
            this.getRdvDispo();
            this.getInvite();
          },
          error => {
            this.manyDceLoading = false;
          },
        )
    );
  }
  public updateDceForManyArtisans(body:any): void{
    this.manyDceLoading = true;
    this.subscriptions.push(
      this.DpgfPostesService.updateMultiDCE(this.dpgf._id, body)
        .subscribe(
          data => {
          if(!environment.onPremise)window.analytics.track('update_dce_grouped',{properties:{acValue: 'update_dce_grouped'}});
            $('#send-mutiple-dce-popup').modal('hide');
            this.manyDceLoading = false;
            this.getRdvDispo();
            this.getInvite();
          },
          error => {
            this.manyDceLoading = false;
          },
        )
    );
  }
  public sendMailsToManyArtisans(body){
    this.manyDceLoading = true;
    this.subscriptions.push(
      this.DpgfPostesService.sendMultiMailToArtisans(this.dpgf._id, body)
        .subscribe(
          data => {
            this.manyDceLoading = false;
            $('#send-mutiple-mail-to-atisans-popup').modal('hide');
            this.getRdvDispo();
            // this.getInvite();
          },
          error => {
            console.log("sendMailsToManyArtisans : ", error);
            this.manyDceLoading = false;
          },
        )
    );
  }
  public sendBoosterDemandeChiffrage(lots){
    this.sumittingRequest = true;
    if(!environment.onPremise)window.analytics.track('ask_for_companies',{properties:{acValue: 'ask_for_companies'}});
    
    this.subscriptions.push(
      this.ceProjectService.requestArtisan(this.projectId,{requestArtisans : lots, messageBody : ""})
      .subscribe((res) => {
        this.sumittingRequest = false;
        
        $("#add-artisan").modal("hide");
        this.resetValues = true;
        
        // this.getRdvDispo();
        this.getInvite();
        // segment
        if(!environment.onPremise)this.segmentIdentify.identifyNumRequestsArtisan(res)
      },
      err => {
        this.sumittingRequest = false;
        console.log(err);
        // this.submitting= false;
        $("#add-artisan").modal("hide");
        this.ErrorService.manageError(err,"");
      }
      )
    )
  }
  public addAgency(body){
    this.sumittingRequest = true;
    //Création de l'artisan et mise à jour du ceProject
    if(body.artisanId){
      this.subscriptions.push(
        this.ceProjectInvitesService.createNewArtisanByArchi(body, this.projectId)
        .subscribe((data) => {
          this.sumittingRequest = false;
          $("#add-artisan").modal("hide");
          this.resetValues = true;
          if(!environment.onPremise && this.view == "lots")window.analytics.track('add_company_from_lots',{properties:{acValue: 'add_company_from_lots'}});
          if(!environment.onPremise && this.view == "enterprises")window.analytics.track('add_company',{properties:{acValue: 'add_company'}});
          this.getRdvDispo();
          // this.getInvite();
        },
        err => {
          this.sumittingRequest = false;
          console.log("addAgency : ", err);
          this.ErrorService.manageError(err,"");
        }
      )
    )
    }
  }
  public addToCarnetAddress(body){
    this.sumittingRequest = true;
    this.architectService.addCarnetAdressesArtisan(body)
    .subscribe(data => {
          this.sumittingRequest = false;
          $("#add-artisan").modal("hide");        
          this.resetValues = true;
          // this.getRdvDispo();
          this.getInvite();
          // this.messageService.add({ key: 'toast', severity: 'success', summary: "Ajout d'une entreprise", detail: "L'entreprise a été invitée dans votre réseau", life: 5000 });
    },
    err => {
      this.sumittingRequest = false;
      this.ErrorService.manageError(err,"");
    }
    );
  }
  public searchEnterprises(event=undefined){
    let body ={
      searchQuery: "",
      tags: []
    }
    let PAGE = 1;
    let LIMIT = 20;

    if(event){
      if(event["page"]){
        PAGE = event["page"];
      }
      if(event["searchQuery"]){
        body["searchQuery"] = event["searchQuery"];
      }
      if(event["page"]){
        body["tags"] = event["tags"];
      }
    }

    this.subscriptions.push(
      this.architectService.searchEntreprise(body, PAGE, LIMIT)
      .subscribe(
        data => {
          this.relationsList = data.data.relations;
          let totalCount = data.metadata.count;
          this.relationsListNumberOfPages = Math.ceil((totalCount / LIMIT));
        },
        err => {
          this.ErrorService.manageError(err,"");
        }
      )
    );
  }
  private loadAuthArchitectEmailsTemplates(){
    this.subscriptions.push(
      this.architectService.getArchiProfile(this.architectId)
      .subscribe(
        data => {
          // Email Templates
          this.forwarderName = data.emailsTemplatesSenderName||"";
          this.architectEmailTemplates = data.emailsTemplates||[];
        },
        err => {
          this.ErrorService.manageError(err,"");
        }
      )
    );
  }

  public openArtisanModal({invitation, column,numberOfPricingLots,openArchitectArtisanPopUP}){
    this.popUpArtisan = invitation;
    this.popUpArtisan.col = column;
    this.numberOfPricingLots=numberOfPricingLots;
    this.openArchitectArtisanPopUP=openArchitectArtisanPopUP;
    $("#artisanModal").modal('show');
  }
  
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
          }  
        )
    );
  }
  public ngOnDestroy(): void {
    this.dragulaService.destroy("invites") 
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
