import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router} from '@angular/router';

// Components
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { ArchitectEmailsTemplatesDropdownComponent } from 'src/app/architect/dce/architect-emails-templates-dropdown/architect-emails-templates-dropdown.component';

// Libraries
import { Subscription } from 'rxjs';
import * as JSZip from 'jszip';

// Modules
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Editor } from 'src/app/all/editor/editor.component';
// Services
import { CeProjectInvitesService, DpgfPostesService, ArchitectService, ErrorService, AuthService } from 'src/app/_services';
import { MessageService } from 'primeng/api';

import { environment } from 'src/environments/environment';
import { LoggsClass } from '../architect-artisans-popup/loggs';
import { formatDate } from '@angular/common';
import { CustomFormatDatePipe } from 'src/app/_pipe/custom-format-date.pipe';
import { CalendarModule } from 'primeng/calendar';
import { ModalLotsListComponent } from '../modal-lots-list/modal-lots-list.component';
import { DateFormatPipe } from 'src/app/_pipe/date-format.pipe';

// Helpers
import { EmailTemplatesHelpers } from 'src/app/_services/emailTemplatesHelpers';
import { PrimeNGConfig } from 'primeng/api';

declare var $;
@Component({
  selector: 'app-new-architect-artisans-popup',
  standalone: true,
  imports: [    
    DpgfModule,
    SpinnerComponent,
    ArchitectEmailsTemplatesDropdownComponent,
    NgSharedModule,     
    Editor,
    CustomFormatDatePipe,
    //DateFormatPipe,
    ModalLotsListComponent

  ],
  templateUrl: './new-architect-artisans-popup.component.html',
  styleUrl: './new-architect-artisans-popup.component.scss'
})
export class NewArchitectArtisansPopupComponent  implements OnInit, OnChanges{
  @Input()invitation: any = {
    artisan : {},
    status : ""
  };
  @Input()projectLicence:string;
  @Input()numberOfPricingLots:any;
  @Input()dpgf:any;
  @Input()isProjectDemo:Boolean = false;
  @Input()openArchitectArtisanPopUP:boolean;
  @Input()collabs:any;
  @Output() refresh = new EventEmitter<boolean>();
  //@Output() updatedInvitation = new EventEmitter<any>();
  @ViewChild('CommentTextArea') CommentTextArea;

  public selectedCollabs:any =[];
  public collabsList:any =[];
  public dpgfLot:any = [];
  public allTags: Array<Object> =[];
  public relationArtisanTags: any = [];
  public selectedTags: any = [];
  public selectedDceStatus:string = 'selectionne';
  //public showCreateMail:boolean = false;
  public removingInvite:boolean = false;
  public sendingDCE:boolean = false;
  public loadingEmails:boolean = false;
  public updateArtisanLots:boolean = false;
  public loadingCommentsAndActivities:boolean = false;
  public creatingComment:boolean = false;
  public showCollabDropdown:boolean = false;
  public disabledButtons:boolean = false;
  public showConfirmRemove:boolean = false;
  public sendingEmail:boolean=false;
  //public showMailInfoBar:boolean = false;
  public showQuantity:boolean = true;
  public newComment: any = '';
  public commentsAndActivities: any[] = [];
  public newEmail: any = {
    subject: '',
    message: ''
  };
  public facticeArtisanEmail: any = {
    email: '',
    subject: '',
    message: ''
  };
  public invalidFacticeMail: boolean = false;
  public loggs = (new LoggsClass).trasnform;
  public emailsList: any[] = [];
  public showFacticeMailForm: boolean = false;
  public showComentSection:boolean=true;
  public showEmailSection:boolean=false;
  public acceptBtnClicked:boolean=false;
  public refuseBtnClicked:boolean=false;
  public assignedLotsAsString: string='';
  public artisanHasLog:boolean;
  public dateValue : any;
  public enCours:any=["artisan-accepter","selectionne","refuse"]
  public offreRemise:any=["artisan-accepter", "selectionne","refuse", "signe","artisan-invite"]
  private subscriptions: Array<Subscription> = [];
  public dceURL: any;
  public relationArtisanTagsToString: string;
  public relationArtisanTagsLengh: boolean;
  public updateSelected:boolean=false;
  public showLotsListModal: boolean = false;
  public statusColors:any={
    "a_consulter": '#9CC7FF',
    "en_attente": '#77D1F3',
    "en_cours": '#FFD66E',
    "offre_remise": '#93E088',
    "ne_chiffre_pas": '#A1A9B8',
  }
  public openAddAssignedLotsModal:boolean=false;
  public showDropdown: boolean = false;
  public listOfStatusToChangeTo:any=[];
  public downloadAllVar: boolean;
  public updatedEmailMessage;
  public updatedEmailSubject;
  public isRefusedOrAcceptedStatusChanged: boolean=false;
  public facticeFormMessageError: boolean=false;
  public facticeFormSubjectError: boolean=false;
  public facticeFormMailError: boolean=false;
  public disabledFacticeButtons: boolean=true;
  public setdefaultUpdatedEmail: boolean=false;

  public ceProject: any = {};
  public architect: any = {};
  public artisan: any = {};

  // architect email templates
  public architectEmailTemplates:any = [];

  // helpers
  private emailTemplatesHelpers: any = null;

  constructor(
    private DpgfPostesService: DpgfPostesService,
    private ceProjectInvitesService: CeProjectInvitesService,
    private ErrorService: ErrorService,
    private cd: ChangeDetectorRef,
    private AuthService: AuthService,
    private architectService: ArchitectService,
    private router: Router,
    private el: ElementRef,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig
    ){}
  

  ngOnInit(): void { 
    this.modelClosedEvent();
    this.disabledButtons = this.dpgf.demo ? false : true;
    this.detectStatusDropdownOutsideClick();
    this.setLanguagePrimeNg()
  }
  ngOnChanges(changes: SimpleChanges){   
    this.artisanHasLog=(((this.invitation.artisan||{}).logo|| {}).cdnUrl)? true:false;
    if(changes.dpgf && changes.dpgf.firstChange){
      this.getDpgfLot();
    }
    if(changes.invitation && !changes.invitation.firstChange){
      let artisanId = changes.invitation.currentValue['artisan']['_id'];

      // emails templates variables
      this.architectEmailTemplates = changes.invitation.currentValue['architect'].emailsTemplates;
      this.ceProject = changes.invitation.currentValue['ceProject'];
      this.architect = changes.invitation.currentValue['architect'];
      this.artisan = changes.invitation.currentValue['artisan'];
      // END emails templates variables

      let currentStatus=changes.invitation.currentValue.status;
      this.getArtisanRelation(artisanId);
      this.loadLogs();
      this.loadMails();
      this.setDefaultMail();
      this.sortAssignedLotsByIndexLot(changes.invitation.currentValue.lots);
      this.arrayToString(changes.invitation.currentValue.lots)
      if(currentStatus == "selectionne"){ 
        $("#select-invitation").val(currentStatus);
        // segment event
        // if(!environment.onPremise)window.analytics.track('company_selected',{properties:{acValue: 'company_selected'}});
      }else if(currentStatus == "refuse"){
        $("#select-invitation").val(currentStatus);
        // segment event
        // if(!environment.onPremise)window.analytics.track('company_refused',{properties:{acValue: 'company_refused'}});
      }else if(currentStatus == "artisan-accepter"){
        $("#select-invitation").val(currentStatus);
        // segment event
        // if(!environment.onPremise)window.analytics.track('company_refused',{properties:{acValue: 'company_refused'}});
      }else{
        $("#select-invitation").val("artisan-invite");
      }
    }

    if(changes.invitation && !changes.invitation.firstChange){
      this.setSelectedTags(changes);
    }
    if(changes.collabs){
      this.collabsList = changes.collabs.currentValue;
    }
  }

  private sortAssignedLotsByIndexLot(assignedLotsArray){
     assignedLotsArray.sort((firstLot, secondLot)=> firstLot.indexLot - secondLot.indexLot)
     return assignedLotsArray;
  }
  private setLanguagePrimeNg(){
    this.primengConfig.setTranslation({
      accept: 'Accepter',
      reject: 'Rejeter',
      // Customize the other labels as needed
      dayNames: ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"],
      dayNamesShort: ["Dim","Lun","Mar","Mer","Jeu","Ven","Sam"],
      dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
      monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer',
      dateFormat: 'dd/mm/yy',
    }); 
  }
  private detectStatusDropdownOutsideClick(){
    let _SELF = this;
    $(document).mouseup(function(e){
      var container = $(".dropdown-status-list");
      if (!container.is(e.target) && container.has(e.target).length === 0){
        if(_SELF.showDropdown){
          _SELF.showDropdown = false;
        }
      }
    });
  }

  public contains(projectInvite, arrayOfString){
    return arrayOfString.includes(projectInvite);
  }
  public setSelectedTags(changes: SimpleChanges) {
    this.selectedTags = (changes.invitation.currentValue.lots||[]);
    this.selectedTags.forEach((tag) => {
      // if(tag.indexLot){
      //   delete tag.indexLot;
      // }
      if(tag.id == 'general' && !tag.icon){
        tag.icon = "facades.png";
      }
    });
  }
  public addCollab(collab){
    this.newComment = this.newComment.substr(0, this.newComment.length - 2);
    this.newComment += ` @${collab.firstName || collab.email} `;
    this.showCollabDropdown = false;
    this.CommentTextArea.nativeElement.focus();
    this.selectedCollabs.push(collab);

  }


  public setDefaultMail() {
    let inConsulterOrNeChiffrePasColumn=(this.invitation.col == 'ne_chiffre_pas'  || this.invitation.col == 'a_consulter');
    let dpgfPricingVersionChanged=this.invitation.dpgfPricing?._id && (this.invitation.dpgfPricing?.currentVersion != this.dpgf?.currentVersion)
    
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: this.artisan,
    };

    if(!this.emailTemplatesHelpers){
      this.emailTemplatesHelpers = new EmailTemplatesHelpers();
    }

    // sendDCE
    let sendDceSubject = "Dossier de Consultation des Entreprises : Demande de chiffrage";
    let sendDceMessage = `<p> <span> Bonjour ${this.invitation.artisan.name}, </span></p><p><span> Nous souhaitons vous consulter sur un nouveau projet: </span></p><ul><li> <span> Maître d’ouvrage: ${this.invitation.ceProject.title} </span></li><li> <span> Adresse: ${this.invitation.ceProject.adresse} , ${this.invitation.ceProject.addressCodePostal} , ${this.invitation.ceProject.addressCountry} </span></li></ul><p><span>Retrouvez plus de détails via le lien vers le dossier de consultation ci-dessous. Nous comptons sur votre réponse avant la date limite indiquée dans le DCE.</span> </p><p><span>Dans l’attente de votre réponse,</span> </p><p><span>Cordialement,</span> </p><p><span>${firstName} ${lastName}</span></p><p><span>${phone}</span> </p>`;

    // update Dce
    // let updateDceSubject = "";
    let updateDceMesage = `<p><span>Bonjour ${this.invitation.artisan.name},</span></p><p><br></p><p><span>Vous pouvez dès à présent accéder à la nouvelle version du DCE pour le projet ${this.invitation.ceProject.title} en cliquant sur le bouton “Chiffrer ce projet”.</span></p> <p><br></p> <p><span>Pour toute question, vous pouvez répondre à cet email ou bien nous contacter au ${phone}.</span></p><p><span>Cordialement,</span></p><p><span>${firstName} ${lastName}</span></p><p><span>${phone}</span></p>`;


  // { message: "<p>Bonjour %NOM_ENTREPRISE%,</p><p><br></p><p>Vous pouvez dès à présent accéder à la nouvelle version du DCE pour le projet %NOM_PROJET% en cliquant sur le bouton “Chiffrer ce projet”.</p><p><br></p><p>Pour toute question, vous pouvez répondre à cet email ou bien nous contacter au %TELEPHONE_MOE%.</p><p><br></p><p>Cordialement,</p><p>%PRENOM_MOE% %NOM_MOE%</p><p>%TELEPHONE_MOE%</p>", isDefault: true },


    // new Mail (relance)
    let newMailSubject = "";
    let newMailMessage = "";

    this.updatedEmailMessage=updateDceMesage;
    this.updatedEmailSubject= `Actualisation du DCE pour le projet ${this.invitation.ceProject.title}`;
    
    // if account email templates have Send DCE email set it as default
    let sendDceEmailTemplate = this.architectEmailTemplates.find(template => template.id == "email_template_send_dce");
    let updateDceEmailTemplate = this.architectEmailTemplates.find(template => template.id == "email_template_update_dce");
    let newEmailTemplate = this.architectEmailTemplates.find(template => template.id == "email_template_new_email");
    
    if(sendDceEmailTemplate != -1){
      const { subject, message } = sendDceEmailTemplate;
      sendDceSubject = subject;
      sendDceMessage = message;
    }
    if(updateDceEmailTemplate != -1){
      const { subject, message } = updateDceEmailTemplate;
      // updateDceSubject = subject;
      this.updatedEmailSubject = subject;
      updateDceMesage = message;
    }
    if(newEmailTemplate != -1){
      const { subject, message } = newEmailTemplate;
      newMailSubject = subject;
      newMailMessage = message;
    }


    if(this.invitation.col == 'a_consulter' ){
      this.newEmail.subject = sendDceSubject;
      
      const SHOW_ARTISAN_DATA = true;
      let mappedEmailTemplate = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, sendDceMessage, variablesDataSource);
      
      this.newEmail.message = mappedEmailTemplate;
    }else if( dpgfPricingVersionChanged && !inConsulterOrNeChiffrePasColumn && this.setdefaultUpdatedEmail ){
      this.updateSelected=true;
      this.newEmail.subject= this.updatedEmailSubject;

      const SHOW_ARTISAN_DATA = true;
      let mappedEmailTemplate = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, updateDceMesage, variablesDataSource);
      
      this.newEmail.message = mappedEmailTemplate;
    }else{
      this.newEmail.subject = newMailSubject;
      
      const SHOW_ARTISAN_DATA = true;
      let mappedEmailTemplate = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, newMailMessage, variablesDataSource);
      
      this.newEmail.message = mappedEmailTemplate;
    }
    
    this.facticeArtisanEmail.message = this.newEmail.message;
    this.facticeArtisanEmail.message += `<br> Ce chiffrage a été préalablement complété par ${this.invitation.architect.name}`;
  }

  public controlEmail(){
    if(this.updateSelected){
      if(this.newEmail.message && this.newEmail.subject!='') return;
      this.newEmail.message = this.updatedEmailMessage;
      this.newEmail.subject=this.updatedEmailSubject;
      this.disabledButtons=false;
     }
  }
  private arrayToString(array){
    let namesOfLots=[]
    array.forEach(item=>{
      if(item.name!="Généralités"){
        namesOfLots.push(item.name|| item.title);
      }
    })
    return namesOfLots.join("/");
  }
  private specifyDceUrl(){
    if(environment.production){
      this.dceURL=`https://app.aglo.ai/architect/dpgf/${this.dpgf._id}`
    }
    else if(environment.onPremise){
      this.dceURL=`https://demo.aglo.ai/architect/dpgf/${this.dpgf._id}`
    }
    else {
      this.dceURL=`http://localhost:4200/architect/dpgf/${this.dpgf._id}`
    }
    return this.dceURL;
  }
  loadMails() {
    this.loadingEmails = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.loadArchiEmails(this.invitation._id).subscribe(
        data => {
          this.loadingEmails = false;
          this.emailsList = data;
          this.emailsList.map(email=>{
            const { subject, message } = email;
            let mappedMessage = this.transformEmailVariables({subject, message});

            email.createdAt = this.formatDate(email.createdAt);
            email.subject = mappedMessage.mappedSubject;
            email.message = mappedMessage.mappedMessage;
          })
        },
        err => {
          this.loadingEmails = false;
          this.messageService.add({ key: 'toast', severity: 'danger', summary: "Loading Mails", detail: "Mails Loading Failed", life: 5000 });
        }
      )
    )
  }

  public toggleDCEMailSection(){
    if(this.AuthService.licence == "without" && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
    }else if(this.showEmailSection){
      this.createPricingArtisan(undefined);
    }
    else{
      this.showEmailSection=true;
      this.showComentSection=false;
      this.disabledButtons = (this.newEmail.subject=='') ? true : false;
    }
  }

  public showLotsModal(){
    this.showLotsListModal=true;
    $('#assignedLotsModal').modal('show');
  }

  public toggleShowUpdateDCEMail(){
    if(this.AuthService.licence == "without" && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
    }else{
      if(this.invitation.isFactice){
        //this.sendingEmail=true;
        this.updateDpgfPricing(true);
        return;
      }
      this.setdefaultUpdatedEmail=true;
      
      // format newEmail fileds
      
      const mappedEmainSubject = this.tranformVariablesFromMailSubject(this.updatedEmailSubject);
      this.newEmail.subject = mappedEmainSubject;
      this.newEmail.message = this.updatedEmailMessage;      

      this.updateSelected=true;
      this.showEmailSection=true;
      this.showComentSection=false;
      this.disabledButtons=false;
    }

  }


  public formatDate(originalDate: string): string {
    const APPROPRIATE_FORM_OF_DATE={
      60:"minutes",
      1440:"" 
    }
    let inputDate = new Date(originalDate);
    let now = new Date();
    let diffMinutes = Math.floor((now.getTime() - inputDate.getTime()) / (1000 * 60));

    if (diffMinutes < 60) {
      return this.formatTime(inputDate) + ` (il y a ${diffMinutes} minutes)`;
    } else {
      if(this.showComentSection) return `${this.formatDateOnly(inputDate)}`
      return `${this.formatDateOnly(inputDate)} (${this.formatTime(inputDate)})`;
    }
  }


  private formatTime(date: Date): string {
    return `${this.paddingZero(date.getHours())}:${this.paddingZero(date.getMinutes())}`;
  }

  private paddingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  private formatDateOnly(date: Date): string {
    let year = date.getFullYear().toString();
    let month = this.paddingZero(date.getMonth() + 1);
    let day = this.paddingZero(date.getDate());
    return `${day}/${month}/${year}`;
  }
  private createPricingArtisan(invite) {  
    if (this.isInvalidMessage()) {
      return;
    }
  
    this.setLoadingState();
  
    let baseInvite = invite || this.invitation;
    
    let artisan = this.createArtisanObject(baseInvite);

    // format mail object variables
    if(artisan.subject && artisan.subject.includes("%")){
      let transformedSubject = this.tranformVariablesFromMailSubject(artisan.subject);
      artisan.subject = transformedSubject;
    }

    if (this.dpgf._id) {
      this.subscriptions.push(
        this.DpgfPostesService.createPricing(this.dpgf._id, baseInvite._id, artisan)
          .subscribe(
            data => this.handleSuccess(),
            error => this.handleError(error)
          )
      );
    } else {
      this.showDceNotFoundError();
    }
  }
  
  private isInvalidMessage() {
    if (!this.newEmail.message || this.newEmail.message === '') {
      return true;
    }
    return false;
  }
  
  private setLoadingState() {
    this.disabledButtons = true;
    this.sendingDCE = true;
  }
  
  private createArtisanObject(baseInvite) {
    return {
      showQuantity: this.showQuantity,
      artisanName: baseInvite.artisan.name,
      artisanEmail: baseInvite.artisan.emailAgency,
      artisanPhone: baseInvite.artisan.telAgency,
      ...this.newEmail
    };
  }
  
  private handleSuccess() {
    this.sendingDCE = false;
    this.showEmailSection=false;
    this.showComentSection=true;
    this.disabledButtons = false;
    //this.showCreateMail = false;
    this.newEmail.subject = '';
    this.refresh.emit(true);
    this.closeModal();
  
    // segment
    if (!environment.onPremise) window.analytics.track('dce_sent', { properties: { acValue: 'dce_sent' } });
  
    this.messageService.add({
      key: 'toast',
      severity: 'success',
      summary: 'DCE envoyé',
      detail: 'Votre DCE a été envoyé avec succès',
      life: 5000
    });
  }
  
  private handleError(error) {
    this.sendingDCE = false;
    this.disabledButtons = false;
    //this.showCreateMail = false;
    this.closeModal();
    this.ErrorService.manageError(error, '');
  }
  
  private showDceNotFoundError() {
    this.messageService.add({
      key: 'toast',
      severity: 'danger',
      summary: 'DCE inexistant',
      detail: 'Merci de créer un DCE avant de la partager',
      life: 5000
    });
  }

  public sendEmail(){
    if(!this.newEmail.message || this.newEmail.subject==''){
      this.sendingEmail=false;
      return;
    }
    if(this.updateSelected) {
      this.updateDpgfPricing();
    }
    else{
      this.sendNewMail();
    }

  }

  public sendNewMail(){
    if(this.newEmail.subject == ''){
      this.disabledButtons=true;
      return;
    }
    if(!this.newEmail.message || this.newEmail.message == ''){
      this.disabledButtons = true;
      return;
    }
    this.disabledButtons = true;
    this.sendingDCE = true;
    this.sendingEmail=true;
    let mailData = {
      artisanId: this.invitation.artisan._id,
      ceProjectId: this.invitation.ceProject._id,
      subject : this.newEmail.subject,
      message : (this.newEmail.message||"").replace(/(\r\n|\n|\r)/gm,"")
    }
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.subscriptions.push(
      this.ceProjectInvitesService.createRelanceNow(this.invitation._id, mailData).subscribe(
        data => {
          let newMail = {
            subject: this.newEmail.subject,
            message: this.newEmail.message,
            userName: `${currentUser.firstName} ${currentUser.lastName}`,
            user: {
                'email': currentUser.email
            }
          };
          this.emailsList.unshift(newMail);
          this.newEmail.subject = '';
          this.sendingDCE = false;
          this.sendingEmail=false;
          this.disabledButtons = false;
          this.toggleCreateMail();
          // segment event
          if(!environment.onPremise)window.analytics.track('send_email',{properties:{acValue: 'send_email'}});
        },
        err => {
          this.sendingDCE = false;
          this.sendingEmail=false;
          this.messageService.add({ key: 'toast', severity: 'danger', summary: "Envoie de mail", detail: "Erreur", life: 5000 });
        }
      )
    )
  }
  public toggleCreateMail(){
    if(this.AuthService.licence == "without" && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
      return;
    }
    $(".mails-content-wrapper").scrollTop(0);
    this.sendingEmail=false;
  }

  public updateDpgfPricing(isFactice = false){
    this.sendingEmail=true;
    if(isFactice){
      this.newEmail.subject = 'test';
      this.newEmail.message = 'test';
    }else{
      if(this.newEmail.subject == ''){
        return;
      }
      if(!this.newEmail.message || this.newEmail.message == ''){
        return;
      }
    }
    let body = {
      subject : this.newEmail.subject,
      message : this.newEmail.message.replace(/(\r\n|\n|\r)/gm,""),
    }
    if(this.newEmail.subject == '' || !this.newEmail.message || this.newEmail.message == ''){
      this.disabledButtons = true;
      //this.sendingDCE = false;
      this.sendingEmail=false;
      return;
    }

    // format mail object variables
    if(body.subject && body.subject.includes("%")){
      let transformedSubject = this.tranformVariablesFromMailSubject(body.subject);
      body.subject = transformedSubject;
    }
    
    //this.sendingDCE = true;
    //this.disabledButtons = true;
    this.subscriptions.push(
      this.DpgfPostesService.patchDpgfPricingToNextVersion(this.dpgf._id,this.invitation.dpgfPricing._id, body)
        .subscribe(
          data => {
            //this.sendingDCE = false;
            //this.disabledButtons = false;
            this.sendingEmail=false;
            this.newEmail.subject == '';
            this.closeModal();
            this.messageService.add({ key: 'toast', severity: 'success', summary: "Version à jour", detail: "L'entreprise a été notifiée de la mise à jour du document", life: 5000 });
            this.refresh.emit(true)
            // segment event
            if(!environment.onPremise)window.analytics.track('update_dce_sent',{properties:{acValue: 'update_dce_sent'}});
          },
          error => {
              this.sendingEmail = false;
              this.disabledButtons = false;
              this.closeModal();
              this.ErrorService.manageError(error,'/architect/ce-project/' + this.dpgf._id + '/artisan');
          })
    )
  }

  public sendNewLogComment(){
    this.creatingComment = true;
    this.disabledButtons = true;
    /// remove collabs names
    let cleanComment = this.newComment;
    let commentData = {
      artisanId: this.invitation.artisan._id,
      artisanName:this.invitation.artisan.name,
      ceProjectId: this.invitation.ceProject._id,
      collabs: this.selectedCollabs,
      maitreDouvrage: this.invitation.ceProject.title,
      comment: cleanComment
    };
    this.subscriptions.push(
      this.ceProjectInvitesService.architectCreateComment(this.invitation._id, commentData).subscribe(
        data => {
          this.commentsAndActivities.unshift(data);
          this.creatingComment = false;
          this.disabledButtons = false;
          this.newComment = '';
          this.selectedCollabs = [];
        },
        err => {
          this.creatingComment = false;
          this.disabledButtons = false;
          this.ErrorService.manageError(err,'');
        }
      )
    )
  }
  
  

  public loadLogs() {
    this.loadingCommentsAndActivities = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.loadArchiLogs(this.invitation._id).subscribe(
        data => {
          this.loadingCommentsAndActivities = false;
          this.commentsAndActivities = data;
        },
        err => {
          this.loadingCommentsAndActivities = false;
          this.messageService.add({ key: 'toast', severity: 'danger', summary: "Loading Mails", detail: "Mails Loading Failed", life: 5000 });
        }
      )
    )
  }
  public getDpgfLot() {
    let dpgfLot =  this.dpgf?.data.map(lot=>{
      return {id:lot.id,name:lot.title,indexLot:lot.indexLot, requested:0,icon : lot.icon}}).filter((lot) => lot.name != "");
    dpgfLot.unshift({id: "general", name: "Tout corps d'états", requested: 0, icon: "facades.png"});
    this.dpgfLot = dpgfLot;
  }

  public closeModal(){
    this.showComentSection=true;
    this.showEmailSection=false;
    this.sendingDCE=false;
    this.sendingEmail=false;
    $("#artisanModal").modal('hide');
  }
  
  public newCommentChange(value){
    let lastChar = value.substr(value.length - 1);
    if(lastChar == '@'){
      this.showCollabDropdown = true;
    }else{
      this.showCollabDropdown = false;
    }
  }
  private getArtisanRelation(artisanId){
    this.openAddAssignedLotsModal=false;
    this.subscriptions.push(
      this.architectService.getArtisanRelation(artisanId)
        .subscribe(
          data => {
            this.relationArtisanTags = data?.relation?.tags || [];
            this.relationArtisanTags=this.arrayToString(this.relationArtisanTags);
            this.relationArtisanTagsLengh=(this.relationArtisanTags.length>1)?true:false;
            this.openAddAssignedLotsModal=true;
          },
          error => {
            console.log("getArtisanRelation Error : ", error);
          }
        )
    );
  }


  public updateAssignedLots(assignedLots){
    this.selectedTags=assignedLots;
    let selectedLots = [];
    assignedLots.forEach((item) => {
      let singleLot = this.dpgfLot.find((lot) => lot.id == item.id);
      selectedLots.push(singleLot);
    });
    this.updateDpgfLot(assignedLots);
  }

  public updateDpgfLot(lots){
    this.updateArtisanLots = true;
    const body ={
      lots
    };
    this.subscriptions.push(
      this.ceProjectInvitesService.setCeInvite(this.invitation._id, body)
        .subscribe(
          data => {
            this.updateArtisanLots =false;
            this.invitation.lots = lots;
            this.messageService.add({ key: 'toast', severity: 'success', summary: "L'invitation a été mise à jour", life: 5000 });
          },
          error => {
            this.updateArtisanLots =false;
            this.ErrorService.manageError(error,'');
          }
        )
    );
  }

  public getIncide(number){
    var n = number-1;
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  public printLog(log){
    //if(log.type=='set_status')
    if(log.type && this.loggs[log.type]){
      return this.loggs[log.type](log)
    }else{
      return "";
    }
  }

  public toggleConfirmRemove(){
    if(this.AuthService.licence == "without" && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
      return;
    }
    this.showConfirmRemove = !this.showConfirmRemove;
  }

  public showLoader(){
    return this.sendingDCE || this.loadingEmails || this.loadingCommentsAndActivities
    || this.removingInvite || this.creatingComment;
  }

  public retirerCeProjectInvite(){
    this.removingInvite = true;
    this.disabledButtons = true;
    this.subscriptions.push(
    this.ceProjectInvitesService.retirerCeProjectInvite(this.invitation._id).subscribe(
      res => {
        this.refresh.emit(true);
        this.closeModal();
        this.removingInvite = false;
        this.disabledButtons = false;
      },
      err => {
        this.removingInvite = false;
        this.disabledButtons = false;
        console.log(err);
      })
    );
  }
  public openDevis(){
    $("#artisanModal").modal('hide');
    let versions = this.invitation.dpgfPricing.versions;
    let latestPricingId = versions.length ? versions[versions.length - 1]['id'] : this.invitation.dpgfPricing._id;
    let url = `${window.location.origin}/architect/dpgf/${this.dpgf._id}/result/chiffrage?devis=${latestPricingId}`;
    if(!environment.onPremise)window.analytics.track('costing_checked',{properties:{acValue: 'costing_checked'}});
    window.open(url, '_blank');
  }

  public changeAccepteOrRefuseStatus(event, value){
    if(this.AuthService.licence == "without" && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
	     return;
    }
    if(!environment.onPremise && value=='selectionne')window.analytics.track('company_selected',{properties:{acValue: 'company_selected'}});
    if(!environment.onPremise && value=='refuse')window.analytics.track('company_refused',{properties:{acValue: 'company_refused'}});

    if(this.invitation.status=='artisan-accepter' || this.invitation.status!=value){
      if(event.srcElement.className=="btn-accept") {
        this.acceptBtnClicked=true;
       }
      else {this.refuseBtnClicked=true;}

      this.updateInviteStatus(value,this.invitation._id);
    }
    else if(this.invitation.status==value) {  //if(this.invitation.status!='artisan-accepter')
      this.updateInviteStatus('artisan-accepter', this.invitation._id);
    }    
  }
  public changeInviteStatus(value){
    if(this.AuthService.licence == "without" && this.projectLicence=="none"){
      $('#upgrade-modal').modal('show');
	     return;
    }
    this.updateInviteStatus(value, this.invitation._id);
  }

  public updateInviteStatus(status,inviteId){
    this.isRefusedOrAcceptedStatusChanged=true;
    this.disabledButtons = true;
    let id = inviteId || this.invitation._id;
    let architectName = this.invitation.architect.name || '';
    let oldStatus = this.invitation.status;
    let oldCol = this.invitation.col;
    let pricingStatus = this.invitation.pricingStatus;
    let isFactice = this.invitation.isFactice;
    let newData = {status : status,oldStatus,pricingStatus,isFactice,col:this.invitation.col, oldCol};
    if(status == "artisan-invite"){
      if(this.invitation.accepted){
        newData = {status : "artisan-accepter",oldStatus,pricingStatus,isFactice,col:this.invitation.col, oldCol};
      }
      if(this.invitation.rdvTaken){
        newData = {status : "rdv-pris",oldStatus,pricingStatus,isFactice,col:this.invitation.col, oldCol};
      }
    }
    newData["architectName"] = architectName;
    this.subscriptions.push(
      this.ceProjectInvitesService.setCeInvite(id,newData)
        .subscribe(
            (data) => {
                this.refresh.emit(true);
                this.isRefusedOrAcceptedStatusChanged=false;
                this.disabledButtons = false;
                //this.updatedInvitation.emit({invitation:data, invitationId:this.invitation._id})
                this.cd.detectChanges();
                this.closeModal();
            },
            error => {
              this.isRefusedOrAcceptedStatusChanged=false;
              this.disabledButtons = false;
              this.closeModal();
              this.ErrorService.manageError(error,'');
            }
        )
    );
  }

  public setVariablesStatusOnSwitchBetweenEmailAndComment(event){
    let emailValidated=this.newEmail.subject != '' && this.newEmail.message != '';
    this.showComentSection=(event.srcElement.className=="comments-label")?true:false;
    this.showEmailSection=(event.srcElement.className=="email-label")?true:false;
    this.disabledButtons=(this.showEmailSection && emailValidated)? false:true;
    if(!this.invitation.isFactice && this.showComentSection) this.showFacticeMailForm=false;
    if(this.showEmailSection && this.invitation.col != 'a_consulter'){
      this.newEmail.message='';
      this.newEmail.subject='';
      this.updateSelected=false;
      this.disabledButtons=true;
    }
  }

  public getPossibleStatus(currentStatus) {
    switch (currentStatus) {
      case "en_attente":
        return ['En cours','Offre remise','Ne chiffre pas'];
      case "en_cours":
        return ['En attente','Offre remise','Ne chiffre pas'];
      case "offre_remise":
        return ['En attente','En cours','Ne chiffre pas'];
      case "ne_chiffre_pas":
        return ['En attente','En cours','Offre remise'];
      default:
        return [];
    }
  }
  public getCeProjectInvitePricingStatus(statusLabel:string){
    switch(statusLabel){
      case'En attente':
        return {status:'artisan-invite',pricingStatus:'pricing-sent', col:'en_attente', actionDate:new Date()}
      case'En cours':
        return {status:'artisan-accepter',pricingStatus:'pricing-sent', col:'en_cours', actionDate:new Date()}
      case'Offre remise':
        return {status:'artisan-accepter',pricingStatus:'pricing-done',col:'offre_remise', actionDate: new Date()}
      case'Ne chiffre pas':
        return {status:'artisan-refuser',pricingStatus:this.invitation.pricingStatus, col:'ne_chiffre_pas',actionDate: new Date()}
      default:
        return {};
    }
  }
  
  public displayDropDownList(statusLabel,status){
    if(status=='signe')return;
    this.listOfStatusToChangeTo=this.getPossibleStatus(statusLabel);
    this.showDropdown = !this.showDropdown;
  }

  public updateInvitation(dataToUpdate:any){
    let isFactice = this.invitation.isFactice;
    let oldCol= this.invitation.col;
    this.showDropdown=false;
    this.isRefusedOrAcceptedStatusChanged=true;

    let newData = {};
    if(typeof dataToUpdate !== 'string'){
      newData = {deliveryDate : new Date(dataToUpdate)}
    }else{
      if(!environment.onPremise)window.analytics.track('changed_invite_status',{properties:{acValue: 'changed_invite_status'}});
      let {status,pricingStatus,col, actionDate}=this.getCeProjectInvitePricingStatus(dataToUpdate);
      switch(col){
        case 'en_attente':
          newData['dateDceSent'] =this.invitation.dateDceSent ||actionDate
          break;
        case 'en_cours':
          newData['deliveryDate']=this.invitation.deliveryDate ||actionDate;
          break;
        case 'offre_remise':
          newData['sentPricingDate']=this.invitation.sentPricingDate ||actionDate;
          break;
        case 'ne_chiffre_pas':
          newData['refusalDate']=this.invitation.refusalDate || actionDate;
          break;
      }
     
      let oldStatus=this.invitation.status;
      let oldPricingStatus = this.invitation.pricingStatus;
      newData = {...newData,status,oldStatus,pricingStatus,isFactice,col,oldCol,oldPricingStatus};
      if(status == "artisan-invite"){
        // if(this.invitation.accepted){
        //   newData = {status : "artisan-accepter",oldStatus,pricingStatus,oldPricingStatus:this.invitation.pricingStatus,isFactice,col};
        // }
        if(this.invitation.rdvTaken){
          newData = {status : "rdv-pris",oldStatus,pricingStatus:this.invitation.pricingStatus,isFactice,col,oldCol};
        }
      }
    }
    this.subscriptions.push(
      this.ceProjectInvitesService.setCeInvite(this.invitation._id,newData)
        .subscribe(
            (data) => {
                this.isRefusedOrAcceptedStatusChanged=false;
                this.refresh.emit(true);
                this.cd.detectChanges();
                this.closeModal();
            },
            error => {
              this.isRefusedOrAcceptedStatusChanged=false;
              this.closeModal();
              this.ErrorService.manageError(error,'');
            }
        )
    );

  }


  public async downloadArtisanFiles(artisanFiles) {
    var zip = new JSZip();
    let files;
    if(!artisanFiles){
      files = [];
    }else{ 
      files = [...artisanFiles];
    }
    files = this.fileProcess(files,"/");
    let  filesLocation = [];
    filesLocation = files.map(file=>file);
    // get all paths documents
    let slash = filesLocation.map((file)=>file.path).filter(path=>path!='/')
    let uniquePathsArray = [...new Set(slash)]

    let filesPathTab = []
    // create path and a file location empty
    for(let i =0;i<uniquePathsArray.length;i++){
      filesPathTab.push({path:uniquePathsArray[i],fileLocation:[]})
    }
    // fill fileLocations depends on path
    for(let i=0;i<filesLocation.length;i++){
      let currentFile=filesLocation[i];
      for(let j=0;j<filesPathTab.length;j++){
        if(currentFile.path==filesPathTab[j].path){
          filesPathTab[j].fileLocation.push(currentFile.cdnUrl || currentFile.Location)
        }
      }
    }
    
    //  add  path '/' to the table and then push on it every path equal to /
    filesPathTab.push({path:'/',fileLocation:[]})
    for(let i = 0;i<filesLocation.length;i++){
      if(filesLocation[i]?.path=='/')
      filesPathTab[filesPathTab.length-1].fileLocation.push(filesLocation[i].cdnUrl || filesLocation[i].Location)
    }
    let filesObj = {
      uniquePathsArray,
      filesPathTab,
      dpgfName:this.dpgf.nomPrenom,
      archiId:this.dpgf.architect
    }
    this.downloadAllVar = true;
    this.architectService.generateZIP(this.dpgf._id,filesObj).subscribe((data)=>{
      let url = data.Location
      window.location.href = url
      this.downloadAllVar = false;
      $('#artisanModal').modal('hide');
      this.cd.markForCheck()
    },err=>{
      console.log('err',err);
      this.downloadAllVar = false;
    });  
  }

  public fileProcess(files,path){
    let allFiles = [];
    for(var i =0; i<files.length ; i++){
      if(files[i].type == "folder"){
        allFiles = allFiles.concat(this.fileProcess((files[i].children ||[]),path  + files[i].name + "/"));
      }else{
        files[i].path = path
        allFiles.push(files[i]);
      }
    }
    return allFiles
  }

  public inputChanged(event){
    this.disabledButtons =(event !='' && this.newEmail.message && this.newEmail.subject!='') ? false : true;
    if(this.disabledButtons) this.updateSelected=false;
    this.disabledFacticeButtons=(event !='' && this.facticeArtisanEmail.message && this.facticeArtisanEmail.subject!='' && this.emailIsValid(this.facticeArtisanEmail.email)) ? false : true;
    this.facticeFormMailError=this.emailIsValid(this.facticeArtisanEmail.email) ? false : true;
  }

  //factice part
  public showFacticeMailFormEmailSection(){
    this.facticeFormMailError = false;
    this.facticeFormSubjectError = false;
    this.facticeFormMessageError = false;
    this.showFacticeMailForm=true;

  }

  public navigateToChiffragePage(){
    $("#artisanModal").modal('hide');
    let url = `${window.location.origin}/artisan/dpgf/${(this.invitation.dpgfPricing || {})._id}?code=${(this.invitation.dpgfPricing || {}).code}`;
    window.open(url, '_blank');
  }
  public sendFacticeMail(){
    this.facticeFormMailError = false;
    this.facticeFormSubjectError = false;
    this.facticeFormMessageError = false;
    let isFactice=false;
    let body = {
      ...this.facticeArtisanEmail,
      isFactice
    };
    if(!this.emailIsValid(body.email)){
      this.facticeFormMailError = true;
      return;
    }
    if(this.facticeArtisanEmail.subject == ''){
        this.facticeFormSubjectError = true;
        return;
    }
    if(!this.facticeArtisanEmail.message || this.facticeArtisanEmail.message == ''){
        this.facticeFormMessageError = true;
        return;
    }
    this.sendingDCE = true;
    this.subscriptions.push(
      this.DpgfPostesService.sendFacticeArtisanMail(this.invitation._id, body).subscribe(
        res => {
          this.sendingDCE = false;
          this.showFacticeMailForm = false;
          this.messageService.add({ key: 'toast', severity: 'success', summary: "email envoyé", detail: "votre courrier a bien été envoyé", life: 5000 });
        },
        err => {
          this.sendingDCE = false;
          console.log(err);
        }
      )
    );

  }
  public emailIsValid(email){
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
     return true
   }
   return false
  }
  public cancelFacticeEmail(){
    this.showFacticeMailForm=false;
    this.facticeArtisanEmail.subject='';
    this.facticeArtisanEmail.email='';
    this.disabledFacticeButtons=true;
    this.showComentSection=true;
    this.showEmailSection=false;
  }


  public modelClosedEvent(){
    let _SELF = this;
    $("#artisanModal").on("hidden.bs.modal", function () {
      _SELF.showConfirmRemove = false;
      _SELF.showFacticeMailForm = false;
      _SELF.showEmailSection=false;
      _SELF.showComentSection=true;
      _SELF.showFacticeMailForm=false;
      _SELF.sendingDCE=false;
      _SELF.sendingEmail=false;
    });
  }

  private fillMailFromArchiTemplates(){
    // this.newEmail.subject = subject;
    // this.newEmail.message = "";

  }

  // Custom Email Templates
  public emailTemplateSelected(emailTemplate){
    let { subject, message } = emailTemplate;
    if(!environment.onPremise)window.analytics.track('use_email_template',{properties:{acValue: 'use_email_template'}});

    // set subject
    if(subject.includes("%NOM_PROJET%")){
      subject = subject.replace("%NOM_PROJET%", this.ceProject.title);
    }
    this.newEmail.subject = subject;


    if(!this.emailTemplatesHelpers){
      this.emailTemplatesHelpers = new EmailTemplatesHelpers();
    }

    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: this.artisan,
    };
    const SHOW_ARTISAN_DATA = true;
    let mappedEmailTemplate = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, message, variablesDataSource);

    this.newEmail.message = mappedEmailTemplate;
  }
  private tranformVariablesFromMailSubject(mailSubject){
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: this.artisan,
    };
    const SHOW_ARTISAN_DATA = true;
    let mappedEmailSubject = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, mailSubject, variablesDataSource);

    return mappedEmailSubject;
  }
  private transformEmailVariables({subject, message}){
    let mappedSubject = this.tranformVariablesFromMailSubject(subject);
    let mappedMessage;

    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: this.artisan,
    };
    const SHOW_ARTISAN_DATA = true;
    mappedMessage = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, message, variablesDataSource);

    return {mappedSubject, mappedMessage}
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }



}
