import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ArchiDbService, AuthService } from 'src/app/_services';
import * as fromSelectors from '../_store';
import * as actions from '../_store/dpgf.actions';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';



@Component({
  standalone:true,
  selector: 'app-archi-db-navigation',
  imports:[
    DpgfModule,
    ArchiDbNavigationComponent,
    NgSharedModule
  ],
  templateUrl: './archi-db-navigation.component.html',
  styleUrls: ['./archi-db-navigation.component.scss']
})
export class ArchiDbNavigationComponent implements OnInit {
  @Input() firstTime;
  @Input() lots;
  @Input() newLots;
  @Input() posts;
  @Input() newPosts;
  @Input() sousLot;
  @Input() addPosteToArchiDb;
  @Input() archiDbLot;
  public openedLotIndex=null;
  public selectedPosts=[];
  public error=false;
  public rootLots=[];
  public archiId=JSON.parse(localStorage.getItem('currentUser')).archiId;
  public collabs;
  public currentLicence=this.authService.licence;
  public names=[];
  public openedRootLotIndex=null;dpgfId;loading:boolean=false;
  public premiumArray=["premium","premium-annuel","premiumn","premiumn-trim","premiumn-annuel","max","max-trim","max-annuel"]
  constructor(private archiDbService: ArchiDbService,
              private cdr: ChangeDetectorRef,
              private store: Store,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.store.pipe(select(fromSelectors.getAny,{name:"selectedPosts"})).subscribe((selectedPosts)=>{
      this.selectedPosts = selectedPosts;
    })
    this.getRoutes();
    // this.getFirstLots();
  }
  private getRoutes(){
    this.activatedRoute.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
      }
    });
    if(this.activatedRoute.snapshot.queryParamMap.get('archiId'))this.archiId = this.activatedRoute.snapshot.queryParamMap.get('archiId');
    console.log('')
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getRoutes();
    if(changes.firstTime && changes.firstTime.currentValue) this.getFirstLots()
    if(changes.lots && !this.firstTime && this.lots.sousLots) {
      this.lots.sousLots.forEach(element => element.open=false);
    }
  }
  getFirstLots(){
    this.loading = true;
    this.archiDbService.getAllLot("title sousLots archiId icon",'',{dpgfId:this.dpgfId,archiId:this.archiId,idLot:this.activatedRoute.snapshot.queryParamMap.get('idLot')}).subscribe(
    (data)=>{
      this.currentLicence=data.licence;
      // parsing collabs and there names
      let h=0;
      this.collabs=data.collabs;
      data.names[0].sharedWith.forEach(element => {
        this.names[h]=element;
        this.names[h]['licence']=((element||[]).users[0]||{}).licence
        h++;
      });
      // end parsing collabs
      // start parsing data.dbs
      let j=0,index;
      let foundArchiLot=null,archiLot;
      this.rootLots=[{archiName:'',lots:[],licence:''}];
      for(var i=0;i<data.dbs.length;i++){
        if(data.dbs[i].archiId==this.archiId) foundArchiLot=j;
        if (i>0 && data.dbs[i].archiId!=data.dbs[i-1].archiId){
          if(data.dbs[i].archiId==this.archiId && !foundArchiLot) foundArchiLot=j+1;
          this.rootLots.push({archiName:'',lots:[]});  
          j++;
        }
        this.rootLots[j]['lots'].push(data.dbs[i])
      }
      archiLot=this.rootLots[foundArchiLot]
      if(foundArchiLot!=null){
        this.rootLots.splice(foundArchiLot,1);
        this.rootLots.unshift(archiLot);
        this.rootLots[0]['archiName']="Ma bibliothèque";
      }
      else this.rootLots.unshift({archiName:'Ma bibliothèque',lots:[]})
      if(this.rootLots.length>1 && this.names.length){
        let arrayToDelete=[];
        for(var k=1;k<this.rootLots.length;k++) {
          index=this.names.findIndex(x=>x._id==(this.rootLots[k].lots[0]||[]).archiId);
          if(index!=-1) {
            this.rootLots[k]['archiName']=((this.isVowel(this.names[index].name))? "Bibliothèque d'":"Bibliothèque de ")+this.names[index].name;
            this.rootLots[k]['licence']=this.names[index].licence;
          }
        }
        this.rootLots.forEach((element,index) => {
          if((((this.currentLicence=="plus" || this.currentLicence=="plus-trim" || this.currentLicence=="plus-annuel") && this.premiumArray.includes(element.licence)) || (this.currentLicence=="base" && element.licence!="base")) || (this.currentLicence=="without") && index>0) arrayToDelete.push(index)
        })
        this.rootLots = this.rootLots.filter((element, index) => !arrayToDelete.includes(index));
      }
      if(this.rootLots.length>1 && !this.rootLots[1].archiName) this.rootLots.splice(1,1);
      this.rootLots.forEach(element => {element.open=false;element.loading=false;});
      this.loading = false;
      this.cdr.detectChanges()

      // end parsing data
    })
  }
  openLot(lotId,j,lot=null){
    this.newPosts=[];
    this.newLots=[];
    if(this.firstTime){
      if(!this.lots[j].open){
        this.lots[j].loading=true;
        this.lots[j].open=true;
      }
      else this.lots[j].open=false;
      if(this.openedLotIndex!=null && this.lots[this.openedLotIndex]!=this.lots[j]) this.lots[this.openedLotIndex].open=false;
    }
    else{
      if(!this.lots.sousLots[j].open){
        this.lots.sousLots[j].loading=true;
        this.lots.sousLots[j].open=true;
      }
      else this.lots.sousLots[j].open=false;
      if(this.openedLotIndex!=null && this.lots.sousLots[this.openedLotIndex]!=this.lots.sousLots[j]) this.lots.sousLots[this.openedLotIndex].open=false;
    }
    this.cdr.detectChanges();
    this.archiDbService.getLotWithPosts(lotId).subscribe(
      (data)=>{
        this.newPosts=data[1];
        this.newLots=data[0];
        if(this.firstTime) this.lots[j].loading=false;
        else this.lots.sousLots[j].loading=false;
        this.openedLotIndex=j;
        this.cdr.detectChanges();
      },
      (err)=>{
        console.log(err);
      }
    )
  }
  selectPost(i){
    let indexOf=-1;
    for(var j=0;j<this.selectedPosts.length;j++){
      if(this.selectedPosts[j]._id ==this.posts[i]._id) indexOf=j;
    }
    if(indexOf==-1) this.selectedPosts.push(this.posts[i])
    else this.selectedPosts.splice(indexOf,1)
    this.store.dispatch(actions.setSelectedPosts(this.selectedPosts));
  }
  importPostes(){
    this.store.dispatch(actions.addManyPostsArchiDb({ postes :this.selectedPosts , sousloti : this.sousLot}));
    this.store.dispatch(actions.setSelectedPosts([]));
    if(!environment.onPremise)window.analytics.track('post_from_db',{properties:{acValue: 'post_from_db'}});
    this.rootLots.forEach(element => {element.open=false;element.loading=false;});
    this.openedRootLotIndex=null;
  }
  importPostesArchiDb(){
  }
  checkPost(post){
    let found=false;
    for(var i=0;i<this.selectedPosts.length;i++){
      if(this.selectedPosts[i]._id == post._id) found=true;
    }
    return found;
  }
  openRootLot(i){
    if(!this.rootLots[i].open){
      this.rootLots[i].open=true;
      if(this.openedRootLotIndex!=null && this.openedRootLotIndex!=i) this.rootLots[this.openedRootLotIndex].open=false;
      this.openedRootLotIndex=i;
      this.openedLotIndex=null;
      this.lots=this.rootLots[i].lots;
      this.lots.forEach(element => {element.open=false;element.loading=false;});
      this.cdr.detectChanges();
    }
    else this.rootLots[i].open=false;    
  }
  public isVowel(x) {
    let listofVowel= ['a','i','e','o','u','A','I','E','O','U'];
    return listofVowel.includes(x[0]);
  }
}
