import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthService } from '../../../_services/index';
import { MessageService } from 'primeng/api';
import initHelpHero from 'helphero';
import { NgSharedModule } from 'src/app/all/ngshared.module';

//Service
import { ErrorService } from '../../../_services/index';
import { CeProjectService, UserService } from '../../../_services/index';
import { ArchitectService } from '../../../_services/index';
import { IdentifySegement } from 'src/app/all/segment/identify';

// Components
import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { CeProjectListComponent } from 'src/app/dpgf/global/ce-project-list/ce-project-list.component';

import { ProjectsSearchbarComponent } from 'src/app/architect/projects/projects-searchbar/projects-searchbar.component';
import { ProjectsCardsListComponent } from 'src/app/architect/projects/projects-cards-list/projects-cards-list.component';
import { PaginationComponent } from 'src/app/all/pagination/pagination.component';


// Others
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-architect-my-consultation',
  imports: [
    HeaderComponent,
    ProjectsSearchbarComponent,
    CeProjectListComponent,
    ArchiExpiredModalComponent,
    ProjectsCardsListComponent,
    PaginationComponent,
    NgSharedModule
  ],
  templateUrl: './architect-my-consultation.component.html',
  styleUrls: ['./architect-my-consultation.component.scss'],
  standalone: true,

})
export class ArchitectMyConsultationComponent implements OnInit {
  
  public loading: boolean = true;
  private FULL_PROJECTS: any = []; // this keeps all projects
  public projects: any = []; // this might changed depending on the searched, or filterd project
  public filtredProjects: any = []; // when you search in projects, & want to filter them (or vice versa)
  private currentFilter:string = "all";

  public currentlySelectedProjectId: string; // set this value, when we want to delete, duplicate, archiver

  // Sorting
  private sortBy:string = 'createdAt'; 
  private direction:string = '-1'; 

  // pagination
  public currentPage: string = "1";
  public recordsPerPage: string = "40";
  public totalNumberOfPages: any[] = [];
  public currentSearchQuery:any = {};
  
  // User
  public isAdmin: boolean = false; 
  public hasDpgf: boolean = false; 
  public currentUser;
  public license: string;
  public authUserFavoritedProjects: any = [];

  // revit
  public isRevitDesktop:boolean;

  // minarm
  public minarmIsAdminArchitect: boolean = false;
  public onPermis: boolean = false;
  public minarmArchitectsList: any = [];

  // architect
  public authArtchitectId:string = ""; // currently auth architect id

  // modals
  public confirmModalTitle :string = "Supprimer le projet ?";
  public confirmModalDescription :string = "Le projet sera supprimé et ne pourra pas être récupéré.";
  public confirmModalAcceptLabel :string = "Supprimer";

  
  private subscriptions: Array<Subscription> = []; 

  constructor(
    private title: Title,
    private router: Router,
    private ceProjectService: CeProjectService,
    private architectService: ArchitectService,
    private userService: UserService,
    public authService: AuthService,
    public messageService: MessageService,
    private ErrorService: ErrorService,
    private identifySegment: IdentifySegement) {
      this.title.setTitle('Aglo - Mes Consultations');
  }

  ngOnInit() {
    // revit
    this.isRevitDesktop = navigator.userAgent.includes("Autodesk.Revit/2024");

    if(!environment.onPremise)window.analytics.page('pageview_main_page');
    // minarm
    this.onPermis = environment.onPremise;

    // auth user
    this.currentUser = this.authService.getAuthUser(); // from loadStorage (does not have all data)
    this.getUserLicence();

    this.loadAuthUser(); // from db

    if(!this.onPermis){
      this.recordsPerPage = "100";
    }

    this.getArchiProjects();

    if(this.onPermis){
      this.minArmLoadArchitects();
    }
  }

  //////////////////////////////////////////////////////////////
  /// UI
  //////////////////////////////////////////////////////////////
  public openConfirmRemoveProjectModal(projectId){
    this.currentlySelectedProjectId = projectId;
    $("#confirm-remove-project-modal").modal('show');
  }
  public openNeedLicenseModal(){
    $("#user-on-without-license-modal").modal('show');
  }
  private removeProjectFromList(projectId){
    this.FULL_PROJECTS = this.FULL_PROJECTS.filter(ceProject => ceProject["_id"] != projectId );
    this.projects = this.FULL_PROJECTS;
  }
  private addNewProjectToList(project){
    this.FULL_PROJECTS.unshift(project);
    this.projects = this.FULL_PROJECTS;
  }
  private changeProjectField(projectId, key, newValue){
    // find project by id & change the a specific field (key) by the value (newValue)
    this.FULL_PROJECTS = this.FULL_PROJECTS.map(ceProject => {
      if(ceProject._id == projectId){
       ceProject[key] == newValue;
      }
      return ceProject;
    });
    this.projects = this.FULL_PROJECTS;
  }

  //////////////////////////////////////////////////////////////
  /// Functionality
  //////////////////////////////////////////////////////////////
  public getUserLicence(){
    this.license = this.authService.licence;
    // auth architect id
    this.authArtchitectId = this.currentUser?.archiId;

    // is admin
    this.isAdmin = this.currentUser.isAdmin;
    // admin for minarm
    this.minarmIsAdminArchitect = this.currentUser?.isAdminArchitect || false;
  }
  public duplicate(projectId){
    this.duplicateProject(projectId);
  }
  public toggleFavorite(body){
    this.authUserFavoritedProjects = body["favoritedProjects"];

    this.updateAuthUser(body);
  }
  public archiveProject(projectBody){
    const { projectId, archiStatus } = projectBody;

    let body = { archiStatus };

    this.updateProject(projectId, body);
  }
  public removeProject(){
    let body = {status: 'deleted'};
    // update project
    this.updateProject(this.currentlySelectedProjectId, body);
    if(!environment.onPremise)window.analytics.track('delete_project',{properties:{acValue: 'delete_project'}});

    // remove project from UI
    this.removeProjectFromList(this.currentlySelectedProjectId);
    // set initial value
    this.currentlySelectedProjectId = "";
  }
  public searchProjects(searchQuery){
    
    if(!environment.onPremise){
      this.sendAnalyticsTracking('filter_projects', searchQuery);
    }

    this.currentSearchQuery = searchQuery;

    // On Minarm do backend pagination & searching
    if(this.onPermis){

      // Format Search Query for mongodb
      let formatedSearchQuery = this.formateSearchQueryForMongodbCriteria(this.currentSearchQuery);
      formatedSearchQuery = {...formatedSearchQuery, projectsFilter: this.currentFilter}

      let sortBy = this.sortBy; 
      let direction = this.direction;
      
      let showArchived = formatedSearchQuery["archiStatus"] ? true : false;
      this.currentPage = "1";

      this.getArchiProjects(formatedSearchQuery, sortBy, direction, showArchived);
      return;
    }


    // is searchQuery is an empty project, get all
    const showAllProjects = !Object.keys(searchQuery).length ? true : false;

    // show all projects, no need to search
    if(showAllProjects){
      this.projects = this.FULL_PROJECTS;
      this.filtredProjects = [];
      if(this.currentFilter != "all"){
        this.filterProjects(this.currentFilter);
      }
      return;
    }

    this.filtredProjects = [];
    
    for(let i = 0; i < this.FULL_PROJECTS.length; i++){
      let currentProject = this.FULL_PROJECTS[i];

      let currentProjectMatchTheSearchQuery = this.matchTheProjectQuery(currentProject, searchQuery);

      if(currentProjectMatchTheSearchQuery){
        this.filtredProjects.push(currentProject);
      }

    }
    
    this.projects = this.filtredProjects;
    
    // if filter is selected, filter the new searched projects
    if(this.currentFilter != "all"){
      this.filterProjects(this.currentFilter);
    }

  }
  private matchTheProjectQuery(project, searchQuery){

    let numberOfSeachQueries = Object.keys(searchQuery).length; // how many elements to search by (text, nature, programme) (the ones selected)
    let currentProjectPassedSearchQueries = 0;// how many elements the current project has passed (if it's the same number as above) then show it
    
    const { title, addressCity, addressCodePostal, addressCountry, adresse } = project;
    const textQuerySearchFields = [title||"", addressCity||"", addressCodePostal||"", addressCountry||"", adresse||""];

    for(let key in searchQuery){
      /// Search String
      // if text is filled 
      if(key == "textQuery"){
        let textQuery = searchQuery[key].toLowerCase();

        // if one of the items, includes the same textQuery, then it should be
        let queryMatchOneOfTheElements = textQuerySearchFields.filter(item => item.toLowerCase().includes(textQuery));
        
        if(queryMatchOneOfTheElements.length){
          currentProjectPassedSearchQueries++;
        }
        
      }else if(key == "status"){
        let projectStatusMatch = this.verifyProjectByStatus(project, searchQuery[key]);
        
        if(projectStatusMatch){
          currentProjectPassedSearchQueries++;
        }
      }else if(key == "architectsNames"){
        let projectStatusMatch = this.findProjectByArchitectsId(project, searchQuery[key]);
        if(projectStatusMatch){
          currentProjectPassedSearchQueries++;
        }
      }

      // Search Array
      if(key in project){
        
        // this might be : natureTravaux or type (these are strings)
        let projectValue = project[key];
        let searchByValues = searchQuery[key];

        if(searchByValues.includes(projectValue)){
          currentProjectPassedSearchQueries++;
        }
      }

    }

    return currentProjectPassedSearchQueries >= numberOfSeachQueries ? true : false;
  }

  private verifyProjectByStatus(project, statusArray){
    let projectMatchTheCriteria = false;

    if(statusArray.includes("en_cours")){
      
      if((project.status != "deleted" && project.archiStatus != "archive") || project.demo){
        projectMatchTheCriteria = true;
      }

    }else if(statusArray.includes("archived")){
      if(project.archiStatus == "archive"){
        projectMatchTheCriteria = true;
      }
    }

    return projectMatchTheCriteria;
  }
  private findProjectByArchitectsId(project, architectsIds){
    let projectMatchTheCriteria = false;
    
    if(architectsIds.includes(project.architect._id)){
      projectMatchTheCriteria = true;
    }

    return projectMatchTheCriteria;
  }

  public filterProjects(filter){
    this.currentFilter = filter;
    
    if(!environment.onPremise){      
      let quickFiltersMap = {
        "all": "quick_filter_tous",
        "my_projects": "quick_filter_mes_projets",
        "favorite": "quick_filter_favoris",
        "shared_with_me": "quick_filter_partages",
        "projects_esid": "quick_filter_mon_orga",
      }
      let mappedValue = quickFiltersMap[filter];
      // window.analytics.track('quick_filters',{ properties:{mappedValue} });
      window.analytics.track('quick_filters',{properties:{acValue: mappedValue}});
    }


    // if minarm filter in the backend
    if(this.onPermis){
      // let query = {...this.currentSearchQuery, projectsFilter: filter};
      let formatedSearchQuery = this.formateSearchQueryForMongodbCriteria(this.currentSearchQuery);
      let query = {...formatedSearchQuery, projectsFilter: filter};

      let sortBy = this.sortBy; 
      let direction = this.direction;
      let showArchived = query["archiStatus"] ? true : false;
      this.currentPage = "1";

      this.getArchiProjects(query, sortBy, direction, showArchived);

      return;
    }

    let projectsSource = this.filtredProjects.length ? [...this.filtredProjects] : [...this.FULL_PROJECTS];    

    if(filter == "all"){
      this.projects = projectsSource;
    }else if(filter == "favorite"){
      this.projects = projectsSource.filter(project => this.authUserFavoritedProjects.includes(project._id));
    }else if(filter == "my_projects"){
      const authUserId = this.currentUser["id"];
      this.projects = projectsSource.filter(project => project?.user?._id == authUserId);
    }else if(filter == "shared_with_me"){
      const authUserId = this.currentUser["id"];
      // find projects where collaborateurs _id equals auth id
      this.projects = projectsSource.filter(project => project.collaborateurs.find(collab => collab._id == authUserId));
    }else if(filter == "projects_esid"){
      const authArchitectId = this.currentUser["archiId"];
      // find projects where architect _id equals auth architect
      this.projects = projectsSource.filter(project => project.architect._id == authArchitectId);
    }
    
  }
  public sortProjects(body){
    const { sortBy, direction } = body;

    if(!environment.onPremise){      
      let directionKeyMap = {
        "createdAt": "Date de création",
        "updatedAt": "Date de modification",
        "title": "Titre"
      }
      
      let directionValueMap = {
        "1": "ascendante",
        "-1": "descendant",
      }

      let mappedKey = directionKeyMap[sortBy];
      let mappedValue = directionValueMap[direction];

      window.analytics.track('sort_projects',{properties:{[mappedKey]: mappedValue}});
    }
    
    // On Minarm do backend sorting
    if(this.onPermis){
      this.sortBy = sortBy; 
      this.direction = direction;

      let formatedSearchQuery = this.formateSearchQueryForMongodbCriteria(this.currentSearchQuery);
      formatedSearchQuery = {...formatedSearchQuery, projectsFilter: this.currentFilter}
      
      let showArchived = formatedSearchQuery["archiStatus"] ? true : false;
      
      this.getArchiProjects(formatedSearchQuery, sortBy, direction, showArchived);
    }else{ // NOT MINARM SORTING
      // if sort by title (don't send http request, sort locally)
      if(sortBy == "title"){
        
        const sortAscending = (a, b) => a.title.localeCompare(b.title); // from a to x
        const sortDescending = (a, b) => b.title.localeCompare(a.title); // from x to a

        const orderFunction = direction == -1 ? sortDescending : sortAscending;
      
        this.projects.sort((a, b) => orderFunction(a,b));  
      }else{
        this.sortBy = sortBy; 
        this.direction = direction;

        let formatedSearchQuery = this.formateSearchQueryForMongodbCriteria(this.currentSearchQuery);
        formatedSearchQuery = {...formatedSearchQuery, projectsFilter: this.currentFilter}
        
        let showArchived = formatedSearchQuery["archiStatus"] ? true : false;
        
        this.getArchiProjects(formatedSearchQuery, sortBy, direction, showArchived);
      }
    }

  }

  public openProject(projet){
    if(this.isRevitDesktop){
      this.router.navigate(['/architect','dpgf',projet.dpgf]);
    }
    else{
      this.router.navigate(['/architect','ce-project',projet._id]);
    }
  }
  public goToNewProjectPage(){
    if(this.license == "without"){
      $("#user-on-without-license-modal").modal('show');
      return;
    }

    this.router.navigate(['architect/new-ce-project']);
  }

  private getEncoursProjects(projectsList, showArchived){
    const authUserId = this.currentUser.id;

    this.projects = projectsList.filter(project => {
      const { status, archiStatus, demo, user } = project;
      let statusShownByDefault = ["created", "validated"].includes(status) && archiStatus != "archive";
      const isDemo = demo;
      // const projectCreator = user._id == authUserId;
      
      // if(statusShownByDefault || isDemo || showArchived || this.isAdmin || projectCreator){
      if(statusShownByDefault || isDemo || showArchived || this.isAdmin){
        return project;
      }

    });

  }
  public toggleValidateProject(data){
    const { _id, status } = data;
    
    // update project api
    this.updateProject(_id, {status});

    // Change project Status from UI
    this.changeProjectField(_id, "status", status);
  }

  public pageSelected(page){
    this.currentPage = page;

    let formatedSearchQuery = this.formateSearchQueryForMongodbCriteria(this.currentSearchQuery);
    formatedSearchQuery = {...formatedSearchQuery, projectsFilter: this.currentFilter}
    
    let sortBy = this.sortBy; 
    let direction = this.direction; 
    
    let showArchived = formatedSearchQuery["archiStatus"] ? true : false;

    this.getArchiProjects(formatedSearchQuery, sortBy, direction, showArchived);

    // this.getArchiProjects();
  }
  private formateSearchQueryForMongodbCriteria(originalSearchQuery){
    let searchQuery = JSON.parse(JSON.stringify(originalSearchQuery));

    let formatedCriteria = {};

    let statusArray = searchQuery.status || [];

    if(statusArray.includes("en_cours")){
      const indexOfEn_cours = statusArray.indexOf("en_cours");
      statusArray[indexOfEn_cours] = "created";
    }
    
    if(statusArray.includes("archived")){
      const indexOfArchived = statusArray.indexOf("archived");
      statusArray.splice(indexOfArchived, 1);
      searchQuery["archiStatus"] = "archive";
    }

    if(statusArray.length == 0){
      delete searchQuery["status"];
    }

    let mongoOperatorsMap = {
      "textQuery": (searchValue) => {
        return {
          "$or": [
            { title: { '$regex': searchValue, '$options': 'i' } },
            { addressCity: { '$regex': searchValue, '$options': 'i' } },
            { addressCodePostal: { '$regex': searchValue, '$options': 'i' } },
            { addressCountry: { '$regex': searchValue, '$options': 'i' } },
            { adresse: { '$regex': searchValue, '$options': 'i' } }
          ]
        }
      },
      "natureTravaux": (values) => {
        return { $in: values };
      },
      "type": (values) => {
        return { $in: values };
      },
      "status": (values) => {
        return { $in: values }
      },
      "archiStatus": (value) => {
        return value;
      },
      "architectsNames": (architectsIds) => {
        return architectsIds
      }
    };

    for(let key in searchQuery){
      if(key in mongoOperatorsMap){
        formatedCriteria[key] = mongoOperatorsMap[key](searchQuery[key]);
      }
    }

    return formatedCriteria;
  }
  //////////////////////////////////////////////////////////////
  /// Http Requests
  //////////////////////////////////////////////////////////////
  // criteria={}, 
  public getArchiProjects(criteria = null, sortBy='createdAt', direction='-1', showArchived=false) {
    const page = this.currentPage;
    const recordsPerPage = this.recordsPerPage;

    this.loading = true;
    this.subscriptions.push(
      this.ceProjectService.getAllProject(criteria, page, recordsPerPage, sortBy, direction)
        .subscribe(
          res => {
            const { data, metadata } = res;
            
            // set projects data
            this.FULL_PROJECTS = data;

            this.getEncoursProjects(data, showArchived); // show only "en_cours" & demo projects & validated projects by default
            // pagination
            let totalNumberOfProjects = metadata.count;
            
            this.totalNumberOfPages = new Array(Math.ceil(totalNumberOfProjects/Number(this.recordsPerPage)));
            
            this.loading = false;
            
            // segment
            this.identifySegment.identifyNumProjects(data.length);
          },
          error => {
            console.log("error", error)
            this.ErrorService.manageError(error, '/architect/my-projects');
          })
    );
  }
  public updateProject(projectId, body){
    this.loading = true;
    this.subscriptions.push(
      this.ceProjectService.update(projectId, body)
      .subscribe(
        data => {
          this.loading = false;
        },
        error => {
          console.log("error", error)
          this.ErrorService.manageError(error, '/architect/my-projects');
        })
    );
  }
  public duplicateProject(projectId){
    this.loading = true;
    this.subscriptions.push(
      this.ceProjectService.duplicate(projectId)
      .subscribe(
        data => {
          // add newly duplicated project to UI
          if(!environment.onPremise)window.analytics.track('duplicate_project',{properties:{acValue: 'duplicate_project'}});
          this.addNewProjectToList(data);
          this.loading = false;
        },
        error => {
          console.log("error", error)
          this.ErrorService.manageError(error, '/architect/my-projects');
        })
    );
  }
  public loadAuthUser(){
    const userId = this.currentUser["id"];

    // this.loading = true;
    this.subscriptions.push(
      this.authService.getUser(userId).subscribe({
        next: (data) => {
          this.authUserFavoritedProjects = data.favoritedProjects || [];
        },
        error: (err) => {
          console.log(err);
        },
      })
    );
  }
  public updateAuthUser(body){
    const userId = this.currentUser["id"];
    this.loading = true;
    this.subscriptions.push(
      this.authService.updateUser(userId, body).subscribe({
        next: (data) => {
          this.loading = false;
        },
        error: (err) => {
          console.log(err);
        },
      })
    );
  }

  //////////////////////////////////////////////////////////////
  /// Minarm
  //////////////////////////////////////////////////////////////
  private minArmLoadArchitects(){
    this.subscriptions.push(
      this.architectService.getAllArchiProfile(1, 100, "").subscribe({
          next: (data) => {
            this.minarmArchitectsList = data.data;
          },
          error: (err) => {
            console.log(err);
          },
        })
    );
  }


  private sendAnalyticsTracking(type, payload){
    window.analytics.track(type,{ properties:{...payload} });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
