import { Component } from '@angular/core';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-logos',
  standalone: true,
  templateUrl: './logos.component.html',
  styleUrl: './logos.component.scss',
  imports: [ HeaderComponent, NgSharedModule]

})
export class LogosComponent {

  public minarmLogo;
  public sgaLogo;
  public isMinarmLogoExist = true;
  public isSgaLogoExist = true;
  
  constructor(private messageService:MessageService){ }

  ngOnInit(): void {
    this.minarmLogo = 'api/v1/static/minarm.png';
    this.sgaLogo = 'api/v1/static/SGA.png';
    this.checkImageExists(this.sgaLogo,this.minarmLogo)
  }
  checkImageExists(sgaLogo: string,minarmLogo: string) {
    const img = new Image();
    const img2 = new Image();
    // Start loading the image
    img.src = sgaLogo; 
    img2.src = minarmLogo; 
    // When the image fails to load (e.g., 404 error)
    img.onerror = () => {
      this.isSgaLogoExist = false;
      console.error('Image not found sgaLogo:', sgaLogo);
    };
    img2.onerror = () => {
      this.isMinarmLogoExist = false;
      console.error('Image not found minarmLogo:', minarmLogo);
    };
  }
  
  public onUploadLogoMinarm(event){
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/jpng'];
    const file = event.files[0];
    if (!allowedTypes.includes(file.type)) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: "Format d'image non pris en charge", detail: "Le format d'image que vous importez n'est pas pris en charge. Les formats supportés sont : png, jpg et jpeg", life: 5000 });
      } else {
    this.minarmLogo = '';
    this.isSgaLogoExist = true;
    setTimeout(() => {
      this.minarmLogo = '/api/v1/static/minarm.png';
    }, 500);
    }
  }

  public onUploadLogoSga(event){
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/jpng'];
    const file = event.files[0];
    if (!allowedTypes.includes(file.type)) {
    this.messageService.add({ key: 'toast', severity: 'error', summary: "Format d'image non pris en charge", detail: "Le format d'image que vous importez n'est pas pris en charge. Les formats supportés sont : png, jpg et jpeg", life: 5000 });
    } else {
      this.sgaLogo = '';
      this.isMinarmLogoExist = true;
      setTimeout(() => {
        this.sgaLogo = '/api/v1/static/SGA.png';
      }, 500);
    }
    }
}
