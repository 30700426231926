<div class="top-navbar-wrapper flex space-between">


  <!-- Back TO Dce -->
  <div class="back-to-dce-wrapper flex center-items pointer" *ngIf="currentView == 'exportDocs'" (click)="changeTab('redaction')">
    <i class="fa fa-arrow-left margin-right-10"></i> Retourner au DCE
  </div>
  <!-- END Back TO Dce -->

  <!-- 1- Indice -->
  <div class="indice-wrapper flex-middle" *ngIf="currentView != 'exportDocs'" >

    <div class="pointer title-div">
      <h3 id="project-title" (click)="toggleEditProjectOverlayPanel($event)">
        {{ projectTitle }} <span class="caret"></span>
      </h3>
    </div>
    
    <div class="indice-container pointer margin-left-15 flex-middle" (click)="emitOpenIndiceModal()">
      Indice {{ dpgfCurrentVersion | pricingIndex }}
      <i class="caret margin-left-4"></i>
    </div>
  </div>
  <!-- 1- END Indice -->

  <!-- 2- Tabs -->
  <div class="tabs-wrapper">
    

    <div class="main-tabs-container flex">
      
      <!-- Non Export Docs View -->
      <ng-container *ngIf="currentView != 'exportDocs'">
        <div class="tab pointer" [ngClass]="{'selected': currentView == 'redaction'}" (click)="changeTab('redaction')">
          <p>Rédaction</p>
        </div>
        <div class="tab pointer" [ngClass]="{'selected': currentView == 'documents'}" (click)="changeTab('documents')">
          <p>Documents</p>
        </div>
      </ng-container>
      <!-- END Non Export Docs View -->
      
      <!-- Export Docs View -->
      <ng-container *ngIf="currentView == 'exportDocs'">
        <div class="tab docs-tab pointer padding-right-8" [ngClass]="{'selected': exportDocsTab == 'export'}" (click)="changeExportTab('export')">
          <p>Contenu de l'export</p>
        </div>
        <div class="tab docs-tab pointer" [ngClass]="{'selected': exportDocsTab == 'perso'}" (click)="changeExportTab('perso')">
          <p>Personnalisation</p>
        </div>
      </ng-container>      
      <!-- END Export Docs View -->      

      
    </div>



  </div>
  <!-- 2- END Tabs -->

  <!-- 3- Export -->
  <div class="export-wrapper flex">

    <!-- Checked Posts Action Buttons -->
    <div class="checked-posts-action-buttons flex center-items" *ngIf="checkedPostsNumber">

      <div class="margin-right-10">
        <div class="pointer" [pTooltip]="'Dupliquer'" (click)="emitDuplicateManyPosts()">
          <i class="fa fa-files-o"></i>
        </div>
      </div>
      <div class="margin-right-10" (click)="emitDeleteManyPosts()">
        <div class="pointer" [pTooltip]="'Supprimer'">
          <i class="fa fa-trash"></i>
        </div>
      </div>
      <div class="margin-right-10" *ngIf="!authService.currentEnvironment || authService.currentEnvironment && currentUser.isAdminArchitect">
        <div class="pointer" (click)="emitSendDbPosts()" [pTooltip]="'Exporter vers ma bibliothèque'">
          <i class="fa fa-cloud-download"></i>
        </div>
      </div>
      <div class="margin-right-10">
        <div class="pointer" (click)="emitMovePoste()" [pTooltip]="'Déplacer dans un autre lot'">
          <i class="fa fa-share"></i>
        </div>
      </div>

      <div class="margin-right-10">
        <div class="pointer" (click)="emitFlagPosts()" [pTooltip]="'Ajouter un indicateur'">
          <i class="fa fa-flag"></i>
        </div>
      </div>
      
      <div class="divider"></div>      
      
    </div>
    <!-- END Checked Posts Actions -->

    <!-- Changes notification -->
    <div *ngIf="changes != 0">
      <div type="button" class="pointer modif-alert" (click)="emitSaveData()">
        <img src="/assets/archi/save_white_24dp.svg" class="save-icon"/>
        <span>{{ changes }} Modifications non enregistrée{{ changes >1?'s':''}} </span>
      </div>
    </div>
    <!-- END Changes notification -->

    <!-- Saving Spinner -->
    <div *ngIf="saving" data-step="3" data-intro="Cliquez ici pour enregistrer" class="margin-right-10">
      <i class="fa fa-spinner fa-spin fa-fw"></i> <span class="hide-min">Enregistrement en cours</span>
    </div>
    <!-- END Saving Spinner -->

    <div class="flex-middle pointer margin-right-30" (click)="emitSaveData()" *ngIf="!saving">
      <img src="/assets/archi/save.svg" class="margin-right-10 image-size" alt="save icon">
      <p>Enregistrer</p>
    </div>
    
    <app-spinner *ngIf="exportingDocs"></app-spinner>
    <div class="flex-middle pointer" *ngIf="!exportingDocs" (click)="emitChangeViewOrExportDocs()">
      <img src="/assets/archi/export-icone.svg" class="margin-right-10 save-image" alt="save icon">
      <p>Exporter</p>
    </div>

  </div>
  <!-- 3- END Export -->



</div>

<!-- Overlay modal -->
<p-overlayPanel [style]="{'width': '350px'}" #projTitle (onHide)="emitOverlayClosed()">
  <ng-template pTemplate>

    <div class="margin-bottom-10">
      <label>Nom du Projet</label>
      <input type="text" class="proj-input" [(ngModel)]="projectTitle"
      (ngModelChange)="emitUpdateProject({ key: 'projectTitle', value: $event })">
    </div>

    <div class="margin-bottom-10">
      <label>TVA par défault</label>
      <input [ngModel]="defaultTva" placeholder="TVA par défault" type="number"  
      (ngModelChange)="emitSetDpgfAttribute({ key  : 'defaultTva', value : $event})">
    </div>

    <div class="flex space-between center-items">
      <label class="toggle-off no-margin-bottom">Numérotation automatique</label>
      <span class="padding-top-5">
        <p-inputSwitch [(ngModel)]="isAutomateNumberIsChecked" (click)="automateNumberChanged()"></p-inputSwitch>
      </span>
    </div>

  </ng-template>
</p-overlayPanel>
<!-- END Overlay modal -->
