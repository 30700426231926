<div class="modal fade" id="user-on-pro-license-modal" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="title">Action non comprise dans votre abonnement</h6>
        </div>
        <div class="modal-body">
          <p>
            Vous êtes actuellement en licence Pro et ne pouvez pas réaliser cette action. Souscrivez à une licence Max ou Intégral pour y accéder.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-cancel ag-btn-tertiary-sm margin-right-16" data-dismiss="modal">Annuler</button>
          <button type="button" class="btn-accept ag-btn-tertiary-sm" data-dismiss="modal" (click)="redirectToLicensesPage()">
            Voir les licences
          </button>
        </div>
      </div>
    </div>
  </div>
  