import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ArchitectService } from '../../../_services/index';
import { Subscription,Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { ModalCreateDepartementComponent } from "../modal-create-departement/modal-create-departement.component";
import { HeaderComponent } from "../../../all/header/header.component";
import { FileUpload } from 'primeng/fileupload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';

declare var $:any;
@Component({
    selector: 'app-admin-departement',
    standalone: true,
    templateUrl: './admin-departement.component.html',
    styleUrl: './admin-departement.component.scss',
    imports: [NgSharedModule, ModalCreateDepartementComponent, HeaderComponent]
})
export class AdminDepartementComponent {
  @ViewChild('fileUpload3') fileUpload: FileUpload;
  public userForm: FormGroup;
  public loadingPage=false;dataArchitect;departements;searchText='';teamDepartement=[];selectedUser;userToRemove;
  public page:number = 0;archiInfo;displaySide=false;departement;query='';usersFound;choosenUser;actuelModal="";name;emailAgency;showBox=false;
  public perPage:number = 50;
  public totalPages:number = 1;actuelDepar;
  private subscriptions: Array<Subscription> = [];
  public numbers:number[] = [1];
  public searchUsers = new Subject<string>();
  public files;
  public isAdmin: boolean = false;
  public userId:string = "";
  constructor(private architectService: ArchitectService,public authService: AuthService,private messageService: MessageService,private fb: FormBuilder,private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Départements');
    this.getDepartements();
    this.listenForUsersSearch();
    this.createForm()
  }
  createForm(){
    this.userForm = this.fb.group({
      telAgency: ['', Validators.required],
      name: ['', Validators.required],
      emailAgency: ['', [Validators.required, Validators.email]]
    });
  }
  submitForm() {
    if (this.userForm.valid) {
      this.updateArchi(this.userForm.value);
    }
  }
  changePage(newPage){
    this.page = newPage;
    this.getDepartements()
  }
  selectDepartement(departement){
    this.departement = departement;
    this.fileUpload.onBasicUploaderClick()
  }
  public onUploadLogoDepartement(event){
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/jpng'];
    const file = event.files[0];
    if (!allowedTypes.includes(file.type)) {
    // Display an error or handle the rejection
    this.messageService.add({ key: 'toast', severity: 'error', summary: "Format d'image non pris en charge", detail: "Le format d'image que vous importez n'est pas pris en charge. Les formats supportés sont : png, jpg et jpeg", life: 5000 });

    // Optionally reset the file input
    // event.clear();  // This is to clear the file selection
  } else {
    // Proceed with your upload logic
    this.departement.logo = event.originalEvent.body[0];
    this.updateArchi({logo:event.originalEvent.body[0]});
  }
  }
  getDepartements(){
    this.loadingPage = false;
    this.subscriptions.push(
    this.architectService.getAllArchiProfile(this.page,this.perPage,this.searchText)
        .subscribe(
            resp => {
                this.departements = resp.data;
                this.totalPages = Math.ceil(resp.metadata.count / this.perPage);
                this.numbers = Array(this.totalPages).fill(1).map((x,i)=>i+1);
                this.loadingPage = true;
            },
            error => {
                console.log("error",error)
            })
    )
  }
  public showSlider(depart){
    this.departement = depart;
    console.log('this.departement',this.departement)
    this.userForm.patchValue(depart);
    this.displaySide = true;
    this.getCollaborateur(this.departement._id)
  }
  public listenForUsersSearch(){
    this.searchUsers.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(value=>{this.search()})
  }
  public search(){
    if(this.query.length>0){
      let usersIds = this.teamDepartement.map(user=>user._id)
    this.subscriptions.push(
    this.architectService.searchDepartement({query:this.query,usersIds})
        .subscribe(
            resp => {
                this.usersFound = resp;
                var divToHide = document.getElementById('myInputautocomplete-list');
                divToHide.style.display = 'block';
            },
            error => {
                console.log("error",error)
            }))
    }else {
      this.usersFound = [];
      var divToHide = document.getElementById('myInputautocomplete-list');
      divToHide.style.display = 'none';
    }
  } 

  public removeArchi(id,architect){
    let obj = {
      userID: id,
      archiID:architect._id,
      add:false
    }
    this.subscriptions.push(
    this.architectService.updateUserAndArchitect(obj).subscribe(
            resp => {
                this.departement = resp;
                this.departements = this.updateSingleDepartementInDepartements(this.departements,resp,id,true);
                $('#delete-user').modal('hide')
                this.getCollaborateur(resp._id)
            },
            error => {
                console.log("error",error)
    })
    )
  }
  public getUserToAdd(user){
    this.actuelModal = "";
    this.actuelModal = "add-user";
    $('#add-user').modal('show');
    this.selectedUser = user;
  }
  public getUserToDelete(item){
    this.actuelModal = "";
    this.actuelModal = "delete-user";
    $('#delete-user').modal('show');
    this.userToRemove = item;
  }
  public addUserToDepartement(id,architect){
    let obj = {
      userID: id,
      archiID:architect._id,
      add:true
    }
    this.subscriptions.push(
    this.architectService.updateUserAndArchitect(obj).subscribe(
            resp => {
                this.departement = resp;
                this.departements = this.updateSingleDepartementInDepartements(this.departements,resp,id,true)
                this.getCollaborateur(resp._id);
                $('#add-user').modal('hide')
              },
            error => {
                console.log("error",error)
    })
    )
  }
  public getCollaborateur(archiId){
    this.subscriptions.push(
    this.architectService.getCollaborateur(archiId).subscribe(
      resp => {
        this.teamDepartement = resp;
      },
      error => {
          console.log("error",error)
      })
      )
  }
  public updateArchi(data){
    this.subscriptions.push(
    this.architectService.update(this.departement._id,{agencyData:data}).subscribe(
      resp => {
        this.departement = resp;
        this.departements = this.updateSingleDepartementInDepartements(this.departements,resp)
      },
      error => {
          console.log("error",error)
      })
    )
  }
  public updateSingleDepartementInDepartements(departments, updatedDepartment,userID?,add?) {
    return departments.map((singleDepartement) => {
      if (singleDepartement._id === updatedDepartment._id) {
        return updatedDepartment;
      }
      let exist = singleDepartement.users.includes(userID)
      let notEmptyTab = (singleDepartement.users||{}).length;
      if(add && singleDepartement._id!==updatedDepartment._id && notEmptyTab && exist){
      // if(singleDepartement!==updatedDepartment.id && add && (singleDepartement.users||{}).length && singleDepartement.users.includes(userID)){
        singleDepartement.users = singleDepartement.users.filter((user)=>user!=userID)
      }
      return singleDepartement;
    });
  }  public clickOutsiteDiv(){
    if(this.usersFound){
      var divToHide = document.getElementById('myInputautocomplete-list');
      document.onclick = function(e){
          divToHide.style.display = 'none';
      };
    }
  }
  public sideBarClose(){
    this.displaySide = false;
  }
  public createDepartement(newDep){
    this.departements.unshift(newDep)
  }
  public openModal(user){
    console.log('open modal',user)
    this.choosenUser = user
    this.isAdmin = user.isAdminArchitect || false;
    $('#modal-update-adminArchitect').modal('show');
  }
  onCancel(): void {
    $('#modal-update-adminArchitect').modal('hide');
  }

  onSave(): void {
    this.subscriptions.push(
      this.architectService.updateUserStatus(this.choosenUser._id,{isAdminArchitect:this.isAdmin}).subscribe(
              resp => {
                $('#modal-update-adminArchitect').modal('hide');
                // this.teamDepartement.forEach(elem=>{
                //   if(elem._id==this.choosenUser._id){
                //     elem.isAdminArchitect = this.isAdmin
                //   }
                //   })
                this.getCollaborateur(this.departement._id)
                },
              error => {
                  console.log("error",error)
      })
      )
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
