import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  
  public currentPage: number = 1;
  @Input() numberOfPages: [] = []; // we need an array with number of total pages so we can apply ngFor
  @Output() pageSelected: EventEmitter<any> = new EventEmitter<any>();

  public emitNextPage(){
    if(this.currentPage == this.numberOfPages.length) return;

    let page = ++this.currentPage;
    this.pageSelected.emit(page);
  }
  
  public emitPrevPage(){
    if(this.currentPage == 1) return;

    let page = --this.currentPage;
    this.pageSelected.emit(page);
  }

  public emitPageSelected(page){
    this.currentPage = page;
    this.pageSelected.emit(page);
  }

}
