import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class ArchiDbService {

  constructor(private http:HttpClient) { }

  // CRUD Lot
  createLot(data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/user-lot`,body, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  getLot(lotId) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/${lotId}`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  getLotWithPosts(lotId) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/${lotId}/posts`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  getAllLot(fields,myDbOnly,objQuery) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot?fields=${fields}&myDbOnly=${myDbOnly}&dpgfId=${objQuery?.dpgfId}&archiId=${objQuery?.archiId}&idLot=${objQuery?.idLot}`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  getAllLotAdmin(obj) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot-admin?archiId=${obj?.archiId}&idLot=${obj?.idLot}`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  updateLot(lotId,data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/user-lot/` + lotId , body, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  deleteLot(lotId): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.delete(`/api/v1/user-lot/` + lotId , options)
    .pipe(map((response: any) => {
      return response.data;
    }));
  }
  // CRUD Lot
  // `/user-lot/:lotId/user-post`
  createPost(lotId,data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/user-lot/${lotId}/user-post`,body, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  createMultiPost(lotId,data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/user-lot/${lotId}/user-post/createMulti`,data, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  updateLotParentMultiPost(lotId,data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/user-lot/${lotId}/user-post/updateMultiLotParent`,data, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  getAllPost(lotId,page,archiId='') : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/${lotId}/user-post/?page=${page}&id=${archiId}`, options)
          .pipe(map((response: any) => {
              return response;
          }));
  }
  getCountPostsOwn(archiId=''): Observable<any> {
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/count?archiId=${archiId}`, options)
          .pipe(map((response: any) => {
              return response;
          }));
  }
  getPost(lotId,postId) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/${lotId}/user-post/${postId}`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  getPostByTitle(title,lotId) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/${lotId}/user-post-search/${title}`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  updatePost(lotId,postId,data): Observable<any> {
    let body = JSON.stringify(data)
    let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/user-lot/${lotId}/user-post/${postId}` , body, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  deletePost(lotId,postId): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.delete(`/api/v1/user-lot/${lotId}/user-post/${postId}`, options)
    .pipe(map((response: any) => {
      return response.data;
    }));
  }
  deleteMultiPost(lotId,posts): Observable<any> {
    let options = new Helpers().getHeader();
    let body = JSON.stringify({posts:posts})
    return this.http.post(`/api/v1/user-lot/${lotId}/user-post/deleteMulti`,body, options)
    .pipe(map((response: any) => {
      return response.data;
    }));
  }
  getLotPath(lotId) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/user-lot/${lotId}/path`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  changePostOrder(data,lotId): Observable<any> {
    let options = new Helpers().getHeader();
    let body = JSON.stringify(data)
    return this.http.post(`/api/v1/user-lot/${lotId}/user-post/order`,body, options)
    .pipe(map((response: any) => {
      return response.data;
    }));
  }
  changeLotOrder(data,lotId): Observable<any> {
    let options = new Helpers().getHeader();
    let body = JSON.stringify(data);
    return this.http.post(`/api/v1/user-lot/${lotId}/order`, body, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
  }
}
