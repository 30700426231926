<div class="modal fade" id="import-lot" >
  <div class="modal-dialog">
    <div class="modal-container" >
      <div class="head">
        <a (click)="close()" class="close"><i class="fa fa-times" aria-hidden="true"></i></a>
      </div>
      <div class="row">
        <!-- <div class="col-md-12">
          <h1>Importer des lots</h1>
        </div> -->
        <div class="col-md-12" *ngIf="loading" >
          <app-spinner style="margin:20px"></app-spinner>
        </div>
        <div class="col-md-12">
          <div id="form-top" class="col-xs-12 top">
            <ul class="nav nav-pills nav-justified">
              <li class="nav-item active"><a data-toggle="tab" href="#own">Mes Modèles</a></li>
              <li class="nav-item"><a data-toggle="tab" href="#template">Templates</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          <div class="tab-content">
            <div id="own" class="tab-pane fade in active">
              <div class="col-md-12" style="margin:15px 0px;">
                <div *ngFor="let oneDpgf of dpgfs;let i = index">
                  <div class="one-dce">
                    <i *ngIf="!oneDpgf.open" (click)="getDpgf(i)" class="fa fa-chevron-right" aria-hidden="true"></i>
                    <i *ngIf="oneDpgf.open" (click)="oneDpgf.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
                    <i *ngIf="!oneDpgf.open" class="fa fa-folder" aria-hidden="true"></i>
                    <i *ngIf="oneDpgf.open" class="fa fa-folder-open" aria-hidden="true"></i>
                    {{oneDpgf?.nomPrenom || oneDpgf.title}}
                  </div>
                  <app-spinner *ngIf="oneDpgf.open && loadingLot" style="margin:20px"></app-spinner>
                  <div *ngIf="oneDpgf.open && !loadingLot">
                    <div class="sommaire-pill" *ngFor="let lot of lots;let loti = index">
                      <p-checkbox [(ngModel)]="lot.selected" binary="true"></p-checkbox>
                      <i *ngIf="(lot.icon||'') == ''" class="fa fa-file" aria-hidden="true"></i>
                      <img *ngIf="(lot.icon||'') != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
                      <span class="lot-sommaire">{{loti}} - {{lot.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12" style="margin:10px 0px;">
                <p-paginator [rows]="15" [totalRecords]="count" (onPageChange)="listAllDpgf($event)"></p-paginator>
              </div>
              <button class='btn-primary-border' (click)="chooseLot()">Importer les lots sélectionnés</button>
            </div>
            <div id="template" class="tab-pane fade in">
              <div *ngIf="dpgfsTemplates.length == 0" class="col-md-12" style="margin:15px 0px;">
                <img src="/assets/timeline.svg" alt="" class="img-soon">
                <h1>Bientôt disponible</h1>
              </div>
              <div *ngIf="dpgfsTemplates.length != 0" class="col-md-12" style="margin:15px 0px;">
                <div *ngFor="let oneDpgf of dpgfsTemplates;let i = index">
                  <div class="one-dce">
                    <i *ngIf="!oneDpgf.open" (click)="getDpgfTemplates(i)" class="fa fa-chevron-right" aria-hidden="true"></i>
                    <i *ngIf="oneDpgf.open" (click)="oneDpgf.open = false" class="fa fa-chevron-down" aria-hidden="true"></i>
                    <i *ngIf="!oneDpgf.open" class="fa fa-folder" aria-hidden="true"></i>
                    <i *ngIf="oneDpgf.open" class="fa fa-folder-open" aria-hidden="true"></i>
                    {{oneDpgf.nomPrenom}}
                  </div>
                  <app-spinner *ngIf="oneDpgf.open && loadingLotTemplates" style="margin:20px"></app-spinner>
                  <div *ngIf="oneDpgf.open && !loadingLotTemplates">
                    <div class="sommaire-pill" *ngFor="let lot of lotsTemplates;let loti = index">
                      <p-checkbox [(ngModel)]="lot.selected" binary="true"></p-checkbox>
                      <i *ngIf="(lot.icon||'') == ''" class="fa fa-file" aria-hidden="true"></i>
                      <img *ngIf="(lot.icon||'') != ''" class="handle grab-it" [src]="'assets/dpgf/Icon_DPGF/' +lot.icon" alt="" height="15">
                      <span class="lot-sommaire">{{loti}} - {{lot.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12" style="margin:10px 0px;">
                <p-paginator [rows]="15" [totalRecords]="countTemplates" (onPageChange)="listAllDpgfTemplates($event)"></p-paginator>
              </div>
              <button class='btn-primary-border' (click)="chooseLotTemplates()">Importer les lots sélectionnés</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
