import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';
import { ArchitectService } from 'src/app/_services';
import {FormBuilder, FormGroup,  Validators} from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { MessageService } from 'primeng/api';

declare var $;

@Component({
  selector: 'app-modal-create-user',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './modal-create-user.component.html',
  styleUrl: './modal-create-user.component.scss'
})
export class ModalCreateUserComponent {
  @Output() userCreated = new EventEmitter();
  public lastName;firstName;email;emailExist=false;loading=false;newUser;emailAlreadyExist = '';autorise=false;
  private subscriptions: Array<Subscription> = [];

  public subscriptionForm: FormGroup;
  public formErrors = {'firstName': '','lastName': '','email': ''};
  private validationMessages = {
    'firstName': {
      'required': 'Le prénom est obligatoire.'
    },
    'lastName': {
      'required': 'Le nom est obligatoire.',
    },
    'email': {
      'required': 'Le mail est obligatoire.',
      'email':'Email invalide'
    }
  };
  constructor(private architectService :ArchitectService,private messageService:MessageService,private fb: FormBuilder,private _notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.createForm();
  }
  public createUser(){
    let user={
      lastName:this.subscriptionForm.value.lastName,
      firstName:this.subscriptionForm.value.firstName,
      email:this.subscriptionForm.value.email,
      typeEntreprise:'archi',
      cgu:true,
      isAdmin:false,
      licence:"premiumn-annuel",
      isAdminArchitect:false
    }
    // this.onValueChanged(this.subscriptionForm.value);
    // if(this.subscriptionForm.value.email && this.subscriptionForm.value.firstName &&this.subscriptionForm.value.lastName){
    if(this.isValid()) {
      this.autorise = true;
      this.loading=true;
      this.emailAlreadyExist = "";
    this.subscriptions.push(
      this.architectService.createNewUser(user).subscribe((data) => {
        this.newUser = data;
        this.userCreatedEmit()
        $("#create-user").modal('hide');
        this._notificationService.success("Succés","L'utilisateur' a été créé", {timeOut: 3000});
        this.loading=false;
      },err=>{
        console.log('err',err.error)
        this.loading=false;
        if(err.error.err.message == "You already reached maximum users") {
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Limite d'utilisateurs atteinte" , detail: "Vous avez atteint la limite maximale de 50 utilisateurs.", life: 10000 });
          $('#create-user').modal('hide');
      }else{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "L'utilisateur' n'a pas été créé", life: 5000 });
          this._notificationService.error("Erreur","L'utilisateur' n'a pas été créé", {timeOut: 3000});
          if(err.error.err.message=='Mail already exist'){
            this.emailExist = false;
            this.emailAlreadyExist = "Ce mail est déja assigné à un autre utilisateur"
          }
      }
      })
    )
    setTimeout(() => {
      this.autorise = false;
    }, 1000)
    }
  }
  public createForm(){
    this.subscriptionForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
    });
    this.subscriptions.push(
      this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    )
    this.onValueChanged();
  }
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  public markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
    this.markFormAsTouched();
    return this.subscriptionForm.valid
  }
  public checkMailExistence(){
    this.subscriptions.push(
      this.architectService.checkMailExistence({email:this.subscriptionForm.value.email}).subscribe((data) => {
        this.emailExist = Object.keys(data).length>0 ? false : true;
        this.emailAlreadyExist = !this.emailExist ? 'Ce mail est déja assigné à un autre utilisateur' : ''
      })
    )
  }
  public userCreatedEmit(){
    this.userCreated.emit(this.newUser);
  }
}
