import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';

@Injectable()
export class DpgfPostesService {
    public token: string;
    public dpgfId:string;

    public selectedProjectId = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    searchWord(word,sort) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-postes?word=${word}&sort=${sort}` , options)
          .pipe(map((response: any) => {
              return response.data;
          }));
    }
    suggestPoste(word,tags) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-entire-postes-suggestion?word=${word}&tags=${tags}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    suggestCstb(data) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/cstb-suggestions`,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    suggestLocalisation(word) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-postes-suggestion?word=${word}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    searchLotWord(word) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/dpgf-lots?word=' + word , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    // getUnite(poste) : Observable<any[]>{
    //   let options = new Helpers().getHeader();
    //     return this.http.get('/api/v1/dpgf-unite?poste=' + poste , options)
    //         .pipe(map((response: any) => {
    //             return response.data;
    //         }));
    // }

    create(architectID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/architects/' + architectID  + '/dpgfs' ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    createMoa(moaID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/moas/dpgfs' ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    duplicate(dpgfID,ceProject,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/dpgfs/${dpgfID}/duplicate?ceProject=${ceProject}` ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    createPricing(dpgfID,ceInviteId,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/dpgfs/${dpgfID}/ce-invite/${ceInviteId}/dpgf-pricings` ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    listDpgf(architectId,page = 0, filter=''): Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/architects/${architectId}/dpgfs?page=${page}&filter=${filter}` , options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    listDpgfTemplates(page = 0): Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/templates?page=${page}` , options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    listDpgfPricing(dpgfID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/${dpgfID}/dpgf-pricings`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getDpgf(dpgfID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/${dpgfID}?lots=yes`  , options)
            .pipe(map((response: any) => {
              let dpgf=response.data;
              const { ceProject } = dpgf;
              const { _id, title, demo, isAutomateNumberIsChecked, licence,
                projetSensible, maitreDouvrage, operationNumber, operation, projetNumber, marcheNumber, marche, collaborateurs, user, architect,
                mainImage
              } = ceProject;
              dpgf.projectData = { 
                id: _id,
                title: title, 
                demo: demo,
                isAutomatic: isAutomateNumberIsChecked,
                licence: licence,
                projetSensible,
                maitreDouvrage,
                operationNumber,
                operation,
                projetNumber,
                marcheNumber,
                marche,
                collaborateurs,
                user,
                mainImage,
                architect
              }
              dpgf.ceProject=dpgf.projectData.id
              return dpgf;
            }));
    }
    getDpgfWithPostes(dpgfID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/${dpgfID}?lots=yes` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getSousLotsData(dpgfId,lotId):Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgfs/${dpgfId}/lot/${lotId}/sousLots`,options).pipe(map((response:any)=>{
        return response.data;
      }))
    }
    getDpgfLot(dpgfID,dpgfLoID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/${dpgfID}/lot/${dpgfLoID}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getDpgfLotPostes(dpgfID,dpgfLoID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/${dpgfID}/lot/${dpgfLoID}/postes` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    setDpgfLot(dpgfID,dpgfLoID,data,query) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${dpgfID}/lot/${dpgfLoID}${query}`, data , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    addManyDpgfLot(dpgfID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/dpgfs/${dpgfID}/new-lot`, data , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    setDpgfLotFiles(dpgfID,dpgfLoID,data,query) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${dpgfID}/lot/${dpgfLoID}/files${query}`, data , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    setDpgfLotStatus(dpgfID,dpgfLoID,data) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/dpgfs/${dpgfID}/lot/${dpgfLoID}/status`, data , options)
        .pipe(map((response: any) => {
          return response.data;
        }));
    }
    createDpgfLot(dpgfID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/dpgfs/${dpgfID}/lot`, data , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    updateDpgfLotOrder(dpgfID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${dpgfID}/lot`, data , options)
          .pipe(map((response: any) => {
            return response.data;
          }));
    }
    updateDpgfLotsIndex(dpgfID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${dpgfID}/update-lots-index`, {} , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    updateDpgfOptions(dpgfID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${dpgfID}/options`, data , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    deleteDpgfLot(dpgfID,dpgfLoID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.delete(`/api/v1/dpgfs/${dpgfID}/lot/${dpgfLoID}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getDpgfPricing(dpgfID,code) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-pricings/${dpgfID}/${code}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getDpgfPricingLot(dpgfID,lotId,code) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-pricings/${dpgfID}/lot/${lotId}/${code}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    setDpgfPricingLot(dpgfID,dpgfLoID,code,data,query) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgf-pricings/${dpgfID}/lot/${dpgfLoID}/${code}${query}`, data , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getDpgfVersions(dpgfID,page,currentVersion) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgfs/${dpgfID}/minor-version?page=${page}&currentVersion=${currentVersion}` , options)
            .pipe(map((response: any) => {
                return response;
            }));
    }
    getDpgfMinorVersion(versionId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-minor-version/${versionId}` , options)
            .pipe(map((response: any) => {
              response.data.dpgf['projectData']={id:response.data.ceProject._id,title:response.data.ceProject.title, demo:response.data.ceProject.demo,isAutomatic:response.data.ceProject.isAutomateNumberIsChecked,licence:response.data.ceProject.licence}
              return response.data;
            }));
    }
    getDpgfMinorVersionLot(versionId,lotId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/dpgf-minor-version/${versionId}/lot/${lotId}` , options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    restoreDpgfMinorVersion(versionId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${versionId}/minor-version` ,{}, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    patchDpgf(dpgfID,data,query) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch('/api/v1/dpgfs/' + dpgfID + query ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    patchDpgfNoVersion(dpgfID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/dpgfs/' + dpgfID ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    patchDpgfToNextVersion(dpgfID,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgfs/${dpgfID}/next-version` ,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    patchDpgfPricing(dpgfID,code,data,query) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/dpgf-pricings/${dpgfID}/${code}${query}`,data, options)
            .pipe(map((response: any) => {            
                return response.data;
            }));
    }
    patchDpgfPricingToNextVersion(dpgfID,dpgfPricingId,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.post(`api/v1/dpgfs/${dpgfID}/dpgf-pricings/${dpgfPricingId}`,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    patchDpgfPricingFinished(dpgfPricingId,code,data) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`api/v1/dpgf-pricings/${dpgfPricingId}/${code}/finished`,data, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    markDocsAsDownloaded(dpgfPricingId) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.patch(`api/v1/dpgf-pricings/${dpgfPricingId}/docs/mark-as-downloaded`,{}, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    deleteDpgf(dpgfID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.delete(`/api/v1/dpgfs/${dpgfID}`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    /// Download Excel
    downloadDCEExcel(dpgfId, selectedLots, includeEstimation=false): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-dce`, { selectedLots, includeEstimation }, options);
    }
    downloadSummaryExcel(dpgfId, pricingsIds, varianteId='',varianteTitle='', varianteDate=''): Observable<any>{
      let options = new Helpers().getHeader();
      let link = `/api/v2/dpgfs/${dpgfId}/generate-summary?varianteId=${varianteId}&varianteTitle=${varianteTitle}&varianteDate=${varianteDate}`;
      return this.http.post(link, {pricingsIds}, options);
    }
    downloadArtisanChiffrageExcel(dpgfPricingId, selectedLots, code): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfPricingId}/generate-artisan-chiffrage-excel`, { selectedLots }, options);
    }
    /// Download pdf
    downloadCCTPPdf(dpgfId, selectedLots, pdfInputs, lotName): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-cctp-pdf?singleLot=${lotName}`, { selectedLots, pdfInputs }, options);
    }
    singleDownloadCCTPPdf(dpgfId, selectedLots, lotName, pdfInputs){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-cctp-pdf?singleLot=${lotName}`, { selectedLots, pdfInputs }, options).toPromise();
    }
    downloadDPGFPdf(dpgfId, selectedLots, pdfInputs): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-dpgf-pdf`, { selectedLots, pdfInputs }, options);
    }
    downloadFacturePdf(dpgfId, dpgfFactureId, body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-facture-pdf/${dpgfFactureId}`, body, options);
    }
    singleDownloadDPGFPdf(dpgfId, selectedLots,lotName, pdfInputs){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-dpgf-pdf?singleLot=${lotName}`, { selectedLots, pdfInputs }, options).toPromise();
    }
    downloadDQEPdf(dpgfId, selectedLots, pdfInputs): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-dqe-pdf`, { selectedLots, pdfInputs }, options);
    }
    singleDownloadDQEPdf(dpgfId, selectedLots, lotName, pdfInputs){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-dqe-pdf?singleLot=${lotName}`, { selectedLots, pdfInputs }, options).toPromise();
    }
    downloadEstimatifPdf(dpgfId, selectedLots, lotName, pdfInputs): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-estimatif-pdf?singleLot=${lotName}`, { selectedLots, pdfInputs }, options);
    }
    singleDownloadEstimatifPdf(dpgfId, selectedLots, lotName, pdfInputs){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-estimatif-pdf?singleLot=${lotName}`, { selectedLots, pdfInputs }, options).toPromise();
    }
    downloadDevisPdf(dpgfId, selectedLots, devisType = '',sender = {}, code): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs-pricing/${dpgfId}/generate-artisan-devis-pdf/${code}`, { selectedLots, devisType, sender }, options);
    }
    downloadDpgfPdfArtisan(dpgfId, code): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs-pricing/${dpgfId}/generate-artisan-dpgf-pdf/${code}`, {  }, options);
    }
    downloadCCTPPricingdf(dpgfId, selectedLots, code,isArtisan): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs-pricing/${dpgfId}/generate-cctp-pdf/${code}`, { selectedLots,isArtisan }, options);
    }
    // /dpgfs/media
    // proxyS3File(fileId): Observable<any>{
    //   let options = new Helpers().getHeader();
    //   return this.http.get(`/api/v2/dpgfs/media/${fileId}`, {responseType:  'blob', headers: options.headers});
    // }

    /// Download docx
    downloadCCTPDocx(dpgfId, selectedLots): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-cctp-docx`, { selectedLots }, options);
    }
    singleDownloadCCTPDocx(dpgfId, selectedLots,lotName){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/generate-cctp-docx?singleLot=${lotName}`, { selectedLots }, options).toPromise();
    }

    searchElasticSearchAllPosts(terms,isLicence,collabs=[]): Observable<any>{
      return terms.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>{
        if(isLicence)return this.searchElasticSearchPostsCSTB(term,0,collabs)
        else return this.searchElasticSearchPosts(term)
      })
    }
    /// Search ElasticSearch Posts
    searchElasticSearchPosts(term): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgf-post-prix-construction`, {query: term}, options)
        .pipe(map((response: any) => {
          let results=[];
          response.data.data.forEach((elem) => {
            results.push({sugg: elem.title, cctp: elem.cahierDesCharges});
          });
          return results;
        }));
    }
    /// Search ElasticSearch Posts CSTB
    searchElasticSearchPostsCSTB(term,size=0,collabs=[]): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgf-post-cstb`, {query: term,size:size,collabs:collabs}, options)
        .pipe(map((response: any) => {
          let results=[];
          response.data.cstb.forEach((elem) => {
            results.push({sugg: elem.title, gid: elem.gid,type:elem._index,cctp:elem.cctp});
          });
          return {cstb:results,userPost:response.data.userPost};
        }))
    }
    getCCTPCSTBFromElastic(itemId,ceprojectId): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgf-post-cstb/cctp/${itemId}?projectid=${ceprojectId}`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    // CSTB Navigation
    getAllCstbLotsNoChildren(): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgf-cstb/lots`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    getCstbLotById(lotId): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgf-cstb/lot/${lotId}`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    /// Upload DCE Excel
    UploadDCEExcel(dpgfId, excelFile): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v2/dpgfs/${dpgfId}/read-excel`, {excelFile}, options);
    }
    /// Update Localisations
    updateLocalisations(dpgfId, localisations): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/dpgf/${dpgfId}/localisations`, {localisations}, options);
    }
    /// Create Factice Artisan
    createFacticeArtisan(dpgfID,ceProjectId,data) : Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgfs/${dpgfID}/ce-projects/${ceProjectId}/create-factice`, data, options)
        .pipe(map((response: any) => {
          return response.data;
      }));
    }
    /// Factice Artisan - Send Mail to Any 1
    sendFacticeArtisanMail(ceProjectInvitesId, data: any): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/ce-project-invites/${ceProjectInvitesId}/send-factice-mail`, data, options)
        .pipe(map((response: any) => {
          return response.data;
      }));
    }

    /// get Dpgf Info with PricingList total Data
    dpgfPricingListInfo(dpgfID, page = ''): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgfs/${dpgfID}/dpgf-pricing-list-info?page=${page}` , options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    getPricingList(dpgfID, pricingId): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgfs/${dpgfID}/pricing/${pricingId}/pricing-list` , options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    //
    getLotPostsById(dpgfID, lotID, pricingsIds): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgfs/${dpgfID}/lot/${lotID}/posts` , {pricingsIds}, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    getArchiCollabs(): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgfs/get-collabs`,{} , options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    // move posts
    movePostsDPGF(dpgfID, lotDestinationID,sousLotId, posts): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgfs/${dpgfID}/move-posts/${lotDestinationID}` , {posts:posts,sousLotId:sousLotId}, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    moveSection(dpgfID, lotDestinationID,sousLotId, sousLot): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/dpgfs/${dpgfID}/move-section/${lotDestinationID}` , {sousLot:sousLot,sousLotId:sousLotId}, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    /// get dpgf Ce Project Id
    getDpgfCeProjectId(dpgfID): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgfs/${dpgfID}/get-ce-project` , options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    

  /// Send DCE to Multiple Artisans
  sendMultiDCE(dpgfID, body): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/dpgfs/${dpgfID}/send-multi-ce-invites/dpgf-pricings` , body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  
  /// Update DCE For Multiple Artisans
  updateMultiDCE(dpgfId, body): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/dpgfs/${dpgfId}/update-multi-dpgf-pricings` , body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  /// Send Mail to Multiple Artisans
  sendMultiMailToArtisans(dpgfID, body): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/dpgfs/${dpgfID}/send-multi-artisans-emails` , body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }

  createNewDpgfIndice(dpgfID,data,query) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/dpgfs/${dpgfID}/create-new-indice${query}`, data , options)
    .pipe(map((response: any) => {
        return response.data;
    }));
  }
  public getStaticFiles(files): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/get-static-files`,{files}, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  public uploadFile(file): Observable<any> {
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/upload-file`, file, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  public getSizeProject(dpgfID) : Observable<any>{
    let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/dpgfs/${dpgfID}/getSizeProject`, options)
          .pipe(map((response: any) => {
              return response.data;
          }));
  }
  public downloadPdfPreview(dpgfId, body): Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v2/dpgfs/${dpgfId}/index-page-preview`, body, options);
  }

}
