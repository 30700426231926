import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class AcvService {

  constructor(private http: HttpClient) {
  }

  getAcvAndDpgf(ceProjectId) : Observable<any[]>{
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/acv/ceProject/${ceProjectId}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  updateAcvProject(ceProjectId, body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.patch(`/api/v1/acv/ceProject/${ceProjectId}`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  importRSET(ceProjectId, body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/acv/importRSET/${ceProjectId}`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  // Generate Json Comenv
  generateJsonComenv(ceProjectId) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.get(`/api/v1/acv/generate-comenv/${ceProjectId}`, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  /// add Zone for Chantier parcell
  addChantierZone(ceProjectId, zone) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/acv/project-chantier/add-zone/ceProject/${ceProjectId}`, {zone}, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  /// remove Zone from Chantier
  removeChantierZone(ceProjectId, zoneId) : Observable<any>{
    let options = new Helpers().getHeader();
    let body = {ceProjectId, zoneId};
    return this.http.post(`/api/v1/acv/project-chantier/remove-zone/ceProject/${ceProjectId}`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  /// remove Zone from Chantier
  updateChantierZoneValue(ceProjectId, zoneId, body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/acv/project-chantier/ceProject/${ceProjectId}/update-zone-value/${zoneId}`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }

  /// Télécharger l’attestation de fin de travaux
  downloadAttestationFinTravaux(ceProjectId, body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/acv/ceProject/${ceProjectId}/download-attestation-fin-travaux`, body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  downloadAttestationFinTravauxPDF(ceProjectId,body) : Observable<any>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/acv/ceProject/${ceProjectId}/download-attestation-fin-travaux-pdf`, body, options)
      .pipe(map((response: any) => {
          return response;
      }));
  }
  getFicheIniesById(serialId,body) : Observable<any[]>{
    let options = new Helpers().getHeader();
    return this.http.post(`/api/v1/acv/getFicheInies/${serialId}`,body, options)
      .pipe(map((response: any) => {
          return response.data;
      }));
  }
  
}
