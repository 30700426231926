<div class="searchbar-container">

  <!-- Inputs Section -->
  <div class="inputs-section flex margin-bottom-20">

    <div class="input-item margin-right-20">
      <input type="text" placeholder="Saisissez un nom, une adresse" [(ngModel)]="searchQuery.textQuery" (keyup.enter)="submitSearchQuery()">
      <img src="/assets/close-icone-black.svg" class="pointer" *ngIf="searchQuery.textQuery != ''" (click)="emptyTextQuery()">
    </div>

    <!-- (types de travaux) Select -->
    <div class="select-item flex center-items pointer">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- Value -->
          <div class="select-item-value">
            <span class="margin-right-24">{{ optionsMainTitles["natureTravaux"] }}</span>
            <img src="/assets/chevron-bottom.svg" alt="">
          </div>          
          <!-- END Value -->
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li *ngFor="let item of inputSectionMenus.typesDeTravaux" (click)="toggleDropdownItem('natureTravaux', item.value)"
            [ngClass]="{'hidden-item': (item.minarmOnly && !isMinarm)}"
            >
            <input type="checkbox" [checked]="searchQuery['natureTravaux'].includes(item.value)">
            <span class="menu-text">{{ item.label }}</span>
          </li>
        </div>
      </div>
    </div>
    <!-- END (types de travaux) Select -->


    <!-- (programmes) Select -->
    <div class="select-item flex center-items pointer margin-left-16">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- Value -->
          <div class="select-item-value">
            <span class="margin-right-24">{{ optionsMainTitles["type"] }}</span>
            <img src="/assets/chevron-bottom.svg" alt="">
          </div>
          <!-- END Value -->
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li *ngFor="let item of inputSectionMenus.programmes" (click)="toggleDropdownItem('type', item.value)">
            <input type="checkbox" [checked]="searchQuery['type'].includes(item.value)">
            
            <!-- If the item has a label for minarm show it, else show the default label -->
            <span class="menu-text">{{ item.minarmLabel ? item.minarmLabel : item.label }}</span>

          </li>
        </div>
      </div>
    </div>
    <!-- END (programmes) Select -->

    <!-- (Project Status) Select -->
    <div class="select-item flex center-items pointer margin-left-16" *ngIf="!isMinarm">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- Value -->
          <div class="select-item-value">
            <span class="margin-right-24">{{ optionsMainTitles["status"] }}</span>
            <img src="/assets/chevron-bottom.svg" alt="">
          </div>
          <!-- END Value -->
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li *ngFor="let item of inputSectionMenus.projectStatus"
            (click)="toggleDropdownItem('status', item.value)">
            <input type="checkbox" [checked]="searchQuery['status'].includes(item.value)">
            <span class="menu-text">{{ item.label }}</span>
          </li>
        </div>
      </div>
    </div>
    <!-- END (Project Status) Select -->



    <!-- (Minarm Project Status) Select -->
    <div class="select-item flex center-items pointer margin-left-16" *ngIf="isMinarm">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- Value -->
          <div class="select-item-value">
            <span class="margin-right-24">{{ optionsMainTitles["status"] }}</span>
            <img src="/assets/chevron-bottom.svg" alt="">
          </div>
          <!-- END Value -->
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li *ngFor="let item of inputSectionMenus.minarmProjectStatus" (click)="toggleDropdownItem('status', item.value)">
            <input type="checkbox" [checked]="searchQuery['status'].includes(item.value)">
            <span class="menu-text">{{ item.label }}</span>
          </li>
        </div>
      </div>
    </div>
    <!-- (Minarm Project Status) Select -->

    <!-- (Minarm Architects names) Select -->
    <div class="select-item flex center-items pointer margin-left-16" *ngIf="isMinarm">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- Value -->
          <div class="select-item-value">
            <span class="margin-right-24">{{ optionsMainTitles["architectsNames"] }}</span>
            <img src="/assets/chevron-bottom.svg" alt="">
          </div>
          <!-- END Value -->
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li *ngFor="let architect of inputSectionMenus.minarmArchitectsNames" (click)="minarmArchitectSelected('architectsNames', architect._id)">
            <input type="checkbox" [checked]="searchQuery['architectsNames'].includes(architect._id)">
            <span class="menu-text">{{ architect.name }}</span>
          </li>
        </div>
      </div>
    </div>
    <!-- END (Minarm Architects names) Select -->



  </div>
  <!-- END Inputs Section -->

  <!-- Filters Section -->
  <div class="filters-section flex space-between">

    <!-- Filters End -->
    <div class="filters flex">
      
      <div class="single-filter flex center-items pointer margin-right-20" [ngClass]="{'selected': selectedProjectsFilter == 'all'}" 
        (click)="selectProjectFilter('all')">
        <img src="/assets/projects/all-projects-icon.svg" class="margin-right-8">
        <span>Tous</span>
      </div>

      <div class="single-filter flex center-items pointer margin-right-20" [ngClass]="{'selected': selectedProjectsFilter == 'favorite'}" 
        (click)="selectProjectFilter('favorite')">
        <img src="/assets/projects/favorite-projects-icon.svg" class="margin-right-8">
        <span>Favoris</span>
      </div>

      <div class="single-filter flex center-items pointer margin-right-20" [ngClass]="{'selected': selectedProjectsFilter == 'my_projects'}" 
        (click)="selectProjectFilter('my_projects')">
        <img src="/assets/projects/my-projects-icon.svg" class="margin-right-8">
        <span>Mes projets</span>
      </div>

      <div class="single-filter flex center-items pointer margin-right-20" [ngClass]="{'selected': selectedProjectsFilter == 'shared_with_me'}" 
        (click)="selectProjectFilter('shared_with_me')">
        <img src="/assets/projects/projects-shared-with-me.svg" class="margin-right-8">
        <span>Partagés avec moi</span>
      </div>

      <div class="single-filter flex center-items pointer" [ngClass]="{'selected': selectedProjectsFilter == 'projects_esid'}" 
        (click)="selectProjectFilter('projects_esid')">
        <img src="/assets/projects/esid-projects-icon.svg" class="margin-right-8">
        <span *ngIf="!isMinarm">Projets de mon organisation</span>
        <span *ngIf="isMinarm">Projets de mon groupe local (ESID/USID/autre)</span>
      </div>

    </div>
    <!-- END Filters End -->

    <!-- Sort Side -->
    <div class="sort flex center-items">

      <div class="sort-list-dropdown margin-right-20">
        <div class="dropdown">
          <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            {{ selectedSortByLabel }}
            <img src="/assets/chevron-bottom.svg" class="margin-left-12">
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li class="dropdown-item" (click)="emitSortBy('Date de création', 'createdAt')"><p>Date de création</p></li>
            <li class="dropdown-item" (click)="emitSortBy('Date de modification', 'updatedAt')"><p>Date de modification</p></li>
            <li class="dropdown-item" (click)="emitSortBy('Titre', 'title')"><p>Titre</p></li>
          </ul>
        </div>
      </div>

      <div class="direction">
        <img src="/assets/projects/sort-arrows-icon.svg" class="pointer" (click)="changeSortDirection()">
      </div>

    </div>
    <!-- END Sort Side -->

  </div>
  <!-- END Filters Section -->

</div>