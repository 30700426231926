import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
// components
import { Editor } from 'src/app/all/editor/editor.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { ArchitectEmailsTemplatesDropdownComponent } from 'src/app/architect/dce/architect-emails-templates-dropdown/architect-emails-templates-dropdown.component';

// modules
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// Helpers
import { Helpers } from 'src/app/_services/helpers';
import { EmailTemplatesHelpers } from 'src/app/_services/emailTemplatesHelpers';
import { environment } from 'src/environments/environment';

@Component({
  standalone:true,
  selector: 'app-send-dce-to-multiple-popup',
  imports:[
    DpgfModule,
    Editor,
    SpinnerComponent,
    ArchitectEmailsTemplatesDropdownComponent,
    NgSharedModule
  ],
  templateUrl: './send-dce-to-multiple-popup.component.html',
  styleUrls: ['./send-dce-to-multiple-popup.component.scss']
})
export class SendDceToMultiplePopupComponent implements OnInit {
  @Input() artisansList:any = [];
  @Input() dpgfCurrentVersion:any;
  @Input() sendDceType:string = ''; /// sent DCE to artisans, or update Artisans DCE
  @Input() dpgfLots:any = [];
  @Input() loading:boolean = false;
  @Input() isDemo:boolean = false;
  
  @Input() forwarderName: string = "";
  @Input() architectEmailTemplates:any = [];

  @Output() createMultiDce = new EventEmitter<any>();
  @Output() updateMultiDce = new EventEmitter<any>();

  public clonedArtisansList:any = [];
  public filteredArtisansList:any = [];
  public lotsIds:any = {};
  public showQuantity:boolean = true;
  
  public selectedView: string = "artisans-list" ///artisans-list, dce-form
  public dceSubjectPlaceholder: string = "";
  public dceSubject: string = "";
  public architect:any={};
  public ceProject:any={};
  public firstArtisanNameInList:any={};
  public createMessage = ``;
  public dceMessage: string = ``;
  public lotsFilterLabel: string = "Filtrer par lot assigné";
  public selectedArtisans:any = {};
  public allArtisansSelected:boolean = false;
  public numberOfSelectedArtisans: number = 0;
  public isDisabled=false;

  public checkedCheckboxesList: string[] = ["offre_remise", "en_cours", "en_attente"];
  public isFirstTime: boolean = false;
  // helpers
  private helpers: any = null;
  private emailTemplatesHelpers: any = null;
  private architectHaveEmailTemplates: boolean = false;  
    
  private subscriptions: Array<Subscription> = [];

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges){
    

    if(changes.isDemo){
      this.isDemo = changes.isDemo.currentValue
      let notEmpty = this.dceSubject == '' ? true:false;
      this.isDisabled = this.isDemo ? true : notEmpty;
    }
    if(changes.sendDceType){
      // this.dceSubjectPlaceholder = changes.sendDceType.currentValue == "create" ?  "Nouveau projet" : "Mise à jour du DCE";
      if(changes.sendDceType.currentValue == "create"){
        this.dceSubjectPlaceholder = "Nouveau projet";
        this.checkedCheckboxesList = ["a_consulter"];
      }else{
        this.dceSubjectPlaceholder = "Mise à jour du DCE";
        this.checkedCheckboxesList = ["offre_remise", "en_cours", "en_attente"];
      }

      if(changes.artisansList.currentValue.length){        
        this.architect = changes.artisansList.currentValue[0]['architect'];
        this.ceProject = changes.artisansList.currentValue[0]['ceProject'];
      }

      // perfill subject & mail body
      this.fillMailFromArchiTemplates();
    }
    if(changes.artisansList && !changes.artisansList.firstChange){
      if(changes.artisansList.currentValue.length){
        this.architect = changes.artisansList.currentValue[0]['architect'];
        this.ceProject = changes.artisansList.currentValue[0]['ceProject'];
        this.firstArtisanNameInList = changes.artisansList.currentValue[0]['artisan']['name'];

        this.setMessagesText(this.architect, this.ceProject);
      }
      this.clonedArtisansList = this.filterInvitations(this.artisansList);

      /// all artisans selected by default
      this.allArtisansSelected = false;
      this.toggleSelectAllArtisans();
    }
    if(changes.dpgfLots && changes.dpgfLots.firstChange){
      changes.dpgfLots.currentValue.forEach(item => {
        this.lotsIds[item.id] = true;
      });
    }
    if(changes.loading && !changes.loading.firstChange){
      this.loading = changes.loading.currentValue;
    }
  }
  public checkIfNotEmty(){
    let notEmpty = this.dceSubject == '' ? true:false;
    this.isDisabled = this.isDemo ? true : notEmpty;
    return this.isDisabled
  }
  private setMessagesText(architect, ceProject){
    // if architect have emails templates, show them don't show the below ones
    if(this.architectHaveEmailTemplates) return;

    if(this.sendDceType == "create"){
      // this.dceMessage = `Bonjour ${this.firstArtisanNameInList},
      const { adresse, addressCodePostal, addressCity, addressCountry } = ceProject;
      const fullAddress = `${adresse}, ${addressCodePostal} ${addressCity}, ${addressCountry}`; 
      this.dceMessage = `Bonjour , 
      ${architect.name} souhaite vous consulter pour un nouveau projet :<ul><li>Maître d’ouvrage : <b>${ceProject.title}</b></li><li>Adresse : <b>${fullAddress}</b></li></ul>`
    }else if(this.sendDceType == "update"){
      this.dceMessage = `Bonjour,<br>Vous pouvez dès à présent accéder à la nouvelle version du DCE pour le projet ${ceProject.title} en cliquant sur le bouton suivant.<br>Pour toute question, vous pouvez répondre à cet email ou bien nous contacter au ${architect.telAgency}.
      <br><br>Bien cordialement,`;
    }
  }
  private filterInvitations(artisansList): void{
    let filterdData = artisansList;
    if(this.sendDceType == 'update'){
      filterdData = filterdData.filter(item => {
        if(item.dpgfPricing.currentVersion != this.dpgfCurrentVersion){
          return item;
        }
      });
    }
    return filterdData;
  }
  public filterArtisansByName(value){    
    if(value != ''){
      let filterdItems = [];
      let addedInvites = {};
      for(let i = 0; i < this.artisansList.length; i++){
        let currentArtisanName = this.artisansList[i].artisan.name.toLowerCase();
        value = value.toLowerCase();
        if(currentArtisanName.includes(value)){          
          filterdItems.push(this.artisansList[i]);
          addedInvites[this.artisansList[i]._id] = true;
        }
      }
      this.clonedArtisansList = filterdItems;
    }else{
      this.clonedArtisansList = this.filterInvitations(this.artisansList);
    }
  }
  public filterArtisans(event): void{
    /// if lots selected filter the artisans list
    if(event.length){
      let selectedLotsIds = [];
      let addedInvites = {};
      event.forEach(item => selectedLotsIds.push(item.id));

      let filterdItems = [];      
      for(let i = 0; i < this.artisansList.length; i++){
        let currentInvitation = this.artisansList[i];
        if(currentInvitation.lots && currentInvitation.lots.length){
          for(let j = 0; j < currentInvitation.lots.length; j++){
            let currentLot = currentInvitation.lots[j];
            if(selectedLotsIds.includes(currentLot.id)){
              if(!addedInvites[currentInvitation._id]){
                filterdItems.push(currentInvitation);
                addedInvites[currentInvitation._id] = true;
              }
            }
          }
        }
      }
      
      this.clonedArtisansList = filterdItems;
    /// else show all artisans
    }else{
      this.clonedArtisansList = this.filterInvitations(this.artisansList);
    }
  }

  public changeView(view){
    let artisansLength = Object.keys(this.selectedArtisans).length;
    if(!artisansLength) return;
    if(artisansLength > 1){
      this.firstArtisanNameInList = "";
    }
    this.selectedView = view;
  }
  public toggleSelectAllArtisans(){ // TODO: REMOVE if not used
    this.selectedArtisans = {}; /// clear all previuosly selected artisans
    this.numberOfSelectedArtisans = 0;

    this.allArtisansSelected = !this.allArtisansSelected;
    for(let i = 0; i < this.clonedArtisansList.length; i++){
      let invitationId = this.clonedArtisansList[i]._id;
      // this.artisanSelected(invitationId);
      this.selectedArtisans[invitationId] = this.allArtisansSelected;

      if(this.selectedArtisans[invitationId]){
        this.numberOfSelectedArtisans++;
      }else{
        this.numberOfSelectedArtisans = 0;
      }
    }
  }

  public artisanSelected(invitationId){
    this.selectedArtisans[invitationId] = !this.selectedArtisans[invitationId];
    //
    if(this.selectedArtisans[invitationId]){
      this.numberOfSelectedArtisans++;
    }else{
      this.numberOfSelectedArtisans--;
    }
  }
  public statusCheckBoxChanged(field){
    if(!this.helpers){
      this.helpers = new Helpers();
    }

    if(this.checkedCheckboxesList.includes(field)){
      let itemIndex = this.checkedCheckboxesList.indexOf(field);
      this.checkedCheckboxesList.splice(itemIndex, 1);
    }else{
      this.checkedCheckboxesList.push(field);
    }
    
    for(let i = 0; i < this.clonedArtisansList.length; i++){
      let currentInvite = this.clonedArtisansList[i];
      const { _id, pricingStatus, status } = currentInvite;

      // the combination of status & pricingStatus (ceProjectInvites) returns a specific status  
      const newProjectInviteStatus = this.helpers.getCeProjetInviteStatus(status, pricingStatus);

      if(field != newProjectInviteStatus) continue;

      if(this.checkedCheckboxesList.includes(newProjectInviteStatus)){
        this.selectedArtisans[_id] = true;
        this.numberOfSelectedArtisans++;
      }else{
        this.selectedArtisans[_id] = false;
        this.numberOfSelectedArtisans--;
      }

    }

  }

  public sendOrUpdateDCE(type){
    let invitations = [];

    for(let key in this.selectedArtisans){
      if(this.selectedArtisans[key]){
        let foundArtisan = this.artisansList.find(artisan => artisan._id == key);
        if(foundArtisan){
          let item = {
            invitationId: foundArtisan._id,
            artisan: {
              _id: foundArtisan.artisan._id,
              name: foundArtisan.artisan.name,
              showQuantity: this.showQuantity,
              emailAgency: foundArtisan.artisan.emailAgency,
              telAgency: foundArtisan.artisan.telAgency,
            },
          };
          foundArtisan.dpgfPricing ? item['dpgfPricingId'] = foundArtisan.dpgfPricing._id : '';
          
          invitations.push(item);
        }
      }
    }

    let body = {
      dceSubject: this.dceSubject,
      dceMessage: this.dceMessage,
      invitations
    };

    // format mail object variables
    if(body.dceSubject && body.dceSubject.includes("%")){
      let transformedSubject = this.tranformVariablesFromMailSubject(body.dceSubject);
      body.dceSubject = transformedSubject;
    }

    if(type == 'create'){
      this.createMultiDce.emit(body);
    }else{
      this.updateMultiDce.emit(body);
    }
  }

  private fillMailFromArchiTemplates(){
    const findCorrectEmailTemplate = {
      "create": "email_template_send_dce",
      "update": "email_template_update_dce"
    }

    const emailTemplateId = findCorrectEmailTemplate[this.sendDceType];

    let foundEmailTemplate = this.architectEmailTemplates.find(template => template.id == emailTemplateId);
    
    if(foundEmailTemplate && foundEmailTemplate != -1){
      this.emailTemplateSelected(foundEmailTemplate);

      this.architectHaveEmailTemplates = true;
    }

  }

  // Custom Email Templates
  public emailTemplateSelected(emailTemplate){
    let { subject, message } = emailTemplate;
    if(!environment.onPremise && this.isFirstTime)window.analytics.track('use_email_template_grouped',{properties:{acValue: 'use_email_template_grouped'}});
    // set subject
    if(subject.includes("%NOM_PROJET%")){
      subject = subject.replace("%NOM_PROJET%", this.ceProject.title);
    }
    this.dceSubject = subject;

    if(!this.emailTemplatesHelpers){
      this.emailTemplatesHelpers = new EmailTemplatesHelpers();
    }

    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: {},
    };

    let mappedEmailTemplate = this.emailTemplatesHelpers.transformTemplate(false, message, variablesDataSource);

    this.dceMessage = mappedEmailTemplate;

    // only for send segment
    this.isFirstTime = true;
  }

  private tranformVariablesFromMailSubject(mailSubject){
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: {},
    };
    const SHOW_ARTISAN_DATA = false;
    let mappedEmailSubject = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, mailSubject, variablesDataSource);

    return mappedEmailSubject;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
