import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { Helpers } from './helpers'

@Injectable()
export class ArchitectService {
    public token: string;
    public licenceNotification: boolean = true;
    constructor(private http: HttpClient) {
    }
    getAllUsersProfile(page,limit,searchWord) : Observable<any>{
      let options = new Helpers().getHeader();
      let variables = '?page=' + page + '&limit=' + limit
      if(searchWord != "" ) variables += '&search=' + searchWord
        return this.http.get('/api/v1/onpremise/user' + variables, options)
            .pipe((response: any) => {
                return response;
            });
    }
    update(architectID, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch('/api/v1/architects/' + architectID, body, options)
        .pipe(
            map((response:any) => {
                return response.data;
            })
        )
    }
    setRecommendation(architectID, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/architects/${architectID}/recommendations`, body, options)
            .pipe( map((response: any) => {
                return response;;
            }));
    }
    updateProjectOrder(architectID, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.patch('/api/v1/architects/' + architectID + '/project-order', body, options)
            .pipe(map((response: any) => {
                // login successful if there's a jwt token in the response
                let token = response && response.token;
                let userData = response && response.user;
                return true;
            }));
    }
    getAllArchiProfile(page,limit,searchWord) : Observable<any>{
      let options = new Helpers().getHeader();
      let variables = '?page=' + page + '&limit=' + limit
      if(searchWord != "" ) variables += '&search=' + searchWord
        return this.http.get('/api/v1/architects' + variables, options)
            .pipe((response: any) => {
                return response;
            });
    }
    getArchiProfile(architectID) : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/architects/' + architectID, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    getArchiUrl(architectID) : Observable<string>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/architects/' + architectID, options)
            .pipe(map((response: any) => {
                return response.data.bamUrl;
            }));
    }
    getArchiProjects(architectID) : Observable<any[]>{
      let options = new Helpers().getHeader();
        return this.http.get('/api/v1/architects/' + architectID + '/projects', options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    sendCallMeNotification(userId): Observable<any[]>{
      if(this.licenceNotification){
        let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/user/${userId}/notification`,{}, options)
        .pipe(map((response: any) => {
          this.licenceNotification = false;
          return response.data;
        }));
      }else{
        return new Observable(observer => {
          observer.next([]);
        });
      }
    }
    delete(architectID): Observable<boolean> {
      let options = new Helpers().getHeader();
        return this.http.delete('/api/v1/architects/' + architectID, options)
            .pipe(map(() => {
                return true;
            }));
    }
    getCarnetAdressesArtisan(): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/relations/`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    addCarnetAdressesArtisan(data): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/relations/`, data, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    removeCarnetAdressesArtisan(relationId): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.delete(`/api/v1/relations/${relationId}`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    getRelationTags(): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/relations/`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    updateRelationTags(relationId, body){
      let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/relations/${relationId}`, body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    searchEntreprise(body, page = 1, limit=20): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/relations/search-entreprise?page=${page}&limit=${limit}`, body, options)
        .pipe(map((response: any) => {
            return response;
        }));
    }
    getArtisanRelation(artisanId): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/relations/artisan/${artisanId}`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    updateUserInfo(body, userId): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/user/${userId}`, body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

    /// Parrainage
    getParrainageList(): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get('/api/v1/parrainage-invitation', options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    sendParrainageInvitation(body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post('/api/v1/send-parrainage-invitation', body, options)
        .pipe(map((response: any) => {
            return response;
        }));
    }
    checkHasUnpaiedParrainage(): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get('/api/v1/check-has-unpaied-parrainage', options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    addCarnetAdressesFromCsv(relations): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/relations/import-csv-relations/`, {relations}, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    getArchiCollabs() : Observable<any>{
      let options = new Helpers().getHeader();
        return this.http.get(`/api/v1/architects/collabs/share-collabs`, options)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
    updateCollabByMail(data): Observable<any> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/architects/collabs/share-collabs' , body, options)
        .pipe(
            map((response:any) => {
                return response.data;
            })
          )
    }
    checkCollabToShare(architectMail): Observable<any> {
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/architects/collabs/check/${architectMail}`, options)
      .pipe(map((response: any) => {
        return response.data;
      }));
    }
    deleteCollab(architectId): Observable<boolean> {
      let options = new Helpers().getHeader();
      return this.http.delete(`/api/v1/architects/collabs/share-collabs/${architectId}`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
      }
    getUsers(architectID): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get('/api/v1/architects/'+ architectID +'/users', options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    deleteUser(userID): Observable<boolean> {
      let options = new Helpers().getHeader();
        return this.http.delete('/api/v1/user/' + userID, options)
            .pipe(map(() => {
                return true;
            }));
    }
    deleteUserAndRemoveItFromDepartement(userID): Observable<boolean> {
      let options = new Helpers().getHeader();
        return this.http.patch(`/api/v1/onpremise/user/${userID}`,{}, options)
            .pipe(map(() => {
                return true;
        }));
    }
    setUsers(architectID, data): Observable<boolean> {
      let body = JSON.stringify(data)
      let options = new Helpers().getHeader();
        return this.http.post(`/api/v1/architects/${architectID}/users`, body, options)
            .pipe( map((response: any) => {
                return response;;
            }));
    }
    userChangePassword(userId,password): Observable<any> {
      let body = JSON.stringify({password: password})
      let options = new Helpers().getHeader();
        return this.http.post('/api/v1/user/'+userId, body, options)
            .pipe(map((response: any) => {
                let done = response
                return done;
            }));
    }
    getUserInfoSegment(): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get('/api/v1/architectsegment', options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    accessReef(doc,setHasReef): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post('/api/v1/accessReef',{docType:doc.docType,docId:doc.docId,setHasReef:setHasReef}, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    generateZIP(dpgfId:any,files:any){
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/generateZIP/${dpgfId}`,files,options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    updateUser(userId,body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/user/${userId}`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    createDepartement(body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/create-departement`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    searchDepartement(body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/search-users`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    updateUserAndArchitect(obj): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/user/${obj.userID}/architect/${obj.archiID}?add=${obj.add}`,{}, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    updateUserStatus(userId,body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.patch(`/api/v1/onpremise/user/${userId}/update-user-status`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    getCollaborateur(archiId): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.get(`/api/v1/onpremise/user/${archiId}/collab`, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    createNewUser(body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`api/v1/onpremise/user`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    createCollaborateur(body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/user/create-collaborateur`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    removeCollaborateur(userId,body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/user/${userId}/remove-collaborateur`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }
    
    checkMailExistence(body): Observable<any>{
      let options = new Helpers().getHeader();
      return this.http.post(`/api/v1/onpremise/check-mail-existence`,body, options)
        .pipe(map((response: any) => {
            return response.data;
        }));
    }

  renderTemplate(template: string, params: any) {
    let rendered = template;
    params.forEach(item=>{
      Object.keys(item).forEach(key => {
        const regex = new RegExp(`{{${key}}}`, 'g');
      rendered = rendered.replace(regex, item[key]);
      });
    })
    return rendered;
 }
}
