import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Observable, Subscription } from 'rxjs';
// Services Imports
import { ErrorService,CeProjectService, AuthService } from 'src/app/_services';
import { MessageService } from 'primeng/api';
import { NotificationsService } from 'angular2-notifications';
import { ConfirmationService } from 'primeng/api';

//Helpers
//import { ArchitectModule } from '../architect.module';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { CeProjectCollaborateursComponent } from 'src/app/dpgf/global/ce-project-collaborateurs/ce-project-collaborateurs.component';
import { CeProjectTasksComponent } from 'src/app/dpgf/global/ce-project-tasks/ce-project-tasks.component';
import { NeedSubscriptionPopup2Component } from 'src/app/architect/need-subscription-popup2/need-subscription-popup2.component';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    standalone: true,
    selector: 'app-archi-ce-project-dashboard',
    imports:[
      ArchiExpiredModalComponent,
      HeaderComponent,
      CeProjectNavComponent,
      SpinnerComponent,
      CeProjectCollaborateursComponent,
      CeProjectTasksComponent,
      NeedSubscriptionPopup2Component,
      NgSharedModule
    ],
    templateUrl: './archi-ce-project-dashboard.component.html',
    providers: [MessageService],
    styleUrls: ['./archi-ce-project-dashboard.component.scss'],
})
export class ArchiCeProjectDashboardComponent implements OnInit {
  public project:any = {};
  public loggs = [];
  public rdvDispo = [];
  public expenses:any = [];
  public quickLinks:any = [];
  public projectId:string = "";
  public loadingData:boolean = true;
  public setLoading:boolean = false;
  public progressionPourcentage:number = 0;
  public onpremiseProjectImage:string="https://s3.eu-central-1.amazonaws.com/bam-consultation-slickshot/22698xu9urkjo4uqwj%2F13787886-architectural-project-architect-rolls-and-plans.jpg";
  public projectLicence:string = "";
  public isAdministrator=JSON.parse(localStorage.getItem('currentUser')).isAdmin;

  private subscriptions: Array<Subscription> = [];currentUser;
  activities: any;
  licence: string;
  constructor(private title: Title,
              private ceProjectService: CeProjectService,
              public authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private ErrorService: ErrorService,
              private confirmationService: ConfirmationService,
            ) {
              this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
             }

  ngOnInit() {
    this.noMultiAccountsAllowed();
    this.getCeProject();
    this.getCeProjectloggs();
    this.title.setTitle("Aglo -  Ma Consultation d'entreprises" )
    this.licence = this.authService.licence || "base";
    if(!environment.onPremise)window.analytics.page('pageview_accueil_project');
  }
  public openDialogConfirmation (){
      this.confirmationService.confirm({
        message: "Vous n’avez pas accès à ce projet en mode édition, vos modifications ne seront pas enregistrées. Contactez le propriétaire du projet pour qu’il vous en donne l’accès",
        header: 'Lecture seule',
        key: 'noaccess',
        acceptLabel : "J’ai compris",
        accept: () => {
      },
        rejectVisible: false

    })
  }
  public getCeProjectloggs(){
    this.ceProjectService.getProjectLoggs(this.projectId)
        .subscribe(
            data => {
                this.loggs = data
            },
            error => {
                console.log("error",error)
                this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
            })
  }
  public getCeProject(){
    this.route.params.forEach((params: Params) => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
        this.subscriptions.push(
          this.ceProjectService.getProject(this.projectId)
              .subscribe(
                  data => {
                    let currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
                    this.projectLicence = data["licence"] || "";
                    if((data.dpgf||{})._id && !currentUser.hasDpgf){
                      currentUser.hasDpgf = true;
                      localStorage.setItem('currentUser',JSON.stringify(currentUser));
                    }
                    this.project = data;
                    if(this.project?.addressCity && this.project?.addressCity != ""){
                      let adresse = this.project['adresse']||''; 
                      let addressCodePostal = this.project['addressCodePostal']||''; 
                      let addressCity = this.project['addressCity']||''; 
                      let addressCountry = this.project['addressCountry']||'';
                      this.project['adresse'] = `${adresse}, ${addressCodePostal} ${addressCity}, ${addressCountry}`;
                    }
                    if(this.project.mainImage != "" && environment.onPremise){
                      this.onpremiseProjectImage = '/api/v1/static/'+this.project.mainImage;
                    }
                    this.rdvDispo = data.rdvDispo || [];
                    this.loadingData = false;
// if(this.authService.currentEnvironment && (this.currentUser.isAdminArchitect && this.project.collaborateurs[0].archiID!=this.currentUser.archiId||this.project.collaborateurs.findIndex(val=>this.currentUser.id==val._id)==-1 && this.project.user._id!=this.currentUser.id && (this.project.collaborateurs[0].archiID!=this.currentUser.archiId && this.currentUser.isAdminArchitect) || (this.project.collaborateurs[0].archiID==this.currentUser.archiId && !this.currentUser.isAdminArchitect)) && !this.isAdministrator) this.openDialogConfirmation();
// if(this.authService.currentEnvironment && this.project.collaborateurs.findIndex((val)=>val._id ==this.currentUser.id) == -1&& ((this.currentUser.isAdminArchitect && this.currentUser.archiId != this.project.collaborateurs[0].archiID)|| (!this.currentUser.isAdminArchitect && this.currentUser.archiId != this.project.collaborateurs[0].archiID)) && !this.isAdministrator) this.openDialogConfirmation();
// if(!(this.project.collaborateurs.some(i=> i._id == this.currentUser._id) || this.currentUser.isAdmin || (!this.currentUser.isAdminArchitect && this.currentUser.archiId == this.project.architect._id) || (this.currentUser.isAdminArchitect && this.currentUser.archiId == this.project.architect._id)))this.openDialogConfirmation();console.log('this.project.collaborateurs.some(i=> i._id == this.currentUser.id)',this.project.collaborateurs.some(i=> i._id == this.currentUser.id))
                    let showModalReadOnly = !this.project.collaborateurs.some(i=> i._id == this.currentUser.id) && !this.currentUser.isAdmin && !(this.currentUser.isAdminArchitect && this.currentUser.archiId == this.project.architect._id);
                    if(this.authService.currentEnvironment&& this.project.projetSensible && !this.project.collaborateurs.some(i=> i._id == this.currentUser.id))this.router.navigateByUrl('/')
                    else if(this.authService.currentEnvironment && showModalReadOnly)this.openDialogConfirmation();
                    // if(this.authService.currentEnvironment && showModalReadOnly)this.openDialogConfirmation();
// this.getCeProjectloggs();
                  },
                  error => {
                      console.log("error",error)
                      this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
                  })
              )
      }
    })
  }
  public printDate(log){
    var date = new Date(log.createdAt);
    var d = date.getDate();
    var m = date.getMonth()+1;
    var y = date.getFullYear();
    return d+"/"+m+"/"+y
  }
  public hideOverflow(){
    $("body").css("overflow", "hidden");
  }
  public showOverflow(){
    $("body").css("overflow", "auto");
  }
  public redirectToPricingTable(){
    $('#upgrade-modal').modal('hide');
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }
  public toggleModal() {
    $("#user-on-without-license-modal").modal('show');
    // $('#upgrade-modal').modal('show');
  }
  navigateToModifyProject(projectId: string) {
    if(this.authService.currentEnvironment && !this.project.collaborateurs.includes(this.currentUser.id) && this.project.architect._id!=this.currentUser.archiId) return;
    this.router.navigate(['/architect/modify-ce-project', projectId]);
  }
  public actualize(ceProjectChanged: boolean) {
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
