import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

declare var $ :any;

@Component({
  selector: 'app-choose-agency-modal',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './choose-agency-modal.component.html',
  styleUrl: './choose-agency-modal.component.scss'
})
export class ChooseAgencyModalComponent implements OnInit {
  
  @Input() addType:string;
  @Input() choosenLot:any;
  @Input() artisans:any;
  @Input() projectId:string;
  @Input() projectType:string;
  @Input() dpgfLot:any = [];
  @Input() sumittingRequest:boolean = false;
  @Input() resetValues:boolean = false;

  @Input() currentRelationsPage:any = 1; /// Carnet d'address list CurrentPage 
  @Input() relationsListNumberOfPages:any = 1; /// Carnet d'address list CurrentPage 
  @Input() relationsList:any = []; /// Carnet d'address list 
  @Input() tagsList:any = []; /// All Lots List (not the dpgf lots) 
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() done = new EventEmitter<boolean>();
  
  @Output() sendDemandeChiffrage = new EventEmitter<any>();
  @Output() addAgency = new EventEmitter<any>();
  @Output() addToCarnetAddress = new EventEmitter<any>();
  @Output() searchRelationsPage = new EventEmitter<any>();

  public artisansFull:any = []; /// keep the original data, when filtering artian 
  public relationsListFull:any = [];
  
  public loading: boolean = true;

  public currentView: string = "carnet-adress"; // booster, carnet-adress, lots, add-to-carnet-address

  public selectedLots: any = {};
  public selectedArtisan: any = {};
  public selectedAgencyName: string = "";
  public numberOfLotsSelected: number = 0;

  public carnetAddressForm: any = {
    emailAgency: '',
    name: '',
    lastName: '',
    firstName: '',
    tags: [],
    telAgency: '',
    demo: false
  };

  public relationsSearchQuery: string = "";
  public relationsSearchTagsList: any = [];


  ngOnInit(): void {
    this.onModalClosed();  
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if(changes.artisans && Object.keys(changes.artisans.currentValue).length){
      this.artisansFull = JSON.parse(JSON.stringify(changes.artisans.currentValue));      
    }
    if(changes.relationsList && Object.keys(changes.relationsList.currentValue).length){
      this.relationsListFull = JSON.parse(JSON.stringify(changes.relationsList.currentValue));      
    }
    if(changes.resetValues && changes.resetValues.currentValue){
      this.resetDefaultValues();
    }
    // set selected Lot
    if(changes.choosenLot && changes.choosenLot.currentValue){
      const { icon, id, indexLot, name } = changes.choosenLot.currentValue;
      this.selectedLots = {};
      this.selectedLots[id] = true;
      this.numberOfLotsSelected++;
    }
    
  }
  public onModalClosed(){ // on close modal, return default values
    $('#add-artisan').on('hidden.bs.modal', () => {
      this.currentView = "carnet-adress";
      this.selectedLots = {};
      this.selectedArtisan = {};
      this.selectedAgencyName = "";
      this.numberOfLotsSelected = 0;
    });
  }
  public closeAddArtisanModal(){
    $('#add-artisan').modal('hide');
  }
  private resetDefaultValues(){
    // currentView
    this.currentView = "booster";

    this.selectedLots = {};
    this.selectedArtisan = {};
    this.selectedAgencyName = "";
    this.numberOfLotsSelected = 0;
    
    /// carnetAddressForm 
    this.carnetAddressForm = {
      emailAgency: '',
      name: '',
      lastName: '',
      firstName: '',
      tags: [],
      telAgency: '',
      demo: false
    };

    this.resetValues = false;
  }
  
  public switchView(view){
    this.currentView = view;
  }

  // Check/uncheck Single Lot 
  public toggleLot(lotId){
    let lotIsSelected = !!this.selectedLots[lotId];
    this.selectedLots[lotId] = !lotIsSelected;

    !lotIsSelected ? this.numberOfLotsSelected++ : this.numberOfLotsSelected--;
  }

  public allLotsChecked({checked}){
    // uncheck All Selected Lots
    if(!checked){
      this.selectedLots = {};
      this.numberOfLotsSelected = 0;
      return;
    }
    
    this.numberOfLotsSelected = 0;
    for(let i = 0; i < this.dpgfLot.length; i++){
      const { id } = this.dpgfLot[i];
      this.numberOfLotsSelected++;
      this.selectedLots[id] = true;
    }
  }

  public selectArtisan(relation){
    this.switchView("artisan-lots");
    this.selectedArtisan = relation.artisanOwned;
    this.selectedAgencyName = relation.artisanName;
  }
  public filterArtisans(data){
    this.relationsSearchTagsList = data;
    if(!data.length){
      this.relationsList = this.relationsListFull;
      return;
    }
    
    let seletedLotsValues = data.map(item => item.value);
    let filteredArtisans = [];

    for (let i = 0; i < this.relationsListFull.length; i++) {
      const singleRelation = this.relationsListFull[i];
      const relationTags = singleRelation["tags"];

      const artisanslotsNames = relationTags.map(item => item.value);

      const artisanHasSlectedLots = this.arrayHasAllOtherArrayItems(seletedLotsValues, artisanslotsNames);
      
      if(artisanHasSlectedLots){
        filteredArtisans.push(singleRelation);
      }

    }

    this.relationsList = filteredArtisans;
  }
  private arrayHasAllOtherArrayItems(array1, array2){
    let iValid = true;

    for(let i = 0; i < array1.length; i++){
      let currentItem = array1[i];

      if(!array2.includes(currentItem)){
        iValid = false;
      }
    }

    return iValid;
  }


  private getSelectedLots(){
    let result = [];

    let selectedLots = [];
    for(let key in this.selectedLots){
      if(this.selectedLots[key]){
        selectedLots.push(key);       
      }
    }
    
    for(let i = 0; i < this.dpgfLot.length; i++){
      let currentLot = this.dpgfLot[i];
      const { id } = currentLot;
      if(selectedLots.includes(id)){
        result.push(currentLot);
      }
    }

    return result;
  }
  public loadRelationsPage(direction){
    if(direction == "prev"){
      // if the current page is 1 don't prev
      if(this.currentRelationsPage == 1) return;
      this.currentRelationsPage--;
    }else{
      // if the the current page is the total number of pages don't next
      if(this.currentRelationsPage == this.relationsListNumberOfPages) return;
      this.currentRelationsPage++;
    }    
    this.emitSearchRelationsPage({page: this.currentRelationsPage});
  }
  public emitSearchRelations(){
    let body ={
      page: 1,
      searchQuery: this.relationsSearchQuery,
      tags: this.relationsSearchTagsList
    }

    this.emitSearchRelationsPage(body);
  }

  private emitSearchRelationsPage(page){
    this.searchRelationsPage.emit(page);
  }
  
  public emitSendDemandeChiffrage(){
    let lots = this.getSelectedLots();

    this.sendDemandeChiffrage.emit(lots);
  }
  public emitAddAgency(){
    let lots = this.getSelectedLots();

    let body ={
      artisanId: this.selectedArtisan._id,
      lots
    };    
    
    this.addAgency.emit(body);
  }
  public addAgencyToCarnetAddress(){
    if(this.carnetAddressForm.emailAgency == '' || this.carnetAddressForm.name == '') return;
    this.addToCarnetAddress.emit(this.carnetAddressForm);
  }

}
