import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';
import { ArchitectService } from '../../../_services/index';
import {FormBuilder, FormGroup,  Validators} from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { formateImage } from '../../../_helpers/formate-image';
import { AuthService } from 'src/app/_services';
import { NgSharedModule } from 'src/app/all/ngshared.module';

declare var $;
@Component({
  selector: 'app-modal-create-departement',
  standalone: true,
  imports: [NgSharedModule],
  templateUrl: './modal-create-departement.component.html',
  styleUrl: './modal-create-departement.component.scss'
})
export class ModalCreateDepartementComponent {
  @Output() departementCreated = new EventEmitter();
  public email;nomAgency;telAgency;loading=false;autorise=false;
  public imageError: boolean=false;
  public uploading: boolean;
  private subscriptions: Array<Subscription> = [];image;
  public subscriptionForm: FormGroup;
  public formErrors = {'name': '','telAgency': '','emailAgency': '','logo': ''};onpremiseProjectImage;
  private validationMessages = {
    'name': {
      'required': 'Le nom du departement est obligatoire.'
    },
    'telAgency': {
      'required': 'Le téléphone du département est obligatoire.',
    },
    'logo': {
      'required': 'Le logo du département est obligatoire.',
    },
    'emailAgency': {
      'required': 'Le mail du département est obligatoire.',
      'email':'Email invalide'
    }
  };
  private STATIC_FILES_API_URL:string = "api/v1/upload-files";

  constructor(private architectService :ArchitectService,private fb: FormBuilder,private _notificationService: NotificationsService,public authService: AuthService) { }

  ngOnInit(): void {
    this.createForm();
  }
  public createForm(){
    this.subscriptionForm = this.fb.group({
      name: ['', Validators.required],
      telAgency: ['', Validators.required],
      emailAgency: ['', [Validators.required,Validators.email]],
      logo: [''],
    });
    this.subscriptions.push(
      this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
    )
    this.onValueChanged();
  }
  private onValueChanged(data ? : any) {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  public markFormAsTouched():void {
    if (!this.subscriptionForm) {
      return;
    }
    const form = this.subscriptionForm;

    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      control.markAsDirty();
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
    }
  }
  public isValid(){
    this.markFormAsTouched();
    return this.subscriptionForm.valid
  }
 
  onUploadLogoDepartement(event){
    let logo = event.originalEvent.body[0];
    let location = event.originalEvent.body[0].location;
    this.subscriptionForm.patchValue({
            logo: logo
    });
    this.onpremiseProjectImage = `/api/v1/static/${location}`;
  }
  createDepartement(){
    let body = {
      emailAgency:this.subscriptionForm.value.emailAgency,
      name:this.subscriptionForm.value.name,
      telAgency:this.subscriptionForm.value.telAgency,
      logo:this.subscriptionForm.value.logo,

    }
    if(this.isValid()){
    this.loading = true;
    this.autorise = true;
    this.architectService.createDepartement(body)
        .subscribe(
            resp => {
              $("#create-departement").modal('hide');
                this.departementCreated.emit(resp)
                this._notificationService.success("Succés","Le département a été créé", {timeOut: 3000});
                this.loading = false;
              },
            error => {
                this.loading = false;
                this._notificationService.success("Erreur","Le département n'a pas été créé", {timeOut: 3000});
                console.log("error",error)
            });
            setTimeout(() => {
              this.autorise = false;
            }, 1000)

  }
}
}
