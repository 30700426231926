<app-header ></app-header>
<app-spinner *ngIf="!loadingPage"></app-spinner>
<div class="all flex justify-center">

<div *ngIf="loadingPage" style="width:90%">
  <div >
    <h1 class="title-departement">Départements</h1>
  </div>
  <div class="flex">
    <div class="flex padding-bottom-10 padding-top-10">
      <input type="text" class="wid300" [(ngModel)]="searchText" placeholder="Chercher un département">
      <button class="button-search-slider" (click)="getDepartements()" ><i class="fa fa-search" aria-hidden="true"></i></button>
    </div>
    <div class="flex wid100" style="justify-content:flex-end;height:40px;margin-top: 10px;">
      <button class="button-search-slider" data-toggle="modal" data-target="#create-departement"> + Ajouter un département</button>
    </div>
  </div>
    <table class="table">
        <thead>
          <tr>
            <th scope="col">Nom du département</th>
            <th scope="col">Email</th>
            <th scope="col">Numéro de téléphone</th>
            <th scope="col">Nombre d'utilisateurs</th>
            <th scope="col">Logo du département</th>
            <th scope="col" class="center">Opérations</th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let departement of departements;let i = index;">
            <td> {{departement?.name || ''}} </td>
            <td> {{departement?.emailAgency || ''}} </td>
            <td> {{departement?.telAgency || ''}} </td>
            <td> {{departement?.users?.length || 0}} </td>
            <td>
              <div *ngIf="departement?.logo?.cdnUrl" class="image-container flex justify-center" >
                <img class="img-style" alt="your-image-description" [src]="'api/v1/static/'+departement?.logo?.cdnUrl || ''">
                <div class="image-button cursor" (click)="selectDepartement(departement)">Modifier</div>
              </div>
              <div class="flex justify-center" *ngIf="!departement?.logo?.cdnUrl">
                <p class="cursor" (click)="selectDepartement(departement)">Ajouter une image</p>
              </div>
            </td>
            <td class="cursor center" >
                <i class="fa fa-pencil" pTooltip="Modifier" aria-hidden="true" (click)="showSlider(departement)" ></i>
            </td>
          </tr>
        </tbody>
    </table>
    <div class="flex justify-center">
    <ul class="pagination">
        <li *ngFor="let number of numbers; let i = index"><a (click)="changePage(i)"  [ngClass]="{ 'actived': page == i}">{{number}}</a></li>
    </ul>
    </div>
</div>

<!-- <div *ngIf="displaySide"> -->
  <p-sidebar  [(visible)]="displaySide" position="right" autoZIndex="false" (click)="clickOutsiteDiv()" [style]="{width:'80rem'}" (onHide)="sideBarClose()"  >
    <div class="header">
    <div class="row">
      <div class="center">
        <div class="inner-container padding-left-20">
            <div>
            <div class="flex-container">
              <form [formGroup]="userForm" (ngSubmit)="submitForm()" class="flex space-between">
                <div class="input-container">
                  <p class="flex font-size-16 bold">Nom:</p>
                  <input class="backgroud-white-wid300px" type="text" id="name" formControlName="name">
                  <div *ngIf="userForm.get('name').errors && userForm.get('name').touched">
                    <div *ngIf="userForm.get('name').hasError('required')">Nom du département est obligatoire</div>
                  </div>
                  <p class="flex font-size-16 bold">Email:</p>
                  <input class="backgroud-white-wid300px" type="email" id="email" formControlName="emailAgency">
                  <div *ngIf="userForm.get('emailAgency').errors && userForm.get('emailAgency').touched">
                    <div *ngIf="userForm.get('emailAgency').hasError('required')">Email est obligatoire</div>
                    <div *ngIf="userForm.get('emailAgency').hasError('emailAgency')">Invalide email format</div>
                  </div>
                  <p class="flex font-size-16 bold">Téléphone du département:</p>
                  <input class="backgroud-white-wid300px" type="email" id="email" formControlName="telAgency">
                  <div *ngIf="userForm.get('telAgency').errors && userForm.get('telAgency').touched">
                    <div *ngIf="userForm.get('telAgency').hasError('required')">Email est obligatoire</div>
                    <div *ngIf="userForm.get('telAgency').hasError('telAgency')">Invalide email format</div>
                  </div>
                </div>
                <div>
                  <button class="save-button" type="submit" [ngClass]="{disabled: userForm.invalid}" [disabled]="userForm.invalid">Enregistrer</button>
                </div>
              </form>
            </div>
            <button [routerLink]="['/architect/archi-db']" [queryParams]="{archiId: departement?._id, archiName: departement?.name}">
              Aller vers la bibliothèque
            </button>
                     <p class="flex font-size-16 bold padding-top-10">Affilier des utilisateurs à ce département</p>
            <div class="flex" style="position: relative;">
            <input id="input-search" class="backgroud-white-wid300px this-input" style="background-color: white;" type="text" placeholder="Rechercher un utilisateur" [(ngModel)]="query" (ngModelChange)="this.searchUsers.next($event)">
            <button class="button-search-slider" (click)="search()"><i class="fa fa-search" aria-hidden="true"></i></button>
            <div  id="myInputautocomplete-list"  class="autocomplete-items"  style="width:306px;position:absolute;">
              <div *ngIf="usersFound?.length>0" style="overflow:scroll;overflow-x:hidden;height: 300px;">
              <div *ngFor="let user of usersFound;let i = index" class="dropdown" (click)="getUserToAdd(user)"  >
                  <p class="bold" > {{ user?.lastName}} {{user?.firstName}} </p>
                  <p class="bold" > {{ user?.email}} </p>
              </div>
              </div>
              <!-- <div *ngIf="usersFound?.length==0" style="overflow:scroll;overflow-x:hidden;height: 63px;">
                <div class="dropdown">
                    <p class="bold" > Utilisateur introuvable </p>
                </div>
              </div> -->
            </div>
            </div>
          </div>
          <div *ngIf="teamDepartement?.length>0" class="padding-top-20">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Prénom</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Email de l'utilisateur</th>
                  <th scope="col">Admin local</th>
                  <th scope="col" class="center">Opérations</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let user of teamDepartement;let i = index; ">
                  <td class="text-align-start"> {{user?.firstName || ''}} </td>
                  <td class="text-align-start"> {{user?.lastName || ''}} </td>
                  <td class="text-align-start"> {{user?.email || ''}} </td>
                  <td class="text-align-start"> {{user?.isAdminArchitect? 'Oui':''}} </td>
                  <td class="cursor center" >
                    <i class="fa fa-pencil" pTooltip="Modifier" aria-hidden="true" (click)="openModal(user)" ></i>
                    <i class="fa fa-solid fa-trash padding-left-20" pTooltip="Retirer l'utilisateur du département" (click)="getUserToDelete(user)"></i>
                  </td>
                </tr>
              </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
<!-- </div> -->
<div >
<div class="modal fade" id="add-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body padding42-bottom20">
        <div class="title-text">
         <h3> Ajouter cet utilisateur au département</h3>
      </div>
      <div>
        <p>Voulez-vous ajouter l'utilisateur <span class="bold"> {{selectedUser?.lastName}} {{selectedUser?.firstName}} </span> au département <span class="bold">{{departement?.name}}</span> ?</p>
      </div>
      <div class="modal-footer" >
        <div class="flex float-right">
        <button type="button" class="button-search-slider" data-dismiss="modal">Annuler</button>
        <button type="button" class="button-search-slider" (click)="addUserToDepartement(selectedUser._id,departement)">Ajouter</button>
        </div>
     </div>
      </div>
    </div>
</div>
</div>
<div *ngIf="actuelModal == 'delete-user'">
<div class="modal fade" id="delete-user" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body padding42-bottom20">
        <div class="title-text">
         <h3> Supprimer cet utilisateur du département</h3>
      </div>
      <div>
        <p>Voulez-vous supprimer l'utilisateur <span class="bold"> {{userToRemove?.lastName}} {{userToRemove?.firstName}} </span> du département actuel?</p>
      </div>
      <div class="modal-footer" >
        <div class="flex float-right">
        <button type="button" class="button-search-slider" data-dismiss="modal">Refuser</button>
        <button type="button" class="button-search-slider" (click)="removeArchi(userToRemove._id,departement)">Accepter</button>
        </div>
     </div>
      </div>
    </div>
</div>
</div>
</div>
<div style="display:none;">
  <p-fileUpload  mode="basic" accept=".png, .jpg, .jpeg"  #fileUpload3 chooseLabel="Naviguer" name="demo1" url="/api/v1/upload-file?type=logo" [auto]="true" 
  (onUpload)="onUploadLogoDepartement($event)"></p-fileUpload>
</div>
<div id="documents-errors-modal" class="modal fade" role="dialog">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-header flex justify-end">
        <img src="assets/close.svg" class="pointer close-icon" data-dismiss="modal">
      </div>
      <div class="modal-body no-padding">

      </div>
      <div class="modal-footer text-center">
        <button type="button" data-dismiss="modal" class="ag-btn-tertiary-sm margin-right-20">Annuler</button>
      </div>
    </div>
  </div>
</div>

<div id="modal-update-adminArchitect" class="modal fade" role="dialog" style="z-index: 100000;">
  <div class="modal-dialog flex-middle margin-top-60">
    <div class="modal-content">
      <div class="modal-body">
        <h2>Modifier les droits d'accès de {{ choosenUser?.lastName }} {{ choosenUser?.firstName }}</h2>
      
        <!-- Bootstrap Toggle Switch -->
        <div class="toggle-container">
          <label class="form-check-label" for="adminSwitch">ADMIN local</label>
          <input type="checkbox" id="adminSwitch" class="toggle-switch" [(ngModel)]="isAdmin">
        </div>
      
        <!-- Action buttons -->
        <div class="flex justify-center">
          <button class="btn cancel-button" (click)="onCancel()">Annuler</button>
          <button class="btn save-button" (click)="onSave()">Enregistrer</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-create-departement (departementCreated)="createDepartement($event)"></app-modal-create-departement>
