import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { ArchitectEmailsTemplatesDropdownComponent } from 'src/app/architect/dce/architect-emails-templates-dropdown/architect-emails-templates-dropdown.component';
import { Editor } from 'src/app/all/editor/editor.component';

import { dpgfReducer } from '../../dce-edition/_store/dpgf.reducer';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

// Helpers
import { Helpers } from 'src/app/_services/helpers';
import { EmailTemplatesHelpers } from 'src/app/_services/emailTemplatesHelpers';

@Component({
  standalone:true,
  selector: 'app-send-mail-to-multiple-artisans',
  imports:[
    DpgfModule,
    SpinnerComponent,
    ArchitectEmailsTemplatesDropdownComponent,
    Editor,
    NgSharedModule
  ],
  templateUrl: './send-mail-to-multiple-artisans.component.html',
  styleUrls: ['./send-mail-to-multiple-artisans.component.scss']
})
export class SendMailToMultipleArtisansComponent implements OnInit {
  @Input() artisansList:any = [];
  @Input() dpgfCurrentVersion:any;
  @Input() dpgfLots:any = [];
  @Input() loading:boolean = false;
  @Input() isDemo:boolean=false;
  @Input() architectEmailTemplates:any = [];

  @Output() sendMailsToManyArtisans = new EventEmitter<any>();


  public selectedView: string = "artisans-list" ///artisans-list, mail-form
  public clonedArtisansList:any = [];
  public architect:any={};
  public ceProject:any={};
  public firstArtisanNameInList:any={};
  public lotsIds:any = {};

  public selectedArtisans:any = {};
  public allArtisansSelected:boolean = false;
  public numberOfSelectedArtisans: number = 0;
  public lotsFilterLabel: string = "Filtrer par lot assigné";

  public mailSubjectPlaceholder: string = "";
  public mailSubject: string = "";
  public mailMessage: string = "";

  public checkedCheckboxesList: string[] = ["offre_remise", "en_cours", "ne_chiffre_pas", "en_attente"];

  private helpers: any = null;
  private emailTemplatesHelpers: any = null;
  private architectHaveEmailTemplates: boolean = false;  

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges){
    
    if(changes.artisansList && !changes.artisansList.firstChange){
      if(changes.artisansList.currentValue.length){
        this.architect = changes.artisansList.currentValue[0]['architect'];
        this.ceProject = changes.artisansList.currentValue[0]['ceProject'];
        this.firstArtisanNameInList = changes.artisansList.currentValue[0]['artisan']['name'];
        this.mailSubject = `Consultation ${this.ceProject.title}`;

        this.fillMailFromArchiTemplates(); // set default emails templates
        this.setMessagesText(this.architect, this.ceProject);
      }
      this.clonedArtisansList = this.filterInvitations(this.artisansList);
      /// all artisans selected by default
      this.allArtisansSelected = false;
      this.toggleSelectAllArtisans();
    }
    if(changes.dpgfLots && changes.dpgfLots.firstChange){
      changes.dpgfLots.currentValue.forEach(item => {
        this.lotsIds[item.id] = true;
      });
    }
    if(changes.loading && !changes.loading.firstChange){
      this.loading = changes.loading.currentValue;
    }
  }
  private setMessagesText(architect, ceProject){
    // if there a default tempalte selected, don't show the below one
    if(this.architectHaveEmailTemplates) return;

    let authUserPhone = JSON.parse(localStorage.getItem("currentUser")).phone;
    // this.mailMessage = `Bonjour,\n\nConcernant le projet  ${ceProject.title},\n\nPour toute question, vous pouvez répondre à cet email ou bien nous contacter au ${architect.emailAgency}.\n\nBien cordialement,`;
    this.mailMessage = `Bonjour,\n\nConcernant le projet  ${ceProject.title},\n\nPour toute question, vous pouvez répondre à cet email ou bien nous contacter au ${authUserPhone}.\n\nBien cordialement,`;
  }

  public filterArtisansByName(value){    
    if(value != ''){
      let filterdItems = [];
      let addedInvites = {};
      for(let i = 0; i < this.artisansList.length; i++){
        let currentArtisanName = this.artisansList[i].artisan.name.toLowerCase();
        value = value.toLowerCase();
        if(currentArtisanName.includes(value)){          
          filterdItems.push(this.artisansList[i]);
          addedInvites[this.artisansList[i]._id] = true;
        }
      }
      this.clonedArtisansList = filterdItems;
    }else{
      this.clonedArtisansList = this.filterInvitations(this.artisansList);
    }
  }
  private filterInvitations(artisansList): void{
    let filterdData = artisansList;
    return filterdData;
  }
  public changeView(view){
    let artisansLength = Object.keys(this.selectedArtisans).length;
    if(!artisansLength) return;
    this.selectedView = view;
  }
  public toggleSelectAllArtisans(){
    this.selectedArtisans = {}; /// clear all previuosly selected artisans
    this.numberOfSelectedArtisans = 0;

    this.allArtisansSelected = !this.allArtisansSelected;
    for(let i = 0; i < this.clonedArtisansList.length; i++){
      let invitationId = this.clonedArtisansList[i]._id;
      this.selectedArtisans[invitationId] = this.allArtisansSelected;

      if(this.selectedArtisans[invitationId]){
        this.numberOfSelectedArtisans++;
      }else{
        this.numberOfSelectedArtisans = 0;
      }
    }
  }
  public artisanSelected(invitationId){
    this.selectedArtisans[invitationId] = !this.selectedArtisans[invitationId];
    
    if(this.selectedArtisans[invitationId]){
      this.numberOfSelectedArtisans++;
    }else{
      this.numberOfSelectedArtisans--;
      this.allArtisansSelected = false;
    }

    /// if all artisans selected by hand, it should be (Tout déselectionner)
    if(this.clonedArtisansList.length == this.numberOfSelectedArtisans){
      this.allArtisansSelected = true;      
    }
  }

  public filterArtisans(event): void{
    /// if lots selected filter the artisans list
    if(event.length){
      let selectedLotsIds = [];
      let addedInvites = {};
      event.forEach(item => selectedLotsIds.push(item.id));

      let filterdItems = [];      
      for(let i = 0; i < this.artisansList.length; i++){
        let currentInvitation = this.artisansList[i];
        if(currentInvitation.lots && currentInvitation.lots.length){
          for(let j = 0; j < currentInvitation.lots.length; j++){
            let currentLot = currentInvitation.lots[j];
            if(selectedLotsIds.includes(currentLot.id)){
              if(!addedInvites[currentInvitation._id]){
                filterdItems.push(currentInvitation);
                addedInvites[currentInvitation._id] = true;
              }
            }
          }
        }
      }
      
      this.clonedArtisansList = filterdItems;
    /// else show all artisans
    }else{
      this.clonedArtisansList = this.filterInvitations(this.artisansList);
    }
  }

  public statusCheckBoxChanged(field){
    if(!this.helpers){
      this.helpers = new Helpers();
    }

    if(this.checkedCheckboxesList.includes(field)){
      let itemIndex = this.checkedCheckboxesList.indexOf(field);
      this.checkedCheckboxesList.splice(itemIndex, 1);
    }else{
      this.checkedCheckboxesList.push(field);
    }
    
    for(let i = 0; i < this.clonedArtisansList.length; i++){
      let currentInvite = this.clonedArtisansList[i];
      const { _id, pricingStatus, status } = currentInvite;

      // the combination of status & pricingStatus (ceProjectInvites) returns a specific status  
      const newProjectInviteStatus = this.helpers.getCeProjetInviteStatus(status, pricingStatus);

      if(field != newProjectInviteStatus) continue;

      if(this.checkedCheckboxesList.includes(newProjectInviteStatus)){
        this.selectedArtisans[_id] = true;
        this.numberOfSelectedArtisans++;
      }else{
        this.selectedArtisans[_id] = false;
        this.numberOfSelectedArtisans--;
      }
    }

  }

  private fillMailFromArchiTemplates(){
    const emailTemplateId = "email_template_new_email";

    let foundEmailTemplate = this.architectEmailTemplates.find(template => template.id == emailTemplateId);
    
    if(foundEmailTemplate && foundEmailTemplate != -1){
      this.emailTemplateSelected(foundEmailTemplate);

      this.architectHaveEmailTemplates = true;
    }

  }

  // Custom Email Templates
  public emailTemplateSelected(emailTemplate){
    let { subject, message } = emailTemplate;
    if(!environment.onPremise)window.analytics.track('use_email_template_grouped',{properties:{acValue: 'use_email_template_grouped'}});

    // set subject
    if(subject.includes("%NOM_PROJET%")){
      subject = subject.replace("%NOM_PROJET%", this.ceProject.title);
    }
    this.mailSubject = subject;

    if(!this.emailTemplatesHelpers){
      this.emailTemplatesHelpers = new EmailTemplatesHelpers();
    }

    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: {},
    };

    let mappedEmailTemplate = this.emailTemplatesHelpers.transformTemplate(false, message, variablesDataSource);

    this.mailMessage = mappedEmailTemplate;
  }

  public emitSendMailsToManyArtisans(){
    let ceProjectInvites = [];
    
    for(let key in this.selectedArtisans){
      if(this.selectedArtisans[key]){
        let foundArtisan = this.artisansList.find(artisan => artisan._id == key);
        if(!ceProjectInvites.includes(foundArtisan._id)){
          ceProjectInvites.push({
            ceProjectId: foundArtisan.ceProject._id,
            artisanId: foundArtisan.artisan._id,
            artisanName: foundArtisan.artisan.name,
            inviteId: foundArtisan._id
          });
        }
      }
    }

    let authUserPhone = JSON.parse(localStorage.getItem("currentUser")).phone;
    
    /// replace message \n with <br>
    let formattedMailMessage = this.mailMessage.replace(/\n/g, "<br />");

    let body = {
      subject: this.mailSubject,
      message: formattedMailMessage,
      ceProjectInvites,
      userPhone: authUserPhone||""
    }

    // format mail object variables
    if(body.subject && body.subject.includes("%")){
      let transformedSubject = this.tranformVariablesFromMailSubject(body.subject);
      body.subject = transformedSubject;
    }    
    
    this.sendMailsToManyArtisans.emit(body);
    if(!environment.onPremise)window.analytics.track('send_email_grouped',{properties:{acValue: 'send_email_grouped'}});
  }

  private tranformVariablesFromMailSubject(mailSubject){
    var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    const { phone, firstName, lastName } = currentUser;

    let variablesDataSource = {
      ceProject: this.ceProject,
      user: {
        phone,
        firstName,
        lastName
      },
      architect: this.architect,
      artisan: {},
    };
    const SHOW_ARTISAN_DATA = false;
    let mappedEmailSubject = this.emailTemplatesHelpers.transformTemplate(SHOW_ARTISAN_DATA, mailSubject, variablesDataSource);

    return mappedEmailSubject;
  }

}
