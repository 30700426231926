import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { CeTaskService, ErrorService, AuthService } from 'src/app/_services'
import { MessageService } from 'primeng/api';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

import { select, Store } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';


declare var $;

@Component({
  standalone:true,
  selector: 'app-ce-project-tasks',
  imports: [DpgfModule,NgSharedModule],
  providers: [],
  templateUrl: './ce-project-tasks.component.html',
  styleUrls: ['./ce-project-tasks.component.scss']
})
export class CeProjectTasksComponent implements OnInit {
  @Input()projectId:string;
  @Input()isDemoProject:boolean = false;
  @Input()collaborateurs:any = [];
  @Input()archi:string;
  @Input()project:any;

  public projectLicence: string='none';
  public getLoading:boolean = false;
  public setLoading:boolean = false;
  public addingTask:boolean = false;
  public creatingTask:boolean = false;
  public task = {title : "",task : "" , date : new Date() , status : "" , users : []};
  public showNewTaskErrors: boolean = false;
  public waitingTasks = [];
  public doneTasks = [];
  public features;
  private subscriptions: Array<Subscription> = [];
  public currentUser;
  // Auth User
  public licence: any;
  constructor(private router: Router,
              private messageService: MessageService,
              private ceTaskService: CeTaskService,
              private ErrorService: ErrorService,
              private store: Store,
              private authService: AuthService,
            ) { 
              this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
            }

  ngOnInit() {
    this.getAuthUserLicence(); // get Licence
    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
    this.getTasks()
    this.authService.features.subscribe((f)=>{
      this.features = f ;
    })
  }

  public getAuthUserLicence(){
    this.licence = this.authService.licence || "base";
  }
  
  public getTasks(){
    this.getLoading = true;
    this.subscriptions.push(
      this.ceTaskService.getAllProjectTasks(this.projectId)
        .subscribe(
            (data) => {
                this.waitingTasks = data.filter(tsk =>(tsk.status == "waiting"));
                this.doneTasks = data.filter(tsk =>(tsk.status == "done"));;
                this.getLoading = false;
            },
            error => {
                this.getLoading = false;
                this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                console.log("error",error)
            })
        )
  }

  public addTask(){
    this.creatingTask = true;
    this.showNewTaskErrors = false;
    let task = {...this.task};
    task.date = new Date();
    task.status = "waiting";
    let selectedUsers = task.users;
    task.users = task.users.map(user=>user._id);
    if(task.title!="" && task.task != ""){
      this.subscriptions.push(
        this.ceTaskService.create(this.projectId, task)
          .subscribe(
              (data) => {
                  this.setLoading = false;
                  data.users = selectedUsers;
                  this.waitingTasks.push(data);
                  this.task = {title : "",task : "" , date : new Date() , status : "" , users : []};
                  this.addingTask = false;
                  this.creatingTask = false;
                  if(!environment.onPremise)window.analytics.track('create_task',{properties:{acValue: 'create_task'}});
              },
              error => {
                  this.setLoading = false;
                  this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                  console.log("error",error);
              })
      )
    }else{
      this.showNewTaskErrors = true;
      this.creatingTask = false;
    }
  }
  public doneTask(taskId) {
    if(this.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    this.setLoading = true;
    this.subscriptions.push(
      this.ceTaskService.update(this.projectId, taskId, {status : "done"})
        .subscribe(
            () => {
                this.getTasks()
                if(!environment.onPremise)window.analytics.track('finish_task',{properties:{acValue: 'finish_task'}});
            },
            error => {
                this.setLoading = false;
                this.ErrorService.manageError(error,'/client/project/' + this.projectId + '/architect');
                console.log("error",error)
            })
    )
  }
  openTaskPopup() {
    if(this.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    /// if project is demo don't add collabs
    // !project?.demo && project.collaborateurs.includes(authUserId) || project.architect.users.includes(authUserId) || isAdmin
    let showModalReadOnly = !this.project.collaborateurs.some(i=> i._id == this.currentUser.id) && !this.currentUser.isAdmin && !(this.currentUser.isAdminArchitect && this.currentUser.archiId == this.project.architect._id);
    if(this.isDemoProject || this.authService.currentEnvironment && showModalReadOnly)return;

    // if(this.isDemoProject || this.authService.currentEnvironment && this.collaborateurs.indexOf((val)=>this.currentUser.id==val._id)==-1) return;
    
    if(this.features.tasks){
        this.addingTask = !this.addingTask;
    }
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }


}
