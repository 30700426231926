import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/_services';

import { select, Store } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';

import { environment } from 'src/environments/environment';

// Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { OverlayPanel } from 'primeng/overlaypanel';

declare var $;

@Component({
  selector: 'app-dce-top-nav-bar',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './dce-top-nav-bar.component.html',
  styleUrl: './dce-top-nav-bar.component.scss'
})
export class DceTopNavBarComponent implements OnInit {

  @Input() currentView: string = "redaction";
  @Input() saving: boolean = false;
  @Input() exportingDocs: boolean = false;
  @Input() dpgfCurrentVersion: number;
  @Input() changes: number = 0;
  @Input() checkedPostsNumber: number = 0;

  @Input() projectTitle: string = "";
  @Input() isAutomateNumberIsChecked: boolean = false;
  @Input() defaultTva: number = 10;

  @Output() changeView: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeExportDocsView: EventEmitter<any> = new EventEmitter<any>();
  @Output() openIndiceModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportDocs: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() setDpgfAttribute: EventEmitter<any> = new EventEmitter<any>();
  @Output() projectOverlayPanelClosed: EventEmitter<any> = new EventEmitter<any>();

  // checked posts actions
  @Output() duplicateManyPosts: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteManyPosts: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendDbPosts: EventEmitter<any> = new EventEmitter<any>();
  @Output() movePoste: EventEmitter<any> = new EventEmitter<any>();
  @Output() flagPosts: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('projTitle') projTitle: OverlayPanel;

  public projectLicence: string='none';
  public currentUser;
  public exportDocsTab: string = "export";
  
  constructor(
    private store: Store,
    public authService:AuthService){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  }

  ngOnInit(): void {
    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
          this.projectLicence = licence;
    })
  }

  public toggleEditProjectOverlayPanel(event){
    this.projTitle.toggle(event);
  }

  public automateNumberChanged() {
    if(this.authService.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    if(this.isAutomateNumberIsChecked && !environment.onPremise){
      window.analytics.track('update_dpgf',{properties:{acValue: 'update_dpgf'}});
    }else if(!environment.onPremise){
      window.analytics.track('set_manual_numbering',{properties:{acValue: 'set_manual_numbering'}});
    }
  }
  
  public changeTab(tab){
    this.currentView = tab;
    // Emit to parent
    this.emitChangeView(tab);
  }
  public changeExportTab(tab){
    this.exportDocsTab = tab;
    this.changeExportDocsView.emit(tab);
  }

  public emitChangeView(view){
    this.changeView.emit(view);
  }
  public emitOpenIndiceModal(){
    this.openIndiceModal.emit();
  }
  public emitChangeViewOrExportDocs(){
    
    // Emit Export Docs
    if(this.currentView == "exportDocs"){
      this.exportDocs.emit();
      return;
    }

    // change view
    this.changeTab("exportDocs");
  }

  public emitSaveData(){
    this.saveData.emit();
  }
  public emitSetDpgfAttribute(data) {
    if(this.authService.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    this.setDpgfAttribute.emit(data);
  }
  public emitUpdateProject(data) {
    if(this.authService.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    this.updateProject.emit(data);
  }

  public emitOverlayClosed(){
    const data = {
      title: this.projectTitle,
      isAutomateNumberIsChecked:this.isAutomateNumberIsChecked
    };

    this.projectOverlayPanelClosed.emit(data);
  }

  ////////////////////////////
  // checked posts actions
  ////////////////////////////
  public emitDuplicateManyPosts(){
    this.duplicateManyPosts.emit();
  }
  public emitDeleteManyPosts(){
    this.deleteManyPosts.emit();
  }
  public emitSendDbPosts(){
    this.sendDbPosts.emit();
  }
  public emitMovePoste(){
    this.movePoste.emit();
  }
  public emitFlagPosts(){
    this.flagPosts.emit();
  }

}
