import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges,  } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgSharedModule } from 'src/app/all/ngshared.module';

/// Services
import { DpgfPostesService } from '../../../_services';
import { MessageService } from 'primeng/api';

import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfModule } from '../../dpgf.module';
import { environment } from 'src/environments/environment';

declare var $;
@Component({
  standalone:true,
  selector: 'app-create-artisan-factice',
  imports:[
    DpgfModule,
    SpinnerComponent,
    NgSharedModule
  ],
  templateUrl: './create-artisan-factice.component.html',
  styleUrls: ['./create-artisan-factice.component.scss']
})
export class CreateArtisanFacticeComponent implements OnInit, OnChanges{
  @Output() refresh = new EventEmitter<boolean>();
  @Input()isProjectDemo:Boolean=false;
  @Input()artisans;
  @Input()dpgf:any;
  public dpgfLot:any = [];
  public invites:any = Array(15);
  public selectedLot = {};
  public selectedInvite = {
    ceProject: {_id:''},
    dpgfPricing: {_id:''},
    lots: []
  };
  public currentList:string = ''; /// lots, artisans
  public artisanName:string = '';
  public invitationsList = [];

  public showHeader: boolean = true;
  public submitting: boolean = false;
  public showEmptyNameErr: boolean = false;
  public showNoSelectionErr: boolean = false;
  public showNoLotsErr: boolean = false;
  public showNoChiffrageErr: boolean = false;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private dpgfPostesService: DpgfPostesService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.setLots();
    // this.formatArtisans(this.artisans);
  }
  ngOnChanges(changes: SimpleChanges){
    if(changes.artisans && changes.artisans.firstChange){
      this.formatArtisans(changes.artisans.currentValue);
    }
  }
  public setLots(){
    let dpgfLot = this.dpgf.data.map(lot=>{
      return {id:lot.id,name:lot.title,requested:0,icon : lot.icon||''}
    }).filter((lot) => lot.name != "");
    dpgfLot.unshift({id: "general", name: "Tout corps d'états", requested: 0, icon: "facades.png"});
    this.dpgfLot =dpgfLot;
  }
  public changeArtisanName(evt){
    this.artisanName = evt.target.value;
    if(this.artisanName == ''){
      this.showEmptyNameErr = true;
    }else{
      this.showEmptyNameErr = false;
    }
  }
  public changeCurrentList(list){
    this.currentList = list;
    this.showNoSelectionErr = false;
    // this.showEmptyNameErr = false;
  }
  public selectLot(lot){
    if(lot == 'check-all'){
      // console.log('Select All');
    }else{
      this.selectedLot[lot.id] = !this.selectedLot[lot.id];
    }
  }
  public lotChoosen(){
    let lots =Object.keys(this.selectedLot).filter((key) => this.selectedLot[key] != false);
    return lots.length === 0 ? false : true;
  }
  public inviteSelected(){
    let keys = Object.keys(this.selectedInvite);
    return keys.includes('_id') ? true : false;
  }

  public chooseArtisan(evt, invite){
    let clickedArtisan = $(evt.currentTarget);
    $('.single-artisan').removeClass('selected-artisan');
    clickedArtisan.addClass('selected-artisan');
    this.selectedInvite = invite;
  }
  public formatArtisans(artisans){
    let allArtisans = [];
    const allowedInvitesStatus = ["en_attente","en_cours","offre_remise"];

    for(let key in artisans){      
      if(allowedInvitesStatus.includes(key)){
        allArtisans.push(...artisans[key]);
      }
    }

    this.invitationsList = allArtisans;
  }
  public submitForm(currentList){
    console.log('test submitForm');
    this.showNoSelectionErr = false;
    this.showEmptyNameErr = false;
    this.showNoLotsErr = false;
    this.showNoChiffrageErr = false;
    if(this.currentList == ''){
      this.showNoSelectionErr = true;
      return;
    }else if(this.artisanName == ''){
      this.showEmptyNameErr = true;
      return;
    }else if(this.currentList == 'lots' && !this.lotChoosen()){
      this.showNoLotsErr = true;
      return;
    }else if(this.currentList == 'artisans' && !this.inviteSelected()){
      this.showNoChiffrageErr = true;
      return;
    }
    if(currentList == 'lots'){
      this.createNewChiffrage();
      if(!environment.onPremise)window.analytics.track('create_costing',{properties:{acValue: 'create_costing'}});
    }else{
      this.chooseOldChiffrage();
      if(!environment.onPremise)window.analytics.track('duplicate_costing',{properties:{acValue: 'duplicate_costing'}});
    }
  }
  public createNewChiffrage(){
    let requestedArtisans = [];
    for(let key in this.selectedLot){
      if(key == 'general'){
        requestedArtisans.push(
          {id: "general", name: "Tout corps d'états", requested: 0, icon: "facades.png"}
        );
      }
      this.dpgf.data.map(lot => {
        if(this.selectedLot[key] && lot.id == key){
          let item = {id:lot.id,name:lot.title,indexLot:lot.indexLot,requested:0,icon : lot.icon};
          requestedArtisans.push(item);
        }
      });
    }
    this.submitting = true;
    let ceProjectId = this.dpgf.ceProject;

    let body = {
      artisanName: this.artisanName,
      lots: requestedArtisans,
      facticeArtisanInfo:{name:this.artisanName}
    }
    
    this.subscriptions.push(
      this.dpgfPostesService.createFacticeArtisan(this.dpgf._id, ceProjectId, body).subscribe(
        res => {
          this.refresh.emit(true);
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Chiffrage crée", detail: "Chiffrage a été crée avec succès", life: 5000 });
          this.submitting = false;
          this.closeModal();
        },
        err => {
          console.log(err);
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Factice Artisan", detail: "An Error Occurred On Factice artisan", life: 5000 });
          this.submitting = false;
        }
      )
    );
  }
  /// copy Other depgfPricing
  public chooseOldChiffrage(){
    let ceProjectId = this.selectedInvite.ceProject._id;
    this.submitting = true;
    let artisanNormal = {
      name:this.selectedInvite['artisan'].name || '',
      address:this.selectedInvite['artisan'].address || '',
      emailAgency:this.selectedInvite['artisan'].emailAgency || '',
      telAgency:this.selectedInvite['artisan'].telAgency || '',
      tvaNumber:this.selectedInvite['artisan'].tvaNumber || '',
      assuranceNumber:this.selectedInvite['artisan'].assuranceNumber || '',
    }
    let body = {
      copy: true,
      artisanName: this.artisanName,
      lots: this.selectedInvite.lots,
      dpgfPricingId: this.selectedInvite.dpgfPricing._id,
      // facticeArtisanInfo: (this.selectedInvite["facticeArtisanInfo"] || artisanNormal || {}),
      facticeArtisanInfo:{name:this.artisanName}

    }
    this.subscriptions.push(
      this.dpgfPostesService.createFacticeArtisan(this.dpgf._id, ceProjectId, body).subscribe(
        res => {
          this.refresh.emit(true);
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Chiffrage crée", detail: "Chiffrage a été crée avec succès", life: 5000 });
          this.submitting = false;
          this.closeModal();
        },
        err => {
          console.log(err);
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Factice Artisan", detail: "An Error Occurred On Factice artisan", life: 5000 });
          this.submitting = false;
        }
      )
    );
  }
  public nextSlide(){
    if(this.currentList == ''){
      this.showNoSelectionErr = true;
    }else{
      this.showNoSelectionErr = false;
    }
    if(this.artisanName == ''){
      this.showEmptyNameErr = true;
    }else{
      this.showEmptyNameErr = false;
    }
  }
  public prevSlide(){
    this.showHeader = true;
    this.selectedInvite = {
      ceProject: {_id:''},
      dpgfPricing: {_id:''},
      lots: []
    };
    this.currentList = '';
  }

  public closeModal(){
    $("#factice-artisan-popup").modal('hide');
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }

}
