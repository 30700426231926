import { enableProdMode, NO_ERRORS_SCHEMA, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {provideRouter} from '@angular/router';

// import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DragulaModule } from 'ng2-dragula';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DpgfModule } from './app/dpgf/dpgf.module';
import { CommonModule } from '@angular/common';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MatTreeModule } from '@angular/material/tree';
import { ArtisansSuggestionsService } from './app/_services/artisansSuggestions.service';
import { UserService } from './app/_services/user.service';
import { AcvService } from './app/_services/acv.service';
import { IdentifySegement } from './app/all/segment/identify';
import { ProductsService } from './app/_services/products.service';
import { relatedFichesService } from './app/_services/relatedFiches.service';
import { VariantesService } from './app/_services/variantes.service';
import { ChantierService } from './app/_services/chantier.service';
import { TeamService } from './app/_services/team.service';
import { ArchitectRouteGuard, NewAccountArchitectRouteGuard, ArtisanRouteGuard, ExternalRouteGuard, LoggedRouteGuard, AdminRouteGuard } from './app/_guard';
import { ConfirmationService } from 'primeng/api';
import { NotificationsService, SimpleNotificationsModule } from 'angular2-notifications';
import { AuthService, AttributService, ArtisanService, ArchitectService, CeProjectService, CeProjectInvitesService, ErrorService, LogService, DpgfPostesService, CeTaskService, PaymentsService, ArchiDbService } from './app/_services/index';
import { Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import {routes} from './app/app.routes';

const interceptors: any = [];
const isRevitDesktop = navigator.userAgent.includes("Autodesk.Revit/2024");


declare var Tawk_API
declare var analytics
declare var Produktly
declare global {
  interface Window { // ⚠️ notice that "Window" is capitalized here
    analytics: any;
    prerenderReady: boolean;
    clickRevit : any;
    dispatchWebViewEvent : any;
    Produktly : any;
  }
}
var global = global || window

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(routes),
        importProvidersFrom(CommonModule, DpgfModule, RouterModule, 
        //Angular
        BrowserModule, FormsModule, ReactiveFormsModule, 
        //External
        SimpleNotificationsModule.forRoot(), 
        // NgRx
        StoreModule.forRoot({}, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }), EffectsModule.forRoot([]), StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        })),
        Title,
        //Services
        AuthService,
        AttributService,
        ArtisanService,
        ArchitectService,
        CeProjectService,
        NotificationsService,
        ConfirmationService,
        CeProjectInvitesService,
        ErrorService,
        LogService,
        DpgfPostesService,
        CeTaskService,
        PaymentsService,
        ArchitectRouteGuard,
        AdminRouteGuard,
        NewAccountArchitectRouteGuard,
        ArtisanRouteGuard,
        ExternalRouteGuard,
        LoggedRouteGuard,
        TeamService,
        ChantierService,
        ArchiDbService,
        VariantesService,
        relatedFichesService,
        ProductsService,
        IdentifySegement,
        AcvService,
        UserService,
        ArtisansSuggestionsService,
        MatTreeModule,
        ...interceptors,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
});
//
if(!environment.production){
  console.log("Not Prod")
}
//Start Amplitude Instance
if (environment.production) {
  document.write('<script type="text/javascript">amplitude.getInstance().init("f062ba8ba823f87503b00ecf7eaf753a");</script>');
}
if(!environment.onPremise){
  analytics.load(environment.segmentKey);
  // document.write("<script type='text/javascript' src='https://js.stripe.com/v3/'></script>");
  var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  if(currentUser.email){
    Tawk_API.visitor = {
      name  : currentUser.firstName + " " + currentUser.lastName,
      email : currentUser.email
    };
  }
}
// var tawkScript = document.createElement('script');
//   tawkScript.async = true;
//   tawkScript.src = 'https://embed.tawk.to/5cf4fdf2b534676f32ad1bcd/default';
//   tawkScript.charset = 'UTF-8';
//   tawkScript.setAttribute('crossorigin', '*');

//   // Append the script to the document
//   var firstScriptTag = document.getElementsByTagName('script')[0];
//   firstScriptTag.parentNode.insertBefore(tawkScript, firstScriptTag);

//   // Wait for Tawk.to script to load and ensure Tawk_API is defined
//   tawkScript.onload = function() {
//     var checkTawkAPI = setInterval(function() {
//       if (window.Tawk_API) {
//         clearInterval(checkTawkAPI); // Stop checking once Tawk_API is available

//         // Set visitor information after the script has loaded
//         var currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
//         if (currentUser.email) {
//           window.Tawk_API.visitor = {
//             name: currentUser.firstName + " " + currentUser.lastName,
//             email: currentUser.email
//           };
//         }
//       }
//     }, 100); // Check every 100 milliseconds
//   };