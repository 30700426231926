import { Component, OnInit , ViewChild, NO_ERRORS_SCHEMA, ElementRef } from '@angular/core';
import { Form3Component } from '../../../architect/form3/form3.component';
import { Form1Component } from '../../../architect/form1/form1.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { PaymentsService } from '../../../_services/index';
import { NgSharedModule } from 'src/app/all/ngshared.module';

// Services Imports
import { MessageService } from 'primeng/api';
import { ErrorService } from '../../../_services/index';
import { ArchitectService } from '../../../_services/index';
import { AuthService } from '../../../_services/index';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { ArchiExpiredModalComponent } from 'src/app/all/archi-expired-modal/archi-expired-modal.component';
import { ArchitectPricingTablesComponent } from 'src/app/architect/architect-pricing-tables/architect-pricing-tables.component';
import { ArchitectUsersComponent } from 'src/app/architect/architect-users/architect-users.component';
import { ArchiParrainageComponent } from '../archi-parrainage/archi-parrainage.component';
import { ArchitectBillsComponent } from 'src/app/architect/architect-bills/architect-bills.component';
import { ArchitectEmailsTempaltesEditorComponent } from 'src/app/architect/profile/architect-emails-tempaltes-editor/architect-emails-tempaltes-editor.component';


import { environment } from 'src/environments/environment';
import { link } from 'fs';
import { Editor } from 'src/app/all/editor/editor.component';
import { Toast } from 'primeng/toast';
import { DepartmentCollaborateursComponent } from "../../../architect/department-collaborateurs/department-collaborateurs.component";

declare var only:any;
declare var $:any;
const getId = (type) =>{
  return type + '_' + Math.random().toString(36).substr(2, 9);
}

// const preferenceArchiFields:String = "workAreaMain workAreaOther programme minBudgetMain minBudgetOther rangeRenovation rangeConstruction rangeSurelevation";
const agencyArchiFields:String = "logo name address typeAgency telAgency emailAgency website siret nOrdre about methode emailsTemplatesSenderName emailsTemplates";
// const savoirFaireArchiFields:String = "archiImage languages competences creationYearAgency nCollaborateur prix prixLaureat logo prixNomine";

@Component({
    selector: 'app-archi-edit-profil',
    imports: [
    HeaderComponent,
    ArchiExpiredModalComponent,
    Form1Component,
    Form3Component,
    ArchitectPricingTablesComponent,
    ArchitectUsersComponent,
    ArchiParrainageComponent,
    ArchitectBillsComponent,
    ArchitectEmailsTempaltesEditorComponent,
    NgSharedModule,
    Editor,
    DepartmentCollaborateursComponent
],
    providers: [],
    templateUrl: './archi-edit-profil.component.html',
    styleUrls: ['./archi-edit-profil.component.scss'],
    standalone: true,
    
})
export class ArchiEditProfilComponent implements OnInit {
  public onPremise: boolean = false;
  public dataArchi : any;
  public preferenceArchi : any;
  public agencyArchi : any;
  public savoirFaireArchi : any;
  public architect : any = {};
  public departement : any = [];
  public recommendations : any = [];
  public noRecommendation:Boolean = false;
  public loading:Boolean = false;
  public loadingData:Boolean = true;
  public archiId:String = "";
  public currentTab='licence';
  private subscriptions: Array<Subscription> = [];
  public selected;
  public subs;
  public users;methodPayment;
  public isAdminArchitect;
  public loadingSubs;loadingUsers;
  public tvaDefault;
  public tableEntry = [
    {id: "perso", name : "Informations générales", icon:"fa fa-user" , image: "home.png"},
    {id: "licence", name : "Licence", minarmName: "Collaborateurs", minarmIcon: "collabs-group-icon.svg", icon:"fa fa-credit-card", image: "group.png"},
    {id : "email-templates", name: "Paramètres ", link :'', icon:"fa fa-cog", image :"settings.svg"}
    
  ];
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  @ViewChild(Form3Component) formAgency: Form3Component;
  @ViewChild(Form1Component) formInfoPersonal: Form1Component;
  licence: any;
  public currentPage='perso';

  // emails templates
  public emailsTemplatesSenderName: string = "";
  public emailsTemplates: [] = [];
  
  // @ViewChild('editor') editorRef: ElementRef;
  @ViewChild('editor') editorRef: Editor;
  public range: any;

  constructor(private title: Title,
              public messageService: MessageService,
              public authService: AuthService,
              private ErrorService: ErrorService,
              private router: Router,
              public paymentsService: PaymentsService,
              private route: ActivatedRoute,
              private archiService: ArchitectService,
              ) {}

  ngOnInit() {
    this.onPremise = environment.onPremise;

    this.noMultiAccountsAllowed();
    // this.currentPage = this.route.snapshot.queryParams.tab || "perso";
    if(this.currentPage=='perso')window.analytics.track('pageview_profile',{properties:{acValue: 'pageview_profile'}});
    this.title.setTitle('Aglo - Editer le profil' );
    this.getUsers();
    this.getUserData();
    this.getUserSubs();
    this.getProfile();
    // this.scrollTo("#all-tabs",75);
    this.currentTab='licence'
    this.selected=this.currentPage;
    // this.changeTabfromQuery();
    if(this.authService.currentEnvironment)this.tableEntry = this.tableEntry.filter((val)=> val.id!='email-templates')
  }
  getUserSubs(){
    this.loadingSubs=true;
    // get subs with subs items
    this.paymentsService.getcustomerSubscriptions().subscribe(
      (data)=>{
        this.subs=data.subscriptions.data;
        this.methodPayment=data.paymentMethod.data[0];
        this.loadingSubs=false;
      })
  }
  // Users of architect
  public getUsers(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.archiId = (currentUser.archiId||"")
    this.loadingUsers = true;
      this.archiService.getUsers(this.archiId).subscribe(
        (data)=>{
          this.users=data;
          this.loadingUsers = false;
        },
        (err)=>{
          console.log("users couldnt be fetched",err)
        }
      )
  }
  //Scroll to
  public scrollTo(element, offset=0){
    $('html, body').animate({
        scrollTop: ($(element).offset().top - offset)
    },1500);
  }
  //Get Current profile
  getProfile(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.dataArchi = currentUser;
    
    this.isAdminArchitect= (currentUser.isAdminArchitect || "");
    // this.preferenceArchi = {};
    this.agencyArchi = {};
    this.savoirFaireArchi = {};
    this.archiId = (currentUser.archiId||"")
    if(this.archiId  != ""){
      this.subscriptions.push(
        this.archiService.getArchiProfile(currentUser.archiId)
        .subscribe(
          data => {
            this.architect = data;
            if(data.step == 2)this.router.navigate(['/architect/subscribe2'],{ queryParams: { email: data.emailAgency }});
            // this.preferenceArchi = only(data,preferenceArchiFields);
            
            this.agencyArchi = only(data,agencyArchiFields);
            this.emailsTemplates = this.agencyArchi.emailsTemplates;
            this.emailsTemplatesSenderName = this.agencyArchi.emailsTemplatesSenderName || this.agencyArchi.name;
            
            // this.savoirFaireArchi = only(data,savoirFaireArchiFields);
            
            // this.departement = this.preferenceArchi.workAreaMain;
            this.loadingData = false;
            this.recommendations = this.noRecommendation?[{name : "" , mail : ""}] : data.recommandation;

          },
          error => {
            console.log("error",error)
            this.loadingData = false;
            this.ErrorService.manageError(error,'/architect/edit/profil');
          })
        )
    }else{
      this.loadingData = false;
    }
  }
  getUserData(){
    this.authService.getUserLicenceStripeUser()
    .subscribe((res)=>{
      this.licence = res.data.licence;
    })
  }
  updateAgencyOrUser(){
    if(this.authService.licence=='without'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    var currentTabId = $('ul.navigation-bar > li.active > a').attr("href");
    this.updateUserPersonalInfo();
    this.update();
    // currentTabId === '#perso' ? this.updateUserPersonalInfo() : this.update();
    // segment event
    if(!environment.onPremise)window.analytics.track('profile_updated',{properties:{acValue: 'profile_updated'}});
  }
  updateUserPersonalInfo(){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userNewData = this.formInfoPersonal.getFormData();

    if(userNewData.email){
      if(!this.emailIsValid(userNewData.email) || !userNewData.email ){
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'E-mail non valide', detail: 'Merci de valide le E-mail', life: 5000 });
        return false;
      }
    }
    this.loading = true;
    this.subscriptions.push(
      this.archiService.updateUserInfo(userNewData, currentUser.id)
        .subscribe(
          data => {
            this.loading = false;
            this.updateLocalStorage(userNewData);
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Merci', detail: 'Vos modifications ont été enregistrées' });
          },
          error => {
            this.loading = false;
          }
      )
    );

  }
  updateLocalStorage(userNewData){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    for(let key in userNewData){
      if(currentUser[key]){
        currentUser[key] = userNewData[key];
      }
    }
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }
  emailIsValid(email) {
    if(email.length == 0) return false;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  //Save Data
  update(removeLogo=false){
    //Set Variables
    this.loading = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let archiId = currentUser.archiId;
    let archiNewData = this.formAgency.getFormData();
    archiNewData.step = -1;
    let validAgency = this.formAgency.isValid(false);
    let isValide =  validAgency ;
    // remove logo
    if(removeLogo){
      archiNewData["logo"] = {};
    }
    
    //Switch to the right onglet
    if(!validAgency){
      $('a[href="#agence"]').click();
      setTimeout(()=>{this.formAgency.scrollToError()},500);
    }
    if(isValide){
      this.subscriptions.push(
        this.archiService.update(archiId, {agencyData:archiNewData})
            .subscribe(
                data => {
                    this.loading = false;
                    this.scrollTo("#all-tabs");
                    // remove logo
                    if(removeLogo){
                      this.architect["logo"] = {};
                      $(".modal").modal("hide");
                    }
                    this.messageService.add({ key: 'toast', severity: 'success', summary: 'Merci', detail: 'Vos modifications ont été enregistrées', life: 5000 });
                },
                error => {
                    this.loading = false;
                    if(error.status == 401){
                      this.authService.logout()
                      this.router.navigate(['login'], { queryParams: { infoBubble: "login-required" }});
                    }else{
                      this.ErrorService.manageError(error,'/architect/edit/profil');
                    }
                })
      )
    }else{
      this.loading = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Merci de completer le formulaire', life: 5000 });
    }
  }
  public removeArchitectLogo(archiNewData){
    this.update(true);
  }
  changeTabfromQuery(){
    const availableTabs = ["profil", "licence", "parametres"];
    
    const tabsNamesMapper = { // this was added for clients to be more readable
      "profil": "perso",
      "licence": "licence",
      "parametres": "email-templates"
    }

    this.route.queryParams
      .subscribe(params => {
        if(params.tab){
          const tab = params.tab;
          if(availableTabs.includes(tab)){
            const getTabNameValue = tabsNamesMapper[tab];
                        
            this.currentPage = getTabNameValue;
            this.selected = getTabNameValue;
          }
        }
      });
  }
  changeTab(tab){
    this.selected=tab
    this.currentPage=tab;
    if(this.currentPage=='perso')window.analytics.track('pageview_profile',{properties:{acValue: 'pageview_profile'}});
    else if(this.currentPage=='licence')window.analytics.track('pageview_licence',{properties:{acValue: 'pageview_licence'}});
    else if(this.currentPage=='email-templates')window.analytics.track('pageview_parametres',{properties:{acValue: 'pageview_parametres'}});
    // this changes the scrolling behavior to "smooth"
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  cancelChanges(){
    this.formAgency.cancelChanges();
    this.formInfoPersonal.cancelChanges();
  }
  sendTvaToPricingTable(event){
    this.tvaDefault = event;
  }
  private noMultiAccountsAllowed(){
    this.subscriptions.push(
      this.authService.noMultiAccountsAllowed()
        .subscribe(
          data => {
            
          }  
        )
    );
  }

  private emailTemplateMessages:any = {
    "create":{severity: "success", summary: "Le modèle a bien été créé"},
    "update":{severity: "success", summary: "Le modèle a bien été mis à jour"},
    "delete":{severity: "error", summary: "Le modèle a bien été supprimé"},
    "sender":{severity: "success", summary: "Le nom de l'expéditeur a bien été modifié"},
  }

  public updateArchitectEmailTemplates(body){
    const {newBody, status} = body;
    const toastMessageBody = this.emailTemplateMessages[status];

    this.archiService.update(this.archiId, {agencyData: newBody})
    .subscribe(
      res=>{
        this.loading = false; 
        this.messageService.add({ key: 'toast', severity: toastMessageBody.severity, summary: toastMessageBody.summary, detail: '', life: 3000 });
      },
      error => {
        console.log("updateArchitectEmailTemplates : ", error);
      }
    );
  }


  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
