import { Component, Input, Output, EventEmitter } from '@angular/core';

// modules
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/_services';
declare var $: any;

@Component({
  selector: 'app-projects-cards-list',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './projects-cards-list.component.html',
  styleUrl: './projects-cards-list.component.scss'
})
export class ProjectsCardsListComponent {

  @Input() isMinarm: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() minarmIsAdminArchitect: boolean = false;
  @Input() license: string = "";

  @Input() authUserId: any = [];
  @Input() authArtchitectId: any = [];
  @Input() projects: any = [];
  @Input() authUserFavoritedProjects: any = [];

  @Output() goToProjectDashboardPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicateProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleFavorite: EventEmitter<any> = new EventEmitter<any>(); // favorite or unfavorite project
  @Output() archiveProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() openNeedLicenseModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleValidateProject: EventEmitter<any> = new EventEmitter<any>();

  /// if the project does not have a thumbnail, use this placeholder instead
  public projectImagePlaceholder:string = "https://s3.eu-central-1.amazonaws.com/bam-consultation-slickshot/22698xu9urkjo4uqwj%2F13787886-architectural-project-architect-rolls-and-plans.jpg";
  public MinarmprojectImagePlaceholder:string = "/assets/ce_project_placeholder.jpg";

  public currentUser;
  constructor(
    public authService: AuthService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
   }
  public checkTheLicense(){
    if(this.license == "without"){
      // close the opened dropdown
      $(".dropdown").dropdown('toggle');
      // open update license modal
      this.emitOpenNeedLicenseModal();
    }
  }
  public showEditorChip(project){ 
    let collaborateur = [];
    collaborateur =[...project.collaborateurs.map(val=>val._id)]
    if(project?.user) collaborateur.push(project.user._id)
    if(!this.authService.currentEnvironment) return !project?.demo &&  collaborateur.includes(this.authUserId) || project.architect.users.includes(this.authUserId) || this.isAdmin
    else return collaborateur.includes(this.currentUser.id) || this.currentUser.isAdmin || (this.currentUser.isAdminArchitect && this.currentUser.archiId == project.architect._id)
   }
  public emitOpenNeedLicenseModal(){
    this.openNeedLicenseModal.emit();
  }
  public emitGoToProjectDashboardPage(_id, dpgf){
    this.goToProjectDashboardPage.emit({_id, dpgf});
  }
  public emitDuplicateProject(projectId){
    this.duplicateProject.emit(projectId);
  }
  public emitToggleFavorite(addToFavorite, projectId){
    if(addToFavorite){
      this.authUserFavoritedProjects.push(projectId);
      if(!environment.onPremise)window.analytics.track('favourite_project',{properties:{acValue: 'favourite_project'}});
    }else{
      this.authUserFavoritedProjects = this.authUserFavoritedProjects.filter(item => item != projectId);
      if(!environment.onPremise)window.analytics.track('unfavourite_project',{properties:{acValue: 'unfavourite_project'}});
    }
    
    let body = {favoritedProjects: this.authUserFavoritedProjects};

    this.toggleFavorite.emit(body);
  }
  public emitArchiveProject(projectUser, projectId, projectArchiStatus){
    // only the owner can archive a project
    if(this.authUserId != projectUser){
      return;
    }

    let newArchiStatus = projectArchiStatus != 'archive' ? 'archive' : 'en_cours';
    
    if(!environment.onPremise && newArchiStatus=='archive')window.analytics.track('archive_project',{properties:{acValue: 'archive_project'}});
    if(!environment.onPremise && newArchiStatus=='en_cours')window.analytics.track('unarchive_project',{properties:{acValue: 'unarchive_project'}});
    
    let body = {
      projectId,
      archiStatus: newArchiStatus,
    }

    this.archiveProject.emit(body);
  }
  public emitRemoveProject(projectUser, projectId){
    // only the owner can archive a project
    if(this.authUserId != projectUser){
      return;
    }

    this.removeProject.emit(projectId);
  }

  public emitToggleValidateProject(projectId, projectStatus){
    let newProjectStatus = projectStatus != 'validated' ? 'validated' : 'en_cours';

    this.toggleValidateProject.emit({_id: projectId, status: newProjectStatus});
  }


}
