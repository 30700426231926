import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { ArchitectService } from 'src/app/_services/architect.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
    standalone:true,
    selector: 'app-archi-collab-modal',
    imports:[NgSharedModule],
    providers: [],
    templateUrl: './archi-collab-modal.component.html',
    styleUrls: ['./archi-collab-modal.component.scss'],
})
export class ArchiCollabModalComponent implements OnInit {

  @Input() lot;
  @Input() collabs;
  @Output() getNewCollabs = new EventEmitter<string>();
  public mail= '';
  public addingCollab: boolean;
  public mailMessage: boolean;
  public message: string;
  @ViewChild("emailInput") emailInput:ElementRef; 
  constructor(private ArchitectService:ArchitectService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit():void{
    fromEvent(this.emailInput.nativeElement, 'keyup').debounceTime(400).subscribe(() => this.validateEmail())
  }
  public addCollab(){
    this.addingCollab=true;
      this.ArchitectService.updateCollabByMail({email:this.mail,architectID:this.lot.archiId}).subscribe((data)=>{
        this.collabs.push({_id:data.archiReturn._id,emailAgency:this.mail})
        this.addingCollab=false;
        this.mail='';
        if(data.CollabAdded) this.messageService.add({ key: 'toast', severity: 'success', summary: "Partage de ma bibliothèque", detail: "Votre bibliothèque a bien été partagée", life: 5000 });
        else this.messageService.add({ key: 'toast', severity: 'success', summary: "Partage de ma bibliothèque", detail: "Votre bibliothèque a bien été partagée et un mail a été envoyé", life: 5000 });
      },
      (err)=>{
        console.log(err);
        this.addingCollab=false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur", detail: "Erreur serveur", life: 5000 });
      })
  }
  public deleteCollab(collab){
    this.confirmationService.confirm({
      message: 'Êtes vous sur de vouloir supprimer ce collaborateur ? ',
      header: 'Supprimer',
      key:'collab',
      accept: () => {
        this.addingCollab=true;
        this.ArchitectService.deleteCollab(collab._id).subscribe((data)=>{
          this.collabs=this.collabs.filter((newCollab)=>{return newCollab.emailAgency!=collab.emailAgency})
          this.addingCollab=false;
          this.messageService.add({ key: 'toast', severity: 'success', summary: "Arrêt du partage de ma bibliothèque", detail: "L'utilisateur n'a plus accès à votre bibliothèque", life: 5000 });
        },
        (err)=>{
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Erreur", detail: "Erreur serveur", life: 5000 });
        })
      }
    });
  }
  public validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let exist;
    this.mailMessage= re.test(String(this.mail).toLowerCase());
    this.collabs||[].forEach(element => {if(element.emailAgency===String(this.mail).toLowerCase()) exist=true;});
    if(this.mailMessage && !exist){
      this.ArchitectService.checkCollabToShare(this.mail).subscribe((data)=>{
        if(data.message){
          this.mailMessage=false;
          this.message=data.message;
        }
        else if(String(this.mail).toLowerCase()===data.myUser[0].email){
          this.mailMessage=false;
          this.message="Vous pouvez pas partagé avec vous-même";
        }
      })
    }
    else if(exist){
      this.mailMessage=false;
      this.message="Ce compte déjà ajouté au liste";
    }
  }
  
}
