<div class="projects-cards-wrapper flex flex-wrap scroller-1-y">

  <div class="single-project-card" *ngFor="let project of projects">
    <!-- Thumbnail Section -->
    <div class="thumbnail-section relative">
        <div class="project-state-icons width-100 flex space-between">

          <span class="read-only-badge" *ngIf="project?.demo">Démo</span>


          <!-- if the user is the one that created a project -->
           <div *ngIf="!authService.currentEnvironment">
             <span class="read-only-badge" *ngIf="!project?.demo && project?.user?._id == authUserId; else editeurAglo">Propriétaire</span>
           </div>
          <!--  -->
          
          <!-- if the user is the one that created a project -->
          <ng-template #editeurAglo>
            <span class="read-only-badge" *ngIf="showEditorChip(project) else lectureSeule">
              Editeur
            </span>
          </ng-template>
          <!--  -->     
          
          <ng-template #lectureSeule>
            <!-- <span class="read-only-badge" *ngIf="!project?.demo">Editeur</span> -->
            <span class="read-only-badge">Lecture seule </span>
          </ng-template>

          <!-- Favorite Star Image -->
          <span *ngIf="authUserFavoritedProjects.includes(project._id)">
            <img src="/assets/projects/golden-star-icon.svg" alt="" srcset="">
          </span>
          <!-- END Favorite Star Image -->

        </div>
        
        <!-- regular project image -->
        <img [src]="project.mainImage || projectImagePlaceholder" *ngIf="!isMinarm">
        <!-- minarm project image -->
        <img [src]="'/api/v1/static/'+project.mainImage" *ngIf="isMinarm && project.mainImage">
        <img [src]="MinarmprojectImagePlaceholder" *ngIf="isMinarm && !project.mainImage">
        

        <div class="image-overlapper pointer" (click)="emitGoToProjectDashboardPage(project._id, project.dpgf)"></div>
    </div>
    <!-- END Thumbnail Section -->
    
    <!-- Project Info Section -->
    <div class="project-info-section">

      <div class="">
        <p class="project-title pointer" (click)="emitGoToProjectDashboardPage(project._id, project.dpgf)">{{ project.title }}</p>
        <p class="project-address">
          {{ project.adresse||'' }}, {{ project.addressCodePostal||'' }} {{ project.addressCity||'' }}, {{ project.addressCountry||'' }}
        </p>
      </div>

      <div class="project-creator flex space-between">
        <div class="info-side flex">
          <span class="first-name-circle">{{ project?.user?.firstName | slice:0:1 }}</span>
          <div class="created-by">
            <p class="name">Créé par {{ project?.user?.firstName || '' }} {{ project?.user?.lastName || '' }}</p>
            <p class="date">{{ project.createdAt | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="dropdown-side">
          <div class="dropdown" *ngIf="!isAdmin">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="checkTheLicense()">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li class="dropdown-item pointer flex" (click)="emitDuplicateProject(project._id)">
                Dupliquer
              </li>
              <li class="dropdown-item pointer flex" *ngIf="!authUserFavoritedProjects.includes(project._id)" (click)="emitToggleFavorite(true, project._id)">
                Ajouter aux favoris
              </li>
              <li class="dropdown-item pointer flex" *ngIf="authUserFavoritedProjects.includes(project._id)" (click)="emitToggleFavorite(false, project._id)">
                Retirer des favoris
              </li>
              <li class="dropdown-item pointer flex" (click)="emitArchiveProject(project?.user?._id, project._id, project.archiStatus)" *ngIf="project?.user?._id == authUserId">
                <span *ngIf="project?.archiStatus != 'archive'">Archiver</span>
                <span *ngIf="project?.archiStatus == 'archive'">Restaurer</span>
              </li>
              <li class="dropdown-item remove pointer flex" (click)="emitRemoveProject(project?.user?._id, project._id)" *ngIf="project?.user?._id == authUserId">
                Supprimer
              </li>

              <li class="dropdown-item pointer flex" *ngIf="minarmIsAdminArchitect && project?.architect?._id == authArtchitectId" 
                (click)="emitToggleValidateProject(project._id, project.status)">
                <span *ngIf="project?.status != 'validated'">Valider le projet</span>
                <span *ngIf="project?.status == 'validated'">Passer le projet en cours</span>
              </li>

            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- END Project Info Section -->
  </div>

</div>