import { Component, OnInit , ViewChild, NO_ERRORS_SCHEMA } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
// Services Imports
import { ArchitectNewProjectComponent } from '../../../architect/architect-new-project/architect-new-project.component';
import { MessageService } from 'primeng/api';
import { ErrorService } from '../../../_services/index';
import { CeProjectService } from '../../../_services/index';
//Helpers
import { formateImage } from '../../../_helpers/formate-image';
import {Promise}  from 'bluebird';
//import { ArchitectModule } from '../architect.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-archi-modify-ce',
    imports:[
      
      NgSharedModule,
      HeaderComponent,
      ArchitectNewProjectComponent
    ],
    providers: [],
    templateUrl: './archi-modify-ce.component.html',
    styleUrls: ['./archi-modify-ce.component.scss'],
    standalone: true,

})
export class ArchiModifyCeComponent implements OnInit {
  @ViewChild(ArchitectNewProjectComponent) form: ArchitectNewProjectComponent;
  public project:any = {};
  public quickLinks:any = [];
  private subscriptions: Array<Subscription> = [];
  public projectId:string = "";
  public loadingData:boolean = true;
  public loading: boolean =false;

  // minarm
  public onPermis: boolean = false;

  constructor(private title: Title,
              private messageService: MessageService,
              private router: Router,
              private ceProjectService: CeProjectService,
              private route: ActivatedRoute,
              private ErrorService: ErrorService,
              private activatedRoute : ActivatedRoute,
              public location: Location) { }

  ngOnInit() {
    
    // minarm
    this.onPermis = environment.onPremise;

    this.getCeProject();
    this.title.setTitle('Aglo -  Modifier ma consultation' )
    // this.form.scrollTo("#q3");
  }
  public getCeProject(){
    this.route.params.forEach((params: Params) => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
        this.subscriptions.push(
          this.ceProjectService.getProject(this.projectId)
              .subscribe(
                  data => {
                      this.loadingData = false;
                      this.project = data;
                  },
                  error => {
                      console.log("error",error)
                      this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
                  })
        )
      }
    })
  }

  public modify(){
    // if project is demo don't update
    if(this.project.demo) return;

    this.loading = true;
    let isValid = this.form.isValid()
    let projectData = this.form.getFormData();

    let filesUploaded = ((projectData.architectFiles||[]).length !== 0 || (projectData.filesUrl||[]).length !== 0)
    if(this.project.status == "converted"){
      projectData.action = "completed"
    }
    if((this.project.status == "converted" || this.project.status == "created" )&& filesUploaded){
      projectData.action = "files"
    }
    if(isValid){
      this.subscriptions.push(
        this.ceProjectService.update(this.projectId,projectData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.router.navigate(['/architect/ce-project/'+ data.projectID]);

                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Projet modifié', detail: 'Votre projet a été modifié', life: 5000 });
                  // segment event
                  if(!environment.onPremise)window.analytics.track('update_project_info',{properties:{acValue: 'update_project_info'}});
              },
              error => {
                  console.log("error",error)
                  this.loading = false;
                  this.ErrorService.manageError(error,'/client/nouveau-projet')
              })
            )
    }else{
      this.loading = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: 'Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers', life: 5000 });
    }
  }
  public deleteThumbnail(): void{
    let projectData = {
      mainImage: ''
    }
    this.form.deleteFormImage();
    this.ceProjectService.update(this.projectId,projectData)
      .subscribe(
        data => {
          console.log('');
        },
        error => {
          console.log(error)
        }
      );

  }

  public goBack(){
   this.location.back();
  }
  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }

}
