<div class="all">
    <app-header ></app-header>
    <div class="folder-grid-container">
        <h1>Bibliothèques</h1>
        <div class="folder-grid">
            <div *ngFor="let folder of folders" class="folder">
                <img src="assets/folder_bibliotheque.svg" alt="Folder Icon">
                <p>{{ folder.name }}</p>
            </div>
        </div>
    </div>
</div>