<div class="floating-card">
  <div class="row">
    <div class="col-xs-6">
      <h2 class="secondary">ÉQUIPE</h2>
    </div>
    <!-- if base NOT licence -->
    <div class="col-xs-6" >

      <button class="add-btn" (click)="openCollabPopup()" type="button" name="button">
        Ajouter
      </button>

    </div>

  </div>
  <div class="team-list-container">
  <div class="row collaborateur" *ngFor="let collaborateur of collaborateurs; let i =index">

    <div class="col-xs-3 icon">
      <div class="user-name"
          [ngStyle]="{'border-color': collaborateur.color ,'color' :collaborateur.color}">
        {{(collaborateur.email || "").substring(0,1) | capitalize}}
      </div>
    </div>
    <div class="col-xs-4">
      <div class="colabName" *ngIf="collaborateur.firstName">{{collaborateur.firstName}} {{collaborateur.lastName}}</div>
      <div class="colabName" *ngIf="!collaborateur.firstName">Pas enregistré</div>
      <div class="colabSub">{{collaborateur.email}}</div>
      <div class="colabSub">
        {{collaborateur.role}} <span *ngIf="!authService.currentEnvironment && i == 0">Créateur</span> 
      </div>
    </div>

    <div class="col-xs-5 icon" *ngIf="i != 0 &&showDeleteIcon()">
        <i mwlConfirmationPopover
          class="fa fa-trash"
          popoverTitle="Supprimer"
          popoverMessage="Êtes vous sur de vouloir supprimer cet utilisateur ?"
          cancelText="X"
          confirmText="✓"
          placement="left"
          (confirm)="removeCollaborater(collaborateur._id)"
          (cancel)="true"></i>
    </div>

  </div>
  </div>
</div>

<!-- @Anas -->
<div id="upgrade-licence"
  class="modal"
  role="dialog" aria-hidden="true">
<div class="modal-dialog">
  <div class="callmodal-container">
    <h1>Update Your Account</h1>
    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum unde aliquam eligendi mollitia, possimus, exercitationem earum, velit reprehenderit iure rerum quidem ipsa. Nam sed ad commodi omnis sapiente neque eius?</p>

    <a class="btn-primary-color white-txt-btn" (click)="redirectToLicencesPage()">Upgrader</a>

  </div>
</div>
</div>

<div id="add-collaborateur"
      tabindex="-1"
      class="modal"
      role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="callmodal-container">
      <h1>Ajouter un partenaire</h1>
      <form class="add-form" [formGroup]="projectForm">
        <div>
          <label class="required-star question-label">Email</label>
          <div *ngIf="!authService.currentEnvironment" class="row form-input">
            <input formControlName="email" type="text" class="form-control" placeholder="Email" aria-describedby="sizing-addon1">
            <small *ngIf="formErrors.email" class="text-danger">{{ formErrors.email }}</small>
          </div>
          <div *ngIf="authService.currentEnvironment" class="row form-input" >
            <input formControlName="email" type="text" class="form-control" placeholder="Email" aria-describedby="sizing-addon1" (keyup)="checkMailExistence()">
            <small *ngIf="formErrors.email" class="text-danger">{{ formErrors.email }}</small>
            <small *ngIf="emailAlreadyExist" class="text-danger">{{ emailAlreadyExist }}</small>
          </div>
        </div>
        <div>
          <label class="required-star question-label">Rôle</label>
          <div class="row form-input">
            <input formControlName="role" type="text" class="form-control" placeholder="Rôle" aria-describedby="sizing-addon1">
            <small *ngIf="formErrors.role" class="text-danger">{{ formErrors.role }}</small>
          </div>
        </div>
        <!-- <div>
          <label class="required-star question-label">Droit</label>
          <div class="row form-input">
            <p-checkbox  [formControl]="projectForm.controls['rigths']" value="admin" label="Administrateur"></p-checkbox>
            <small *ngIf="formErrors.rigths" class="text-danger">{{ formErrors.rigths }}</small>
          </div>
        </div> -->
      </form>
      <button  *ngIf="!authService.currentEnvironment"  class='btn-primary-color' (click)="sendCollabInvite()" [disabled]="setLoading">
        <span *ngIf="!setLoading">Inviter</span>
        <app-spinner *ngIf="setLoading"></app-spinner>
      </button>
      <div *ngIf="authService.currentEnvironment">
        <button  class='btn-primary-color' [disabled]="!emailExist" (click)="sendCollabInvite()" >
          <span *ngIf="!setLoading">Inviter</span>
          <app-spinner *ngIf="setLoading"></app-spinner>
        </button>
    </div>
  </div>
</div>
