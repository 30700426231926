import { ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild, NO_ERRORS_SCHEMA, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '../_store';
import { saveDpgf, setDpgfAttribut } from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import { ArchitectService, AuthService, CeProjectService, DpgfPostesService } from 'src/app/_services';
import { ErrorService } from 'src/app/_services/index';
import { MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { FormatMOPipe } from 'src/app/_pipe/format-mo.pipe';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';

// external libraries
import { PdfViewerModule } from 'ng2-pdf-viewer';

const _ = require('lodash');

declare var $:any;

@Component({
  standalone:true,
  selector: 'app-archi-edit-document',
  imports:[
    DpgfModule,
    SpinnerComponent,
    FormatMOPipe,
    NgSharedModule,
    PdfViewerModule
  ],
  templateUrl: './archi-edit-document.component.html',
  styleUrls: ['./archi-edit-document.component.scss'],
})
export class ArchiEditDocumentComponent implements OnInit {

  @Input() pdfPreviewSrc:string;
  @Output() sendDpgfEvent = new EventEmitter<any>();
  @Output() reloadPdfPreview = new EventEmitter<any>();

  public projectId;
  public onpremiseProjectImage:string="";
  public dpgf$;
  public selectedDate;
  public typeIndice='Aglo';
  public indice;
  public template=1;
  public docData={
    assistants:[],
    agencement:1,
    font:'Lato',
    color:'#3805F2',
    typeIndice:'Aglo',
    indice:'A',
    showLogo:true,
    showIndice:true,
    showDate:true,
    typeDoc:'cctp',
    format:'pdf',
    options:{},
    colorHistory:[],
    customDate:false,
  };
  public colors=[
    {color:'blue',isSelected:false,hex:'#3805F2'},
    {color:'red',isSelected:false,hex:'#F21D2F'},
    {color:'green',isSelected:false,hex:'#5A9E6F'},
    {color:'pink',isSelected:false,hex:'#FF038B'},
    {color:'black-blue',isSelected:false,hex:'#0A1747'},
    {color:'orange',isSelected:false,hex:'#F88B3D'},
    {color:'grey',isSelected:false,hex:'#A9A9A9'},
    {color:'black',isSelected:false,hex:'#000'},
  ]
  public assistants=[];
  public style;
  public checkbox={logo:true,date:true,indice:true};
  public colorSelected=0;
  public subscriptions=[];
  public loadingData;
  public project;
  public showCalendar=true;
  public dpgf;
  public docTitle;
  loadingDate: boolean;
  agencement: any;
  font: any;
  dceDate: any;
  imageUrl: any;
  logo: any;
  public customDateSelected=false;
  titles: { dpgf: string; dqe: string; estimatif: string; devis: string; devisComm: string; };
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });

  public previewInputFields: any = {
    etablissement: "",
    maitreDouvrage: "",
    lieu: "",
    operation: "",
    operationNumber: "",
    marche: "",
    marcheNumber: "",
    projetNumber: "",
  };

  // Minarm
  public onPermis: boolean = false;

  constructor(private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              public authService: AuthService,
              private store: Store,
              private ceProjectService: CeProjectService,
              private ErrorService: ErrorService,
              private messageService:MessageService,
              private archiService: ArchitectService,
              private dpgfService: DpgfPostesService,
    ) { }

  ngOnInit(): void {

    // minarm
    this.onPermis = environment.onPremise;

    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    let currentDate = new Date().toDateString();
    this.dpgf$ = this.store.pipe(select(fromSelectors.getDpgf))
    this.store.pipe(select(fromSelectors.getDpgf)).subscribe((dpgf)=>{
      this.dpgf=dpgf;
      this.docData=_.get(dpgf,'docData',{assistants:[],agencement:1,font:'Lato',color:'#6F74E8',typeIndice:'Aglo',indice:'A',showLogo:true,showIndice:true,showDate:true,typeDoc:'cctp',format:'pdf',options:{},colorHistory:[]});
      if(!_.get(this.docData,'colorHistory.length',0)) {
        this.docData.colorHistory=[];
        this.docData.colorHistory.push('#3805F2');
      }
      this.projectId=(dpgf||{}).ceProject;
      this.dceDate=(dpgf||{}).dceDate || new Date();
      this.docData.customDate = (new Date(this.dceDate).toDateString() == currentDate) ? false : true;
      let i=this.colors.findIndex((color)=>color.hex==this.docData.color);
      if(i!=-1){
        this.colors[i].isSelected=true;
        this.colorSelected=i;
      }
      if(this.docData.typeDoc=='dpgf' && !this.docData.options['dqe']) this.docTitle="Décomposition du Prix Global et Forfaitaire"
      if(this.docData.typeDoc=='dpgf' && this.docData.options['dqe'] && !this.docData.options['estimatif']) this.docTitle="Décomposition du Prix Global et Forfaitaire et Détail Quantitatif Estimatif"
      if(this.docData.typeDoc=='dpgf' && this.docData.options['estimatif']) this.docTitle="Estimation Détaillée du Montant des Travaux"
      if(this.docData.typeDoc=='cctp') this.docTitle="Cahier des Clauses Techniques Particulières"
      if(this.docData.typeDoc=='recap' && this.docData.format=='pdf') this.docTitle="Récapitulatif de l'estimation"
      this.style={
        color:{
          'color':this.docData.color
        }
      }
    })
    this.getCeProject();
    this.authService.getUserLicenceStripeUser().subscribe((user)=>{})
    this.archiService.getArchiProfile(currentUser.archiId).subscribe((data)=>{
      this.logo=_.get(data,'logo.cdnUrl','');
    })
    this.authService.getImage().subscribe((data)=>{
      this.imageUrl=data;
    })
    $("body").css("overflow", "hidden");
  }
    
  addAssistant(){
    if(!_.isArray(this.docData.assistants)) this.docData.assistants=[];
    else if(this.docData.assistants.length<6){
      this.docData.assistants.push({name:"",role:"",email:"",tel:''})
      this.dpgf.docData=this.docData;
      this.sendDpgfToParent()
    }
    // this.store.dispatch(setDpgfAttribut({key:'docData.assistants',value:this.docData.assistants}));
    // this.store.dispatch(addChange({}))
  }
  deleteAssistant(i){
    this.docData.assistants=this.docData.assistants.filter((a,index)=>index!=i)
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    // this.store.dispatch(setDpgfAttribut({key:'docData.assistants',value:this.docData.assistants}));
    // this.store.dispatch(addChange({}))
  }
  addChange(){
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
  }
  setDPGFAttribut(data){
    this.store.dispatch(setDpgfAttribut(data));
  }
  saveToServe(){
    this.store.dispatch(saveDpgf({}));
  }
  sendDpgfToParent(){
    if(!environment.onPremise)window.analytics.track('update_exports_settings',{properties:{acValue: 'update_exports_settings'}});
    this.sendDpgfEvent.emit(this.dpgf);
  }
  saveInfo(ob){
    this.dpgf[ob.key]= ob.value;
  }
  public reRenderPdfPreview(key, value){
    this.dpgf[key]= value;
    
    this.fillInputsFields(this.project, true);
  }
  changeFont(value){
    this.docData.font=value;
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    // this.store.dispatch(setDpgfAttribut({key:'docData.font',value:value}));
    // this.addChange()
    $(".preview-container p").css("font-family", value.toString());
    $(".preview-container span").css("font-family", value.toString());
    
    // Reload PDF Preview
    this.fillInputsFields(this.project, false);
    this.emitReloadPdfPreview();
  }
  changeColor(color,i=undefined){
    this.style['color']['color']=color;
    if(i!=undefined){
      this.colors[i].isSelected=true;
      this.colors[this.colorSelected].isSelected=false;
      this.colorSelected=i;
    }
    else this.colors[this.colorSelected].isSelected=false;
    this.docData.color=color;
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    this.docData.colorHistory.unshift(color);
    if(this.docData.colorHistory.length>8) this.docData.colorHistory=this.docData.colorHistory.slice(0,8);
    // this.store.dispatch(setDpgfAttribut({key:'docData.color',value:color}));
    // this.addChange()


    // Reload PDF Preview
    this.fillInputsFields(this.project, false);
    this.emitReloadPdfPreview();
  }
  setAgencement(type){
    this.docData.agencement=type;
    setTimeout(()=>{
      $(".preview-container p").css("font-family", this.docData.font);
      $(".preview-container span").css("font-family", this.docData.font);
    },1)
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    // this.store.dispatch(setDpgfAttribut({key:'docData.agencement',value:type}));
    // this.addChange()
    // Reload PDF Preview
    this.fillInputsFields(this.project, false);
    this.emitReloadPdfPreview();
  }
  changeIndice(){
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    // this.store.dispatch(setDpgfAttribut({key:'docData.indice',value:this.docData.indice}));
    // this.addChange()
  }
  changeTypeIndice(value){
    this.docData.typeIndice=value
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    // this.store.dispatch(setDpgfAttribut({key:'docData.typeIndice',value:this.docData.typeIndice}));
    // this.addChange()

    // Reload PDF Preview
    this.fillInputsFields(this.project, false);
    this.emitReloadPdfPreview();
  }
  check(type){
    if(this.docData[type]==undefined)this.docData[type]=false;
    else this.docData[type]=!this.docData[type]
    this.dpgf.docData=this.docData;
    this.sendDpgfToParent()
    setTimeout(()=>{
      $(".preview-container .detail-parag").css("font-family", this.docData.font);
    },1)
    // this.store.dispatch(setDpgfAttribut({key:`docData.${type}`,value:this.docData[type]}));
    // this.addChange()

    // Reload PDF Preview
    this.fillInputsFields(this.project, false);
    this.emitReloadPdfPreview();
  }
  appendStyle(...args){
    let style={}
    args.forEach(element => {
      style={...style,...element}
    });
    return style;
  }
  public getCeProject(){
    this.loadingData =true;
    this.route.params.forEach((params: Params) => {
      if (this.projectId) {
        this.subscriptions.push(
          this.ceProjectService.getProject(this.projectId)
              .subscribe(
                  data => {
                      this.loadingData = false;
                      this.project=data;
                      this.fillInputsFields(data);

                      if(environment.onPremise){
                        let mainImage = this.project.mainImage;
                        this.onpremiseProjectImage = (mainImage && mainImage != "") ? `/api/v1/static/${mainImage}` : "/assets/ce_project_placeholder.jpg";
                      }
                      this.selectedDate = new Date(data.dateTravaux);
                      setTimeout(()=>{
                        $(".preview-container p").css("font-family", this.docData.font);
                        $(".preview-container span").css("font-family", this.docData.font);
                      },1)
                  },
                  error => {
                      console.log("error",error)
                      this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
                  })
        )
      }
    })
  }
  public updateCeProject(body){
    this.subscriptions.push(
      this.ceProjectService.update(this.projectId, body)
        .subscribe({
          next: (data) => {
            // console.log("updateCeProject : ", data);
          },
          error: (err) => {
            console.log("updateCeProject : ", err);
          },
        })
    );
  }
  
  public updateDpgf(body){
    this.subscriptions.push(
      this.dpgfService.patchDpgf(this.dpgf._id, body, "")
        .subscribe({
          next: (data) => {
            // console.log("updateCeProject : ", data);
          },
          error: (err) => {
            console.log("updateCeProject : ", err);
          },
        })
    );
  }

  formateDate(dateString){
    let date = new Date(dateString);

    if(!this.docData.customDate){ // is "Date du jour" is selected, use today's date
      date = new Date();
    }
    // if "" is checked, and no value on date input, use today's date
    if(!this.dpgf?.dceDate){
      date = new Date();
    }

    let months= ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
    let dayMonth = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    return `${dayMonth} ${months[month]} ${year}`
  }

  handleDateSection(event){
    let target = event.target;
    let className = event.srcElement.className;
    this.customDateSelected = (className == 'custom-date' && target.checked) ? true : false;
    this.docData.customDate = !this.docData.customDate;    

    if(!this.customDateSelected){
      this.dpgf['dceDate'] = new Date().toDateString();
      this.dpgf.docData= this.docData;
      this.sendDpgfEvent.emit(this.dpgf);
      this.cd.detectChanges();
    }

    // Reload PDF Preview
    this.fillInputsFields(this.project, false);
    this.emitReloadPdfPreview();
  }
  private fillInputsFields(project, triggerReload=true){
    const { 
      adresse, addressCodePostal, addressCity, 
      maitreDouvrage, operationNumber, operation, projetNumber, marcheNumber, marche,
      etablissement, lieu,
      architect
    } = project;
    
    const {
      operationText, maitreOuvrage, maitreOeuvre
    } = this.dpgf;

    let etablissementValue = etablissement || architect?.name;
    let lieuValue = lieu || `${adresse}, ${addressCodePostal} ${addressCity}`;

    this.previewInputFields.etablissement = etablissementValue;
    this.previewInputFields.maitreDouvrage = this.dpgf?.maitreOuvrage;
    this.previewInputFields.lieu = lieuValue;
    this.previewInputFields.operation = this.dpgf?.operationText;

    // this.previewInputFields.maitreDouvrage = maitreDouvrage;
    this.previewInputFields.maitreOeuvre = maitreOeuvre;

    this.previewInputFields.operation = this.onPermis ? operation : operationText;
    this.previewInputFields.operationNumber = operationNumber;
    this.previewInputFields.marche = marche;
    this.previewInputFields.marcheNumber = marcheNumber;
    this.previewInputFields.projetNumber = projetNumber;

    // if minarm use template 'minarm' else else template number from 0-4
    let template = this.onPermis ? "minarm" : this.docData.agencement;
    this.previewInputFields.template = template;

    // architect agency logo
    this.previewInputFields.architectAgencyLogo = this.logo;    

    // emit generate pdf preview
    if(triggerReload){
      this.reloadPdfPreview.emit(this.previewInputFields);
    }
  }

  public previewTextFieldChanged(){
    const { etablissement, lieu } = this.previewInputFields;
    // save values on ceProject
    this.updateCeProject(this.previewInputFields);
    // save values for dpgf
    this.updateDpgf({etablissement, lieu});
    
    this.emitReloadPdfPreview();
  }

  private emitReloadPdfPreview(){
    this.reloadPdfPreview.emit(this.previewInputFields);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    $("body").css("overflow", "auto");
  }
}
