<div class="all">
  <app-header></app-header>
  <div class="container">
    <app-architect-new-project 
      [project]="{architectFiles:[]}" 
      [updating]="false"
      [onPermis]="onPermis"
    ></app-architect-new-project>

    <!-- <app-google-autocomplete ></app-google-autocomplete> -->
    <div class="center">
      <button [disabled]="loading" (click)="register();" href="#" class="btn-primary-color">
        <div *ngIf="!loading">Créer</div>
        <app-spinner *ngIf="loading"></app-spinner>
      </button>
    </div>

  </div>
</div>

<app-need-subscription-popup-2></app-need-subscription-popup-2>
