<div class="modal fade" id="create-departement" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body padding42-bottom20">
          <div class="title-text">
           <h3> Ajouter un nouveau département</h3>
        </div>
        <form [formGroup]="subscriptionForm">
          <p class="padding-top-10 margin-bottom-0">Nom du département</p>
          <input type="text" formControlName="name"  type="text" class="form-control"> 
          <small *ngIf="formErrors.name" class="text-danger">
            {{ formErrors.name }}
          </small>
          <p class="padding-top-10 margin-bottom-0">Téléphone du département</p>
          <input type="text" formControlName="telAgency"  type="text" class="form-control"> 
          <small *ngIf="formErrors.telAgency" class="text-danger">
            {{ formErrors.telAgency }}
          </small>
          <p class="padding-top-10 margin-bottom-0">Email</p>
          <input type="text" formControlName="emailAgency"  type="text" class="form-control">
          <small *ngIf="formErrors.emailAgency" class="text-danger">
            {{ formErrors.emailAgency }}
          </small>
          <p class="padding-top-10 margin-bottom-0">Logo du département</p>
          <!-- <input type="file" formControlName="logo" class="form-control" (click)="initUppy()" accept="image/png, image/jpeg"> -->
        <!-- <div id="DashboardContainer"></div> -->
        <div *ngIf="!uploading &&onpremiseProjectImage">
          <img style="width:110px;" [src]="onpremiseProjectImage || ''">
        </div>
        <p-fileUpload mode="basic" accept=".png, .jpg, .jpeg"  chooseLabel="Naviguer" name="demo1" url="/api/v1/upload-file?type=logo" [auto]="true" 
        (onUpload)="onUploadLogoDepartement($event)"></p-fileUpload>
        
        <small *ngIf="formErrors.logo" class="text-danger">
            {{ formErrors.logo }}
          </small>
      </form>
        <div class="modal-footer" >
          <div class="flex float-right">
          <button type="button" class="annuler-button" data-dismiss="modal">Annuler</button>
          <button type="button" *ngIf="!loading" class="send-button" [disabled]="autorise"  (click)="createDepartement()" >Valider</button>
          <button type="button" *ngIf="loading" class="send-button" [disabled]="autorise">
              <app-spinner *ngIf="loading"></app-spinner>
          </button>

        </div>
       </div>
        </div>
      </div>
  </div>