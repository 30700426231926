import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ArchitectService, AuthService, PaymentsService } from 'src/app/_services';
import { environment } from '../../../environments/environment';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Subscription } from 'rxjs';

declare var $:any;
declare var Stripe: any;

@Component({
  selector: 'app-department-collaborateurs',
  standalone: true,
  imports:[NgSharedModule],
  templateUrl: './department-collaborateurs.component.html',
  styleUrl: './department-collaborateurs.component.scss'
})
export class DepartmentCollaborateursComponent {
  private archiData;
  public password;passwordConfirmation;
  public user;loading;
  public archiId;
  public userForm: UntypedFormGroup;
  public loadingUsers=true;loadingSubs=true;creatingUser=false;mailExist=false;isAdminArchitect;
  public plan;
  public posSubMonth=-1;posSubYear=-1;posSubAdmin=-1;
  public loadinglicence:Boolean = false;

  @Input() subscriptions;
  @Input() users;
  @Output() getSubsEvent = new EventEmitter<string>();
  @Output() getUsersEvent = new EventEmitter<string>();
  public deletingUser: boolean=false;
  public loadingPassword: boolean;
  public firstTime=true;
  public dateEndTrial: any;

  constructor(
    private route: ActivatedRoute,
    private archiService: ArchitectService,
    private authService: AuthService,
    public paymentsService: PaymentsService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.plan = this.authService.licence;
    }, 1000);
    this.route.queryParams.subscribe((params: Params) => {
      if(params.doneUser){
        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Mise à jour des abonnoments', detail: 'Votre abonnements ont été bien mise à jour', life: 5000 });
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.users && !changes.users.firstChange) {
      this.loadingUsers=true;
      this.createForm();
    }
  }
  public createForm(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.archiData = currentUser;
    let archiUser=this.users.filter(user=>user.isAdminArchitect==true);
    this.dateEndTrial=archiUser[0].dateEndTrial;
    this.isAdminArchitect=currentUser.isAdminArchitect;
    this.archiId=currentUser.archiId;
    this.userForm = this.fb.group({
      email: ['',
      Validators.compose([
        Validators.required, Validators.email
      ])
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: '',
      archiID:this.archiId,
      archiName:this.archiData.firstName,
      licence:"premiumn-annuel",
      cgu:true,
      isAdmin:false,
      isAdminArchitect:false,
      isPending:true
    });
    this.loadingUsers=false;
  }
  public onKeyUpMail(){
    this.authService.validateEmail(this.userForm.value.email).subscribe(
      (data)=>{
          this.mailExist=data;
      },
      (err)=>{
        console.log(err);
      })
  }

  public setUser(user){
    this.user=user;
  }
  public createNewUser(){
    if (this.userForm.valid) {
    this.subscriptions.push(
      this.archiService.createCollaborateur(this.userForm.value).subscribe((data) => {
        this.userForm.patchValue({email:"",lastName:"",firstName:"",phone:""})
        this.getUsersEvent.emit();
        $('#ajouter-user-popup').modal('hide');
        this.messageService.add({ key: 'toast', severity: 'success', summary: "L'utilisateur a été créé", life: 5000 });
      },err=>{
        if(err.error.err.message == "You already reached maximum users") {
          this.messageService.add({ key: 'toast', severity: 'error', summary: "Limite d'utilisateurs atteinte" , detail: "Vous avez atteint la limite maximale de 50 utilisateurs. Pour ajouter de nouveaux utilisateurs, veuillez contacter l'administrateur.", life: 10000 });
          $('#ajouter-user-popup').modal('hide');
      }
        else this.messageService.add({ key: 'toast', severity: 'error', summary: "L'utilisateur n'a pas été créé", life: 5000 });
      })
    )
  }
  }
  public removeCollaborateur(){
    this.deletingUser = true;
    this.subscriptions.push(
      this.archiService.removeCollaborateur(this.user._id,{}).subscribe((data) => {
        this.deletingUser = false;
        this.getUsersEvent.emit();
        this.user={};
        $('#delete-user').modal('hide');
        this.messageService.add({ key: 'toast', severity: 'success', summary: "L'utilisateur a été supprimé", life: 5000 });
      },err=>{
        console.log('err',err.error)
        this.deletingUser = false;
        this.messageService.add({ key: 'toast', severity: 'error', summary: "L'utilisateur n'a pas été supprimé", life: 5000 });
      })
    )
  }
  public changeUserPassword(){
    this.loadingPassword=true;
    this.archiService.userChangePassword(this.user._id,this.password).subscribe(
      (data)=>{
        this.loadingPassword=false;
        $('#modify-user-password-popup').modal('toggle');
        this.messageService.add({ key: 'toast', severity: 'success', summary: "Mot de pass d'utilisateur", detail: 'Le mot de pass a été changer', life: 5000 });
        this.password="";this.passwordConfirmation="";
      },
      (err)=>{
        this.messageService.add({ key: 'toast', severity: 'error', summary: "Mot de pass d'utilisateur", detail: "Erreur Serveur", life: 5000 });
      }
    )
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
}
}
