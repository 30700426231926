import { Component } from '@angular/core';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

@Component({
  selector: 'app-bibliotheque',
  standalone: true,
  imports: [ HeaderComponent, NgSharedModule],
  templateUrl: './bibliotheque.component.html',
  styleUrl: './bibliotheque.component.scss'
})
export class BibliothequeComponent {
  public  folders = [
    { name: 'Bibliothèque de ESID de Lyon' },
    { name: 'Bibliothèque de ESID de Rennes' },
    { name: 'Bibliothèque de ESID de Toulon' },
    { name: 'Bibliothèque de ESID de Brest' },
    { name: 'Bibliothèque de ESID de Metz' },
    { name: 'Bibliothèque de ESID de Bordeaux' },
    { name: 'Bibliothèque du CNPID' },
    { name: 'Bibliothèque de ESID de Lyon' },
    { name: 'Bibliothèque de ESID de Rennes' },
    { name: 'Bibliothèque de ESID de Toulon' },
    { name: 'Bibliothèque de ESID de Brest' },
    { name: 'Bibliothèque de ESID de Metz' },
    { name: 'Bibliothèque de ESID de Bordeaux' },
    { name: 'Bibliothèque du CNPID' }
  ];
}
