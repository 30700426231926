import { Component, OnInit, Input, HostListener, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PrintDpgf } from 'src/app/_interfaces/printDpgf.interface';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { Subscription } from 'rxjs';
import { trigger, state, animate, style, transition } from '@angular/animations';

import { DpgfPostesService, ErrorService, AuthService, VariantesService } from 'src/app/_services';

import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { DpgfModule } from '../../dpgf.module';
import { DpgfResultTabsNavbarComponent } from '../dpgf-result-tabs-navbar/dpgf-result-tabs-navbar.component';
import { DpgfDevisPopupComponent } from '../dpgf-lots-popup/dpgf-devis-popup.component';
import { environment } from 'src/environments/environment';

import { select, Store } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';

declare var $;


@Component({
  standalone:true,
  selector: 'app-dpgf-result-summary',
  templateUrl: './dpgf-result-summary.html',
  imports:[
    DpgfModule,
    DpgfResultTabsNavbarComponent,
    DpgfDevisPopupComponent,
    NgSharedModule
  ],
  styleUrls: ['./dpgf-result-summary.scss'],
  animations: [
    trigger('showHide', [
      state('in', style({
        overflow: 'hidden',
        height: '*',
      })),
      state('out', style({
        overflow: 'hidden',
        height: '95px'
      })),
      transition('in <=> out', animate(150)),
    ])
  ]
})
export class DpgfResultSummaryComponent implements OnInit {
  public projectLicence: string='none';
  public ht:boolean = true;
  public options:boolean = true;
  public showedData:any =  [];
  public devisIndex:number = 0;
  public isSticky:boolean = false;
  public shadowRight: boolean = false;
  public filteredArtisans: string[] = [];
  public filteredLots: string[] = [];
  public hiddenLots: string[] = [];
  public toggleComments = [];
  public estimatifs: any = {};
  public features;
  public selectedDpgfPricing: any = {};
  public loadingPage:boolean =true;
  public downloadingExcel:boolean =false;
  public dpgfId:any = '';
  /// Variantes
  public variantesList: any = [];
  public newVarianteTitle:string = '';
  public selectedVarianteTitle:string = '';
  public selectedVarianteId:string = '';
  public checkedLots: any = {};
  public creatingVarianteMode:boolean =false;
  public updatingVarianteMode:boolean =false;
  public creatingVariante:boolean =false;
  public showingVariante:boolean =false;
  public viewingVariante:boolean =false;
  public newlyCreatedVariante:boolean =false;
  public showVariantesList:boolean =false;
  public openLots: any = {};
  public varianteToDelete: any = {};
  public varianteToRename: any = {};
  public updateVarianteTitle: string = '';
  public varianteRemodified:boolean = false;
  public currentVariantePostsIds: any = [];
  public artisansFilterChecked: boolean = true;
  public lotsFilterChecked: boolean = true;
  public deletingVariante: boolean = false;
  public showSubmitButton:boolean =false;
  public selectedLots: any = {}


  public artisansColorsMap: any = {};
  public colors = [
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" ,
    "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9" ,"#a4e43f" ,"#d298e2" ,"#6119d0",
    "#d2737d" ,"#c0a43c" ,"#f2510e" ,"#651be6" ,"#79806e" ,"#61da5e" ,"#cd2f00" ,
    "#9348af" ,"#01ac53" ,"#c5a4fb" ,"#996635","#b11573" ,"#4bb473" ,"#75d89e" ,
    "#2f3f94" ,"#2f7b99" ,"#da967d" ,"#34891f" ,"#b0d87b" ,"#ca4751" ,"#7e50a8" ,
    "#c4d647" ,"#e0eeb8" ,"#11dec1" ,"#289812" ,"#566ca0" ,"#ffdbe1" ,"#2f1179" ,
    "#935b6d" ,"#916988" ,"#513d98" ,"#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
    "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
    "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
    "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
    "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
    "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
    "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
    "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
    "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
    "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
    "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
    "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
    "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
    "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
    "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
    "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
    "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
    "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
    "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
    "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
    "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
    "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
    "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
    "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
    "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
    "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"];

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    if (window.pageYOffset > 100) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  public printOptions: any = {
    comments : false,
    cctp : false,
    contract : false
  };

  public pricingList: any[] = [];
  public dpgf: {_id :string, data : any[],nomPrenom:String, currentVersion: any,totalHt:any, totalTtc :any , options :any , demo :any};
  public dpgfTotalHt: any = 0;
  public subscriptions: Array<Subscription> = [];

  @Output() onArtisanSelect: EventEmitter<number> = new EventEmitter<number>();


  constructor(
    public dpgfPostesService: DpgfPostesService,
    private store: Store,
    private authService: AuthService,
    private route: ActivatedRoute,
    private ErrorService: ErrorService,
    private variantesService: VariantesService
  ) { }

  ngOnInit() {
    this.getData();

    this.authService.features.subscribe((f)=>{
      this.features = f ;
    });
    this.getVariantes();

    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
  }
  public scrollListener(){
    const sum = document.getElementById("result-summary");
    const fixedBar = document.getElementById('fixed-bar');
    const sticky = $('.sticky');
    sum.addEventListener('scroll',() => {
      sticky.css({marginTop: sum.scrollTop + 'px'});
      const x = sum.scrollLeft;
      fixedBar.style.marginLeft = x + 'px';
      if(x > 0)
        this.shadowRight = true;
      else
        this.shadowRight = false;
    });
    $(document).on('click', '.filter-container .dropdown-menu', function (e) {
      e.stopPropagation();
    });
  }
  public hideAllLots() {
    this.showedData.forEach(lot => {
      this.hiddenLots.push(lot.id);
    });
  }
  public computeDpgfs(data, calculatePosts = false, lotId=null){
    let newData = []
    
    // if(this.filteredArtisans.length){
    //   this.pricingList = this.pricingList.filter(item => !this.filteredArtisans.includes(item.artisanIndex));
    // }
    
    for (let i = 0; i < data.length; i++) {
      let newStructure = [];
      
      // if(this.filteredArtisans.length){

      // }

      this.pricingList[i].artisanIndex = "artisan"+i;

      //Lots
      for (let j = 0; j < data[i].data.length; j++) {
        if(lotId && lotId != data[i].data[j].id) return;
          newStructure.push({
            id  : data[i].data[j].id ,
            artisan : "artisan"+i,
            total : data[i].data[j].total ,
            totalTtc : data[i].data[j].totalttc
          });
          //Sous Lots
          if(calculatePosts && data[i].data[j].sousLots){
            for (let k = 0; k < data[i].data[j].sousLots.length; k++) {
              newStructure.push({
                id  : data[i].data[j].sousLots[k].id ,
                artisan : "artisan"+i,
                total : data[i].data[j].priceAll?'-':data[i].data[j].sousLots[k].total,
                totalTtc : data[i].data[j].priceAll?'-':data[i].data[j].sousLots[k].totalttc
              });
              //Postes
              for (let l = 0; l < (data[i].data[j].sousLots[k].postes||[]).length; l++) {
                let thisPost = data[i].data[j].sousLots[k].postes[l];
                let sousPostesTab = [];
                // console.log('thisPost',thisPost)
                if(thisPost.sousPostes){
                  for (let m = 0; m < (thisPost.sousPostes||[]).length; m++) {
                    let thisousPost = thisPost.sousPostes[m];
                    // console.log('thisousPost',thisousPost)
                    sousPostesTab.push({
                      id  : thisousPost.id,
                      artisan : "artisan"+i,
                      color : this.colors[i],
                      sousLotId : data[i].data[j].sousLots[k].id,
                      comment : (thisousPost.comment||[]) ,
                      total : data[i].data[j].priceAll?'-':thisousPost.price ,
                      qte : data[i].data[j].priceAll?'-':thisousPost.qte ,
                      isAtisan : thisousPost.isAtisan,
                      sousPost :thisousPost
                    });
                  }
                }
                newStructure.push({
                  id  : data[i].data[j].sousLots[k].postes[l].id,
                  designation  : data[i].data[j].sousLots[k].postes[l].designation,
                  artisan : "artisan"+i,
                  color : this.colors[i],
                  sousLotId : data[i].data[j].sousLots[k].id,
                  comment : (data[i].data[j].sousLots[k].postes[l].comment||[]) ,
                  total : data[i].data[j].priceAll?'-':data[i].data[j].sousLots[k].postes[l].price ,
                  qte : data[i].data[j].priceAll?'-':data[i].data[j].sousLots[k].postes[l].qte ,
                  totalTtc : data[i].data[j].priceAll?'-':data[i].data[j].sousLots[k].postes[l].pricettc,
                  isAtisan : data[i].data[j].sousLots[k].postes[l].isAtisan,
                  post :thisPost,
                  sousPostes :sousPostesTab
                });
              }
            }
          }
      }
      newData.push(newStructure)
    }
    // console.log('newData',newData)
    return newData;
  }
  public removeOptions(pricings){
    for(var pricing of pricings){
      for(var lot of pricing.data){
        var toRemoveLot = 0;
        for(var souslot of lot.sousLots){
          var toRemoveSousLot = 0;
          for(var poste of souslot.postes){
            if(poste.option){
              toRemoveLot += poste.total||0
              toRemoveSousLot += poste.total||0
            }
          }
          souslot["total"] = souslot["total"] - toRemoveSousLot
        }
        lot["total"] = souslot["total"] - toRemoveLot
      }
    }
    return pricings;
  }

  public showData(filteredPricingList=undefined){
    var data = this.pricingList;
    
    if(filteredPricingList){
      data = filteredPricingList;
    }

    let artisanPricing = this.computeDpgfs(data);
    let dpgfData = this.dpgf.data;

    for (let i = 0; i < dpgfData.length; i++) {
        let lot: any = dpgfData[i];
        lot.priceList = this.getPrices(artisanPricing,lot.id);

        if(lot.sousLots && lot.sousLots.length){
        for (let j = 0; j < lot.sousLots.length; j++) {
            let souslot = lot.sousLots[j];
            souslot.priceList = this.getPrices(artisanPricing,souslot.id);
            let addedPostes = this.getAddedPostes(artisanPricing,souslot.id);
            Array.prototype.push.apply((souslot.postes||[]), addedPostes);
            for (let k = 0; k < (souslot.postes||[]).length; k++) {
              let poste = souslot.postes[k];
              poste.priceList = this.getPrices(artisanPricing,poste.id);
              poste.comment = this.getComments(artisanPricing,poste.id);
              if(poste.sousPostes){
                for (let r = 0; r < poste.sousPostes.length; r++) {            
                  let sousPoste = poste.sousPostes[r];              
                  sousPoste.priceList = this.getPrices(artisanPricing,sousPoste.id);
                  sousPoste.comment = null;
                  sousPoste.comment = this.getComments(artisanPricing,sousPoste.id);
                }
              }
            }
        }
      }
    }

    this.showedData = this.sortLots(dpgfData);


    this.loadingPage = false;
    setTimeout(() => {
      this.scrollListener();
    }, 500);
  }
  public getPrices(prices, id,idSousPoste?){
    // console.log('prices',prices)
    function setMaxMin(list){
      // console.log('list',list)
      let min = Math.min(...list.map(e => ((e||{}).total||0)).filter(e=>e!=0));
      let max = Math.max(...list.map(e => ((e||{}).total||0)).filter(e=>e!=0));
      list.forEach(e=>{
        if(e){
          if(e && ((e||{}).total||0) == min) e.isMin = true;
          if(e && ((e||{}).total||0) == max) e.isMax = true;
        }
      })
      return list
    }
    let priceList= [];
    if(!idSousPoste){
      for (let i = 0; i < prices.length; i++) {
        priceList.push(prices[i].find(el=>el.id == id));
        // Here check
      }
    }else{
      for (let i = 0; i < prices.length; i++) {
        let currentId = prices[i].find(el=>el.id == id);
        if(currentId && currentId.id==id) {
          priceList.push(currentId.sousPostes.find(el=>el.id==idSousPoste))
        }
      }
    }
    priceList = setMaxMin(priceList);

    return priceList
  }
  public getAddedPostes(prices, sousLotId){
    let addedPostes= [];
    for (let i = 0; i < prices.length; i++) {
      let data = prices[i].filter(el=>(el.sousLotId == sousLotId && el.isAtisan)).map((el)=>{
        let res = Object.assign({},el.post)
        res.artisan = "artisan"+i;
        res.color = this.colors[i];
        return res
      });
      Array.prototype.push.apply(addedPostes, data);
    }
    return addedPostes
  }
  public getComments(prices, id){
    let commentList= [];
    for (let i = 0; i < prices.length; i++) {
      let price = prices[i].find(el=>el.id == id)||{};
      let comment = price.comment||[];
      comment = comment.map(com=>{
        return {com : com.com, artisan : price.artisan,color : price.color }
      })
      commentList = commentList.concat(comment);
    }
    return commentList
  }
  public lineNumber(loti,sousloti,posti,sousPosti?){
    let res = (loti) + '.' + (sousloti) + '.'  + (posti+1) + ' - ';;
    if(sousPosti || sousPosti == 0)res = (loti) + '.' + (sousloti) + '.'  + (posti+1) +  '.'  + (sousPosti+1) + ' - ';
    return res;
  }
  public selectCom(event,comm, overlaypanel) {
    this.toggleComments = comm;
    overlaypanel.toggle(event);
  }
  public getDevis(index){
    this.selectedDpgfPricing = this.pricingList[index];
    $("#print-dpgf-devis-popup").modal('show');
  }
  public closeModal(){
    $("#print-modal").modal("hide");
  }
  public addOptions(posteId){
    if ((this.dpgf.options||[]).indexOf(posteId) == -1){
      if(Array.isArray(this.dpgf.options)){
        this.dpgf.options.push(posteId)
      }else{
        this.dpgf.options= [posteId]
      }
      this.dpgfPostesService.updateDpgfOptions(this.dpgf._id,{options : this.dpgf.options})
      .subscribe((a)=>{})
    }
  }
  onLotFilterChange(val: any) {
    const id = val.target.value;
    const index = this.filteredLots.indexOf(id);
    if(index === -1){
      this.lotsFilterChecked = false;
      this.filteredLots.push(id);
    }
    else{
      this.filteredLots.splice(index, 1);
    }
  }
  onLotFilterGlobal(task){
    let filteredLots = [];
    for(var i = 0; i<this.showedData.length; i++){
      filteredLots.push(this.showedData[i].id)
    }
    // this.filteredLots = (task =="uncheck")?filteredLots:[];
    this.filteredLots = this.lotsFilterChecked ? filteredLots : [];
    this.lotsFilterChecked = !this.lotsFilterChecked;
  }
  onArtisanFilterChange(val: any) {
    const id = val.target.value;
    const index = this.filteredArtisans.indexOf(id);
    if(index === -1){
      this.artisansFilterChecked = false;
      this.filteredArtisans.push(id);
    }
    else{
      this.filteredArtisans.splice(index, 1);
    }

    this.reSetPricingList(this.filteredArtisans);
  }
  onArtisanFilterGlobal(task){
    let filteredArtisans = ['estim'];
    for(var i = 0; i<this.pricingList.length; i++){
      filteredArtisans.push(this.pricingList[i].artisanIndex)
    }
    // this.filteredArtisans = (task =="uncheck")?filteredArtisans:[];
    this.filteredArtisans = this.artisansFilterChecked ? filteredArtisans : [];
    this.artisansFilterChecked = !this.artisansFilterChecked;
  }
  private reSetPricingList(filteredArtisans){
    let filteredPricingList = [...this.pricingList];
    filteredPricingList = filteredPricingList.filter(pricing => !filteredArtisans.includes(pricing.artisanIndex));

    // totalVariante
    this.showData(filteredPricingList);
  }
  toggleLot(lotId: string, loti): void {
    const index = this.hiddenLots.indexOf(lotId);
    if(index > -1){
      this.hiddenLots.splice(index, 1);
      if(!this.openLots[lotId]){
        this.openLots[lotId] = true;
        this.loadLotPricingList(lotId);
        this.toggleLoader(loti);
      }
    }else{
      this.hiddenLots.push(lotId);
    }
  }
  public toggleLoader(loti){
    $('.loader-container').addClass('hidden');
    $(`#loader-${loti}`).removeClass('hidden');
  }
  onArtisanNameClick(index: number) {
    this.onArtisanSelect.emit(index);
  }
  public getPricingListIds(){
    let ids = [];
    this.pricingList.forEach((pricing) => {
      ids.push(pricing._id);
    });
    return ids;
  }
  downloadFile() {
    let pricingsIds = this.getPricingListIds();

    // if(!this.features.downloadExcel){
    //   $('#upgrade-modal').modal('show');
    //   return;
    // };
    let currentVariante = this.variantesList.find((variante) => variante._id == this.selectedVarianteId);
    this.downloadingExcel =true;
    let title = this.dpgf.nomPrenom;
    let varianteTitle = this.viewingVariante ? this.selectedVarianteTitle : '';
    let varianteDate = this.viewingVariante ? currentVariante.createdAt : '';
    let varianteId = this.viewingVariante ? currentVariante._id : '';
    this.dpgfPostesService.downloadSummaryExcel(this.dpgf['_id'], pricingsIds, varianteId, varianteTitle, varianteDate).subscribe(
      (data) => {
          var zip = new JSZip();
          zip.loadAsync(data.data).then((data) => {
            return zip.generateAsync({type:"blob"})
          }).then(function(content) {
            let fileName = varianteTitle != '' ? `Analyse des offres - Variante ${varianteTitle} ${title}.xlsx` : `Analyse des offres - ${title}.xlsx` ;
            saveAs(content, fileName);
          });
          if(!environment.onPremise)window.analytics.track('download_comparatif',{properties:{acValue: 'download_comparatif'}});
          this.downloadingExcel =false;
      },
      (err) => {
        console.log(err);
        this.downloadingExcel =false;
      }
    );
  }

  public closeDevisPopup(){
    $("#print-dpgf-devis-popup").modal('hide');
  }
  public getData(){
    this.route.parent.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.dpgfPostesService.dpgfPricingListInfo(this.dpgfId, 'comparatif')
        .subscribe(
          data => {
            /// set dpgf
            // console.log('data.pricingList',data.pricingList[2].data[0].sousLots[0].postes[1])
            this.setCeProjectId(data['dpgf']['ceProject']);
            this.dpgf = data.dpgf;
            this.dpgfTotalHt = data.dpgf.totalHt
            /// set pricing List
            data.pricingList.forEach((artisan)=>{
              artisan.total = artisan.data.reduce((accumulator, currentArtisan) => accumulator + currentArtisan.total,0)
            })
            // console.log('data.dpgf',data.dpgf)
            this.pricingList = data.pricingList;
            this.showVariantesList = false;
            this.showData();
            this.hiddenLots = [];
            this.hideAllLots();
          },
          error => {
            console.log(error);
            this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
          }
        );
      }
    });
  }
  public addPricingListToLot(lotId, pricingList){
    let artisanPricing = this.computeDpgfs(pricingList, true, lotId);
    
    // if there are already unselected artisans, don't show their pricings
    if(this.filteredArtisans.length){
      for(let i = 0; i < artisanPricing.length; i++){
        artisanPricing[i] = artisanPricing[i].filter(item => !this.filteredArtisans.includes(item.artisan));
      }
    }

    for(let i = 0; i < this.showedData.length; i++){
      let lot = this.showedData[i];
      if(lot.id == lotId){
        for (let j = 0; j < lot.sousLots.length; j++) {
          let souslot = lot.sousLots[j];
          // this is commented because it duplicates posts added by artisan
          let addedPostes = this.getAddedPostes(artisanPricing,souslot.id);
          const uniqueData = [];
          const seenIds = new Set();
          addedPostes.forEach(item => {
              const itemId = item.id;
              if (!seenIds.has(itemId)) {
                  uniqueData.push(item);
                  seenIds.add(itemId);
              }
          });
          Array.prototype.push.apply(souslot.postes, uniqueData);
          souslot.priceList = this.getPrices(artisanPricing,souslot.id);

          for (let k = 0; k < souslot.postes.length; k++) {            
              let poste = souslot.postes[k];              
              poste.priceList = this.getPrices(artisanPricing,poste.id);
              poste.comment = null;
              poste.comment = this.getComments(artisanPricing,poste.id);
              if(poste.sousPostes){

                for (let r = 0; r < poste.sousPostes.length; r++) {            
                  let sousPoste = poste.sousPostes[r];              
                  sousPoste.priceList = this.getPrices(artisanPricing,poste.id,sousPoste.id);
                  sousPoste.comment = null;
                  sousPoste.comment = this.getComments(artisanPricing,sousPoste.id);
                }
              }
          }
        }
        
        break;
      }
    }
    $('.loader-container').addClass('hidden');
  }
  public getPricingsIds(): Array<string>{
    let list = [];
    for(let i = 0; i < this.pricingList.length; i++){
      list.push(this.pricingList[i]._id);
    }
    return list;
  }
  public loadLotPricingList(lotId){
    let body = this.getPricingsIds();
    this.subscriptions.push(
      this.dpgfPostesService.getLotPostsById(this.dpgfId, lotId, body)
        .subscribe(
          data => {
            let pricingList = data;

            /// Sort By Created At
            pricingList.map(item => {
              /// Change Created At to Epoch Time then 
              let pricingCreatedAt = item['createdAt'];
              let pricingDate = new Date(pricingCreatedAt);
              item.createdAtEpoch = pricingDate.getTime();

              return item;
            });
            /// Sort by Epoch Time
            pricingList = pricingList.sort((a, b) => (a.createdAtEpoch < b.createdAtEpoch) ? -1 : 1);
            /// Remove Epoch Time & createdAt, Cuz we already sorted the array
            // pricingList.map(item => {
            //   delete item["createdAt"];
            //   delete item["createdAtEpoch"];
            // });
            /// END Sort by CreatedAt

            this.addPricingListToLot(lotId, pricingList);
          },
          error => {
            console.log(error);
            this.ErrorService.manageError(error,'/architect/edit/project/' + this.dpgfId);
          }
        )
    );
  }
  public toggleVariantePost(lotId, postId){
    let id = `${lotId}-${postId}`;
    this.checkedLots[id] = !this.checkedLots[id];
    if(this.viewingVariante){
      this.varianteRemodified = true;
    }
    this.varianteRemodified = true;
    this.showSubmitButton = true;
  }
  public variantePostsSelected(){
    // viewingVariante && varianteRemodified
    let selectedPosts = [];
    for(let key in this.checkedLots){
      if(this.checkedLots[key]){
        selectedPosts.push(key);
      }
    }
    if(this.viewingVariante){
      return (this.viewingVariante && this.varianteRemodified);
    }else{
      return selectedPosts.length;
    }
  }
  public createNewVariante(){
    this.creatingVarianteMode = true;
    this.updatingVarianteMode = false;
    this.showingVariante = false;
    this.newlyCreatedVariante = false;
    this.showVariantesList = false;
    this.checkedLots = {}; /// clear prev selected posts
    $('#newVarianteModal').modal('hide');
    this.selectedVarianteTitle = this.newVarianteTitle;
    this.showSubmitButton = true;
    this.loadInitialPricings(true);
    this.creatingVarianteMode = true;
  }
  public checkPosts(data){
    /// lod_id-poste_id
    for(let i = 0; i < data.length; i++){
      let lotId = data[i].split('-')[0];
      let postId = data[i].split('-')[1];
      this.toggleVariantePost(lotId, postId);
    }
  }
  public checkEntireLot(lot){
    this.selectedLots[lot.id] = !this.selectedLots[lot.id];
    let lotId = lot.id;
    for(let i = 0; i < lot.sousLots.length; i++){
      let currentLot = lot.sousLots[i];
      for(let j = 0; j < currentLot.postes.length; j++){
        let currentPost = currentLot.postes[j];
        let postId = lot.id+'-'+currentPost.id;
        this.checkedLots[postId] = this.selectedLots[lot.id];
      }
    }
    this.varianteRemodified = true;
    this.showSubmitButton = true;
  }
  public checkEntireLot_OLD(event, lot){
    let lotId = lot.id;
    for(let i = 0; i < lot.sousLots.length; i++){
      let currentLot = lot.sousLots[i];
      for(let j = 0; j < currentLot.postes.length; j++){
        let currentPost = currentLot.postes[j];
        let postId = lot.id+'-'+currentPost.id;
        this.checkedLots[postId] = event.target.checked;
      }
    }
    this.varianteRemodified = true;
    this.showSubmitButton = true;
  }
  public checkEntireSousLot(lotId, sousLot){
    this.selectedLots[sousLot.id] = !this.selectedLots[sousLot.id];
    for(let j = 0; j < sousLot.postes.length; j++){
      let currentPost = sousLot.postes[j];
      let postId = lotId+'-'+currentPost.id;
      this.checkedLots[postId] = !this.checkedLots[postId];
    }
    this.varianteRemodified = true;
    this.showSubmitButton = true;
  }

  public async selectVariante(varianteIndex){
    // this.getData();
    // this.loadInitialPricings();
    this.selectedVarianteTitle = this.variantesList[varianteIndex].title;
    let id = this.variantesList[varianteIndex]._id;
    this.updatingVarianteMode = true;
    this.creatingVariante = true;
    this.variantesService.selectVariante(id)
      .subscribe(
        (res) => {
          this.viewingVariante = true;
          this.showVariantesList = false;
          this.selectedVarianteId = res.variante._id;
          this.checkedLots = {}; /// clear prev selected posts
          this.selectedLots = {};
          this.checkPosts(res.variante.postsIds);
          this.currentVariantePostsIds = res.variante.postsIds;
          this.creatingVarianteMode = true;
          this.creatingVariante = false;
          this.reCalculateThePricings(res.varianteDpgfPricings);
          this.varianteRemodified = false;
          this.showSubmitButton = false;
          this.hiddenLots = [];
          this.hideAllLots();

        },
        err => {
          console.log(err);
          this.creatingVariante = false;
        }
      );
  }
  public getVariantes(){
    this.subscriptions.push(
      this.variantesService.getVariantes(this.dpgfId)
        .subscribe(
          data => {
            this.variantesList = data;
          },
          error => {
            console.log(error);
            this.ErrorService.manageError(error,'/variantes');
          }
        )
    );
  }

  public loadInitialPricings(creatingNewVariante=false){
    if(this.selectedVarianteTitle != 'Initial'){
      if(!creatingNewVariante){
        this.selectedVarianteTitle = 'Initial';
      }
      this.getData();
      this.openLots = [];
      this.creatingVariante = false;
      this.viewingVariante = false;
      this.updatingVarianteMode = false;

      this.showSubmitButton = false;
      this.showingVariante = false;
      this.creatingVarianteMode = false;

      this.checkedLots = {}; /// clear prev selected posts
      // console.log('Load Initial');
    }
  }
  public getPricingsByIds(pricingsListIds){
    let pricingsData = [];
    for(let i = 0; i < pricingsListIds.length; i++){
      let pricing = this.pricingList.find((p) => p._id == pricingsListIds[i]);
      pricingsData.push(pricing);
    }
    return pricingsData;
  }
  public saveOrUpdateVariante(){
    if(this.authService.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    let pricingsList = this.getPricingsByIds(this.getPricingListIds());
    let selectedPosts = [];
    this.creatingVariante = true;
    for(let key in this.checkedLots){
      if(this.checkedLots[key]){
        selectedPosts.push(key);
      }
    }
    if(this.updatingVarianteMode || this.newlyCreatedVariante){
      this.updateVariante(pricingsList, this.selectedVarianteId, selectedPosts);
    }else{
      this.saveVariante(pricingsList, selectedPosts);
    }
    this.hiddenLots = [];
    this.hideAllLots();
  }
  public saveVariante(pricingsList, selectedPosts){
    let pricingListIds = this.getPricingsIds();
    let body = {
      title: this.newVarianteTitle,
      dpgfId: this.dpgfId,
      postsIds: selectedPosts,
      pricingListIds
    };
    this.subscriptions.push(
      this.variantesService.createVariantes(body)
        .subscribe(
          data => {
            // console.log('RESPONSE : ', data.varianteDpgfPricings);
            this.varianteRemodified = false;
            this.creatingVariante = false;
            this.newlyCreatedVariante = true;
            this.viewingVariante = true;
            this.checkedLots = {}; /// clear prev selected posts
            this.selectedLots = {};

            this.newVarianteTitle = '';
            this.checkPosts(data.variante.postsIds);
            this.variantesList.unshift({title: data.variante.title, _id: data.variante._id});
            this.selectedVarianteId = data.variante._id;
            this.selectedVarianteTitle = data.variante.title;
            this.currentVariantePostsIds = data.variante.postsIds;
            if(!environment.onPremise)window.analytics.track('create_variante',{properties:{acValue: 'create_variante'}});
            this.reCalculateThePricings(data.varianteDpgfPricings);
            this.updatingVarianteMode = false;
            this.varianteRemodified = false;
            this.showSubmitButton = false;
          },
          error => {
            this.creatingVariante = false;
            this.showSubmitButton = false;
            this.checkedLots = {}; /// clear prev selected posts
            console.log(error);
            this.ErrorService.manageError(error,'/variantes');
          }
        )
    );
  }

  public updateVariante(pricingLists, varianteId, postsIds){
    let pricingListIds = this.getPricingsIds();
    let body = {
      postsIds,
      pricingListIds,
      pricingLists
    };
    this.subscriptions.push(
      this.variantesService.updateVariantes(varianteId, body)
        .subscribe(
          data => {
            this.checkedLots = {}; /// clear prev selected posts
            this.checkPosts(data.variante.postsIds);
            this.currentVariantePostsIds = data.variante.postsIds;
            if(!environment.onPremise)window.analytics.track('update_variante',{properties:{acValue: 'update_variante'}});
            this.reCalculateThePricings(data.varianteDpgfPricings);
            this.creatingVariante = false;
            this.varianteRemodified = false;
            this.showSubmitButton = false;
          },
          error => {
            console.log(error);
            this.creatingVariante = false;
            this.showSubmitButton = false;
            this.ErrorService.manageError(error,'/variantes');
          },
        )
    );
  }
  public deleteVariante(){
    this.deletingVariante = true;
    this.showSubmitButton = false;
    this.subscriptions.push(
      this.variantesService.deleteVariantes(this.varianteToDelete._id)
        .subscribe(
          data => {
            let deletedVarianteIndex = this.variantesList.findIndex(variante => variante._id == this.varianteToDelete._id);
            if(deletedVarianteIndex != -1){
              this.variantesList.splice(deletedVarianteIndex, 1);
            }
            this.showVariantesList = false;
            this.deletingVariante = false;
            $('#deleteVarianteModal').modal('hide');
            this.loadInitialPricings();
            if(!environment.onPremise)window.analytics.track('delete_variante',{properties:{acValue: 'delete_variante'}});
          },
          error => {
            this.deletingVariante = false;
            console.log(error);
          }
        )
    );
  }
  public renameVariante(){
    let body = {
      title: this.updateVarianteTitle
    }
    this.subscriptions.push(
      this.variantesService.renameVariantes(this.varianteToRename._id, body)
      .subscribe(
        data => {
          // this.varianteToRename = data.title;
          this.selectedVarianteTitle = data.title;
          let renameVariante = this.variantesList.findIndex(variante => variante._id == this.varianteToRename._id);
          if(renameVariante != -1){
            this.variantesList[renameVariante] = data;
          }
          $('#renameVarianteModal').modal('hide');
          this.showVariantesList = false;
        },
        error => {
          console.log(error);
        }
      )
    );
  }
  public openUpdateVarianteModel(variante){
    this.varianteToRename = variante;
    this.updateVarianteTitle = this.varianteToRename.title;
  }
  public openDeleteVarianteModel(variante){
    this.varianteToDelete = variante;
  }
  public reFormatVariantesDpgfPricings(variantesDpgfPricings){
    let result = {};
    for(let i = 0; i < variantesDpgfPricings.length; i++){
      let varianteItem = variantesDpgfPricings[i];
      for(let j = 0; j < varianteItem.data.length; j++){
        let lotItem = varianteItem.data[j];
        for(let k = 0; k < lotItem.sousLots.length; k++){
          let sousLotitem = lotItem.sousLots[k];
          if(!result[sousLotitem.id]){
            result[sousLotitem.id] = [{total: sousLotitem.total, totalttc: sousLotitem.totalttc, fixed: sousLotitem.fixed, artisan: varianteItem.artisanIndex}]
          }else{
            result[sousLotitem.id].push({total: sousLotitem.total, totalttc: sousLotitem.totalttc, fixed: sousLotitem.fixed, artisan: varianteItem.artisanIndex})
          }
          /// remove those without artisan0, artisan1
          result[sousLotitem.id] = result[sousLotitem.id].filter(item => item.artisan);
          /// sort by artisan id 0, 1, 2...
          result[sousLotitem.id] = result[sousLotitem.id].sort((a, b) => (a.artisan||{}).localeCompare(b.artisan));
        }
      }
    }
    return result;
  }
  public recalculateEstimatif(showedData, variantePostsIds){
    let lotsIds = [];
    let postsIds = [];
    let allLotsTotal = 0;
    /// get lots Ids
    for(let i = 0; i < variantePostsIds.length; i++){
      let lotId = variantePostsIds[i].split('-')[0];
      let postId = variantePostsIds[i].split('-')[1];
      postsIds.push(postId);
      if(!lotsIds.includes(lotId)){
        lotsIds.push(lotId);
      }
    }
    for(let i = 0; i < lotsIds.length; i++){
      let currentLot = lotsIds[i];
      let dataItem = showedData.find((item) => item.id == currentLot);
      let lotTotal = 0;
      let globallyPriceLotsIds = [];
      let globallyPriceLotsTotal = [];
      let globallyPriceLotsTotalTtc = [];

      if(dataItem){
        for(let j = 0; j < dataItem.sousLots.length; j++){

          let currentSousLot = dataItem.sousLots[j];
          let sousLotTotal = 0;
          for(let k = 0; k < dataItem.sousLots[j].postes.length;k ++){
            let currentPost = dataItem.sousLots[j].postes[k];
            if(!postsIds.includes(currentPost.id)){
              if(!dataItem.priceAll){
                lotTotal += currentPost.priceArchi;
                sousLotTotal += currentPost.priceArchi;
              }else{
                if(!globallyPriceLotsIds.includes(currentLot)){
                  globallyPriceLotsIds.push(currentLot)
                  globallyPriceLotsTotal.push(dataItem.totalArchi)
                  globallyPriceLotsTotalTtc.push(dataItem.totalttcArchi)
                }
              }

            }
          }
          /// sous Lot Total
          currentSousLot.totalArchi = sousLotTotal;
        }
      }
      if(globallyPriceLotsIds.length){
        lotTotal = 0;
        // lotTotalTtc = 0;
        for(let i = 0; i < globallyPriceLotsTotal.length; i++){
          lotTotal += globallyPriceLotsTotal[i];
          // lotTotalTtc += globallyPriceLotsTotalTtc[i];
        }
      }
      /// Lot Total
      if(dataItem){
        dataItem.totalArchi = lotTotal || 0;
      }
    }
    /// Calculate All Lots Total
    showedData.forEach((item, i) => {
      allLotsTotal += item.totalArchi ? item.totalArchi : 0;
    });
    /// All Lots Total
    this.dpgf['totalHt'] = allLotsTotal;
  }
  public reCalculateThePricings(variantesDpgfPricings){
    
    if(this.filteredArtisans.length){
      this.pricingList = this.pricingList.filter(item => !this.filteredArtisans.includes(item.artisanIndex));
    }

    let artisanIds = [];
    /// add artisanId to varianteDpgfPricing
    variantesDpgfPricings.map((item, index) => {
      let pricingIndex = this.pricingList.findIndex((p) => item.dpgfPricing == p._id);
      if(pricingIndex != -1){
        item.artisanIndex = this.pricingList[pricingIndex].artisanIndex;
        artisanIds.push(item.artisanIndex);
      }
    });

    //
    let formatedVariantesDpgfPricing = this.reFormatVariantesDpgfPricings(variantesDpgfPricings);
    /// re-set to 0
    this.pricingList.forEach((item) => item.totalVariante = 0);


    /// main Pricing list Total
    for(let i = 0; i < variantesDpgfPricings.length; i++){
      let pricingIndex = this.pricingList.findIndex((pricing) => pricing._id == variantesDpgfPricings[i].dpgfPricing);
      if(pricingIndex != -1){        
        this.pricingList[pricingIndex].totalVariante = variantesDpgfPricings[i].pricingHt;
        this.pricingList[pricingIndex].totalTtcVariante = variantesDpgfPricings[i].pricingTtc;
      }
    }

    /// Lot Total
    for(let i = 0; i < this.showedData.length; i++){
      /// Pricing list
      for(let j = 0; j < this.showedData[i].priceList.length; j++){
        let currentPricingList = this.showedData[i].priceList[j];

        if(currentPricingList){
          if(artisanIds.includes(currentPricingList.artisan)){
            let variantePricingLot = variantesDpgfPricings
              .find((item) => item.artisanIndex == currentPricingList.artisan).data
              .find((lot) => lot.id == currentPricingList.id);
            this.showedData[i].priceList[j].totalVariante = (variantePricingLot?.total || 0);
          }else{
            this.showedData[i].priceList[j].totalVariante = 0;
          }
        }

      }
      /// Sous Lot Total
      for(let k = 0; k < this.showedData[i].sousLots.length; k++){
        let sousLot = this.showedData[i].sousLots[k];
        if(formatedVariantesDpgfPricing[sousLot.id]){
          for(let l = 0; l < formatedVariantesDpgfPricing[sousLot.id].length; l++){
            (this.showedData[i].sousLots[k].priceList[l]||{}).totalVariante = formatedVariantesDpgfPricing[sousLot.id][l].total;
          }
        }
      }
    }
    this.recalculateEstimatif(this.showedData, this.currentVariantePostsIds);
  }
  public openCreateVariante(){
    if(this.authService.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    $("#newVarianteModal").modal('show');
  }
  private setCeProjectId(projectId){
    this.dpgfPostesService.selectedProjectId.next(projectId);
  }
  private  sortLots(lots){
    return lots.sort((a,b) =>  a.indexLot - b.indexLot )
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
