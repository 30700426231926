import { Component, OnInit , Input, SimpleChanges,ViewChild, ElementRef, NgZone, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup,  Validators} from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { formateImage } from '../../_helpers/formate-image';
import { trigger,style,animate,transition,keyframes } from '@angular/animations';
//Maps
import { GoogleAutocompleteComponent } from 'src/app/all/google-autocomplete/google-autocomplete.component';
import { ConfirmModalComponent } from 'src/app/all/confirm-modal/confirm-modal.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/_services';

declare var jsonConcat:any;
declare var only:any;
declare var $:any;

@Component({
  standalone:true,
  selector: 'app-form3',
  imports:[NgSharedModule,ConfirmModalComponent,GoogleAutocompleteComponent],
  templateUrl: './form3.component.html',
  styleUrls: ['./form3.component.scss'],
  animations: [
    trigger('error', [
      transition('* => true', [
        animate('2s', keyframes([
          style({ backgroundColor: 'transparent'}),
          style({ backgroundColor: 'rgba(255, 0, 0, 0.175)'}),
          style({ backgroundColor: 'transparent'})
        ]))
      ]),
    ])
  ]
})
export class Form3Component implements OnInit {
  @Input() agency:any;
  @Input() disabled;
  @Input() isProfile;
  @Output() removeLogo: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("search")
  public searchElementRef: ElementRef;
  public languages:{}[];
  public options:{};
  public place = {};
  public geoloc = {};
  public error = '';
  public filesLoading:number[] = [];
  public subscriptionForm: UntypedFormGroup;
  public logoValide:Boolean = false;
  public filedCouverture:boolean = false;
  private subscriptions: Array<Subscription> = [];
  public formErrors = {'name': '','emailAgency': '','address': ''};
  private validationMessages = {
    'name': {
      'required': "Le Nom de l'organisation est obligatoire.",
    },
    'emailAgency': {
      'email': "Email invalide"
    },
    'address': {
      'required': "L'adresse de votre agence est obligatoire."
    }
  };
  private firstValueEnvirenementSubject = new BehaviorSubject<boolean>(environment.onPremise);
  firstValueEnvirenementObservable = this.firstValueEnvirenementSubject.asObservable(); 
  constructor(private fb: UntypedFormBuilder,
              // private mapsAPILoader: MapsAPILoader, tocorrect
              private ngZone: NgZone,
              private cd:ChangeDetectorRef,
              public authService:AuthService) { 

               }
  ngOnInit() {
    $('.modal-backdrop').remove();
    
    this.createForm();
    this.subscriptionForm.patchValue(this.agency || {});
    this.filedCouverture = !$.isEmptyObject(this.agency.logo);
    this.filedCouverture = !$.isEmptyObject(this.agency.logo)
  }
  onUploadArchiLogo(event){
    this.agency.logo = formateImage.do(event.originalEvent.body[0],"logo");
  }
  //On change
  ngOnChanges(changes: SimpleChanges) {
        if (changes['agency'] && this.subscriptionForm) {
            this.subscriptionForm.patchValue(this.agency);
            this.place = (this.agency.place ||{})
            this.geoloc = (this.agency.geoloc ||{})
            this.filedCouverture = !$.isEmptyObject(this.agency.logo)
        }
  }
  //patch Adresse
  public patchAdress(val){
    let adress = val.description || "";
    let data = this.subscriptionForm.value;
    data.address = adress;
    this.subscriptionForm.patchValue(data)
  }
  //Form
  createForm() {
      this.subscriptionForm = this.fb.group({
        name: [{value: '',disabled: false}, [Validators.required]],
        emailAgency: [{value: '',disabled: false}, [Validators.email]],
        address: [{value: '',disabled: false}],
      });
      this.subscriptions.push(
        this.subscriptionForm.valueChanges.subscribe(data => this.onValueChanged(data))
      )
      this.onValueChanged();
    }

    //Generate Error
    onValueChanged(data ? : any) {
      if (!this.subscriptionForm) {
        return;
      }
      const form = this.subscriptionForm;

      for (const field in this.formErrors) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);

        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
              this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }

    //Mark as touched
    public markFormAsTouched():void {
      if (!this.subscriptionForm) {
        return;
      }
      const form = this.subscriptionForm;

      for (const field in this.formErrors) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        control.markAsDirty();
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
              this.formErrors[field] += messages[key] + ' ';
          }
      }
    }
    public scrollTo(element, offset=0){
      $('html, body').animate({
          scrollTop: ($(element).offset().top - offset)
      },500);
    }
    //Scroll to error
    public scrollToError(){
      let loop = 0;
      let keys = Object.keys(this.formErrors);
      let errorKey = ""
      while(loop < keys.length && this.formErrors[keys[loop]] =="" ){
        loop ++;
      }
      if (keys[loop]) {
        this.scrollTo(`[formControlName=${keys[loop]}]`, 40);
        this.error = keys[loop]
        setTimeout(()=>{this.error = ''},2000)
      }
    }
    //Validate Form
    isValid(scroll = true){
        this.markFormAsTouched()
        if (scroll) {
          // this.scrollToError()
        }
        return this.subscriptionForm.valid && this.filesLoading.length == 0 ;
    }
    getFormData(){
      let meduimJson = jsonConcat(this.subscriptionForm.value, {place : this.place,geoloc : this.geoloc});
      return jsonConcat(meduimJson, only(this.agency,"logo"));
    }
    cancelChanges(){
      this.subscriptionForm.patchValue(this.agency);
    }
    public openConfirmRemoveLogo(){
      $("#confirm-remove-modal").modal("show");
    }
    public emitDeleteLogo(){
      this.removeLogo.emit({});
      this.agency.logo = {};
    }
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
    onUploadAgencyLogo(event){
      this.agency.logo = event.originalEvent.body[0];
    }
}

