import { ActionReducer, ActionReducerMap, MetaReducer, createReducer ,on} from '@ngrx/store';
import * as dpgfActions from './dpgf.actions';
const dotProp = require('dot-prop-immutable');
import { ModeClass } from './mode'
import { environment } from 'src/environments/environment';
const _ = require('lodash');

const getId = (type) =>{
  return type + '_' + Math.random().toString(36).substr(2, 9);
}

export const initialState = {
  type : "dpgf",
  loadingData : false,
  addingLot : false,
  movingPosts:false,
  movingSection:false,
  checkedPostsNumber:0,
  typeAmount:'',
  waitingPass : true,
  savingData : false,
  finishing : false,
  pricingDone : false,
  changes : 0 ,
  modeList : new ModeClass().modeList,
  mode : "archi",
  code : "",
  files : [],
  cctpEdit : {cctp : "", sousloti : undefined , posti : undefined, gid : "" ,cstbData : undefined,sousPosteIndex:undefined},
  lot : {id : "" , sousLots :[]},
  dpgf : {data : [] , defaultTva : 20},
  selectedPosts: [],
  exportManyPostsDb:false,
  exportDoc:false,
  descView:0,
  projectLicence:'none',
  buttonState:{},
  discount:{},
  isAllPostesSelected:false,
  isAutomatic:true,
  sizeDPGF:0
};

const _dpgfReducer = createReducer(initialState,
  on(dpgfActions.setDpgf, (state,action:any) => {
    state = dotProp.delete(state, "openConflitLot")
    state = dotProp.set(state, 'projectLicence',action.payload.ceProject.licence || action.payload.projectData.licence)
    state = dotProp.set(state, 'dpgf', action.payload)
    state = dotProp.set(state, 'changes', 0)
    state = dotProp.set(state, 'lot',{})
    if(action.payload?.projectData){
      state = dotProp.set(state, 'isAutomatic', action.payload.projectData.isAutomatic)
    }
    state = dotProp.set(state, 'dpgf', action.payload)
    return dotProp.set(state, 'loadingData', false)
  }),
  on(dpgfActions.setIsAutomatic, (state,action:any) => {
    state = dotProp.set(state, 'isAutomatic', action.payload.isAutomatic);
    
    return state;
  }),
  on(dpgfActions.resetLotsIndex, (state,action:any) => {
    let newDpgfLots = state.dpgf.data.map((item, index) => {
      item["indexLot"] = index;
      
      // if lot is opened, update it's indexLot
      if(state?.lot?.id && item?.id == state.lot.id){
        state = dotProp.set(state, 'lot.indexLot', index);
      }

      return item;
    });    
      
    return state = dotProp.set(state, 'dpgf.data', newDpgfLots);
  }),
  
  on(dpgfActions.setDpgfPricing, (state,action:any) => {
    let dpgf = action.payload;
    let artisan = (dpgf.artisan||{})
    let assuranceNumber = artisan.assuranceNumber|| "";
    let tvaNumber = artisan.tvaNumber || "";
    let consultedFor = (dpgf.ceProjectInvite.lots||[]).map(l=>l.id);
    let consultedForId = (dpgf.ceProjectInvite.lots||[]).map(l=>l.id);
    for(var i = 0 ; i < dpgf.data.length; i++){
      if(consultedFor.length != 0 && consultedForId.indexOf(dpgf.data[i].id) == -1 &&consultedFor.indexOf(dpgf.data[i].title) == -1 && consultedFor.indexOf("general") == -1){
        dpgf.data[i].notConsulted = true;
      }
    }
    if((dpgf.artisanHeader  ||"") == ""){
      dpgf.artisanHeader = `${dpgf.artisanName}\nAdresse \nTéléphone : ${dpgf.artisanPhone}\nEmail : ${dpgf.artisanEmail}\nN° TVA: ${tvaNumber}\nAssurance et N° contrat: ${assuranceNumber}`
    }
    if((dpgf.clientHeader  ||"") == ""){
      dpgf.clientHeader = dpgf.maitreOuvrage
    }
    if((dpgf.condition  ||"") == ""){
      dpgf.condition ="Acompte de __% à la signature du devis \n __% à __% d’avancement travaux \n __% à __% d’avancement travaux \n __% à la livraison "
    }
    if((dpgf.numberId  ||"") == ""){
      dpgf.numberId = ""
    }
    state = dotProp.set(state, 'dpgf', dpgf)
    state = dotProp.set(state, 'waitingPass', false)
    state = dotProp.set(state, 'pricingDone', dpgf.status == "finished")
    return dotProp.set(state, 'loadingData', false)
  }),
  on(dpgfActions.saveDpgfToServer, (state) => {
    return dotProp.set(state, 'savingData', true)
  }),
  on(dpgfActions.finishDpgfPricing, (state) => {
    return dotProp.set(state, 'finishing', true)
  }),
  on(dpgfActions.finishDpgfPricingDone, (state) => {
    state = dotProp.set(state, 'pricingDone', true)
    return dotProp.set(state, 'finishing', false)
  }),
  on(dpgfActions.saveDpgfLotToServer, (state) => {
    state = dotProp.set(state, 'changes', 0)
    state = dotProp.delete(state, "openConflitLot")
    return dotProp.set(state, 'savingData', true)
  }),
  on(dpgfActions.setAny, (state,action:any) => {
    return dotProp.set(state, action.payload.name, action.payload.value)
  }),
  on(dpgfActions.setMode, (state,action:any) => {
    return dotProp.set(state, 'mode', action.payload)
  }),
  on(dpgfActions.setType, (state,action:any) => {
    return dotProp.set(state, 'type', action.payload)
  }),
  on(dpgfActions.setDpgfData, (state,action:any) => {
    return dotProp.set(state, 'dpgf.data', action.payload)
  }),
  on(dpgfActions.setDpgfAttribut, (state,action:any) => {
    return dotProp.set(state, `dpgf.${action.payload.key}`, action.payload.value)
  }),
  on(dpgfActions.changeOrderLot, (state,action:any) => {
    let newDpgfOrder = [];
    for(var i = 0;i< action.payload.length ; i++){
      let index = state.dpgf.data.map(l=>l.id).indexOf(action.payload[i].lotId);
      let currentLot = dotProp.get(state, `dpgf.data.${index}`);
      currentLot["indexLot"] = i;
      
      // if lot is opened, update it's indexLot
      if(state?.lot?.id && currentLot?.id == state.lot.id){
        state = dotProp.set(state, 'lot.indexLot', i);
      }

      newDpgfOrder.push(currentLot);
    }
    return dotProp.set(state, `dpgf.data`, newDpgfOrder);
  }),
  //**************************************** CCTP *******************************************************
  on(dpgfActions.setCctp, (state,action:any) => {
    return dotProp.set(state, 'cctpEdit', action.payload)
  }),
  on(dpgfActions.setCctpText, (state,action:any) => {
    return dotProp.set(state, 'cctpEdit.cctp', action.payload)
  }),
  on(dpgfActions.addCctpTextAgloGpt, (state,action:any) => {
    let cctpText = state.cctpEdit.cctp ? `${state.cctpEdit.cctp} <br> ${action.payload}` : action.payload;
    return dotProp.set(state, 'cctpEdit.cctp', cctpText)
  }),
  on(dpgfActions.attachCctpText, (state,action:any) => {
    return dotProp.set(state, 'cctpEdit.cctp', (state.cctpEdit.cctp||"") + (((state.cctpEdit.cctp||"")=="")?"":" <br><br>") + action.payload)
  }),
  on(dpgfActions.setCctpCstbData, (state,action:any) => {
    return dotProp.set(state, 'cctpEdit.cstbData', action.payload)
  }),
  on(dpgfActions.setCctpGid, (state,action:any) => {
    return dotProp.set(state, 'cctpEdit.gid', action.payload)
  }),
  on(dpgfActions.setCctpOnDpgf, (state,action:any) => {
    if(typeof state.cctpEdit.sousloti !== 'undefined' && typeof state.cctpEdit.posti === 'undefined' && typeof state.cctpEdit.sousPosteIndex === 'undefined'){
      state= dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.description`, state.cctpEdit.cctp)
      state= dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.gid`, state.cctpEdit.gid)
      return dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.cstbData`, state.cctpEdit.cstbData)
    }else if(typeof state.cctpEdit.posti === 'undefined'){
      state= dotProp.set(state, `lot.description`, state.cctpEdit.cctp)
      state= dotProp.set(state, `lot.gid`, state.cctpEdit.gid)
      return dotProp.set(state, `lot.cstbData`, state.cctpEdit.cstbData)
    }else if(typeof state.cctpEdit.sousPosteIndex !== 'undefined'){
      state = dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.sousPostes.${state.cctpEdit.sousPosteIndex}.cctp`, state.cctpEdit.cctp)
      state= dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.sousPostes.${state.cctpEdit.sousPosteIndex}.gid`, state.cctpEdit.gid)
      return dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.sousPostes.${state.cctpEdit.sousPosteIndex}.cstbData`, state.cctpEdit.cstbData)
    }else{
      state = dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.cctp`, state.cctpEdit.cctp)
      state = dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.fromCstb`, false)
      state= dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.gid`, state.cctpEdit.gid)
      return dotProp.set(state, `lot.sousLots.${state.cctpEdit.sousloti}.postes.${state.cctpEdit.posti}.cstbData`, state.cctpEdit.cstbData)
    }
  }),
  //****************************************Lot*******************************************************
  on(dpgfActions.setDpgfLot, (state,action:any) => {    
    let index = state.dpgf.data.map(l=>l.id).indexOf(action.payload.id)
    state = dotProp.delete(state, "openConflitLot")
    state = dotProp.set(state, 'savingData', false)
    state = dotProp.set(state, 'lot', action.payload)
    //state = dotProp.set(state, 'lot.indexLot', index)
    return dotProp.set(state, 'loadingLot', false) 
  }),
  on(dpgfActions.emptyLot, (state,action:any) => {
    return dotProp.delete(state, 'lot')
  }),
  on(dpgfActions.setLoadingDpgfLot, (state,action:any) => {
    return dotProp.set(state, 'loadingLot', action.payload)
  }),
  on(dpgfActions.setSaveDone, (state,action:any) => {
    // state = dotProp.set(state, 'changes', 0)
    state = dotProp.set(state, 'pricingDone', false)
    if(action.nowDate){
      state = dotProp.set(state, 'lot.lastSaveAt', action.nowDate)
    }
    state = dotProp.set(state, `saveLocalisationToServer`, false)
    return dotProp.set(state, 'savingData', false)
  }),
  on(dpgfActions.addChange, (state,action:any) => {
    return dotProp.set(state, 'changes', state.changes +1)
  }),
  on(dpgfActions.addnewLot, (state:any,action:any) => {
    state = dotProp.delete(state, 'lot')
    return dotProp.set(state, 'addnewLot', true)
  }),
  on(dpgfActions.addnewLotDone, (state:any,action:any) => {
    state = dotProp.set(state, 'lot', action.payload)
    state = dotProp.set(state, 'lot.indexLot', (state.dpgf.data||[]).length)
    state = dotProp.set(state, 'addnewLot', false)
    let dpgf = dotProp.get(state, 'dpgf.data')
    // console.log('action.payload',action.payload)
    dpgf.push(action.payload)
    return dotProp.set(state, 'dpgf.data', dpgf)
  }),
  on(dpgfActions.addDuplicatedLotDone, (state:any,action:any) => {
    // state = dotProp.set(state, 'lot', state.dpgf.data[(state.dpgf.data||[]).length-1])
    // state = dotProp.set(state, 'lot.indexLot', (state.dpgf.data||[]).length-1)
    state = dotProp.set(state, 'addnewLot', false)
    return state;
  }),
  on(dpgfActions.duplicateSection, (state:any,action:any) => {
    const obj = Object.assign({}, action.payload.sousLot);
    if (obj.id) {
      obj.oldId = action.payload.sousLot.id;
      obj.id = getId("sous_lot");
    }
    if (Array.isArray(obj.postes)) {
      obj.postes = obj.postes.map(poste => {
        const newId = getId('poste');
        const newPoste = Object.assign({}, poste, { id: newId, selected: false });

        if (Array.isArray(newPoste.sousPostes)) {
          newPoste.sousPostes = newPoste.sousPostes.map(sousPoste => {
            const newSousPoste = Object.assign({}, sousPoste, { id: getId('sous-poste') });
            return newSousPoste;
          });
        }

        return newPoste;
      });
    }
    return dotProp.set(state, `lot.sousLots`, list=> [...list,obj])
  }),
  on(dpgfActions.addLot, (state:any,action:any) => {
    state = dotProp.set(state, 'isAutomatic', action.payload.isAutomatic)
    // action.payload.lot.forEach((l)=>{
    //   l.oldId = l.id;
    //   l.id = getId("lot");
    // })
    // state = dotProp.set(state, 'dpgf.data', list => [...list, ...action.payload.lot])
    // if(action.payload.isAutomatic){
    //   let newDpgfOrder = [];  
    //   for(var i = 0;i< state.dpgf.data.length ; i++){
    //     let currentLot = state.dpgf.data[i];        
    //     currentLot["indexLot"] = i;
    //     newDpgfOrder.push(currentLot);
    //   }
    //   state = dotProp.set(state, 'dpgf.data', newDpgfOrder);
    // }
    // state = dotProp.delete(state, 'lot')
    // // dotProp.set(state, 'addnewLot', true)
    // return state
     state = dotProp.delete(state, 'lot')
    return dotProp.set(state, 'addnewLot', true)
    }),
  on(dpgfActions.updateLot, (state,action:any) => {
    let data = action.payload.data;
    let index;
    for(var i = 0; i<state.dpgf.data.length ; i++){
      if(state.dpgf.data[i].id == state.lot.id)index = i;
    }
    Object.keys(data).forEach(function (key){
      state = dotProp.set(state, `lot.${key}`, data[key])
      if(index || index == 0)state = dotProp.set(state, `dpgf.data.${index}.${key}`, data[key])
    })
    return state;
  }),
  on(dpgfActions.updateLotStatus, (state,action:any) => {
    const { lotId, status } = action.payload;

    let lotIndex = state.dpgf.data.map(l=>l.id).indexOf(lotId);    

    if(lotIndex){
      state = dotProp.set(state, `dpgf.data.${lotIndex}.status`, status);
    }

    return state;
  }),

  on(dpgfActions.updatePriceLot, (state,action:any) => {
    let modeParam = state.modeList[state.mode];
    state = dotProp.set(state, `lot.${modeParam.totalName}`, Number(action.payload.value))
    return dotProp.set(state, `lot.${modeParam.totalttcName}`, Number(action.payload.value)*(1 + state.dpgf.defaultTva/100));
  }),
  on(dpgfActions.removeLot, (state:any,action:any) => {
    state = dotProp.set(state, 'addnewLot', true)
    if((state.lot||{}).id == action.payload.lotId)state = dotProp.delete(state, `lot`);

    if(action.payload.isAutomatic){
      let newDpgfOrder = [];  
      let filterdData = state.dpgf.data.filter(lot=> lot.id != action.payload.lotId);

      for(var i = 0;i< filterdData.length ; i++){
        let currentLot = filterdData[i];        
        currentLot["indexLot"] = i;
        newDpgfOrder.push(currentLot);
      }
      state = dotProp.set(state, 'dpgf.data', newDpgfOrder);
    }

    return state
  }),
  on(dpgfActions.removeLotDone, (state:any,action:any) => {
    let index;
    for(var i = 0; i<state.dpgf.data.length ; i++){
      if(state.dpgf.data[i].id == action.payload.lotId)index = i;
    }
    state = dotProp.set(state, 'addnewLot', false)
    if((state.lot||{}).id == action.payload.lotId)state = dotProp.delete(state, `lot`)
    if(index || index == 0)return dotProp.delete(state, `dpgf.data.${index}`)
    return state
  }),
  on(dpgfActions.calculate, (state:any,action:any) => {
    let parseNumber = (n)=>{
      let res = 0
      try {res = parseFloat((n).toFixed(2))}catch(err){}
      return res;
    }
    let lot = state.lot;
    let nbLines = 0;
    let fieldCount = 0;
    let modeParam = state.modeList[state.mode]
    //recalculate Lot
    let doneCount = 0;
    let totalLot = 0;
    let totalLotttc = 0;
    let countPostInLot = 0;
    let doneCountInLot = 0;
    let doneCountInLot2 = 0;
    let nbLines1 = 0;
    let dpgf=dotProp.get(state, `dpgf`)
    // new
    let notConsulted = (dpgf.data[dpgf.data.findIndex(lotVar=>lotVar.id == (lot||{}).id)]||{}).notConsulted;  
    for (let j = 0; j < lot.sousLots.length; j++) {
      let totalSousLot = 0;
      let totalSousLotttc = 0;
      if(lot.sousLots[j].priceAll){
        //totalSousLot=lot.sousLots[j].totalArchi;
        //totalSousLotttc=lot.sousLots[j].totalttcArchi;
        totalSousLot = dotProp.get(state, `lot.sousLots.${j}.${modeParam.totalName}`)
        totalSousLotttc = dotProp.get(state, `lot.sousLots.${j}.${modeParam.totalttcName}`)
        totalLot += totalSousLot;
        totalLotttc += totalSousLotttc;
      }
      else{
        if(lot.sousLots[j].postes)
      for (let k = 0; k < lot.sousLots[j].postes.length; k++) {
        let isSameTva = false;
        nbLines = notConsulted ? nbLines: nbLines +1;
        let sousPostesTTc = 0;
        let sousPostesHT = 0;
        let price = Number(lot.sousLots[j].postes[k][modeParam.priceName] || 0);
        let unitePrice = Number(lot.sousLots[j].postes[k][modeParam.unitePriceName] || 0);
        let qte = Number(lot.sousLots[j].postes[k].qte || 0)
        let tva = Number(lot.sousLots[j].postes[k].tva || 0)
        // typeAmount : qte / tva / price / unitePrice
        // case unitePrice and price are null, dont change anything
        if(lot.sousLots[j].postes[k][modeParam.priceName]==null && lot.sousLots[j].postes[k][modeParam.unitePriceName]==null){
        }
        // case of 0
        // case if we set u to 0, we set price to 0
        else if(unitePrice==0 && state.typeAmount=='unitePrice'){
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, 0)
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, 0)
          price=0;unitePrice=0;
        }
        // case if we set p to 0, we dont set unitePrice to 0
        else if(price==0 && state.typeAmount=='price'){
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, 0)
          price=0;
        }
        // if qte is 0 we set p to 0 no matter what we have
        else if(qte==0 && (state.typeAmount=='qte' || state.typeAmount=='unitePrice')) {
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, 0)
          price=0;
        }
        // case of not 0
        // if we set qte we set p
        else if(unitePrice != 0 && qte != 0 && state.typeAmount=='qte'){
          price = unitePrice * qte;
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, price)
        }
        // if we set p we recalculate u
        else if(qte != 0 && price!=0 && state.typeAmount=='price'){
          unitePrice = parseNumber(price / qte);
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, unitePrice)
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, price)
        }
        // if we set u we recalculate p
        else if(unitePrice != 0 && qte != 0 && state.typeAmount=='unitePrice'){ // else qualtity any way
          price = unitePrice * qte;
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, unitePrice)
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, price)
        }
        else if(unitePrice != 0 && qte != 0){ // if quantity & val exits
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, unitePrice * qte)
          price = unitePrice * qte;
        }else if(qte != 0 && price!=0){ // else  price exists
          unitePrice = parseNumber(price / qte);
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, unitePrice)
        }else if(unitePrice != 0 && qte != 0){ // else qualtity any way
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, unitePrice * qte)
          price = unitePrice * qte;
        }else { //last set values
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, price)
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, 0)
          unitePrice=0
        }
        if(lot.sousLots[j].postes[k]?.sousPostes?.length){
        for (let r = 0; r < lot.sousLots[j].postes[k]?.sousPostes.length; r++) {
          // nbLines = notConsulted ? nbLines: nbLines +1;
          let priceCurrentSousPoste = Number(lot.sousLots[j].postes[k].sousPostes[r][modeParam.priceName] || 0);
          let unitepriceCurrentSousPoste = Number(lot.sousLots[j].postes[k].sousPostes[r][modeParam.unitePriceName] || 0);
          let qte1 = Number(lot.sousLots[j].postes[k].sousPostes[r].qte || 0)
          let tvaCurrentSousPoste = Number(lot.sousLots[j].postes[k].sousPostes[r].tva || 0)
          if(r>0){
            if(tvaCurrentSousPoste!=Number(lot.sousLots[j].postes[k].sousPostes[r-1].tva)){
              isSameTva = true;
            }
          }
          

          // typeAmount : qte / tva / price / unitePrice
          // case unitePrice and price are null, dont change anything
          if(lot.sousLots[j].postes[k].sousPostes[r][modeParam.priceName]==null && lot.sousLots[j].postes[k].sousPostes[r][modeParam.unitePriceName]==null){
          }
          // case of 0
          // case if we set u to 0, we set price to 0
          else if(unitepriceCurrentSousPoste==0 && state.typeAmount=='unitePrice'){
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, 0)
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, 0)
            priceCurrentSousPoste=0;unitepriceCurrentSousPoste=0;
          }
          // case if we set p to 0, we dont set unitePrice to 0
          else if(priceCurrentSousPoste==0 && state.typeAmount=='price'){
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, 0)
            priceCurrentSousPoste=0;
          }
          // if qte is 0 we set p to 0 no matter what we have
          else if(qte1==0 && (state.typeAmount=='qte' || state.typeAmount=='unitePrice')) {
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, 0)
            priceCurrentSousPoste=0;
          }
          // case of not 0
          // if we set qte we set p
          else if(unitepriceCurrentSousPoste != 0 && qte1 != 0 && state.typeAmount=='qte'){
            priceCurrentSousPoste = unitepriceCurrentSousPoste * qte1;
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, priceCurrentSousPoste)
          }
          // if we set p we recalculate u
          else if(qte1 != 0 && priceCurrentSousPoste!=0 && state.typeAmount=='price'){
            unitepriceCurrentSousPoste = parseNumber(priceCurrentSousPoste / qte1);
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.unitePriceName}`, unitepriceCurrentSousPoste)
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, priceCurrentSousPoste)
          }
          // if we set u we recalculate p
          else if(unitepriceCurrentSousPoste != 0 && qte1 != 0 && state.typeAmount=='unitePrice'){ // else qualtity any way
            priceCurrentSousPoste = unitepriceCurrentSousPoste * qte1;
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.unitePriceName}`, unitepriceCurrentSousPoste)
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, priceCurrentSousPoste)
          }
          else if(unitepriceCurrentSousPoste != 0 && qte1 != 0){ // if quantity & val exits
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, unitepriceCurrentSousPoste * qte1)
            priceCurrentSousPoste = unitepriceCurrentSousPoste * qte1;
          }else if(qte1 != 0 && priceCurrentSousPoste!=0){ // else  price exists
            unitepriceCurrentSousPoste = parseNumber(priceCurrentSousPoste / qte1);
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.unitePriceName}`, unitepriceCurrentSousPoste)
          }else if(unitepriceCurrentSousPoste != 0 && qte1 != 0){ // else qualtity any way
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, unitepriceCurrentSousPoste * qte1)
            priceCurrentSousPoste = unitepriceCurrentSousPoste * qte1;
          }else { //last set values
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.priceName}`, priceCurrentSousPoste)
            state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.sousPostes.${r}.${modeParam.unitePriceName}`, 0)
            unitepriceCurrentSousPoste=0
          }
          sousPostesTTc = sousPostesTTc + priceCurrentSousPoste *  (1 + tvaCurrentSousPoste/100);
          sousPostesHT += priceCurrentSousPoste
        }
        state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.unitePriceName}`, sousPostesHT/qte)
        state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.priceName}`, sousPostesHT)
        state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.pricettcName}`, sousPostesTTc)
        if(isSameTva){
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.tva`,'Mixte')
        }else {
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.tva`,state.lot.sousLots[j].postes[k]?.sousPostes[0]?.tva ||state.lot.sousLots[j].postes[k].tva)
        }
        //Totals based on addition of sousPostes
          totalSousLot += sousPostesHT;
          totalSousLotttc += sousPostesTTc
        }else{
        //Totals based on poste without sousposte
          let ttcPrice=  price * (1 + tva/100);
          state = dotProp.set(state, `lot.sousLots.${j}.postes.${k}.${modeParam.pricettcName}`, ttcPrice)
          totalSousLot += price;
          totalSousLotttc += ttcPrice
        }
      
        //Counts
        fieldCount = fieldCount +1;
        doneCountInLot = price ==0 ? doneCountInLot : doneCountInLot+1;
        //new saad (achraf)
        doneCountInLot2 = (price  && !notConsulted) ? doneCountInLot2+1 : doneCountInLot2;
        nbLines1 = (price == 0 && !notConsulted) ? nbLines1 ++ : nbLines1;
        countPostInLot += 1; 
      }}
      state = dotProp.set(state, `lot.sousLots.${j}.${modeParam.totalName}`, totalSousLot)
      state = dotProp.set(state, `lot.sousLots.${j}.${modeParam.totalttcName}`, totalSousLotttc)
      //Totals      
      totalLot += totalSousLot;
      totalLotttc += totalSousLotttc;
    }
    if(!lot.priceAll) {
      // doneCount = doneCountInLot;
      doneCount = doneCountInLot2;
      state = dotProp.set(state, `lot.${modeParam.totalName}`, totalLot)
      state = dotProp.set(state, `lot.${modeParam.totalttcName}`, totalLotttc)
    }else{
      doneCount = countPostInLot;
      totalLot =  dotProp.get(state, `lot.${modeParam.totalName}`)
      totalLotttc =  dotProp.get(state, `lot.${modeParam.totalttcName}`)
    }
    if(nbLines%50==0 && nbLines<=400&&!environment.onPremise){
      window.analytics.track(`${nbLines}e_post_added`,{properties:{acValue: `${nbLines}e_post_added`}});
    }
    state = dotProp.set(state, `lot.nbLines`, nbLines)
    state = dotProp.set(state, `lot.${modeParam.nbLineDone}`, doneCount)
    let index = state.dpgf.data.map(l=>l.id).indexOf(state.lot.id);
    state = dotProp.set(state, `dpgf.data.${index}.${modeParam.totalName}`, totalLot)
    state = dotProp.set(state, `dpgf.data.${index}.${modeParam.totalttcName}`, totalLotttc)
    state = dotProp.set(state, `dpgf.data.${index}.nbLines`, nbLines)
    state = dotProp.set(state, `dpgf.data.${index}.${modeParam.nbLineDone}`, doneCount)
    return state
  }),
  on(dpgfActions.nbLine, (state:any) => {
    let lot = state.lot;
    let nbLines = 0;
    for (let j = 0; j < lot.sousLots.length; j++) {
      if(lot.sousLots[j].postes)
      for (let k = 0; k < lot.sousLots[j]?.postes.length; k++) {
        nbLines++;
      }
    }
    let index = state.dpgf.data.map(l=>l.id).indexOf(state.lot.id);
    state = dotProp.set(state, `dpgf.data.${index}.nbLines`, nbLines)
    state = dotProp.set(state, 'changes', state.changes +1)
    return dotProp.set(state, `lot.nbLines`, nbLines)
  }),
  //****************************************Sous Lot*******************************************************
  on(dpgfActions.addNewSousLot, (state:any,action:any) => {
    let id = getId("sous_lot");
    let sousLot = {
      id : id,title: "",color:"",total : 0,description:'',
      postes : [
        {
          id : getId('poste'),designation : "",localisation : "",
          unite: "ens",qte : 1,tva : state.dpgf.defaultTva,
          price : null,unitePrice : null,isAtisan : false,comment : []
        }
      ]
    }
    if(!environment.onPremise)window.analytics.track('add_section',{properties:{acValue: 'add_section'}});
    return dotProp.set(state, `lot.sousLots`, list => [...list, sousLot])
  }),
  on(dpgfActions.updateSousLot, (state:any,action:any) => {
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.title`, action.payload.title)
  }),
  on(dpgfActions.updateSousLotState, (state:any,action:any) => {
    return dotProp.set(state, `lot.sousLots.${action.payload.data.souslotIndex}.priceAll`, action.payload.data.priceAll)
  }),
  on(dpgfActions.updatePriceSousLot, (state:any,action:any) => {
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.totalArchi`, action.payload.value)
  }),  
  on(dpgfActions.changeStateButtonSuggestion, (state:any,action:any) => {
    return dotProp.set(state, `buttonState.${action.payload.key}`, action.payload.buttonSuggestion)
  }),on(dpgfActions.setSizeDPGF, (state:any,action:any) => {
    return dotProp.set(state, 'sizeDPGF', (action.payload.size/16.777216)*100)
  }),
  on(dpgfActions.removeSousLot, (state:any,action:any) => {
    return dotProp.delete(state, `lot.sousLots.${action.payload.sousloti}`)
  }),
  on(dpgfActions.switchSousLot, (state:any,action:any) => {
    let souslot1 = dotProp.get(state, `lot.sousLots.${action.payload.sousLot1}`);
    let souslot2 = dotProp.get(state, `lot.sousLots.${action.payload.sousLot2}`);
    if(souslot1 && souslot2){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousLot1}`, souslot2)
      return dotProp.set(state, `lot.sousLots.${action.payload.sousLot2}`, souslot1)
    }else{
      return state;
    }
  }),
  on(dpgfActions.selectAllPostes, (state,action:any) => {
    let lot=state.lot;
    let checkedPostsNumber = dotProp.get(state,'checkedPostsNumber')
    if(checkedPostsNumber>0 && action.payload.isAllPostesSelected) {state = dotProp.set(state,'checkedPostsNumber',0);checkedPostsNumber=0;}
    for(let i=0;i<lot.sousLots.length;i++){
      let postes = dotProp.get(state, `lot.sousLots.${i}.postes`);
      if(postes){
        for(let j=0;j<postes.length;j++){
          state=dotProp.set(state, `lot.sousLots.${i}.postes.${j}.selected`,action.payload.isAllPostesSelected)
          checkedPostsNumber = action.payload.isAllPostesSelected && state.lot.sousLots[i].postes[j].selected ?checkedPostsNumber+1:checkedPostsNumber-1;
        }
      }
    }    
    state=dotProp.set(state,'checkedPostsNumber',checkedPostsNumber);
    state = dotProp.set(state,'isAllPostesSelected',action.payload.isAllPostesSelected)
    return state;
  }),
  //****************************************Poste*******************************************************
  on(dpgfActions.addNewPoste, (state:any,action:any) => {
    let id = action.payload.id? action.payload.id:getId("poste");
    let postDesignation = action.payload.designation?action.payload.designation:"";
    let poste = {
      id : id,designation :  postDesignation ,localisation : "",
      unite: "ens" ,qte : 1,tva : state.dpgf.defaultTva,
      price : null,unitePrice : null,isAtisan : action.payload.isAtisan, postIniesCount:0
    }
    //unite: action.payload.unit
    state = dotProp.set(state, 'isAllPostesSelected',false);
    state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => {
      if (!Array.isArray(list)) {
        list = [];
      }
      return [...list, poste];
    });
    return state;
  }),
  on(dpgfActions.addNewPosteFromSousPoste, (state:any,action:any) => {
    let id = getId("poste");
    let poste = {
      id : id,designation : action.payload.sousPost.designation,localisation : action.payload.sousPost.localisation,
      unite: action.payload.sousPost.unite ,qte : action.payload.sousPost.qte,tva : action.payload.sousPost.tva,
      price : null,unitePrice : null,isAtisan : action.payload.sousPost.isAtisan, postIniesCount:0,
      unitePriceArchi:action.payload.sousPost.unitePriceArchi,priceArchi:action.payload.sousPost.priceArchi,
      cctp:action.payload.sousPost.cctp
    }
    let postes = dotProp.get(state, `lot.sousLots.${action.payload.sousloti}.postes`);
    postes.splice(action.payload.posti+1, 0, poste);
    state = dotProp.set(state, 'isAllPostesSelected',false);
    state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, postes);
    return state;
  }),
  on(dpgfActions.addNewSousPoste, (state:any,action:any) => {
    let id = getId("sous-poste");
    let sousPoste = {
      id : id,designation : "",localisation : "",
      unite: "ens" ,qte : 1,tva : state.dpgf.defaultTva,
      price : null,unitePrice : null,isAtisan : false, postIniesCount:0
    } 
    // if(!state.lot.sousLots[action.payload.sousloti].postes[action.payload.indexPoste]['sousPostes']){
    // let modeParam = state.modeList[state.mode];
    // console.log('modeParam',modeParam)
    //   state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.indexPoste}."unitePriceArchi"`,0);
    //   state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.indexPoste}.priceArchi`,0);
    // }
       
    //unite: action.payload.unit
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.indexPoste}`, (poste: any) => {
      if (!poste.sousPostes) {
        poste.sousPostes = []; // Create sousPostes array if it doesn't exist
      }
      poste['sousPostes'].push(sousPoste); // Add sousPoste to the sousPostes array
      return poste;
    })
  }),
  on(dpgfActions.addNewPosteBim, (state:any,action:any) => {
    let id = getId("poste");
    let poste = {
      id : id,designation : action.payload.designation ,localisation : "",
      unite: "ens" ,qte : 1,tva : state.dpgf.defaultTva,
      price : null,unitePrice : null,isAtisan : action.payload.isAtisan, postIniesCount:0
    }
  //unite: action.payload.unit
  return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => [...list, poste])
}),
  on(dpgfActions.addSuggestionPoste, (state:any,action:any) => {
    let id = getId("poste");
    let poste = {
      id : id,designation : action.payload.selected.poste,localisation : "",gid :  action.payload.selected.posteid,
      unite: action.payload.selected.unite ,qte : 1,tva : action.payload.selected.tva,
      price : null,unitePrice : null,isAtisan : false , postIniesCount:0
    }
    // to correct
    state = dotProp.set(state, 'isAllPostesSelected',false);
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => [...list, poste])
  }),
  // on(dpgfActions.addSuggestionSousPoste, (state:any,action:any) => {
  //   let id = getId("poste");
  //   let poste = {
  //     id : id,designation : action.payload.selected.poste,localisation : "",gid :  action.payload.selected.posteid,
  //     unite: action.payload.selected.unite ,qte : 1,tva : action.payload.selected.tva,
  //     price : null,unitePrice : null,isAtisan : false , postIniesCount:0
  //   }
  //   if(!environment.onPremise)window.analytics.track('add_suggestion',{properties:{acValue: 'add_suggestion'}});
  //   return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => [...list, poste])
  // }),
  on(dpgfActions.setAllPosteInSouLot, (state:any,action:any) => {
    let postes = JSON.parse(JSON.stringify(action.payload.postes));
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, postes )
  }),
  on(dpgfActions.setOrderSousPosteInPoste, (state:any,action:any) => {
    let sousPostes = JSON.parse(JSON.stringify(action.payload.sousPostes));
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes`, sousPostes )
  }),
  on(dpgfActions.setPosteData, (state:any,action:any) => {
    if(action.payload.data.word){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.designation`, action.payload.data.word)
    }if(action.payload.data.cctp){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.cctp`, action.payload.data.cctp)
    }if(action.payload.data.gid){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.gid`, action.payload.data.gid)
    }if(action.payload.data.unite){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.unite`, action.payload.data.unite)
    }if(action.payload.data.hasOwnProperty("localisation")){
      state = dotProp.set(state, `saveLocalisationToServer`, true)
      if((state.dpgf.localisations||[]).indexOf(action.payload.data.localisation) === -1 && action.payload.data.localisation!="") {
        state = dotProp.set(state, `dpgf.localisations`, (localisations) => {
          if(Array.isArray(localisations)){
            let newLoca=(action.payload.data.localisation||'').replace('Renseignez une localisation','')
            let localisationsArray=newLoca.split(',')
            return _.union(localisationsArray,localisations)
            // return [...localisations, action.payload.data.localisation]
          }else{
            return action.payload.data.localisation?[action.payload.data.localisation]:[]
          }
        })
      }
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.localisation`, action.payload.data.localisation)
    }if(action.payload.data.showCCTP){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.showCCTP`, action.payload.data.showCCTP)
    }
    if(action.payload.data.products){
      //rachid
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.products`, action.payload.data.products)
    }if(action.payload.data.postType){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.postType`, action.payload.data.postType)
    }
    if(!action.payload.data.postType){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.postType`, '')
    }
    if(action.payload.data.postIniesCount!=undefined){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.postIniesCount`, action.payload.data.postIniesCount)
    }
    if(action.payload.data.isIniesUncomplete!=undefined){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.isIniesUncomplete`, action.payload.data.isIniesUncomplete)
    }
    if(typeof action.payload.data.option !== 'undefined'){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.option`, action.payload.data.option)
    }
    return state
  }),
  on(dpgfActions.setSousPosteData, (state:any,action:any) => {
    if(action.payload.data.word){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.designation`, action.payload.data.word)
    }if(action.payload.data.cctp){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.cctp`, action.payload.data.cctp)
    }if(action.payload.data.gid){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.gid`, action.payload.data.gid)
    }if(action.payload.data.unite){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.unite`, action.payload.data.unite)
    }if(action.payload.data.hasOwnProperty("localisation")){
      state = dotProp.set(state, `saveLocalisationToServer`, true)
      if((state.dpgf.localisations||[]).indexOf(action.payload.data.localisation) === -1 && action.payload.data.localisation!="") {
        state = dotProp.set(state, `dpgf.localisations`, (localisations) => {
          if(Array.isArray(localisations)){
            let newLoca=(action.payload.data.localisation||'').replace('Renseignez une localisation','')
            let localisationsArray=newLoca.split(',')
            return _.union(localisationsArray,localisations)
            // return [...localisations, action.payload.data.localisation]
          }else{
            return action.payload.data.localisation?[action.payload.data.localisation]:[]
          }
        })
      }
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.localisation`, action.payload.data.localisation)
    }if(action.payload.data.showCCTP){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.showCCTP`, action.payload.data.showCCTP)
    }if(action.payload.data.postType){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.postType`, action.payload.data.postType)
    }
    if(!action.payload.data.postType){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.postType`, '')
    }
    if(action.payload.data.postIniesCount!=undefined){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.postIniesCount`, action.payload.data.postIniesCount)
    }
    if(action.payload.data.isIniesUncomplete!=undefined){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.isIniesUncomplete`, action.payload.data.isIniesUncomplete)
    }
    if(typeof action.payload.data.option !== 'undefined'){
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.option`, action.payload.data.option)
    }
    return state
  }),
  on(dpgfActions.setPosteAmount, (state:any,action:any) => {
    if(action.payload.data.qte){
      state=dotProp.set(state,'typeAmount', 'qte')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.qte`, Number(action.payload.data.qte))
    }if(action.payload.data.tva){
      state=dotProp.set(state,'typeAmount', 'tva')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.tva`, Number(action.payload.data.tva))
    }if(action.payload.data.revitIcon){
      state=dotProp.set(state,'typeAmount', 'revitIcon')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.revitIcon`, Number(action.payload.data.revitIcon))
    }

    if(["priceArchi","unitePriceArchi", "price","unitePrice"].indexOf(action.payload.data.key) != -1){
      if(action.payload.data.key=="unitePriceArchi" || action.payload.data.key=="unitePrice") state=dotProp.set(state,'typeAmount', 'unitePrice')
      if(action.payload.data.key=="priceArchi" || action.payload.data.key=="price") state=dotProp.set(state,'typeAmount', 'price')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.${action.payload.data.key}`, Number(action.payload.data.value))
    }
    return state
  }),
  on(dpgfActions.setSousPosteAmount, (state:any,action:any) => {
    if(action.payload.data.qte){
      state=dotProp.set(state,'typeAmount', 'qte')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.qte`, Number(action.payload.data.qte))
    }if(action.payload.data.tva){
      state=dotProp.set(state,'typeAmount', 'tva')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.tva`, Number(action.payload.data.tva))
    }if(["priceArchi","unitePriceArchi", "price","unitePrice"].indexOf(action.payload.data.key) != -1){
      if(action.payload.data.key=="unitePriceArchi" || action.payload.data.key=="unitePrice") state=dotProp.set(state,'typeAmount', 'unitePrice')
      if(action.payload.data.key=="priceArchi" || action.payload.data.key=="price") state=dotProp.set(state,'typeAmount', 'price')
      state = dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.${action.payload.data.key}`, Number(action.payload.data.value))
    }
    return state
  }),
  on(dpgfActions.updateDiscount, (state:any,action:any) => {
    state=dotProp.set(state,'dpgf.discount', action.payload)
    return state
  }),
  on(dpgfActions.addManyPostes, (state:any,action:any) => {
    let postes = [];
    for(var i = 0; i<action.payload.pasteRows.length ;i ++){
      let poste = {
        id : getId('poste'),designation : "",localisation : "",
        unite: "",qte : 1,tva : state.dpgf.defaultTva,price : null,
        unitePrice : null,isAtisan : false,cctp : "",
      }
      for(var j = 0; j<action.payload.matching.length ;j ++){
        let key =action.payload.matching[j].key;
        if(key=='none'){//do noting
        }else if(key && (key == 'qte' || key == 'tva'|| key == 'priceArchi'|| key == 'unitePriceArchi') ){
          poste[action.payload.matching[j].key] = parseFloat((action.payload.pasteRows[i][j]||"0").replace(/,/g,"."))||0;
        }else{
          poste[action.payload.matching[j].key] = action.payload.pasteRows[i][j]
        }
      }
      postes.push(poste)
    }
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => [...list, ...postes])
  }),
  on(dpgfActions.addManyPostesCstb, (state:any,action:any) => {
    let postes = [];
    for(var i = 0; i<action.payload.postes.length ;i ++){
      let poste = {
        id : getId('poste'),designation : action.payload.postes[i].title,localisation : "",
        unite: "ens",qte : 1,tva : state.dpgf.defaultTva,price : null,
        unitePrice : null,isAtisan : false,cctp : "",gid : action.payload.postes[i].gid,
        fromCstb:true
      }
      postes.push(poste)
    }
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => [...list, ...postes])
  }),
  on(dpgfActions.addManyPostsArchiDb, (state:any,action:any) => {
    let postes = [];
    let postesToAdd=action.payload.postes
    for(var i = 0; i<postesToAdd.length ;i ++){
      if(typeof(postesToAdd[i].puht)=='string') postesToAdd[i].puht = postesToAdd[i].puht.replace(/,/g, '.')
      let poste = {
        id : getId('poste'),designation : postesToAdd[i].title,localisation : "",
        unite: postesToAdd[i].unity,qte : 1,tva : state.dpgf.defaultTva,
        unitePriceArchi : parseFloat(postesToAdd[i].puht),isAtisan : false,cctp : postesToAdd[i].cctp,
        gid : postesToAdd[i].gid,postType:'archiDb',priceArchi:parseFloat(postesToAdd[i].puht),sousPostes:postesToAdd[i].sousPostes||[]
      }
      postes.push(poste)
    }
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, list => [...list, ...postes])
  }),
  on(dpgfActions.removePoste, (state:any,action:any) => {
    return dotProp.delete(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}`)
  }),
  on(dpgfActions.removeSousPoste, (state:any,action:any) => {
    return dotProp.delete(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}`)
  }),
  on(dpgfActions.duplicatePoste, (state:any,action:any) => {
    let duplicatedPoste = dotProp.get(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}`);
    duplicatedPoste = JSON.parse(JSON.stringify(duplicatedPoste));
    let newId = getId('poste');
    duplicatedPoste['id'] = newId;
    duplicatedPoste.selected=false;
    let postes = dotProp.get(state, `lot.sousLots.${action.payload.sousloti}.postes`);
    postes.splice(action.payload.posti+1, 0, duplicatedPoste);
    if(Array.isArray(postes[action.payload.posti+1].sousPostes)){
      let sousPostes = postes[action.payload.posti+1].sousPostes;
      if (sousPostes.length > 0) {
        for(let i = 0 ; i <sousPostes.length;i++){
          sousPostes[i].id = getId('sous-poste')
        }
      }
    }
    // postes.push(duplicatedPoste);
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes`, postes)
  }),
  on(dpgfActions.duplicateSousPoste, (state:any,action:any) => {
    let duplicatedSousPoste = dotProp.get(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}`);
    duplicatedSousPoste = JSON.parse(JSON.stringify(duplicatedSousPoste));
    let newId = getId('sous-poste');
    duplicatedSousPoste['id'] = newId;
    duplicatedSousPoste.selected=false
    // let sousPostes = dotProp.get(state, `lot.sousLots.${action.payload.sousloti}.postes${action.payload.posti}.sousPostes`);
    // sousPostes.splice(action.payload.posti+1, 0, duplicatedPoste);
    let sousPostes = dotProp.get(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes`);
    sousPostes.push(duplicatedSousPoste);
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes`,sousPostes)
  }),
  //*************************************** Comments ***********************************************
  on(dpgfActions.addPosteComment, (state:any,action:any) => {
    let comment = {com:'',by: action.payload.by ||'art'}
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.comment`,list => {
      if(Array.isArray(list)){
        return [...list, comment]
      }else{
        return [comment]
      }
    })
  }),
  on(dpgfActions.addSousPosteComment, (state:any,action:any) => {
    let comment = {com:'',by: action.payload.by ||'art'}
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.comment`,list => {
      if(Array.isArray(list)){
        return [...list, comment]
      }else{
        return [comment]
      }
    })
  }),
  on(dpgfActions.setPosteCommentData, (state:any,action:any) => {
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.comment.${action.payload.commenti}.com`,action.payload.com)
  }),
  on(dpgfActions.setSousPosteCommentData, (state:any,action:any) => {
    return dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.comment.${action.payload.commenti}.com`,action.payload.com)
  }),
  on(dpgfActions.removePosteComment, (state:any,action:any) => {
    return dotProp.delete(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.comment.${action.payload.commenti}`)
  }),

  on(dpgfActions.removeSousPosteComment, (state:any,action:any) => {
    return dotProp.delete(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.comment.${action.payload.commenti}`)
  }),
  //*************************************** Files ***********************************************
  on(dpgfActions.addInFile, (state:any,action:any) => {
    return dotProp.set(state, "dpgf." + action.payload.path , (list) => {
      if(Array.isArray(list)){
        return [...list, action.payload.file]
      }else{
        return [action.payload.file]
      }
    })
  }),
  on(dpgfActions.addInManyFile, (state:any,action:any) => {
    return dotProp.set(state, "dpgf." + action.payload.path , (list) => {
      if(Array.isArray(list)){
        return [...list, ...action.payload.file]
      }else{
        return action.payload.file
      }
    })
  }),
  on(dpgfActions.renameFile, (state:any,action:any) => {
    let path = action.payload.path;
    if((action.payload.id||"") != ""){
      let files = dotProp.get(state, "dpgf." + action.payload.selectedFolderPath)
      let index = files.map(f=>f._id).indexOf(action.payload.id);
      path = action.payload.selectedFolderPath + "." + index + ".name"
    }
    return dotProp.set(state, "dpgf." + path , action.payload.name )
  }),
  on(dpgfActions.deleteFile, (state:any,action:any) => {
    return dotProp.delete(state, "dpgf." + action.payload.path )
  }),
  on(dpgfActions.addInFileArtisan, (state:any,action:any) => {
    return dotProp.set(state, "dpgf.artisanFiles", (list) => {
      if(Array.isArray(list)){
        return [...list, ...action.payload]
      }else{
        return action.payload
      }
    })
  }),
  on(dpgfActions.removeFileArtisan, (state:any,action:any) => {
    return dotProp.delete(state, "dpgf.artisanFiles." + action.payload )
  }),
  //*************************************** Errors ***********************************************
  on(dpgfActions.errorSaveDpgf, (state:any,action:any) => {
    state = dotProp.set(state, "addnewLot" , false )
    state = dotProp.set(state, "savingData" , false )
    if(action.payload.status == 405){
      return dotProp.set(state, "openNextVersion" , true )
    }else if(action.payload.status == 409){
      return dotProp.set(state, "openConflitLot" , {open : true, ...action.payload.error} )
    }else if (action.payload.status == 0){
      return dotProp.set(state, "openNoInternet" , true )
    }
    return state;
  }),
  on(dpgfActions.errorSaveDpgfPricing, (state:any,action:any) => {
    state = dotProp.set(state, "savingData" , false )
    if(action.payload.status == 405){
      return dotProp.set(state, "openNextVersion" , true )
    }else if (action.payload.status == 0){
      return dotProp.set(state, "openNoInternet" , true )
    }
    return state;
  }),
  on(dpgfActions.setSelectedPosts, (state,action:any) => {
    return dotProp.set(state, 'selectedPosts', action.payload)
  }),
  on(dpgfActions.emptyTypeAmount, (state,action:any) => {
    return dotProp.set(state,'typeAmount', '')
  }),
  // set moving posts
  on(dpgfActions.movePostes, (state:any,action:any) => {
    if(!environment.onPremise)window.analytics.track('send_posts_other_lot',{properties:{acValue: 'send_posts_other_lot'}});
    return dotProp.set(state,'movingPosts',true);
  }),
  on(dpgfActions.flagPost, (state:any,action:any) => {
    let flaged=action.payload.flaged;
    if(flaged==undefined) {
      flaged=false;
    }
    state=dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.flaged`, !flaged)
    return state
  }),
  on(dpgfActions.flagSoustPost, (state:any,action:any) => {
    let flaged=action.payload.flaged;
    if(flaged==undefined) {
      flaged=false;
    }
    state=dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.sousPostes.${action.payload.sousPosteIndex}.flaged`, !flaged)
    return state
  }),
  // select post dpgf
  on(dpgfActions.selectPost, (state,action:any) => {
    let selected=action.payload.selected;
    let checkedPostsNumber = dotProp.get(state,'checkedPostsNumber');
    let lot = state.lot
    let lengthSousLotPostes = 0;
    if(selected==undefined) {
      checkedPostsNumber +=1;
      state=dotProp.set(state,'checkedPostsNumber',checkedPostsNumber);
      selected=false;
    }
    else if(selected){
      checkedPostsNumber -=1;
      state=dotProp.set(state,'checkedPostsNumber',checkedPostsNumber);
    }
    else if(selected==false){
      checkedPostsNumber +=1;
      state=dotProp.set(state,'checkedPostsNumber',checkedPostsNumber);
    }
    for(let i=0;i<lot.sousLots.length;i++){
      lengthSousLotPostes += lot.sousLots[i].postes.length;
    }
    state=dotProp.set(state, 'isAllPostesSelected',checkedPostsNumber == lengthSousLotPostes?true:false)
    state=dotProp.set(state, `lot.sousLots.${action.payload.sousloti}.postes.${action.payload.posti}.selected`, !selected)
    return state;
  }),
  on(dpgfActions.unselectPosts, (state,action:any) => {
    let lot= state.lot;
    if(action.payload.isLoading) state=dotProp.set(state,'movingPosts',false)
    if(_.get(lot,'sousLots.length',0)){
      for(let i=0;i<lot.sousLots.length;i++){
        let postes = dotProp.get(state, `lot.sousLots.${i}.postes`);
        if(postes){
          for(let j=0;j<postes.length;j++){
            state=dotProp.set(state, `lot.sousLots.${i}.postes.${j}.selected`,false)
          }
        }
      }
    }
    state = dotProp.set(state, `checkedPostsNumber`,0);
    state = dotProp.set(state,'isAllPostesSelected',false);

    return state;
  }),
  // delete many posts
  on(dpgfActions.moveSection, (state,action:any) => {
    // console.log('action',action.payload)
    // let lot= state.lot;
    // console.log('lot',lot);
    // let data = lot.sousLots[action.payload.indexSection];
    // console.log('data',data);
    // lot.sousLots.splice(action.payload.indexSection,1)
    // state = dotProp.set(state,`lot`,lot)
    // let lotId = state.dpgf.data.findIndex(element=>element.id==action.payload.lotDestinationID)
    // console.log('lotId',lotId)
    // let dpgf = dotProp.get(state, `dpgf.data`);
    // // dpgf.data[lotId].push(data);
    // console.log('dpgf',dpgf)
    // dpgf[lotId].sousLots.push(action.payload.sousLot);
    // console.log('dpgf[lotId].sousLots',dpgf[lotId].sousLots)
    // state = dotProp.set(state,`dpgf.data`,dpgf)
    return dotProp.set(state,'movingPosts',true);

    // return state
  }),
  on(dpgfActions.removeSection, (state,action:any) => {
    let lot= state.lot;
    let data = lot.sousLots[action.payload.data.indexSection];
    lot.sousLots.splice(action.payload.data.indexSection,1)
    state = dotProp.set(state,`lot`,lot)
    if(action.payload.isLoading) state=dotProp.set(state,'movingPosts',false)

    return state;
  }),
  on(dpgfActions.removeManyPosts, (state,action:any) => {
    let lot= state.lot;
    if(action.payload.isLoading) state=dotProp.set(state,'movingPosts',false)
    for(let i=0;i<lot.sousLots.length;i++){
      let postes = dotProp.get(state, `lot.sousLots.${i}.postes`);
      var j=postes.length;
      while(j--){
        if(postes[j].selected) {
          postes.splice(j,1);
        }
      }
      state=dotProp.set(state, `lot.sousLots.${i}.postes`,postes)
    }
    state=dotProp.set(state, `checkedPostsNumber`,0)
    state = dotProp.set(state,'isAllPostesSelected',false);
    return state;
  }),
  // duplicate many posts 
  on(dpgfActions.duplicateManyPosts, (state,action:any) => {
    let lot = state.lot
    for(let i=0;i<lot.sousLots.length;i++){
      let postes = dotProp.get(state, `lot.sousLots.${i}.postes`);
      for(let j=0;j<lot.sousLots[i].postes.length;j++){
        if(lot.sousLots[i].postes[j].selected==true){
          let post={...lot.sousLots[i].postes[j]};
          let newId = getId('poste');
          post['id'] = newId;
          post.selected=false;
          if(Array.isArray(post.sousPostes)){
            let sousPostes = post.sousPostes;
            if (sousPostes.length > 0) {
            sousPostes = sousPostes.map(sousPoste => {
              const newSousPoste = Object.assign({}, sousPoste, { id: getId('sous-poste') });
              return newSousPoste;
            });
            post.sousPostes = sousPostes;
            }
          }
          postes.push(post);
          lot.sousLots[i].postes[j].selected = false; // unselect the the post you want to duplicated
        }
      }
      state=dotProp.set(state, `lot.sousLots.${i}.postes`, postes)
      state=dotProp.set(state, `checkedPostsNumber`,0)
    }
    state = dotProp.set(state,'isAllPostesSelected',false);
    return state;
  }),
  on(dpgfActions.flagManyPosts, (state:any,action:any) => {
    let lot= state.lot;
    let flaged;
    for(let i=0;i<lot.sousLots.length;i++){
      let postes = dotProp.get(state, `lot.sousLots.${i}.postes`);
      var j=postes.length;
      while(j--){
        if(postes[j].selected) {
          if(postes[j].flaged==undefined) {
            flaged=false;
          }
          else flaged=postes[j].flaged
          state=dotProp.set(state, `lot.sousLots.${i}.postes.${j}.flaged`, !flaged)
          state=dotProp.set(state, `lot.sousLots.${i}.postes.${j}.selected`, false)
        }
      }
    }
    state = dotProp.set(state,'isAllPostesSelected',false);
    state=dotProp.set(state, `checkedPostsNumber`,0)
    return state;
  }),
  on(dpgfActions.setExportPostsDb, (state:any,action:any) => {
    let manyPost=action.payload.manyPost;
    if(action.payload.setSelected){
      let lot=state.lot;
      for(let i=0;i<lot.sousLots.length;i++){
        let postes = dotProp.get(state, `lot.sousLots.${i}.postes`);
        var j=postes.length;
        while(j--){
          if(postes[j].selected) {
            state=dotProp.set(state, `lot.sousLots.${i}.postes.${j}.selected`, false)
          }
        }
      }
      state=dotProp.set(state, `checkedPostsNumber`,0)
    }
    return dotProp.set(state,'exportManyPostsDb',manyPost);
  }),
  on(dpgfActions.setExportDoc, (state:any,action:any) => {
    let exportDoc=action.payload.export;
    return dotProp.set(state, 'exportDoc', exportDoc);
  }),
  on(dpgfActions.setDescView, (state:any,action:any) => {
    let descView=action.payload.descView;
    return dotProp.set(state, 'descView', descView)
  }),
  on(dpgfActions.setProjLicence, (state:any,action:any) => {
    let projectLicence=action.payload.projectLicence;
    return dotProp.set(state, 'projectLicence', projectLicence)
  })
);

export function dpgfReducer(state, action) {
  return _dpgfReducer(state, action);
}
