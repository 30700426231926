<!-- <div class="callmodal-container"> -->
  <!-- <div class="modal-content-el"> -->
    <!-- <div class="close-popup">
      <a (click)="closePopup()"> <i class="fa fa-times"></i> </a>
    </div> -->
    <!-- <div class="upper-text">
      <h4>Exporter des documents</h4>
      <p>Définissez le(s) lot(s) à exporter et choisissez le format qui vous convient.</p>
    </div> -->
    <div class="row add-enterprise-container">
      <!-- Left Side -->
      <div class="col-md-6 tags-col">
        <div class="content-wrapper">
          <div class="choice-title">
            Choix des lots
          </div>
          <div class="content-list">
              <div class="single-lot-card clearfix" (click)="checkAll()">
                <div class="lot-check">
                  <input class="input-aglo" type="checkbox" [checked]="checkedAll">
                </div>
                <div class="info-img-text-wrap" >
                  <div class="lot-name">
                    <p style="padding-left: 5px;cursor: pointer;">Tous les lots</p>
                  </div>
                </div>
              </div>
              <div class="single-lot-card clearfix" *ngFor="let lot of dpgfLot,let loti = index">
                <div class="lot-check">
                  <!-- <p-checkbox binary="true" [(ngModel)]="selectedLot[lot.lotId]"></p-checkbox> -->
                  <input class="input-aglo" type="checkbox" (click)="selectLot(lot.lotId)" [checked]="selectedLot[lot.lotId]" >
                </div>
                <div class="info-img-text-wrap" (click)="selectLot(lot.lotId)" style="width:95%">
                  <div class="lot-icon" >
                    <img [src]="lot.icon !='' ? '/assets/dpgf/Icon_DPGF/'+lot.icon : '/assets/rob/helmet.png'" />
                  </div>
                  <div class="lot-name">
                    <p *ngIf="!isAutomateNumberIsChecked">{{ lot.indexLot }} - {{ lot.name }}</p>
                    <p *ngIf="isAutomateNumberIsChecked">{{ loti }} - {{ lot.name }}</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!-- Check & Uncheck Buttons -->
        <!-- <div class="selection-btns">
          <button (click)="checkAll()"><i class="fa fa-check-circle-o"></i> Tout sélectionner</button>
          <button (click)="uncheckAll()"><i class="fa fa-circle-o"></i> Tout désélectionner</button>
        </div> -->
      </div>
      <!-- ENd Left Side -->
      <!-- divider -->
      <div class="divider col-md-3"></div>
      <!-- Right Side -->
      <div class="col-md-6" style="padding-left: 32px;">
        <div class="choice-title">
          Modalités d’export
        </div>
        <div class="right-container scroller-1">
          <div class="one-option">
            <p class="secondary-title">1. Type du Document</p>
            <div class="flex margin-left-24">
              <div (click)="changeDocData('cctp','typeDoc')">
                <input class="input-aglo size-20" type="radio" name="typeDoc" [checked]="docData.typeDoc=='cctp'">
                <p class="option-p ">CCTP</p>
              </div>
              <div class="one-radio" (click)="changeDocData('dpgf','typeDoc')">
                <input class="input-aglo size-20" type="radio" name="typeDoc" [checked]="docData.typeDoc=='dpgf'">
                <p class="option-p ">DPGF</p>
              </div>
            </div>
            <div class="flex margin-left-48">
              <div class="one-radio" (click)="changeOption('dqe')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.dqe" [disabled]="docData.typeDoc!='dpgf' || docData?.options?.estimatif">
                <p class="option-p " [ngStyle]="{'color': docData.typeDoc!='dpgf'?'gray':'#333'}">Inclure les quantités (DQE)</p>
              </div>
              <div class="one-radio" (click)="changeOption('estimatif')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.estimatif" [disabled]="docData.typeDoc!='dpgf'">
                <p class="option-p " [ngStyle]="{'color': docData.typeDoc!='dpgf'?'gray':'#333'}">Inclure l'estimation</p>
              </div>
            </div>
            <div class="flex margin-left-24">
              <div class="one-radio" (click)="changeDocData('recap','typeDoc')">
                <input class="input-aglo size-20" type="radio" name="typeDoc" [checked]="docData.typeDoc=='recap'">
                <p class="option-p ">Récapitulatif de l'estimation</p>
              </div>
            </div>
            <!-- <div class="flex margin-left-24">
              <div class="one-radio" (click)="changeDocData('product','typeDoc')">
                <input class="input-aglo size-20" type="radio" name="typeDoc" [checked]="docData.typeDoc=='product'">
                <p class="option-p ">Products</p>
              </div>
            </div> -->
          </div>
          <div class="secondary-option margin-left-24">
            <p class="secondary-title">2. Format</p>
            <div class="flex margin-left-24">
              <div (click)="changeDocData('pdf','format')">
                <input class="input-aglo size-20" type="radio" name="formatDoc" [checked]="docData.format=='pdf'">
                <p class="option-p">PDF</p>
              </div>
              <div *ngIf="docData.typeDoc=='cctp'" class="one-radio" (click)="changeDocData('word','format')">
                <input class="input-aglo size-20" type="radio" name="formatDoc" [checked]="docData.format=='word'">
                <p class="option-p">Word</p>
              </div>
              <div *ngIf="docData.typeDoc=='dpgf'" (click)="changeDocData('excel','format')" class="one-radio">
                <input class="input-aglo size-20" type="radio" name="formatDoc" [checked]="docData.format=='excel'">
                <p class="option-p ">Excel</p>
              </div>
            </div>
          </div>
          <div class="secondary-option margin-left-24">
            <p class="secondary-title">3. Options de l’export</p>
            <div *ngIf="docData.typeDoc=='cctp' && docData.format=='pdf'" class="flex margin-left-24">
              <div (click)="changeOption('provisoire')" *ngIf="!onPermis">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.provisoire">
                <p class="option-p pointer-p">Indiquer que cette version est provisoire</p>
              </div>
              <div class="one-radio" (click)="changeOption('showPosts')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.showPosts">
                <p class="option-p pointer-p">Afficher les postes dans le sommaire</p>
              </div>
              <div class="one-radio" (click)="changeOption('byLot')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.byLot">
                <p class="option-p pointer-p">Exporter un fichier pour chaque lot</p>
              </div>
            </div>
            <div *ngIf="docData.typeDoc=='dpgf' && docData?.options?.dqe && docData.format=='pdf'" class="flex margin-left-24">
              <div (click)="changeOption('addQteArtisan')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.addQteArtisan">
                <p class="option-p pointer-p">Ajouter une colonne “Quantité artisan”</p>
              </div>
              <div class="one-radio" (click)="changeOption('fusion')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.fusion">
                <p class="option-p pointer-p">Fusionner les colonnes unité et quantité</p>
              </div>
              <div class="one-radio" (click)="changeOption('provisoire')" *ngIf="!onPermis">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.provisoire">
                <p class="option-p pointer-p">Indiquer que cette version est provisoire</p>
              </div>
              <div class="one-radio" (click)="changeOption('showPosts')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.showPosts">
                <p class="option-p pointer-p">Afficher les postes dans le sommaire</p>
              </div>
              <div class="one-radio" (click)="changeOption('byLot')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.byLot">
                <p class="option-p pointer-p">Exporter un fichier pour chaque lot</p>
              </div>
            </div>
            <div *ngIf="docData.typeDoc=='dpgf' && !docData?.options?.dqe && docData.format=='pdf'" class="flex margin-left-24">
              <div (click)="changeOption('provisoire')" *ngIf="!onPermis">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.provisoire">
                <p class="option-p pointer-p">Indiquer que cette version est provisoire</p>
              </div>
              <div class="one-radio" (click)="changeOption('fusion')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.fusion">
                <p class="option-p pointer-p">Fusionner les colonnes unité et quantité</p>
              </div>
              <div class="one-radio" (click)="changeOption('showPosts')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.showPosts">
                <p class="option-p pointer-p">Afficher les postes dans le sommaire</p>
              </div>
              <div class="one-radio" (click)="changeOption('byLot')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.byLot">
                <p class="option-p pointer-p">Exporter un fichier pour chaque lot</p>
              </div>
            </div>
            <div *ngIf="docData.typeDoc=='dpgf' && !docData?.options?.dqe && docData.format=='excel'" class="flex margin-left-24">
              <div (click)="changeOption('fusion')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.fusion">
                <p class="option-p pointer-p">Fusionner les colonnes unité et quantité</p>
              </div>
            </div>
            <div *ngIf="docData.typeDoc=='dpgf' && docData?.options?.dqe && docData.format=='excel'" class="flex margin-left-24">
              <div (click)="changeOption('addQteArtisan')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.addQteArtisan">
                <p class="option-p pointer-p">Ajouter une colonne “Quantité artisan”</p>
              </div>
              <div class="one-radio" (click)="changeOption('fusion')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.fusion">
                <p class="option-p pointer-p">Fusionner les colonnes unité et quantité</p>
              </div>
            </div>
            <div *ngIf="!onPermis && docData.typeDoc=='recap'" class="flex margin-left-24">
              <div (click)="changeOption('provisoire')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.provisoire">
                <p class="option-p pointer-p">Indiquer que cette version est provisoire</p>
              </div>
            </div>
            <div *ngIf="docData.typeDoc=='cctp' && docData.format=='word'" class="flex margin-left-24">
              <div (click)="changeOption('provisoire')" *ngIf="!onPermis">
                <input (click)="changeOption('provisoire')" class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.provisoire">
                <p class="option-p pointer-p">Indiquer que cette version est provisoire</p>
              </div>
              <div class="one-radio" (click)="changeOption('showPosts')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.showPosts">
                <p class="option-p pointer-p">Afficher les postes dans le sommaire</p>
              </div>
              <div class="one-radio" (click)="changeOption('byLot')">
                <input class="input-aglo size-20" type="checkbox" [checked]="docData?.options?.byLot">
                <p class="option-p pointer-p">Exporter un fichier pour chaque lot</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <button  class="ag-btn-primary export-button" (click)='exportDoc()'>
          Exporter
        </button> -->
        <!-- <div *ngIf="downloadingDoc" class="export-button">
          <app-spinner ></app-spinner>
        </div> -->
        
        <!-- <div class="buttons-container">
            <div *ngIf="!downloadingDoc">
              <button (click)="downloadDPGFPdf()" class="reverse-btn">  Imprimer la DPGF - PDF <i class="fa fa-file-pdf-o"></i></button>
              <button (click)="downloadDQEPdf()" class="reverse-btn"> Imprimer le DQE - PDF <i class="fa fa-file-pdf-o"></i></button>
              <button (click)="downloadEstimatifPdf()" class="reverse-btn"> Imprimer l'estimatif - PDF <i class="fa fa-file-pdf-o"></i></button>
              <button (click)="downloadCCTPPdf()" class="reverse-btn"> Imprimer le CCTP - PDF <i class="fa fa-file-pdf-o"></i></button>
              <hr>

              <div class="down-dce-cont">
                <button (click)="showExcelDropDown = !showExcelDropDown"> le DCE - EXCEL
                  <i class="margin-left-5 fa fa-file-excel-o"></i>
                  <span class="arr-ic">
                    <i class="fa fa-sort-down"></i>
                  </span>
                </button>
                <div class="drop-items" [ngClass]="{'unvisible': !showExcelDropDown}">
                  <div (click)="downloadDCEExcel(true)" class="excel-item padding-10 cursor">Estimatif</div>
                  <div (click)="downloadDCEExcel(false)" class="excel-item padding-10 cursor">DPGF</div>
                </div>
              </div>

              <button (click)="downloadCctpDocx()"> le CCTP - WORD <i class="margin-left-5 fa fa-file-word-o"></i> </button>
            </div>
            <div class="spinner-container" *ngIf="downloadingDoc">
              <app-spinner></app-spinner>
            </div>
        </div>
      </div> -->
      <!-- END Right Side -->
    </div>
  <!-- </div> -->
<!-- </div> -->
<app-need-subscription-popup></app-need-subscription-popup>


<!-- Errors on the Document Modal -->
<app-documents-errors-modal 
  [messageObject]="documentsErrorInfo"
  [ceProjectId]="ceProjectId"
  (goToLot)="goToLot($event)"
  ></app-documents-errors-modal>
<!-- Errors on the Document Modal -->


<!-- Modal -->
<app-doc-file-is-downloaded
  (emitDontShowMessage)="toggleShowDocFileIsDownloaded($event)"
></app-doc-file-is-downloaded>
<!-- END Modal -->