<div class="main-page-wrapper">

  <app-header type="full"></app-header>

  <div class="inner-container">
    
    <!-- Page title -->
    <div class="page-title-wrapper flex center-item space-between">
      <div class="">
        <h1 class="no-margin-top no-margin-bottom">Projets</h1>
      </div>
      <div class="flex center-items" *ngIf="!currentUser.isAdmin">
        <button class="ag-btn-primary-xs justify-center pointer relative" (click)="goToNewProjectPage()">
          <img src="/assets/add-icon-white.svg" class="margin-right-5">
          <span>Créer un projet</span>
        </button>
      </div>
    </div>
    <!-- END Page title -->

    <!-- Projects Navbar -->
    <div class="projects-searchbar-container margin-top-16">
      <app-projects-searchbar
        [isMinarm]="onPermis"
        [minarmArchitectsList]="minarmArchitectsList"
        (searchProjects)="searchProjects($event)"
        (filterProjects)="filterProjects($event)"
        (sortBy)="sortProjects($event)"
      ></app-projects-searchbar>
    </div>
    <!-- END Projects Navbar -->


    <!-- Projects Cards List -->
    <div class="projects-cards-list-container margin-top-40">
      <app-spinner *ngIf="loading"></app-spinner>

      <app-projects-cards-list *ngIf="!loading"
        [isMinarm]="onPermis"
        [isAdmin]="isAdmin"
        [minarmIsAdminArchitect]="minarmIsAdminArchitect"
        [authArtchitectId]="authArtchitectId"
        [license]="license"
        [authUserId]="currentUser.id"
        [projects]="projects"
        [authUserFavoritedProjects]="authUserFavoritedProjects"
        (openNeedLicenseModal)="openNeedLicenseModal()"
        (goToProjectDashboardPage)="openProject($event)"
        (duplicateProject)="duplicate($event)"
        (toggleFavorite)="toggleFavorite($event)"
        (archiveProject)="archiveProject($event)"
        (removeProject)="openConfirmRemoveProjectModal($event)"
        (toggleValidateProject)="toggleValidateProject($event)"
      ></app-projects-cards-list>
    </div>
    <!-- END Projects Cards List -->


    <!-- Pagination (minarm only) -->
    <app-pagination *ngIf="onPermis"
      [numberOfPages]="totalNumberOfPages"
      (pageSelected)="pageSelected($event)"
    ></app-pagination>
    <!-- END Pagination -->
  
  
  </div>

</div>




<!-- Modals
================================================ -->

<!-- AGLO Design System confirm Modal -->
<app-ds-confirm-modal
  [modalId]="'confirm-remove-project-modal'"
  [title]="confirmModalTitle"
  [description]="confirmModalDescription"
  [confirmButtonLabel]="confirmModalAcceptLabel"
  (confirm)="removeProject()"
></app-ds-confirm-modal>
<!-- END AGLO Design System confirm Modal -->

<!-- Need Subscription Modal -->
<app-user-on-without-license-modal></app-user-on-without-license-modal>
<!-- END Need Subscription Modal -->