import { Component, OnInit,ViewChild, HostListener, NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { trigger,state,style,transition,animate,keyframes } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import {Promise}  from 'bluebird';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Store , select} from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';
import * as actions from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import { Location, CommonModule } from '@angular/common'
import { NgSharedModule } from 'src/app/all/ngshared.module';

import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Services Imports
import { MessageService } from 'primeng/api';
import { ErrorService } from '../../../_services/index';
import { DpgfPostesService, ArtisanService, CeProjectInvitesService } from '../../../_services/index';
import { ConfirmationService } from 'primeng/api';

import { ArtisanCguModalComponent } from 'src/app/all/artisan-cgu-modal/artisan-cgu-modal.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { HeadDpgfComponent } from 'src/app/dpgf/dce-edition/head-dpgf/head-dpgf.component';
import { TableDpgfComponent } from 'src/app/dpgf/dce-edition/table-dpgf/table-dpgf.component';
import { DpgfFoldersComponent } from 'src/app/dpgf/dce-edition/dpgf-folders/dpgf-folders.component';
import { DpgfDevisPopupComponent } from 'src/app/dpgf/pricing-results/dpgf-lots-popup/dpgf-devis-popup.component';
import { DocumentsErrorsModalComponent } from 'src/app/all/documents-errors-modal/documents-errors-modal.component';
import { environment } from 'src/environments/environment';
// Services
import { AuthService } from 'src/app/_services';

declare var introJs: any;
declare var $: any;
@Component({
    selector: 'app-artisan-price-dpgf',
    imports:[
    ArtisanCguModalComponent,
    SpinnerComponent,
    HeadDpgfComponent,
    TableDpgfComponent,
    DpgfFoldersComponent,
    DpgfDevisPopupComponent,
    DocumentsErrorsModalComponent,
    CommonModule,
    NgSharedModule
],
    providers: [],
    templateUrl: './artisan-price-dpgf.component.html',
    styleUrls: ['./artisan-price-dpgf.component.scss'],
    animations: [
        trigger('appear', [
            state('hide', style({
                top: '-50px',
            })),
            state('show', style({
                top: '10px',
            })),
            transition('hide => show', animate('2000ms ease-in')),
            transition('show => hide', animate('2000ms ease-in')),
        ]),
    ],
    standalone: true,
    
})

export class ArtisanPriceDpgfComponent implements OnInit {
  public documentsErrorInfo: any = {};
  public dpgfPricingId:string;
  public archiMessage:string ="";
  public code:string="";
  public openLotJson:any={};
  public saving:boolean = false;
  public isSticky:boolean = true;
  public planDocument:boolean = false;
  public loadingFiles:boolean = false;
  public respecter:boolean = false;
  public assurer:boolean = false;
  public errorSend:boolean = false;
  public downloadingDoc:boolean = false;
  public waitingPass$;loadingData$;finishing$;done$;saving$;changes$;
  public dpgf$;total$;totalTTC$;totalHtAfterRemise$;discount$;totalTtcAfterRemise$
  public nbLines$:Observable<number>;nbLineDone$:Observable<number>
  public state: string = 'hide';changes;timeInterval;lotExist;
  public dpgfPricing: any = {};
  public showDevisDropDown:boolean = false;
  public documentType:string = 'devis';
  public remiseButton = false;
  public discountUnite = '€';
  public discountAmount = 0;
  public discountTotal = 0;
  public discountTotalTtc = 0;backbutton;

  public ceProjectInvite:string = "";
  public artisanMot:String = "";
  public artisanRaisonRefus:String = "";
  public projectName:String = "";
  public projectLicence: string='none';
  
  public showAcceptOrRefuseButtons:boolean = false;
  public isFactice: boolean = false;
  // when an artisan didn't accept the invitation yet he only can read
  public canEditOrSubmit:boolean = true; 
  public acceptingOrRefusingLoader:boolean = false; 
  private STATIC_FILES_API_URL:string = "api/v1/upload-files";
  public isMinarm:boolean = false;
  public deliveryDate = new Date().toISOString().split('T')[0];;
  public files;
  private subscriptions: Array<Subscription> = [];  
  public firstValueEnvirenementObservable = new Observable<boolean>(observer => {
    observer.next(environment.onPremise);
    observer.complete();
  });
  constructor(private title: Title,
              private router: Router,
              private messageService: MessageService,
              private DpgfPostesService: DpgfPostesService,
              private artisanService: ArtisanService,
              private ceProjectInvitesService: CeProjectInvitesService,
              private route: ActivatedRoute,
              private store: Store,
              private ErrorService: ErrorService,
              public authService: AuthService,
              private confirmationService: ConfirmationService,
              private location: Location) { }

  ngOnInit() {
    this.timeInterval = setInterval(()=>{
      if(this.lotExist && this.changes != 0)this.store.dispatch(actions.saveDpgfLotToServer({}));
    }, 20000);
    this.title.setTitle('Aglo - Chiffrer mon DPGF' );
    let code = this.route.snapshot.queryParams.code;
    this.code = (code||"").replace(/ /g,'');
    this.store.dispatch(actions.setType("pricing"));
    this.store.dispatch(actions.setAny( {name  : "mode" , value : "artisan"}));
    this.store.dispatch(actions.emptyLot( {}));
    this.loadingData$ = this.store.pipe(select(fromSelectors.getAny , {name : "loadingData"} ))
    this.finishing$ = this.store.pipe(select(fromSelectors.getAny , {name : "finishing"} ))
    this.done$ = this.store.pipe(select(fromSelectors.getAny , {name : "pricingDone"} ))
    this.dpgf$ = this.store.pipe(select(fromSelectors.getDpgf));
    //new (achraf)
    this.waitingPass$ = this.store.pipe(select(fromSelectors.getAny , {name : "waitingPass"}))
    // this.nbLines$ = this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" }))
    this.nbLines$ = this.store.pipe(select(fromSelectors.getNbLinesArtisan, {name : "nbLines"}))
    this.nbLineDone$ = this.store.pipe(select(fromSelectors.getNbLinesArtisan, { name: "nbLineDone" }))
    this.saving$ =  this.store.pipe(select(fromSelectors.getAny , {name : "savingData"} ))
    this.total$ = this.store.pipe(select(fromSelectors.getTotal, { name: "total" }))
    this.totalHtAfterRemise$ = this.store.pipe(select(fromSelectors.getTotalAfterDiscount, { name: "total" }))
    this.totalTtcAfterRemise$ = this.store.pipe(select(fromSelectors.getTotalTtcAfterDiscount))
    this.totalTTC$ = this.store.pipe(select(fromSelectors.getTotal, { name: "totalttc" }))
    this.store.pipe(select(fromSelectors.getAny, { name: "openNextVersion" })).subscribe(open=>{
      if(open)this.openNextVersion();
    })
    this.store.pipe(select(fromSelectors.getLot)).subscribe((lot)=>{
      if(lot)this.lotExist = true
      else this.lotExist = false
    })
    this.changes$ = this.store.pipe(select(fromSelectors.getAny, {name : "changes"}))
    this.store.pipe(select(fromSelectors.getAny, {name : "changes"})).subscribe((changes)=>{
      this.changes = changes;
    })
    if(this.code != "" )this.getDPGF();
    this.discount$ = this.store.pipe(select(fromSelectors.getDiscount, {name : "discount"})).subscribe(val =>{     
      this.discountAmount = val?.discountAmount;
      this.discountUnite = val?.discountUnite;
      if( this.discountAmount > 0)
      this.remiseButton = true; 
    });
    // get CeProject Invite
    this.store.pipe(select(fromSelectors.getDpgfPricingCeProjectInvite)).subscribe(ceProjectInvite => {
      if(ceProjectInvite._id){
        this.isFactice = ceProjectInvite.isFactice || false;
        let status = ceProjectInvite.status || "";
        let isAGuestUser = this.isUserAGuest(); /// if the user is a Guest not Logged In
        
        let canEditStatus = ["artisan-accepter", "dce_envoyer", "pricing-sent", "rdv-pris", "selectionne", "signe"];
        // this.showAcceptOrRefuseButtons = (status == "artisan-invite" && !isAGuestUser) ? true : false;
        this.showAcceptOrRefuseButtons = (status == "artisan-invite") ? true : false;
        this.canEditOrSubmit = (canEditStatus.includes(status) || this.isFactice || isAGuestUser) ? true : false;      

        this.ceProjectInvite = ceProjectInvite;
      }
    });
    this.store.pipe(select(fromSelectors.getDpgf)).subscribe(dpgf => {
      if(dpgf?.ceProject){
        this.projectName = dpgf.ceProject.title || "";
      }
    });
    this.dpgf$.subscribe((val)=>{
      if(((val.ceProject||{}).dateDce)) { 
        this.deliveryDate = (val.ceProject||{}).dateDce.split('T')[0];
      }
      })
    this.backbutton = window.location.href.split('&').pop() == 'app=true' ? true : false;

    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
  }
  public getDPGF(){
    this.route.params.forEach((params: Params) => {
      this.dpgfPricingId = params['dpgfPricingId'];
      if (params['dpgfPricingId'] && this.code.replace(/ /g,'') != "") {
        this.store.dispatch(actions.setAny( {name  : "loadingData" , value : true}));
        this.store.dispatch(actions.setAny( {name  : "code" , value : this.code.replace(/ /g,'')}));
        this.store.dispatch(actions.loadDpgfPricing( {dpgfId : this.dpgfPricingId , code : this.code.replace(/ /g,'')}));
      }
    })
  }
  // onUploadFiles(event){
  //     let filesUploaded = event.originalEvent.body;
  //     $("#uppy-files-modal").modal("hide");
  //     this.store.dispatch(actions.addInFileArtisan(filesUploaded));
  // }
  public openNextVersion(){
    this.confirmationService.confirm({
            message: `La version actuelle a été envoyée à l'architecte donc ne peut pas être modifiée! Nous allons donc créer une nouvelle version !`,
            accept: () => {
              this.store.dispatch(actions.saveDpgfLotToServer({goNext : "yes"}));
              this.store.dispatch(actions.saveDpgfToServer({goNext : "yes"}));
              this.store.dispatch(actions.setAny( {name  : "openNextVersion" , value : false}));
            },
            reject: () => {
              this.store.dispatch(actions.setAny( {name  : "openNextVersion" , value : false}));

              this.messageService.add({ key: 'toast', severity: 'error', summary: 'Modifications non enregistrées', detail: "Vos modifications n'ont pas été enregistrées", life: 5000 });
            }
        });
  }
  saveToServe(){
    this.store.dispatch(actions.saveDpgf({}));
  }
  setAttribut(data){
    this.store.dispatch(actions.setDpgfAttribut(data));
  }
  public saveDpgfModification(){
    if(!this.canEditOrSubmit) return;
    this.store.dispatch(actions.saveDpgfToServer({}));
    this.store.dispatch(actions.saveDpgfLotToServer({}));
    // segment event
    if(!environment.onPremise)window.analytics.track('change_dpgf_pricing');
  }
  public setArtisanMessage(message){
    this.archiMessage = message;
  }
  public finishDpgfModification(){
    if(this.authService.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    let formatedMessage = this.archiMessage.replace(/\n/g, '<br>');
    let sentPricingDate= new Date();
    let data =this.store.dispatch(actions.finishDpgfPricing({message : formatedMessage, sentPricingDate:sentPricingDate }));
    this.messageService.add({ key: 'toast', severity: 'success', summary: 'Super !', detail: 'Le chiffrage a été envoyé avec succès.', life: 5000 });
    
    // segment event
    if(!environment.onPremise)window.analytics.track('dpgf_pricing_sent');
    let _SELF = this;
    setTimeout(function(){
      _SELF.toogleSendChiffrageModal();
    }, 1500);
  }
  public fileEvent(fileInput: any) {
    const AWSService = AWS;
    const region = 'eu-central-1';
    const bucketName = 'bam-consultation-slickshot';
    const IdentityPoolId = 'eu-central-1:7a0af26b-a2d9-4bd5-aa01-dff3f719b7db';
    let files = fileInput.files ||[];
    this.loadingFiles = true;
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
    const s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: bucketName}
    });
    const ID = function () {
      return Math.random().toString(36).substr(2, 9);
    };
    let filesUpload = files.map(file=>s3.upload({ Key: file.name , Bucket: bucketName, Body: file, ACL: 'public-read'}).promise());
    Promise.all(filesUpload).then((filesUploaded)=> {
      this.loadingFiles = false;
      this.store.dispatch(actions.addInFileArtisan(filesUploaded));
    }).catch(err=>{
      console.log(err);
      this.loadingFiles = false;
    })
  }
  onDateChange(event){
    console.log('delivery date',event)
  }
  public removeFile(i){
    this.store.dispatch(actions.removeFileArtisan(i));
  }
  public getPdf(version = null){
    this.downloadingDoc = true;
    let id = (version && version.createdAt) ? version.id : version;
    let selectedDpgfPricingId = id ? id : this.dpgfPricingId;
    this.DpgfPostesService.downloadDevisPdf(this.dpgfPricingId, {},"", {},this.code)
      .subscribe(
          (data) => {
              saveAs(data.data.data, `${data.data.pdfName}.pdf`);
              this.downloadingDoc = false;
          },
          err => {
            this.downloadingDoc = false;
          },
      );
  }
  public getCctp(){
    this.dpgf$.subscribe(val => { this.dpgfPricing = val; });
    this.documentType = 'cctp';
    $("#print-dpgf-devis").modal('show');
  }
  public downloadChiffrageExcel(){
    this.downloadingDoc = true;
    let fileName;
    let version;
    this.dpgf$.subscribe(val => {
      version = this.getIncide(val.pricingVersion);
      fileName = `Devis - ${val.nomPrenom} - Ind-${version}`;
    });
    this.DpgfPostesService.downloadArtisanChiffrageExcel(this.dpgfPricingId, [], this.code)
      .subscribe(
          (data) => {
            var zip = new JSZip();
            zip.loadAsync(data.data).then((data) => {
              return zip.generateAsync({type:"blob"})
            }).then(function(content) {
              saveAs(content, `${fileName}.xlsx`);
            });
            this.downloadingDoc =false;
          },
          err => {
            this.downloadingDoc = false;
          },
      );
  }
  public getIncide(number){
    var n = number-1;
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }
  public openLot(lotId){
    this.openLotJson = {lotId : lotId}
  }
  public back(){
    this.location.back()
  } 
  public scrollTo(element){
    $('html, body').animate({
      scrollTop: ($("#" +element).offset().top - 60)
    });
  }

  public openDevisPopup(){
    this.dpgf$.subscribe(val => { this.dpgfPricing = val; });
    this.documentType = 'devis';
    $("#print-dpgf-devis").modal('show');
  }

  public closeDevisPopup(){
    $("#print-dpgf-devis").modal("hide");
  }
  public remiseButtonState (){
    this.remiseButton = !this.remiseButton
    if(!this.remiseButton){
      this.discountUnite='€';this.discountAmount = 0;
      this.store.dispatch(actions.updateDiscount({discountAmount:this.discountAmount,discountUnite:this.discountUnite}));
    }
  }
  

  public ammountChange(event){
    let discount= {
      discountAmount:this.discountAmount,discountUnite:this.discountUnite
    }
    this.store.dispatch(actions.updateDiscount(discount));
    if(event.key=='Enter')$('.discount-input').blur()
  }
  public updateAgencyInfo(data){
    if(!data['_id']) return;
    let body = {...data};
    delete body._id;

    this.subscriptions.push(
      this.artisanService.update(data['_id'], body)
        .subscribe(
          data => {
            // console.log('Kdima : ', body);
          },
          err => {
            console.log('updateAgencyInfo : ', err);
          },
        )
    );
  }
  public updateCeInviteFacticeArtisanInfo(data){
    let id = data["ceProjectInviteId"];
    let body = {facticeArtisanInfo: data["artisanData"]};
    this.subscriptions.push(
      this.ceProjectInvitesService.setCeInvite(id, body)
        .subscribe(
          data => {
            // console.log(data);
          },
          error => {
            console.log('updateCeInviteFacticeArtisanInfo : ', error);
          },
        )
    );
  }
  public showConfirmRefuseInvitationModal(){
    $("#confirm-refuse-invite-modal").modal("show");
  }
  public refuseCeProjectInvitation(){
    let refusalDate=new Date();
    let data = { artisanRaisonRefus : this.artisanRaisonRefus, refusalDate: refusalDate};
    let ceProjectInviteId = this.ceProjectInvite["_id"];
    
    this.acceptingOrRefusingLoader = true;
    this.subscriptions.push(
      this.ceProjectInvitesService.refusArtisanInvite(ceProjectInviteId, data)
        .subscribe(
          () => {
            this.acceptingOrRefusingLoader = false;
            this.showAcceptOrRefuseButtons = false;
            $("#confirm-refuse-invite-modal").modal("hide");
            this.back();
          },
          error => {
              this.ErrorService.manageError(error,'');
          })
    )
  }
  public acceptCeProjectInvitation(){
    let data = { artisanMot : this.artisanMot,deliveryDate:this.deliveryDate};
    let ceProjectInviteId = this.ceProjectInvite["_id"];
    this.acceptingOrRefusingLoader = true;    

    this.subscriptions.push(
      this.ceProjectInvitesService.accepteArtisanInvite(ceProjectInviteId, data)
        .subscribe(
          (data) => {
            this.acceptingOrRefusingLoader = false;
            this.showAcceptOrRefuseButtons = false;
            this.canEditOrSubmit = true;
            $("#accept-invite-to-edit-modal").modal("hide");
          },
          error => {
            this.ErrorService.manageError(error,'');
          }
        )
    );
  }
  /// check if the current User is Not Authenticated
  private isUserAGuest(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) || undefined;

    return currentUser ? false : true;
  }
  public openAcceptInviteToEditModal(){
    $("#accept-invite-to-edit-modal").modal("show");
  }

  public openUploadArtisanFiles(){
    if(this.authService.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    if(!this.canEditOrSubmit) return;

    $('.attachments-card p-fileUpload input').click();
  }
  public toogleSendChiffrageModal(){
    if(this.authService.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    if(!this.canEditOrSubmit) return;

    $("#send-chiffrage-popup").modal('toggle');
  }
  public showErrorLocationModal(event){
    this.documentsErrorInfo = event;
    this.openDocumentErrorsModal();
  }
  private openDocumentErrorsModal(){
    $("#documents-errors-modal").modal('show');
  }

  public ngOnDestroy(): void {
    clearInterval(this.timeInterval);
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
