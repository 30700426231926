import { Component } from '@angular/core';
import { ActivatedRoute , Params, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';

// RxJs
import { Subscription, of } from 'rxjs';


// NgRx
import { Store , select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';
import * as actions from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import { ModeClass } from 'src/app/dpgf/dce-edition/_store/mode'

// Services
import { AuthService, DpgfPostesService, CeProjectService } from '../../../_services/index';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

// External Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';


// Imported Components
import { HeaderComponent } from 'src/app/all/header/header.component';
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { DceTopNavBarComponent } from 'src/app/architect/dce/dce-top-nav-bar/dce-top-nav-bar.component';
import { LotsListViewComponent } from 'src/app/architect/dce/lots-list-view/lots-list-view.component';
import { DpgfFoldersComponent } from 'src/app/dpgf/dce-edition/dpgf-folders/dpgf-folders.component';
import { TableDpgfComponent } from '../../../dpgf/dce-edition/table-dpgf/table-dpgf.component';
import { RightBarConsultAddEntrepriseComponent } from 'src/app/dpgf/dce-edition/right-bar-consult-add-entreprise/right-bar-consult-add-entreprise.component';

import { ArchiEditDocumentComponent } from 'src/app/dpgf/dce-edition/archi-edit-document/archi-edit-document.component';
import { DpfgDocsPopupComponent } from '../../../architect/dpfg-docs-popup/dpfg-docs-popup.component'

// design system components
import { UserOnWithoutLicenseModalComponent } from 'src/app/_design_system_components/user-on-without-license-modal/user-on-without-license-modal.component';

// Modals
import { ConfirmRemoveLotModalComponent } from 'src/app/architect/dce/confirm-remove-lot-modal/confirm-remove-lot-modal.component';
import { DpgfVersionsModalComponent } from 'src/app/architect/dce/dpgf-versions-modal/dpgf-versions-modal.component';
import { DpgfImportLotPopupComponent } from 'src/app/dpgf/dce-edition/dpgf-import-lot-popup/dpgf-import-lot-popup.component';
import { ConfirmRestoreDceVersionModalComponent } from 'src/app/architect/dce/confirm-restore-dce-version-modal/confirm-restore-dce-version-modal.component';
import { ConfirmModalComponent } from 'src/app/all/confirm-modal/confirm-modal.component';
import { MaxLibraryPostsModalComponent } from 'src/app/architect/max-library-posts-modal/max-library-posts-modal.component';
import { ModalConsultEntrepriseComponent } from 'src/app/dpgf/dce-edition/modal-consult-entreprise/modal-consult-entreprise.component';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs-compat';


declare var $: any;

@Component({
  selector: 'app-archi-dpgf-new',
  standalone: true,
  imports: [
    NgSharedModule,
    // Components
    LotsListViewComponent,
    DpgfFoldersComponent,
    HeaderComponent,
    CeProjectNavComponent,
    DceTopNavBarComponent,
    ConfirmRemoveLotModalComponent,
    DpgfVersionsModalComponent,
    DpgfImportLotPopupComponent,
    TableDpgfComponent,
    RightBarConsultAddEntrepriseComponent,
    ConfirmRestoreDceVersionModalComponent,
    ConfirmModalComponent,
    MaxLibraryPostsModalComponent,
    ArchiEditDocumentComponent,
    DpfgDocsPopupComponent,
    ModalConsultEntrepriseComponent,
    UserOnWithoutLicenseModalComponent
  ],
  templateUrl: './archi-dpgf-new.component.html',
  styleUrl: './archi-dpgf-new.component.scss'
})
export class ArchiDpgfNewComponent {

  public onPremise: boolean = false;
  
  // Global
  public modeList = new ModeClass().modeList;

  public currentView = 'redaction'; // redaction, documents, exportDocs
  public mode:string = "archi";
  public consulteSidebarOpened: boolean = false;


  // Auth User
  public licence: any;
  
  // Dpgf
  public dpgf: any = {};
  public dpgfId :string;
  public nbLines: Number = 0;
  public showTableDpgf :boolean = false;
  public openSpecificLot :any;
  public triggerAddNewLot :boolean = false;
  public selectedLotIdToRemove: string;
  public selectedLotTitleToRemove: string;
  public maxLibraryPostsModalshowLibraryText: boolean = false;
  public showImportDpgfLotsModal: boolean = false;
  public showConsulteEnterpriseModal: boolean = false;
  public dpgfChanges: any = 0;
  
  // Versions
  public nextIndiceModalTitle:string = "Création d'un nouvel indice";
  public nextIndiceModalBody:string = "Vous êtes sur le point de changer l'indice. L'indice actuel ne pourra plus être modifié, souhaitez-vous continuer";
  public showDpgfVersionModal :boolean = false;
  public dpgfCurrentVersion:Number;
  public selectedDpgfMinorVersion:string;  

  /// Project
  public ceProjectId;
  public projectData;
  public isAutomateNumberIsChecked: boolean = false;

  // Export Docs
  public exportView:string = "export"; // export, perso
  public callExport: boolean = false;
  public exportSaving: boolean = false;
  public checkedPostsNumber: number;

  // Obervables
  public dpgf$;
  public loadingData$;
  public saving$: Observable<boolean> = of(false);
  public changes$: Observable<any> = of(0);
  public exportDoc$: Observable<boolean> = of(false);
  public nbLines$;
  public total$;
  public totalTTC$;
  public projectLicence;

  // Views Data
  public lotsListViewData: any = {};

  // pdf preview
  public pdfPreviewSrc:string = "";

  public pdfPreviewInputsData: any = {};currentUser;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private title: Title,
    private store: Store,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    public dpgfPostesService: DpgfPostesService,
    public ceProjectService: CeProjectService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
  }

  ngOnInit(){
    this.onPremise = environment.onPremise;

    this.title.setTitle('AGLO - DCE');
    this.getAuthUserLicence(); // get Licence
    this.selectStoreData(); // select
    this.dispatchInitialActions(); // dispatch
    if(!environment.onPremise)window.analytics.page('pageview_DCE');
  }


  //////////////////////////////////////////////////////////////
  /// Store
  //////////////////////////////////////////////////////////////
  private dispatchInitialActions(){
    // 1- Load Dpgf
    this.getDPGF();
  }

  public getDPGF(){
    this.store.dispatch(actions.setAny( {name  : "loadingData" , value : true}));
    this.store.dispatch(actions.setType("dpgf"));
    this.route.params.forEach((params: Params) => {
      if (params['dpgfId']) {
        this.dpgfId = params['dpgfId'];
        this.store.dispatch(actions.loadDpgf( {dpgfId : this.dpgfId}));
      }
    })
  }


  private selectStoreData(){
    this.dpgf$ = this.store.pipe(select(fromSelectors.getDpgf));
    this.loadingData$ = this.store.pipe(select(fromSelectors.getAny , {name : "loadingData"} ));
    this.saving$ =  this.store.pipe(select(fromSelectors.getAny , {name : "savingData"} ));
    this.changes$ = this.store.pipe(select(fromSelectors.getAny, {name : "changes"}));
    this.exportDoc$ = this.store.pipe(select(fromSelectors.getAny, {name : "exportDoc"}));
    this.nbLines$ = this.store.pipe(select(fromSelectors.getTotal, { name: "nbLines" }))
    this.store.pipe(select(fromSelectors.getAny,{name:'projectLicence'})).subscribe((data)=>{
      this.projectLicence = data;
    })
    this.total$ = this.store.pipe(select(fromSelectors.getTotal, { name: this.modeList[this.mode].totalName }));
    this.totalTTC$ = this.store.pipe(select(fromSelectors.getTotal, { name: this.modeList[this.mode].totalttcName }));

    this.store.pipe(select(fromSelectors.getAny , {name : "openNextVersion"} )).subscribe((open)=>{
      if(open)this.openNextVersion();
    });

    this.store.pipe(select(fromSelectors.getAny , {name : "openConflitLot"} )).subscribe((conflit)=>{
      if((conflit||{}).open)this.openConflit(conflit);
    })

    // after docs finished downloading set (callExport) to false (otherwise you'll download docs only once)
    this.store.pipe(select(fromSelectors.getAny, {name : "exportDoc"})).subscribe((exportDoc)=>{
      if(!exportDoc){
        this.callExport = false;
      }
    });
    // number of checked posts
    this.store.pipe(select(fromSelectors.getAny, {name : "checkedPostsNumber"})).subscribe((checkedPostsNumber)=>{
      this.checkedPostsNumber = checkedPostsNumber;
    });

    this.store.pipe(select(fromSelectors.getAny, {name : "isAutomatic"})).subscribe((isAutomatic)=>{
      this.isAutomateNumberIsChecked = isAutomatic;
    });    

    // project
    this.selectProjectData();
  }
  public openConflit(conflit){
    var date = new Date(conflit.at);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    this.confirmationService.confirm({
            header : "Conflit",
            message: `${conflit.user} a modifié ce lot à ${formattedTime} ! Voulez-vous actualiser le lot pour récupérer les modifications effectuées ?`,
            acceptLabel : "Actualiser",
            rejectLabel : "Ne pas enregistrer",
            accept: () => {
              this.store.dispatch(actions.loadSameDpgfLot({}));
              this.store.dispatch(actions.setLoadingDpgfLot(true));
              //this.store.dispatch(actions.saveDpgfLotToServer({goNext : "yes"}));
            },
            reject: () => {
              //this.store.dispatch(actions.setAny( {name  : "openNextVersion" , value : false}));
            }
        });
  }
  private selectProjectData(){
    this.store.pipe(select(fromSelectors.getDpgf)).subscribe((dpgf)=>{
      if(!dpgf._id) return;
      this.dpgf = dpgf;

      if(this.authService.currentEnvironment){
        let collaborateur = [];
        collaborateur = [...dpgf.projectData.collaborateurs]
        collaborateur.push({_id:dpgf.projectData.user})
        if(dpgf.projectData.projetSensible && !collaborateur.some(i=> i._id == this.currentUser.id))this.router.navigateByUrl('/');
      }
      this.projectData = dpgf.projectData;
      // this.isAutomateNumberIsChecked = dpgf.projectData.isAutomatic;
      this.dpgfCurrentVersion = dpgf.currentVersion;
      this.ceProjectId = dpgf.ceProject;      

      this.fillLotsListViewData(dpgf);

      if(dpgf.nbLines){
        this.nbLines = dpgf.nbLines;
      }

    });
  }
  public addSelectedLots(lots){
    let clonedLots = JSON.parse(JSON.stringify(lots));
    this.store.dispatch(actions.addLot({...clonedLots, isAutomatic: this.isAutomateNumberIsChecked}));
  }
  public saveDpgfModification(){
    this.store.dispatch(actions.saveDpgfToServer({}));
    this.store.dispatch(actions.saveDpgfLotToServer({}));
    // segment
    if(!environment.onPremise)window.analytics.track('update_dpgf',{properties:{acValue: 'update_dpgf'}});
  }
  public exportDocs(){
    this.store.dispatch(actions.setExportDoc({export:true}));
    this.callExport = true;
  }
  public setDPGFAttribut(data){
    this.store.dispatch(actions.setDpgfAttribut(data));
    this.store.dispatch(actions.addChange({}));
  }

  //////////////////////////////////////////////////////////////
  /// UI
  //////////////////////////////////////////////////////////////
  public setPageTitle(){
    this.title.setTitle('Aglo - Editer Mon DPGF' );
  }
  public changeView(view){
    if(view == this.currentView) return; // if the clicked view is the currently selected one do nothing

    // if current view is dpgfDocs (load pdfpreview)
    if(view == "exportDocs"){
      // load initial prd preview page
      // this.loadPdfPreviewPage({});
    }

    this.currentView = view;
    this.showTableDpgf = false;
  }
  public changeExportDocsView(view){
    this.exportView = view;
  }
  public hideTableDpgf(){
    this.currentView = 'redaction';
    this.showTableDpgf = false;
    this.triggerAddNewLot = false;
  }
  public openConfirmRemoveLotModal({lotId, lotTitle}){
    this.selectedLotIdToRemove = lotId;
    this.selectedLotTitleToRemove = lotTitle;    
    $("#confirm-remove-lot-modal").modal('show');
  }
  public duplicateLot(lot){
    let dpgfMaxPosts = (this.authService.premuimFeatures[this.licence]||{}).lineNb;

    // if the user's licence is without
    if(this.licence == "without" && this.projectLicence=='none'){
      $('#upgrade-modal').modal('show');
      return;
    }

    /// did the user reached his Max number of posts for his licence
    const maxNumberOfPostsForLicenceIsReached = ['plus','plus-trim','plus-annuel'].includes(this.licence) && this.nbLines >= dpgfMaxPosts;

    if(maxNumberOfPostsForLicenceIsReached){
      this.maxLibraryPostsModalshowLibraryText = false;
      $('#max-posts-modal').modal('show');
      return;
    }
    
    const newLot = JSON.parse(JSON.stringify(lot));
    this.store.dispatch(actions.addLot({lot :[newLot] ,dpgfId : this.dpgfId, isAutomatic: this.isAutomateNumberIsChecked}));     

    if(!environment.onPremise)window.analytics.track('duplicate_lot',{properties:{acValue: 'duplicate_lot'}});
  }
  public showConfirmRestoreDceVersionModal(selectedDpgfMinorVersionId){
    this.selectedDpgfMinorVersion = selectedDpgfMinorVersionId;
    if(this.licence == 'without' && this.projectLicence == 'none'){
      $('#upgrade-modal').modal('show');
      return;
    }

    $("#confirm-restore-dce-version-modal").modal("show");
  }
  public showConfirmIndiceModal(){
    $("#confirm-next-indice-modal").modal('show');
  }
  public openIndiceModal(){
    this.showDpgfVersionModal = true;

    setTimeout(() => {
      $("#versions-modal").modal('show');
    }, 100);
  }
  public openImportLotModal(){

    if(this.licence == "without"){
      $("#user-on-without-license-modal").modal('show');
      return;
    }

    this.showImportDpgfLotsModal = true;
    
    setTimeout(() => {
      $('#import-lot').modal('show');
    }, 100);
  }

  public toggleSidebar(){
    this.consulteSidebarOpened = !this.consulteSidebarOpened;
  }
  public openConsulteBoosterModal(){
    this.showConsulteEnterpriseModal = true;

    setTimeout(() => {
      $("#consult-booster").modal('show');
      if(!environment.onPremise)window.analytics.track('boost_consultations_dce',{properties:{acValue: 'boost_consultations_dce'}});
    }, 100);
  }

  //////////////////////////////////////////////////////////////
  /// Functionality
  //////////////////////////////////////////////////////////////
  public confirmRemoveLot(){
    this.store.dispatch(actions.removeLot({lotId : this.selectedLotIdToRemove, isAutomatic: this.isAutomateNumberIsChecked}));
    if(!environment.onPremise)window.analytics.track('delete_lot',{properties:{acValue: 'delete_lot'}});
    if(this.isAutomateNumberIsChecked){              
      setTimeout(() => {
        this.updateAllLotsIndex();
      }, 1000);
    }
  }
  private updateAllLotsIndex(){
    let dpgfId = this.dpgfId;
    this.subscriptions.push(
      this.dpgfPostesService.updateDpgfLotsIndex(dpgfId).subscribe(
        data => {
          // console.log("updateAllLotsIndex : ", data);
        },
        err => {
          console.log("updateAllLotsIndex : ", err);
        }
      )
    );
  }
  public getAuthUserLicence(){
    this.licence = this.authService.licence || "base";
  }  
  private fillLotsListViewData(dpgf){
    let { nbLines, totalHt, totalTtc } = dpgf;
    
    const { data, totalFinishedLots } = this.formatDpgfLots(dpgf["data"]);

    this.lotsListViewData = {
      nbLines,
      data,
      totalFinishedLots,
      totalHt, 
      totalTtc
    };
  }
  private formatDpgfLots(data){
    let totalFinishedLots = 0;

    for(let i = 0; i < data.length; i++){
      let currentLot = data[i];
      const { nbLines, nbLineDoneArchi, numberOfPostsWithCctp, sousLots, status } = currentLot;

      // if lot status is finished
      const lotStatusIsTermine = status && status == "termine";
      if(lotStatusIsTermine){
        totalFinishedLots++;
      }

      for(let j = 0; j < sousLots.length; j++){
        const { postes } = sousLots[j];
        for(let k = 0; k < postes.length; k++){
          const { cctp } = postes[k];
          // add number of posts with cctp to Each Lot
          if(cctp){
            currentLot["numberOfPostsWithCctp"]+=1;
          }
        }
      }

      // Set Percentage of Posts with CCTP
      const percentageOfPostsWithCctp = (numberOfPostsWithCctp/nbLines)*100;
      currentLot["percentageOfPostsWithCctp"] = Math.round(percentageOfPostsWithCctp);

      // Set percentage of estimation
      const percentageOfEstimattion = (nbLineDoneArchi/nbLines)*100;
      currentLot["percentageOfEstimation"] = Math.round(percentageOfEstimattion);

    }

    // sort lots by indexLot
    data = this.sortLots(data);

    return {
      data,
      totalFinishedLots
    };
  }
  public sortLots(lots){
    if(this.isAutomateNumberIsChecked)return lots;
    return lots.sort((a,b) =>  a.indexLot - b.indexLot);
  }
  public openNextVersion(){
    this.confirmationService.confirm({
      message: `L'indice actuel du DCE a été envoyé à une ou plusieurs entreprise, pour apporter des modifications à votre DCE nous allons donc créer un nouvel indice. Ce nouvel indice n'est pas envoyé automatiquement aux entreprises, vous êtes libre de leur envoyer depuis l'onglet "Entreprises"`,
      accept: () => {
        this.store.dispatch(actions.saveDpgfLotToServer({goNext : "yes"}));
        this.store.dispatch(actions.setAny( {name  : "openNextVersion" , value : false}));
        setTimeout(()=>{
          this.store.dispatch(actions.saveDpgfToServer({goNext : "yes"}));
        },1500)
        // segment
        if(!environment.onPremise)window.analytics.track('new_version_dpgf_created',{properties:{acValue: 'new_version_dpgf_created'}});
      },
      reject: () => {
        this.store.dispatch(actions.setAny( {name  : "openNextVersion" , value : false}));
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Modifications non enregistrées', detail: "Vos modifications n'ont pas été enregistrées", life: 5000 });
      }
    });
  }

  public updateLotStatus({lotId, status}){
    const dpgfId = this.dpgfId;
    const body = {
      status
    };

    this.subscriptions.push(
      this.dpgfPostesService.setDpgfLotStatus(dpgfId, lotId, body)
        .subscribe({
          next: (res) => {
            this.store.dispatch(actions.updateLotStatus({lotId, status}));
            if(!environment.onPremise)window.analytics.track('change_lot_status',{properties:{acValue: 'change_lot_status'}});
          },
          error: (err) => {
            console.log("updateLotStatus : ", err);
          },
        })
    );
  }
  public addLot(){
    if(this.licence == "without"){
      $("#user-on-without-license-modal").modal('show');
      return;
    }

    this.triggerAddNewLot = true;
    this.showTableDpgf = true;
  }
  public openLot(lotId){
    this.openSpecificLot = {lotId: lotId};
    this.showTableDpgf = true;
  }
  public setDpgfChange(dpgf){
    this.dpgfChanges++;
    this.dpgf=dpgf;
  }
  public changeExportButton(value){
    this.exportSaving = value;
  }
  public projectOverlayPanelClosed(body){
    // this.isAutomateNumberIsChecked = body.isAutomateNumberIsChecked;
    this.store.dispatch(actions.setIsAutomatic({isAutomatic: body.isAutomateNumberIsChecked}));

    this.updateProject(body);
    
    if(body.isAutomateNumberIsChecked){
      this.store.dispatch(actions.resetLotsIndex({}));
      
      this.store.pipe(
        select(fromSelectors.getAny, {name : "dpgf"})).subscribe(({_id, data})=>{
          if(data){
            let lotsData = [];
            for(let i = 0; i < data.length; i++){
              let lot = data[i];
              lotsData.push({lotId: lot.id, indexLot: lot.indexLot});
            }            
            this.dpgfPostesService.updateDpgfLotOrder(_id,{lots : lotsData}).subscribe(res => {
            });
          }
      }).unsubscribe();
    }
  }

  /////////////////////////////////
  /// Checked Posts functionality
  /////////////////////////////////
  public duplicateManyPosts(){
    let dpgfMaxPosts = this.authService.premuimFeatures[this.authService.licence].lineNb;
    let licence = this.authService.licence;
    if(licence == 'without'){
      $('#upgrade-modal').modal('show');
    }else if(["plus","plus-trim","plus-annuel"].includes(this.licence) && this.nbLines >= dpgfMaxPosts){
      $('#max-posts-modal').modal('show');
    }else{
      this.confirmationService.confirm({
        message: `Êtes-vous sûr(e) de vouloir dupliquer ces ${this.checkedPostsNumber} postes ?`,
        header: 'Duplication de postes',
        key: 'duplicatePosts',
        acceptLabel : "Confirmer",
        rejectLabel : "Annuler",
        blockScroll:false,
        accept: () => {
          this.store.dispatch(actions.duplicateManyPosts({}));
        }
      });
    }
  }

  public deleteManyPosts(){
    if(this.licence == 'without'){
      $('#upgrade-modal').modal('show');
      return;
    }

    this.confirmationService.confirm({
      message: `Êtes-vous sûr(e) de vouloir supprimer ces ${this.checkedPostsNumber} postes ?`,
      header: 'Suppression de postes',
      key: 'deletePosts',
      acceptLabel : "Confirmer",
      rejectLabel : "Annuler",
      blockScroll:false,
      accept: () => {
        this.store.dispatch(actions.removeManyPosts({}));
      }
    });
  }

  public sendDbPosts(){
    if(this.licence == 'without'){
      $('#upgrade-modal').modal("show");
      return;
    }
    
    this.store.dispatch(actions.setExportPostsDb({manyPost:true}));
    $('#import-post').modal('toggle');
  }

  public movePoste(){
    if(this.licence == 'without'){
      $('#upgrade-modal').modal('show');
      return;
    }

    $('#dpgf-navigation').modal('toggle');
  }

  public flagPosts(){
    if(this.licence == 'without'){
      $('#upgrade-modal').modal('show');
      return;
    }

    this.store.dispatch(actions.flagManyPosts({}));
  }

  //////////////////////////////////////////////////////////////
  /// Http Requests
  //////////////////////////////////////////////////////////////
  public confirmRestoreVersion(){
    $("#versions-modal").modal("hide");

    this.subscriptions.push(
      this.dpgfPostesService.restoreDpgfMinorVersion(this.selectedDpgfMinorVersion).subscribe(()=>{
        $("#confirm-restore-dce-version-modal").modal("hide");

        this.messageService.add({ key: 'toast', severity: 'success', summary: 'Version restaurée', detail: 'La version a bien été restaurée', life: 5000 });
        this.store.dispatch(actions.loadDpgf( {dpgfId :  this.dpgfId}));
        this.store.dispatch(actions.emptyLot({}));
      })
    );
  }
  public confirmGoToNextIndice(){
    let query = "?gonext=yes&";
    this.subscriptions.push(
      this.dpgfPostesService.createNewDpgfIndice(this.dpgfId,{}, query)
        .subscribe(
          data => {
            $("#confirm-next-indice-modal").modal('hide'); 
            $("#versions-modal").modal('hide'); 
            this.getDPGF();
            this.messageService.add({ key: 'toast', severity: 'success', summary: 'Indice mis à jour', detail: 'Indice mis à jour', life: 5000 });
          },
          error => {
            console.log("confirmGoToNextIndice : ", error);
          }
        )
    );
  }
  public saveDocModification(){
    this.exportSaving=true;

    if(!this.dpgfChanges){
      setTimeout(()=>{
        this.exportSaving=false;
      },100)
    }
    else{
      this.subscriptions.push(
        this.dpgfPostesService.patchDpgfNoVersion(this.dpgfId, this.dpgf).subscribe(
          (data)=>{
            // this.dpgfChanges=0;
            // this.exportSaving=false;
          },
          (err)=>{
            // this.exportSaving=false;
          }
        )
      );
      

    }
  }
  public updateProject(body){
    this.subscriptions.push(
      this.ceProjectService.update(this.ceProjectId, body)
        .subscribe(
          data => {
              // this.savingProject = false;
              // this._notificationService.success('Titre de Projet modifié','Votre titre de projet a été modifié.', {timeOut: 5000})
              // segment event
              if(!environment.onPremise)window.analytics.track('update_project_info',{properties:{acValue: 'update_project_info'}});
          },
          error => {
              console.log("error",error)
              // this.savingProject = false;
          })
    );
  }
  public updateTrigger(){
    this.triggerAddNewLot = false;
  }
  public loadPdfPreviewPage(body){
    this.subscriptions.push(
      this.dpgfPostesService.downloadPdfPreview(this.dpgfId, body)
      .subscribe(
        data => {
          this.pdfPreviewSrc = data.data.data;
        },
        err => {
          console.log("loadPdfPreviewPage : ", err);
        }
      )
    )
  }
  public reloadPdfPreview(body){
    const {
      maitreOeuvre, maitreOuvrage, currentVersion, operationText, pricingDate, pricingVersion, pricingDateArtisan, dceDate,
      docData, projectData,
    } = this.dpgf;

    let dpgfData = {
      maitreOeuvre, maitreOuvrage, currentVersion,
      operationText, pricingDate, pricingVersion,
      pricingDateArtisan, dceDate, docData,
    }

    body = {
      ...body,
      projectTitle: projectData.title || "",
      projectImage: projectData.mainImage || "", 
      dpgf: dpgfData
    };

    this.loadPdfPreviewPage(body);
  }
  
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
