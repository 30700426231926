<form [formGroup]="dpgfForm" novalidate>

  <div class="floating-card" id="q6" (click)="scrollTo('#q6')">
    <label class="question-label">Taux de TVA par default en %</label>
    <div class="row">
      <input formControlName="defaultTva" placeholder="TVA par default" type="text" class="form-control" >
    </div>
    <small *ngIf="formErrors.defaultTva" class="text-danger">
      {{ formErrors.defaultTva }}
    </small>
  </div>

  <div class="floating-card" >
    <label class="question-label">Sélectionnez les lots que vous voulez décrire :</label><br>
    <span class="sub-label">Ces lots vont être créés automatiquement dans votre DPGF</span>
    <div class="row">
      <div class="ng-radio-button">
        <app-lot-select [options]="travauxListe" formControlName="prefillData"  multiple="multiple" (onChange)="changeLot($event)"></app-lot-select>
      </div>
    </div>
  </div>

  <!-- <div  class="floating-card" *ngIf="showLot">
    <label class="question-label">Sélectionnez les lots que vous voulez décrire :</label><br>
    <span class="sub-label">Ces lots vont êtres créés automatiquement dans votre DPGF</span>
    <div class="row">
      <div class="ng-radio-button">
        <app-checkbox-simple styleClass="full-width-check" formControlName="prefillData"  [options]="travauxListe" multiple="multiple" (onChange)="changeLot($event)"></app-checkbox-simple>
      </div>
    </div>
  </div> -->
</form>

  <div  class="floating-card" *ngIf="questions.demolition" id="q8" (click)="scrollTo('#q8')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/demolition__depose.png" height="40">
      Démolition/Dépose - Sélectionnez les éléments concernés :
    </label>
    <br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Dans l'ensemble</span>
        <app-checkbox-simple  [(ngModel)]="poste.demolition.ensemble"  [options]="demolutionOption.ensemble" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Dans la cuisine</span>
        <app-checkbox-simple  [(ngModel)]="poste.demolition.cuisine"   [options]="demolutionOption.cuisine" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Dans la salle de bain</span>
        <app-checkbox-simple  [(ngModel)]="poste.demolition.sdb"   [options]="demolutionOption.sdb" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>


  <div  class="floating-card" *ngIf="questions.menuiserie_interieure" id="q13" (click)="scrollTo('#q13')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/menuiserie_interieure__agencement.png" height="40">
      Menuiserie interieure - Sélectionnez les éléments à poser ou à créer :
    </label>
    <br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Pose</span>
        <app-checkbox-simple  [(ngModel)]="poste.menuiserie_interieure.pose"  [options]="menuiserieInterieureOption.pose" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Création</span>
        <app-checkbox-simple  [(ngModel)]="poste.menuiserie_interieure.creation"   [options]="menuiserieInterieureOption.creation" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>

  <div  class="floating-card" *ngIf="questions.revetement_sol" id="q14" (click)="scrollTo('#q14')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/revetement_de_sol.png" height="40">
      Revêtement de sol - Sélectionnez les éléments à réaliser ou à poser :
    </label><br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Réalisation</span>
        <app-checkbox-simple  [(ngModel)]="poste.revetement_sol.realisation"  [options]="revetementSolOption.realisation" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Pose</span>
        <app-checkbox-simple  [(ngModel)]="poste.revetement_sol.pose"   [options]="revetementSolOption.pose" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>

  <div  class="floating-card" *ngIf="questions.peinture" id="q15" (click)="scrollTo('#q15')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/peinture__revetements_muraux__nettoyage.png" height="40">
      Peinture/Revêtements Muraux - Sélectionnez les éléments concernés :
    </label><br>
    <div class="row">
      <div class="ng-radio-button">
        <span class="title-radio">Par la peinture</span>
        <app-checkbox-simple  [(ngModel)]="poste.peinture.peinture"  [options]="peintureOption.peinture" multiple="multiple"></app-checkbox-simple>
      </div>
      <div class="ng-radio-button">
        <span class="title-radio">Par le revêtement mural </span>
        <app-checkbox-simple  [(ngModel)]="poste.peinture.revetement"   [options]="peintureOption.revetement" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>

  <div  class="floating-card" *ngIf="questions.electricite" id="q16" (click)="scrollTo('#q16')">
    <label class="question-label">
      <img class="icon-clique" src="assets/dpgf/Icon_DPGF/electricite.png" height="40">
      Electricité - Sélectionnez les éléments à poser :
    </label><br>
    <div class="row">
      <div class="ng-radio-button">
        <app-checkbox-simple  [(ngModel)]="poste.electricite.electricite"  [options]="electriciteOption" multiple="multiple"></app-checkbox-simple>
      </div>
    </div>
  </div>
