import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import {RouterOutlet} from '@angular/router';

// Services
import { AuthService } from 'src/app/_services';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

// Licenses modal
import { UserOnWithoutLicenseModalComponent } from 'src/app/_design_system_components/user-on-without-license-modal/user-on-without-license-modal.component';
import { UserOnProLicenseModalComponent } from 'src/app/_design_system_components/user-on-pro-license-modal/user-on-pro-license-modal.component';
import { UserOnProLicensePostProjectModalComponent } from 'src/app/_design_system_components/user-on-pro-license-post-project-modal/user-on-pro-license-post-project-modal.component';
import { UserOnProLicensePostLibraryModalComponent } from 'src/app/_design_system_components/user-on-pro-license-post-library-modal/user-on-pro-license-post-library-modal.component';
import { CstbLicenseModalComponent } from 'src/app/_design_system_components/cstb-license-modal/cstb-license-modal.component';

declare var profitwell;

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [ NgSharedModule ,MessageService],
  imports: [
    ToastModule,
    RouterOutlet,
    UserOnWithoutLicenseModalComponent,
    UserOnProLicenseModalComponent,
    UserOnProLicensePostProjectModalComponent,
    UserOnProLicensePostLibraryModalComponent,
    CstbLicenseModalComponent
  ]
})
export class AppComponent {
  title = 'app';
  public notificationsOptions = {
    position: ["bottom", "right"],
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose:true,
    animate:"fromRight",
    showProgressBar:false
  }
  public alertsOptions={}
  public confirmationsOptions={}

  constructor(
    private authService:AuthService
  ){}
  
  ngOnInit(): void {
    console.log("APP")
    this.authService.checkToken();
    this.authService.getUserLicence();
    this.emitToProfitwell();
    // when you're inside input number, and you scroll the value changes (this to disable it)
    this.diableInputNumberScrollChange();
  }
  private emitToProfitwell(){
    let currentUser = this.authService.getAuthUser();
    if(environment.production && currentUser){
      profitwell('start', { 'user_email': currentUser["email"] });
    }
  }

  private diableInputNumberScrollChange(){
    document.addEventListener("wheel", function(event){
      const numberInput = (<HTMLInputElement>document.activeElement);
      if (numberInput.type === "number") {
        numberInput.blur();
      }
    });
  }

}
