import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
// Services Imports
import { DpgfPostesService, ProductService, UserService } from '../../_services/index';
import { AuthService } from '../../_services/index';
import { MessageService } from 'primeng/api';
declare var fs: any;
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { select, Store } from '@ngrx/store';
import * as actions from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import { DocumentsErrorsModalComponent } from 'src/app/all/documents-errors-modal/documents-errors-modal.component';
import { DocFileIsDownloadedComponent } from '../doc-file-is-downloaded/doc-file-is-downloaded.component';
import { NeedSubscriptionPopupComponent } from '../need-subscription-popup/need-subscription-popup.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { environment } from 'src/environments/environment';
import * as fromSelectors from './../../dpgf/dce-edition/_store'

const _ = require('lodash');

declare var $;

@Component({
    standalone:true,
    selector: 'app-dpfg-docs-popup',
    imports:[
      NgSharedModule,
      NeedSubscriptionPopupComponent,
      DocumentsErrorsModalComponent,
      DocFileIsDownloadedComponent
    ],
    providers: [],
    templateUrl: './dpfg-docs-popup.component.html',
    styleUrls: ['./dpfg-docs-popup.component.scss'],
})
export class DpfgDocsPopupComponent implements OnInit, OnChanges {
  @Input() isAutomateNumberIsChecked:Boolean;
  @Input() licence:any;
  @Input() dpgf:any;
  @Input() callExport;
  @Input() pdfPreviewInputsData;
  @Output() sendDpgfEvent = new EventEmitter<any>();
  @Output() saveDPGFEvent = new EventEmitter<any>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() returnToDCETab = new EventEmitter<any>();
  public documentsErrorInfo: any = {};
  public ceProjectId:string = "";
  public selectedLot: any = {};
  public dpgfLot: [] = [];
  public downloadingDoc: boolean = false;
  public showExcelDropDown: boolean = false;
  private subscriptions: Array<Subscription> = [];
  public hasCstb = false;
  public checkedAll;
  public postsCount={};
  public docData={assistants:[],agencement:1,font:'Lato',color:'#3805F2',typeIndice:'Aglo',indice:'A',showLogo:true,showIndice:true,showDate:true,typeDoc:'cctp',format:'pdf',options:{}};
  hasDownloadExcel: boolean;
  public showDocFileDownloadedModal: boolean = true;
  public projectLicence: string='none';

  public onPermis: boolean = false;

  constructor(
    private DpgfPostesService: DpgfPostesService,
    private authService: AuthService,
    private userService: UserService,
    private store: Store,
    private messageService: MessageService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    // minarm
    this.onPermis = environment.onPremise;

    if(this.onPermis){
      this.docData.options["showPosts"] = true;
    }

    this.authService.features.subscribe((f)=>{
      this.hasCstb = f.cstb
      this.hasDownloadExcel= f.downloadExcel
    })
    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
    $("body").css("overflow", "hidden");

    this.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges){
      if(changes.dpgf){
        if(changes.dpgf.currentValue){
          this.filterDpgf();
          this.checkAll();
          this.docData=_.get(this.dpgf,'docData',{assistants:[],agencement:1,font:'Lato',color:'#6F74E8',typeIndice:'Aglo',indice:'A',showLogo:true,showIndice:true,showDate:true,typeDoc:'cctp',format:'pdf',options:{}});
          this.ceProjectId = _.get(this.dpgf, 'ceProject', undefined);
          // to remove for prod
          if(!this.docData.options) this.docData.options={};
          
          if(this.onPermis){
            this.docData.options["showPosts"] = true;
          }

          if(!this.docData.format) this.docData.format='pdf';
          if(!this.docData.typeDoc) this.docData.typeDoc='cctp'
        };
      }
      
      if(changes.callExport && changes.callExport.currentValue){
        this.exportDoc();
      }
  }

  public filterDpgf(){
    let dpgfLot =  this.dpgf.data.map(lot=>{
      return {lotId: lot.id, id:lot.title,indexLot: lot.indexLot,name:lot.title, requested:0, icon : (lot.icon||"")}
    }).filter(lot => lot.name != "")
    this.dpgfLot =  dpgfLot;
  }
  public lotChoosen(){
    let lots =Object.keys(this.selectedLot).filter((key) => this.selectedLot[key] != false);
    return lots.length === 0 ? false : true;
  }
  public chooseLot(lot){
    this.selectedLot[lot.lotId] = !this.selectedLot[lot.lotId];
  }
  checkAll(){
    if(this.checkedAll)this.uncheckAll();
    else {
      for(let lot of this.dpgfLot){
        if(lot['lotId']){
          this.selectedLot[lot['lotId']] = true;
        }
      }
      this.checkedAll=true;
    }
  }
  uncheckAll(){
    for(let lot of this.dpgfLot){
      this.selectedLot[lot['lotId']] = false;
    }
    this.checkedAll=false;
  }
  public filterSelectedLots(obj){
    let filterd = [];
    if(Object.keys(obj).length > 0){
      for(let key of Object.keys(obj)){
        if(key != 'undefined'){
          if(key && obj[key]){
            filterd.push(key);
          }
        }
      }
    }
    return filterd;
  }

  public changeDocData(value,type){
    if(!environment.onPremise)window.analytics.track('update_exports_settings',{properties:{acValue: 'update_exports_settings'}});
    if(value=='cctp' && this.docData.format=='excel') this.docData.format='word';
    if(value=='dpgf' && this.docData.format=='word') this.docData.format='excel';
    if(value == "recap") this.docData.format='pdf';
    if(value == 'product') this.docData.format='pdf';
    
    this.docData[type]=value;
    this.sendDpgfToParent()
  }
  public selectLot(id){
    if(this.selectedLot[id]==undefined)this.selectedLot[id]=true;
    else this.selectedLot[id]=!this.selectedLot[id];
    this.checkedAll=(Object.values(this.selectedLot).indexOf(false) == -1)
  }
  public changeOption(value){
    // if(value=="dqe" && this.docData.typeDoc!='dpgf' || _.get(this.docData,'options.estimatif',null)) return
    if(this.docData.options[value]==undefined)this.docData.options[value]=true;
    else this.docData.options[value]=!this.docData.options[value]
    if(value=='estimatif' && !this.docData.options['dqe']) this.changeOption('dqe');
    this.sendDpgfToParent()
  }
  sendDpgfToParent(){
    this.dpgf.docData=this.docData;
    this.sendDpgfEvent.emit(this.dpgf);
  }
  public exportDoc(){
    // save
    // this.exportEvent.emit(true);
    this.DpgfPostesService.patchDpgfNoVersion(this.dpgf._id,this.dpgf).subscribe(
      (data)=>{
        this.downloadDocs();
      },
      (err)=>{
        this.store.dispatch(actions.setExportDoc({export:false}));
        /// if the error is demo Only (download the documents)
        if(err && err.error && err.error.message.includes("Only demo")){
          this.downloadDocs();
        }
        console.log('err',err);
      }
    )
  }
  private downloadDocs(){
    if(this.docData.typeDoc=='dpgf' && this.docData.format=='pdf' && !this.docData.options['dqe']){
      if(this.docData.options['byLot']){
        this.downloadEachLot(this.docData.typeDoc, this.docData.format, false, false);
      }else{
        this.downloadDPGFPdf();
      }
      if(!environment.onPremise)window.analytics.track('download_dpgf',{properties:{acValue: 'download_dpgf',model:this.dpgf.docData}});
    }
    else if(this.docData.typeDoc=='dpgf' && this.docData.format=='pdf' && this.docData.options['dqe'] && !this.docData.options['estimatif']){
      if(this.docData.options['byLot']){
          this.downloadEachLot(this.docData.typeDoc, this.docData.format, true, false);
      }else{
        this.downloadDQEPdf()
      }
      if(!environment.onPremise)window.analytics.track('download_dpgf',{properties:{acValue: 'download_dpgf',model:this.dpgf.docData}});
    }
    else if(this.docData.typeDoc=='dpgf' && this.docData.format=='pdf' && this.docData.options['estimatif']){          
      if(this.docData.options['byLot']){
          this.downloadEachLot(this.docData.typeDoc, this.docData.format, false, true);
      }else{
         this.downloadEstimatifPdf()
      }
      if(!environment.onPremise)window.analytics.track('download_dpgf',{properties:{acValue: 'download_dpgf',model:this.dpgf.docData}});
    }
    else if(this.docData.typeDoc=='dpgf' && this.docData.format=='excel' && this.hasDownloadExcel){
      if(!environment.onPremise)window.analytics.track('download_dpgf',{properties:{acValue: 'download_dpgf',model:this.dpgf.docData}});
      this.downloadDCEExcel(this.docData.options['estimatif'])
    } 
    else if(this.docData.typeDoc=='cctp' && this.docData.format=='pdf') {
      if(this.docData.options['byLot']){
          this.downloadEachLot(this.docData.typeDoc, this.docData.format);
      }else{
        this.downloadCCTPPdf()
      }

    }
    else if(this.docData.typeDoc=='cctp' && this.docData.format=='word' && this.hasDownloadExcel){
      if(!environment.onPremise)window.analytics.track('download_cctp',{properties:{acValue: 'download_cctp',model:this.dpgf.docData}});
      if(this.docData.options['byLot']){
        this.downloadEachLot(this.docData.typeDoc, this.docData.format);
      }else{
        this.downloadCctpDocx();
      }


      /// show that modal after downloading the docx
      this.showDocFileIsDownloadedModal();
    }
    else if(this.docData.typeDoc=='recap' && this.docData.format=='pdf') {
      if(!environment.onPremise)window.analytics.track('download_recap_estimatif',{properties:{acValue: 'download_recap_estimatif'}});
      this.downloadEstimatifPdf()
    }
    else if(this.docData.typeDoc=='product' && this.docData.format=='pdf') {
      this.generateProductPDF()
    }
    else {
      if(this.licence == "without" && this.projectLicence == 'none'){
        $("#user-on-without-license-modal").modal('show');
      }else if(["plus", "plus-trim", "plus-annuel"].includes(this.licence) && this.projectLicence == 'none'){
        $("#user-on-pro-license-modal").modal('show');
      }
      this.store.dispatch(actions.setExportDoc({export:false}));
    }
  }
  /// Each Lot
 public async downloadEachLot(typeDoc, format, isDqe = false, isEstimatif = false){
    // this.store.dispatch(actions.setExportDoc({export:false}));
    for(let i = 0; i < this.dpgf.data.length; i++){
      let lot = this.dpgf.data[i];
      let lotFileName = `Lot ${lot.indexLot} - ${lot.title}`;
      if((isDqe || isEstimatif) && this.selectedLot[lot.id]){
        if(isDqe){
          await this.singleDownloadDQEPdf(this.dpgf._id, lot.id,true, lotFileName);
        }else if(isEstimatif){
          await this.singleDownloadEstimatifPdf(this.dpgf._id, lot.id,true, lotFileName);
        }
      }else if(this.selectedLot[lot.id]){
        switch(true){
          case typeDoc == 'cctp' && format == 'word':
            await this.singleDownloadCctpDocx(this.dpgf._id, lot.id,true, lotFileName);
            break;
          case typeDoc == 'cctp' && format == 'pdf':
            await this.singleDownloadCCTPPdf(this.dpgf._id, lot.id,true, lotFileName);
            break;
          case typeDoc == 'dpgf' && format == 'pdf':
            await this.singleDownloadDPGFPdf(this.dpgf._id, lot.id,true, lotFileName);
            break;
            default: 
            // console.log('NOTHING HOMMIE');
        }
      }
    }
    this.store.dispatch(actions.setExportDoc({export:false}));
  }
  /// PDF
  /// DPGF
  public downloadDPGFPdf(){
    let lotsChoosen =this.filterSelectedLots(this.selectedLot);
    const pdfInputs = this.pdfPreviewInputsData;
    this.subscriptions.push(
        this.DpgfPostesService.downloadDPGFPdf(this.dpgf._id, lotsChoosen, pdfInputs)
        .subscribe(
          data => {
            saveAs(data.data.data, `${data.data.pdfName}.pdf`);
            this.showExcelDropDown = false;
            this.store.dispatch(actions.setExportDoc({export:false}));
            // if(!environment.onPremise)window.analytics.track('download_cctp',{properties:{acValue: 'download_cctp',model:this.dpgf.docData}});
          },
          err => {
            // console.log("downloadDPGFPdf : ", err?.error?.err?.error);
            this.documentsErrorInfo = ( err?.error?.err?.error || {} );
            this.store.dispatch(actions.setExportDoc({export:false}));
            this.showExcelDropDown = false;
            // Show the Documents Error
            this.openDocumentErrorsModal();
          }
        )
    );
  }
  public async singleDownloadDPGFPdf(dpgfId, lot,changeLoader, lotFileName){
    const pdfInputs = this.pdfPreviewInputsData;

    if(!changeLoader) {  
      this.exportEvent.emit(true)
    }
    return this.DpgfPostesService.singleDownloadDPGFPdf(dpgfId, [lot], lotFileName, pdfInputs)
      .then((data) => {
        saveAs(data['data'].data, `${data['data'].pdfName} ${lotFileName}.pdf`);
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false})); 
        this.showExcelDropDown = false;
      })
      .catch(() => {
        if(!changeLoader) {
      
      this.exportEvent.emit(false)
    }
        this.showExcelDropDown = false;
      });
  }

  /// DQE
  public downloadDQEPdf(){
    let lotsChoosen =this.filterSelectedLots(this.selectedLot);
    const pdfInputs = this.pdfPreviewInputsData;
    this.subscriptions.push(
        this.DpgfPostesService.downloadDQEPdf(this.dpgf._id, lotsChoosen, pdfInputs)
        .subscribe(
          data => {
            saveAs(data.data.data, `${data.data.pdfName}.pdf`);
            if(!environment.onPremise)window.analytics.track('download_cctp',{properties:{acValue: 'download_cctp',model:this.dpgf.docData}});
            this.showExcelDropDown = false;
            this.store.dispatch(actions.setExportDoc({export:false}));
          },
          err => {
            // console.log("downloadDQEPdf : ", err?.error?.err?.error);
            this.documentsErrorInfo = ( err?.error?.err?.error || {} );
            this.store.dispatch(actions.setExportDoc({export:false}));
            this.showExcelDropDown = false;
            // Show the Documents Error
            this.openDocumentErrorsModal();
          }
        )
    );
  }
  public async singleDownloadDQEPdf(dpgfId, lot,changeLoader, lotFileName){
    const pdfInputs = this.pdfPreviewInputsData;
    return this.DpgfPostesService.singleDownloadDQEPdf(dpgfId, [lot], lotFileName, pdfInputs)
      .then((data) => {
        saveAs(data['data'].data, `${data['data'].pdfName} ${lotFileName}.pdf`);
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
        this.showExcelDropDown = false;
      })
      .catch(() => {
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
        this.showExcelDropDown = false;
      });
  }

  /// Estimatif
  public downloadEstimatifPdf(){
    let lotsChoosen =this.filterSelectedLots(this.selectedLot);
    const pdfInputs = this.pdfPreviewInputsData;
    let lotFileName="";
    if(lotsChoosen.length==1){
      let [thisLot] = this.dpgf.data.filter((singleDpgf)=>singleDpgf.id==lotsChoosen[0])
      lotFileName = `Lot ${thisLot.indexLot} - ${thisLot.title}`;
    }
    this.subscriptions.push(
        this.DpgfPostesService.downloadEstimatifPdf(this.dpgf._id, lotsChoosen,lotFileName,pdfInputs)
        .subscribe(
          data => {
            saveAs(data.data.data, `${data.data.pdfName}.pdf`);
            this.showExcelDropDown = false;
            this.store.dispatch(actions.setExportDoc({export:false}));
            // if(!environment.onPremise)window.analytics.track('print_recap_estimation',{properties:{acValue: 'print_recap_estimation'}});
          },
          err => {
            // console.log("downloadEstimatifPdf : ", err?.error?.err?.error);
            this.documentsErrorInfo = ( err?.error?.err?.error || {} );

            this.store.dispatch(actions.setExportDoc({export:false}));
            this.showExcelDropDown = false;
            // Show the Documents Error
            this.openDocumentErrorsModal();
          }
        )
    );
  }
  public async singleDownloadEstimatifPdf(dpgfId, lot,changeLoader, lotFileName){
    const pdfInputs = this.pdfPreviewInputsData;

    if(!changeLoader) {  
      this.exportEvent.emit(true)
    }
    return this.DpgfPostesService.singleDownloadEstimatifPdf(dpgfId, [lot], lotFileName, pdfInputs)
      .then((data) => {
        saveAs(data['data'].data, `${data['data'].pdfName}.pdf`);
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
        this.showExcelDropDown = false;
      })
      .catch((err) => {
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
        this.showExcelDropDown = false;
      });
  }
  /// cctp
  public downloadCCTPPdf(){
    let lotsChoosen =this.filterSelectedLots(this.selectedLot);
    let lotFileName = "";
    if(lotsChoosen.length==1){
      let [thisLot] = this.dpgf.data.filter((singleDpgf)=>singleDpgf.id==lotsChoosen[0])
      lotFileName = `Lot ${thisLot.indexLot} - ${thisLot.title}`;
    }
    const pdfInputs = this.pdfPreviewInputsData;

    this.subscriptions.push(
        this.DpgfPostesService.downloadCCTPPdf(this.dpgf._id, lotsChoosen, pdfInputs, lotFileName)
        .subscribe(
          data => {
            saveAs(data.data.data, `${data['data'].pdfName}.pdf`);
            this.showExcelDropDown = false;
            $('#dpgf-documents').modal('hide');
            this.store.dispatch(actions.setExportDoc({export:false}));
            // $('#pdf-errors-list').modal('toggle');
          },
          err => {
            // console.log("downloadCCTPPdf : ", err?.error?.err?.error);
            this.documentsErrorInfo = ( err?.error?.err?.error || {} );

            this.messageService.add({ key: 'toast', severity: 'error', summary: "Error d'export", detail: '', life: 5000 });
            this.store.dispatch(actions.setExportDoc({export:false}));
            this.showExcelDropDown = false;
            // Show the Documents Error
            this.openDocumentErrorsModal();
          }
        )
    );
  }
  public singleDownloadCCTPPdf(dpgfId,lot,changeLoader, lotFileName){
    const pdfInputs = this.pdfPreviewInputsData;
    return this.DpgfPostesService.singleDownloadCCTPPdf(dpgfId, [lot], lotFileName, pdfInputs)
    .then((result)=>{
      // saveAs(result['data'].data, `${result['data'].pdfName}.pdf`);
      if(!environment.onPremise)window.analytics.track('download_cctp',{properties:{acValue: 'download_cctp',model:this.dpgf.docData}});
      saveAs(result['data'].data, `${result['data'].pdfName} ${lotFileName}.pdf`);

      if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
      this.showExcelDropDown = false;
    }).catch((err)=>{
      if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
      this.showExcelDropDown = false;
    })
  }
  /// DOCX
  public downloadCctpDocx(){
    // if(this.hasCstb){
      let lotsChoosen = this.filterSelectedLots(this.selectedLot);
      // let title = this.dpgf.title;
      let title = this.dpgf?.nomPrenom || this.dpgf.title;

      this.subscriptions.push(
        this.DpgfPostesService.downloadCCTPDocx(this.dpgf._id, lotsChoosen)
        .subscribe(
          data => {
            var zip = new JSZip();
            zip.loadAsync(data.data.data).then((data) => {
              return zip.generateAsync({type:"blob"})
            }).then(function(content) {
              saveAs(content, `cctp - ${title}.docx`);
            });
            this.showExcelDropDown = false;
            this.store.dispatch(actions.setExportDoc({export:false}));
          },
          err => {
            this.store.dispatch(actions.setExportDoc({export:false}));
            this.showExcelDropDown = false;
          }
        )
      );
    // }else{
    //   this.store.dispatch(actions.setExportDoc({export:false}));
    //   $('#need-subscription-premium-modal').modal('show');
    // }
  }
  public async singleDownloadCctpDocx(dpgfId, lot,changeLoader, lotName){
    return await this.DpgfPostesService.singleDownloadCCTPDocx(dpgfId, [lot], lotName)
      .then(result => {
        if(!environment.onPremise)window.analytics.track('download_cctp',{properties:{acValue: 'download_cctp',model:this.dpgf.docData}});
        // let title = this.dpgf.title;
        let title = this.dpgf?.nomPrenom || this.dpgf.title;

        var zip = new JSZip();
        zip.loadAsync(result['data'].data).then((data) => {
          return zip.generateAsync({type:"blob"})
        }).then(function(content) {
          // saveAs(content, `cctp - ${title}.docx`);
          saveAs(content, `cctp - ${title} ${lotName}.docx`);
        });
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
        this.showExcelDropDown = false;
      })
      .catch((err) => {
        if(!changeLoader) this.store.dispatch(actions.setExportDoc({export:false}));
        this.showExcelDropDown = false;
      });
  }

  /// Excel
  public downloadDCEExcel(addDceEstimatif){
    let isDqe = _.get(this.docData, 'options.dqe', false);
    let isEstimatif = _.get(this.docData, 'options.estimatif', false);

    // if(this.hasCstb){
      let lotsChoosen =this.filterSelectedLots(this.selectedLot);
      let title = this.dpgf?.nomPrenom || this.dpgf.title;
      let includeEstimation = (addDceEstimatif==undefined)?false:addDceEstimatif;
      
      this.DpgfPostesService.downloadDCEExcel(this.dpgf['_id'], lotsChoosen, includeEstimation).subscribe(
        (data) => {
          if(!environment.onPremise)window.analytics.track('download_cctp',{properties:{acValue: 'download_cctp',model:this.dpgf.docData}});
          var zip = new JSZip();
            zip.loadAsync(data.data).then((data) => {
              return zip.generateAsync({type:"blob"})
            }).then(function(content) {
              let fileName = `DPGF - ${title}.xlsx`;
              if(isDqe){
                fileName = `DQE - ${title}.xlsx`;
              }if(isEstimatif){
                fileName = `Estimation - ${title}.xlsx`;
              }
              saveAs(content, fileName);
            });
            
            this.showExcelDropDown = false;
            this.store.dispatch(actions.setExportDoc({export:false}));
          },
        err => {
          this.store.dispatch(actions.setExportDoc({export:false}));
          this.showExcelDropDown = false;
        }
      );
    // }else{
    //   this.store.dispatch(actions.setExportDoc({export:false}));
    //   $('#need-subscription-premium-modal').modal('show');
    // }
  }
  private openDocumentErrorsModal(){
    $("#documents-errors-modal").modal('show');
  }

  generateProductPDF() {
    const dpgfId = this.dpgf._id;

    this.productService.generatePDF(dpgfId)
      .subscribe(
        (response: Blob) => {
          // Create a blob from the response data
          const blob = new Blob([response], { type: 'application/pdf' });
          this.store.dispatch(actions.setExportDoc({export:false}));
          // Create a URL for the blob and open it in a new tab
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error => {
          console.error('Error generating PDF:', error);
          this.store.dispatch(actions.setExportDoc({export:false}));
        }
      );
  }

  public ngOnDestroy(): void {
    $("body").css("overflow", "auto");
    this.saveDPGFEvent.emit();
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
  
  public goToLot(lotId){
    this.returnToDCETab.emit(lotId);
  }
  public toggleShowDocFileIsDownloaded(dontShowMessageAgain){
    $("#doc-file-is-downloaded-modal").modal("hide");
    if(dontShowMessageAgain){
      // set don't show message on 
      this.updateUser({showDocFileIsDownloadedModal: false});
      this.showDocFileDownloadedModal = false;
    }
  }
  private showDocFileIsDownloadedModal(){
    if(this.showDocFileDownloadedModal){
      $("#doc-file-is-downloaded-modal").modal("show");
    }
  }

  private getCurrentUser(){
    let userId = this.authService.getAuthUser()["id"];

    this.subscriptions.push(
      this.authService.getUser(userId)
        .subscribe(
          res => {
            this.showDocFileDownloadedModal = res["showDocFileIsDownloadedModal"];
          },
          err => {
            console.log("getCurrentUser : ", err);
          },
        )
    );
  }
  private updateUser(body){
    let userId = this.authService.getAuthUser()["id"];

    this.subscriptions.push(
      this.userService.updateUser(userId, body)
        .subscribe(
          res => {
            // console.log("updateUser : ", res);
          },
          err => {
            console.log("updateUser : ", err);
          },
        )
    );
  }

  loopingg(child,i){
    child.forEach(childI => {
      if(Array.isArray(childI.children)) this.loopingg(childI.children,i++)
      else{
        if(childI.isPoste){
          if(childI.title+'' in this.postsCount)console.log('');
          else this.postsCount[childI.title+'']=0;
        }
      }
    });

  }
}
