<!-- App Header -->
<app-archi-expired-modal></app-archi-expired-modal>
<div class="all">
  <app-header [cssClass]="'fixed-bar'" [quickLinks]="quickLinks"></app-header>
  <app-ce-project-nav [projectId]="projectId"
                      selected="projet"
                      [devisNumber]="0"
                      [disable]="loadingData"
                      [DPGFId]="(project.dpgf||{})._id"></app-ce-project-nav>

                      
  <div class="container loading-page" *ngIf="loadingData">
    <div style="margin-top:60px;">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="contente-container">
    <div class="fluid-container" *ngIf="!loadingData">
      <div class="row">
        <div class="col-md-12">
          <div class="floating-card">
            <div class="row step-section">
              <div class="image-container">
                <img  *ngIf="!authService.currentEnvironment"[src]="project.mainImage || 'assets/zeroprojectimage.jpg'" height="207" width="293" style="object-fit: cover;"/>
                <img  *ngIf="authService.currentEnvironment"[src]="onpremiseProjectImage" height="207" width="293" style="object-fit: cover;"/>              </div>
              <div class="information-container">
                <div class="col-md-12 no-padding first-row">
                  <!-- <h2 class="secondary">INFORMATIONS PRINCIPALES</h2> -->
                  <h2 class="secondary">{{ project.title }}</h2>
                  <div class="modify-history-container">
                    <a *ngIf="licence!='without' || projectLicence=='custom'" class="modifier" (click)="navigateToModifyProject(project._id)"><i class="fa fa-pencil" aria-hidden="true"></i> Modifier</a>
                    <a *ngIf="licence=='without' && projectLicence=='none'" class="modifier" (click)='toggleModal()'><i class="fa fa-pencil" aria-hidden="true"></i> Modifier</a>
                    <button data-toggle="modal" data-target="#activities-popup" class="history-button"><i class="fa fa-bars fa-6x" aria-hidden="true"></i><p>Historique des activités</p></button>
                  </div>
                </div>
                <!-- <div class="row"> -->
                  <!-- <a class="modifier" [routerLink]="[ '/architect/modify-ce-project',project._id]"><i class="fa fa-pencil" aria-hidden="true"></i> Modifier</a> -->
                <!-- </div> -->
                <div class="col-md-6 no-padding">
                  <div class="title">Maître d'ouvrage</div> 
                  <div class="title-wrap">
                    {{ project.maitreDouvrage || "" }}
                  </div>
                  <div class="title title-margin">Type de travaux</div> {{project.natureTravaux | capitalize}}
                  <div class="title title-margin">Programme</div> {{project.type | capitalize}}
                </div>
                <div class="col-md-6 no-padding">
                  <div class="title">Adresse</div> {{project.adresse || '-'}}
                  <div class="title title-margin">Surface</div> {{project.surface | number}} m²
                  <div class="title title-margin">Début des travaux</div> {{project.dateTravaux | dateFr}}
                </div>
                <img  *ngIf="project.typeConsultation == 'converted'" id="logo-bam" src="assets/new-bam-logo.svg" height="10"/>
                <!-- <div class="col-md-4">
                  <h2 class="secondary">STATISTIQUES</h2>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 fix-pos">
          <app-ce-project-collaborateurs 
            [projectId]="projectId" 
            [projectLicence]="projectLicence"
            [isDemoProject]="project?.demo" 
            [collaborateurs]="project?.collaborateurs" 
            [archi]="project?.architect?._id"
            (done)="getCeProject()">
          </app-ce-project-collaborateurs>
        </div>
        <div class="col-md-6">
          <app-ce-project-tasks [projectId]="projectId" [isDemoProject]="project.demo" [archi]="project?.architect?._id" [collaborateurs]="project.collaborateurs" [project]="project"></app-ce-project-tasks>
        </div>
      </div>
    </div>

  </div>

  <!-- Need Subscriiption -->
  <app-need-subscription-popup-2></app-need-subscription-popup-2>

</div>

<!-- Create User -->
<div id="activities-popup"
  class="modal fade"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="header-container">
          <div class="header-title">
            Historique des activités
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="scrollable scroller-1" *ngIf="loggs.length!=0">
          <div *ngFor="let log of loggs">
            <div class="log-card">
              <p class="main-title" [innerHTML]="log | loggs"></p>
              <p class="date-title" [innerHTML]="printDate(log)"></p>
            </div>
          </div>
        </div>
        <div class="no-activity-container" *ngIf="loggs.length==0">
          <p style="font-size: 25px;">Aucune activité !</p>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog key="noaccess"></p-confirmDialog>

<app-need-subscription-popup-2></app-need-subscription-popup-2>
