<div class="container-fluid">
  <div class="col-md-5 full-height colored-side">
    <div>
      <img class="aglo-logo" src="/assets/rob/aglo_logo_round_blanc.png" alt="">
      <h2 class="slogan">
          L’assistant intelligent <br>
          pour votre consultation d’entreprise
      </h2>
    </div>
  </div>
  <div class="col-md-7 full-height">

    <div class="connect">
      <h1 *ngIf="!authService.currentEnvironment">Mot de passe oublié</h1><br>
      <h1 *ngIf="authService.currentEnvironment">Définissez votre mot de passe</h1><br>
      <!-- <h2>Connectez-vous pour continuer</h2> -->
      <h3 *ngIf="!done && !authService.currentEnvironment">Veuillez entrer votre nouveau mot de passe</h3>
      <h3 *ngIf="!done && authService.currentEnvironment">Veuillez entrer votre mot de passe</h3>
      <div *ngIf="!done" class="row">
        <div class="col-md-6 col-md-offset-3 forget-form">
          <form [formGroup]="resetForm" novalidate>

            <div class="input-group input-group-lg">
              <input formControlName="password"  type="password" class="form-control" placeholder="Mot de passe">
            </div>
            <small *ngIf="formErrors.password" class="text-danger">
              {{ formErrors.password }}
            </small><br>

            <div class="input-group input-group-lg">
              <input formControlName="password2"  type="password" class="form-control" placeholder="Mot de passe">
            </div>
            <small *ngIf="formErrors.password2" class="text-danger">
              {{ formErrors.password2 }}
            </small><br>

          </form>
          <button [disabled]="loading" (click)="resetPassword()" class="btn-primary-color validater-password">
            <div *ngIf="!loading && !authService.currentEnvironment">Changer le mot de passe</div>
            <div *ngIf="!loading && authService.currentEnvironment">Valider le mot de passe</div>
            <app-spinner *ngIf="loading"></app-spinner>
          </button>
        </div>
      </div>
      <div *ngIf="done" class="row">
        <div class="col-md-6 col-md-offset-3 forget-form">
          <img id="email-sent" class="hide" src="assets/exchange.png" width=200 alt="">
          <br><br>
          <h3 class="center gray-color">Votre mot de passe a bien été modifié. Essayez-le !</h3>
        </div>
      </div>
      <button *ngIf="done" [disabled]="loading" (click)="goToConnect()" class="btn-primary-color">
        <div>Se connecter</div>
      </button>
    </div>

  </div>

</div>
