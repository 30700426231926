import { Component, OnInit,Input,Output, EventEmitter, ElementRef, ViewChild, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { Store , select} from '@ngrx/store';
import * as fromSelectors from '../_store';
import * as actions from 'src/app/dpgf/dce-edition/_store/dpgf.actions';
import { setDpgfAttribut, saveDpgf} from '../_store/dpgf.actions';

import { AuthService, DpgfPostesService } from 'src/app/_services';
import {ConfirmationService} from 'primeng/api';
import { MessageService } from 'primeng/api';
import { emit } from 'cluster';
import { PricingVersionIndex } from 'src/app/_pipe/pricingVersionIndex.pipe';
import { FormatNumberPricePipe } from 'src/app/_pipe/format-number-price.pipe';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';


declare var $:any;

declare var only;
@Component({
  standalone:true,
  selector: 'app-head-dpgf',
  imports:[
    DpgfModule, 
    PricingVersionIndex,
    FormatNumberPricePipe,
    NgSharedModule
  ],
  providers: [],
  templateUrl: './head-dpgf.component.html',
  styleUrls: ['./head-dpgf.component.scss']
})
export class HeadDpgfComponent implements OnInit {
  public dpgf$;
  @Input()mode;
  @Input()canEditOrSubmit;
  @Output() updateAgencyInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCeInviteFacticeArtisanInfo: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("maitreOeuvre") maitreOeuvre: ElementRef;
  @ViewChild("operationText") operationText: ElementRef;
  @ViewChild("maitreOuvrage") maitreOuvrage: ElementRef;
  @ViewChild("generalite") generalite: ElementRef;
  public total$;
  public totalTTC$;
  public dpgfId;
  public countHistory = 0;
  public maitreOeuvreShow = false;
  public maitreOuvrageShow = false;
  public operationTextShow = false;
  public loadingVersions = false;
  public phaseShow = false;
  public headerData = {};
  public code:string="";
  public widgetDceFiles:any;
  public filesLoading:number[] = [];
  public versions:number[] = [];
  private subscriptions: Array<Subscription> = [];
  public licence;
  public projectLicence: string='none';
  public showEditInputs: boolean = false;
  public artisanInfoData: any = {
    name: "", 
    address: "",
    telAgency: "", 
    emailAgency: "", 
    tvaNumber: "", 
    assuranceNumber: ""
  };
  public isFactice: boolean = false;
  public ceProjectInviteId:string="";

  constructor(private router: Router,
              private confirmationService: ConfirmationService,
              private DpgfPostesService: DpgfPostesService,
              private store: Store,
              public authService: AuthService,
              private route: ActivatedRoute,
              private messageService: MessageService
              ) { }

  ngOnInit() {
    this.dpgf$ = this.store.pipe(select(fromSelectors.getDpgf))
    
    this.total$ = this.store.pipe(select(fromSelectors.getTotal, { name: "total" }))
    
    this.totalTTC$ = this.store.pipe(select(fromSelectors.getTotal, { name: "totalttc" }))
    // this.subscriptions.push(
      this.store.pipe(select(fromSelectors.getDpgf)).subscribe(dpgf=>{
        if(dpgf._id){
          this.dpgfId = (dpgf||{})._id;

          this.isFactice = (dpgf.ceProjectInvite?.artisanName != "" || dpgf.ceProjectInvite?.isFactice) ? true : false;

          if(dpgf.ceProjectInvite){
            this.ceProjectInviteId = dpgf.ceProjectInvite._id;
          }          
          if(!this.isFactice){ // Real Artisan
            this.artisanInfoData = {...dpgf.artisan};
          }else if(dpgf?.ceProjectInvite?.facticeArtisanInfo){ // Factice Artisan
            this.artisanInfoData = {...dpgf.ceProjectInvite.facticeArtisanInfo};
          }

        }

      })
    // )

    this.licence = this.authService.licence
    let code = this.route.snapshot.queryParams.code;
    this.code = (code||"").replace(/ /g,'');

    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
  }
  public toggleUpdateInputs(){
    if(this.licence == "without" && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    this.showEditInputs = !this.showEditInputs;
  }
  public updateArtisanInfo(){
    if(!this.isFactice){   
      this.updateAgencyInfo.emit(this.artisanInfoData);
      this.setDPGFAttribut({key: 'artisan', value: this.artisanInfoData});
      this.toggleUpdateInputs();
    }else{
      let data = {
        ceProjectInviteId: this.ceProjectInviteId,
        artisanData: this.artisanInfoData
      };
      this.updateCeInviteFacticeArtisanInfo.emit(data);
      this.toggleUpdateInputs();
    }
  }
  saveToServe(){
    this.store.dispatch(saveDpgf({}));
    this.closeModal('#dpgf-data-modal');
  }
  getVersions(event){
   this.loadingVersions = true;
   this.subscriptions.push(
     this.DpgfPostesService.getDpgfVersions(this.dpgfId,event.page || 0,1).subscribe((res)=>{
       this.loadingVersions = false;
       this.versions = res.data;
       this.countHistory = res.metadata.count;
     })
   )
 }
 restorer(id){
    $("#versions-modal").modal("hide");
    if(this.licence!='without'){
      this.loadingVersions = true;
      this.confirmationService.confirm({
          message: "Vous êtes sur le point de restaurer une ancienne version du DCE.<br>Toutes les modifications réalisées entre temps vont être écrasées.",
          header: 'Restaurer la version',
          accept: () => {
            this.subscriptions.push(
              this.DpgfPostesService.restoreDpgfMinorVersion(id).subscribe(()=>{
                this.loadingVersions = false;
                this.messageService.add({ key: 'toast', severity: 'success', summary: "Version restoré", detail: "Votre version à bien été restoré", life: 5000 });
                this.store.dispatch(actions.loadDpgf( {dpgfId :  this.dpgfId}));
                this.store.dispatch(actions.emptyLot({}));
              })
            )
          },
          reject: () => {
          }
      });
    }
    else $('#upgrade-modal').modal('show');
  }
  setDPGFAttribut(data){
    this.store.dispatch(setDpgfAttribut(data));
  }
  public resize(){
    let maitreOeuvre = this.maitreOeuvre.nativeElement;
    maitreOeuvre.style.height = 'auto';
    maitreOeuvre.style.height = `${maitreOeuvre.scrollHeight}px`;
    let operationText = this.operationText.nativeElement;
    operationText.style.height = 'auto';
    operationText.style.height = `${operationText.scrollHeight}px`;
    let maitreOuvrage = this.maitreOuvrage.nativeElement;
    maitreOuvrage.style.height = 'auto';
    maitreOuvrage.style.height = `${maitreOuvrage.scrollHeight}px`;
    let generalite = this.generalite.nativeElement;
    generalite.style.height = 'auto';
    generalite.style.height = `${generalite.scrollHeight}px`;
  }

  public closeModal(modalId){
    $(modalId).modal("hide");
  }
  public goToMinorVerson(versionId){
    $("#versions-modal").modal("hide");
    this.router.navigate(['/architect/dpgf-version-minor',versionId]);
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
  }
}
