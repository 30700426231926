import { Component, OnInit, ViewChild , NO_ERRORS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute , Params,Router} from '@angular/router';
import { FormNewDpgfComponent } from 'src/app/dpgf/global/form-new-dpgf/form-new-dpgf.component';
import { NgSharedModule } from 'src/app/all/ngshared.module';

import { MessageService } from 'primeng/api';
import { DpgfPostesService } from '../../../_services/index';
import { CeProjectService } from '../../../_services/index';
import { ErrorService } from '../../../_services/index';
import { AuthService } from '../../../_services/index';
//import { ArchitectModule } from '../architect.module';
import { HeaderComponent } from 'src/app/all/header/header.component';
import { CeProjectNavComponent } from 'src/app/dpgf/global/ce-project-nav/ce-project-nav.component';
import { SpinnerComponent } from 'src/app/all/spinner/spinner.component';
import { DpgfListComponent } from 'src/app/dpgf/global/dpgf-list/dpgf-list.component';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
    selector: 'app-archi-new-dpgf',
    imports:[
      
      HeaderComponent,
      CeProjectNavComponent,
      FormNewDpgfComponent,
      SpinnerComponent,
      DpgfListComponent,
      NgSharedModule
    ],
    providers: [],
    templateUrl: './archi-new-dpgf.component.html',
    styleUrls: ['./archi-new-dpgf.component.scss'],
    standalone: true,

})
export class ArchiNewDpgfComponent implements OnInit {
  public newProject: any = {};
  public project: any = {};
  public loading:Boolean = false;
  public hasLicence:Boolean = false;
  public loadingData:Boolean = true;
  public projectId:String;
  public dpgfType:String = "none";hasDPGF:boolean = false;currentUser;
  // here add scratch to dpgftype && check if the user have project in the backend by counting them and change the dpgfType based on it 
  @ViewChild(FormNewDpgfComponent) form: FormNewDpgfComponent;
  private arhitectCreatorId: string = ""; // the architect who created the project not a (partenaire)
  private subscriptions: Array<Subscription> = [];

  constructor(private title: Title,
              private messageService: MessageService,
              private router: Router,
              private route: ActivatedRoute,
              private DpgfPostesService: DpgfPostesService,
              private ceProjectService: CeProjectService,
              private ErrorService: ErrorService,
              public authService: AuthService,
              ) { }

  ngOnInit() {
    this.title.setTitle("Aglo - Créer votre DPGF" )
    this.getCeProject();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    this.hasDPGF = (this.currentUser.hasDpgf ||false);
    this.dpgfType = this.hasDPGF ? 'none' : 'scratch';
    this.hasLicence =   this.hasDPGF || (this.authService.licence!="none");
  }
  getCeProject(){
    this.route.params.forEach((params: Params) => {
      if (params['projectId']) {
        this.projectId = params['projectId']
        this.subscriptions.push(
          this.ceProjectService.getProject(this.projectId)
              .subscribe(
                  data => {
                    if((data.dpgf||{})._id && !this.currentUser.hasDpgf){
                      this.currentUser.hasDpgf = true;
                      this.dpgfType = 'scratch';
                      localStorage.setItem('currentUser',JSON.stringify(this.currentUser));
                    }
                      this.project = data;
                      this.arhitectCreatorId = data.architect["_id"];
                      this.loadingData = false;
                      let newProject = {
                        natureTravaux : data.natureTravaux,
                        type: data.type,
                        nomPrenom:data.title ,
                        adresse: data.adresse,
                      }
                      this.form.setData(newProject)
                      this.form.scrollTo("#q6")
                      // this.getExpensesRequests()
                  },
                  error => {
                      console.log("error",error)
                      this.ErrorService.manageError(error,'/architect/ce-project/' + this.projectId);
                  })
              )
      }else{
        this.loadingData = true;
      }
    })
  }
  //Register
  create(type){
    this.loading = true;
    let isValid = this.form.isValid()
    let projectData = this.form.getFormData();
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    let archiId = currentUser.archiId;
    if(this.projectId)projectData.ceProject = this.projectId;
    projectData.typeDpgf = type;
    projectData.title = this.project.title;
    projectData.natureTravaux = this.project.natureTravaux;
    projectData.type = this.project.type;
    projectData.adresse = `${this.project.adresse} ${this.project.addressCodePostal} ${this.project.addressCity}`;
    projectData.maitreOuvrage = this.project.maitreDouvrage;

    if(isValid){
      this.subscriptions.push(
        // this.DpgfPostesService.create(archiId,projectData)
        this.DpgfPostesService.create(this.arhitectCreatorId,projectData)
          .subscribe(
              data => {
                  this.loading = false;
                  this.router.navigate(['/architect/dpgf/'+ data._id]);
                  if(!environment.onPremise)window.analytics.track('create_DCE',{properties:{acValue: 'create_DCE'}});
                  this.messageService.add({ key: 'toast', severity: 'success', summary: 'Projet créé', detail: 'Votre DCE a été créé', life: 5000 });
              },
              error => {
                  console.log("error",error)
                  this.loading = false;
                  this.ErrorService.manageError(error,'/architect/new-dpgf')
              })
          )
    }else{
      this.loading = false;
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Formulaire incomplet', detail: "Veuillez remplir tous les champs obligatoires et attendre la fin du chargement des fichiers", life: 5000 });
    }
  }
  public openQuestion(){
    $("#question").toggle()
  }
  public duplicate(id){
    this.subscriptions.push(
      this.DpgfPostesService.duplicate(id,this.projectId,{})
        .subscribe(
            data => {
                this.loading = false;
                this.router.navigate(['/architect/dpgf/'+ data._id]);
                if(!environment.onPremise)window.analytics.track('duplicate_DCE',{properties:{acValue: 'duplicate_DCE'}});
                this.messageService.add({ key: 'toast', severity: 'success', summary: 'Projet créé', detail: 'Votre DCE a été créé', life: 5000 });
            },
            error => {
                console.log("error",error)
                this.loading = false;
                this.ErrorService.manageError(error,'')
            })
        )
  }
  public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription) => {
          subscription.unsubscribe();
      });
  }

}
