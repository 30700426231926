import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cstb-license-modal',
  standalone: true,
  imports: [],
  templateUrl: './cstb-license-modal.component.html',
  styleUrl: './cstb-license-modal.component.scss'
})
export class CstbLicenseModalComponent {

  constructor(
    private router: Router
  ){

  }

  public redirectToLicensesPage(){
    this.router.navigate(['/architect/profil'], { queryParams: { tab: 'licence' } });
  }

}
