  <div class="row" *ngIf="!loadingUsers">
    <div class="table-container">
      <div class="row">
        <!-- <button type="button" style="outline: none;" *ngIf="isAdminArchitect && (plan=='premium' || plan=='premium-annuel' || plan=='premiumn' || plan=='premiumn-annuel' || plan=='max' || plan=='max-annuel') && !dateEndTrial" class="btn add-new" data-toggle="modal" data-target="#ajouter-user-popup"><i class="fa fa-plus"></i> Ajouter un collaborateur  </button> -->
        <button type="button" style="outline: none;" *ngIf="isAdminArchitect && (plan=='premium' || plan=='premium-annuel' || plan=='premiumn' || plan=='premiumn-trim'  || plan=='premiumn-annuel' || plan=='max' || plan=='max-trim'|| plan=='max-annuel') && !dateEndTrial" class="btn add-new" data-toggle="modal" data-target="#ajouter-user-popup"><i class="fa fa-plus"></i> Ajouter un collaborateur</button>
        <label style="font-size: 24px;color: #000;">Collaborateurs</label>
        <!-- <span *ngIf="users?.length<45" style="font-size: 24px;color: green;"> {{users?.length}}/50 </span>
        <span *ngIf="users?.length>44" style="font-size: 24px;color: red;"> {{users?.length}}/50 </span> -->
      </div>
      <table class="table">
        <thead>
          <tr>
          <th class="t-head">Nom complet</th>
          <th class="t-head">E-mail</th>
          <th class="t-head">Numéro de téléphone</th>
          <th class="t-head">Statut</th>
          <th *ngIf="isAdminArchitect" class="t-head center">Operations</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td> 
              <!-- <ng-template [ngIf]="user.isAdminArchitect">
                <i class="fa fa-user-circle-o"></i>
              </ng-template>
              <ng-template [ngIf]="!user.isAdminArchitect">
                <i class="fa fa-user-circle"></i>
              </ng-template> -->
              {{user.firstName + '  ' + user.lastName}}</td>
            <td>{{user.email}}</td>
            <td>{{user.phone}}</td>
            <td>{{user.isPending ? "Invitation envoyée" : "Actif"}}</td>
          <ng-template [ngIf]="isAdminArchitect">
            <td class="text-right">
              <!-- <div class="more-dropdown no-open" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" *ngIf="!user.isAdminArchitect" style="cursor: pointer;">
                <i class="fa fa-ellipsis-v no-open" ></i>
              </div> -->
              <div class="flex" style="justify-content: center;" *ngIf="!user.isAdminArchitect">
                <div *ngIf="user.isPending" style="height:1px;width: 18px;"></div>
                <img *ngIf="!user.isPending" [pTooltip]="'Modifier le mot de passe'" [src]="'assets/archi/key.svg'" data-toggle="modal" data-target="#modify-user-password-popup" (click)="setUser(user)" style="cursor: pointer;" alt="">
                <img [pTooltip]="'Supprimer le collaborateur'" [src]="'assets/archi/trashcan.svg'" (click)="setUser(user)" data-toggle="modal" data-target="#delete-user" alt="" style="margin-left: 14px;cursor: pointer;">
              </div>
              <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li data-toggle="modal" data-target="#modify-user-password-popup" (click)="setUser(user)">
                  <i class="fa fa-key" aria-hidden="true"></i>
                  <span class="menu-text padding-2">Changer de mot de passe</span>
                </li>
                <li (click)="setUser(user)" data-toggle="modal" data-target="#delete-user">
                  <i class="fa fa-trash" aria-hidden="true"></i>&nbsp;
                  <span class="menu-text">Supprimer l'utilisateur</span>
                </li>
              </ul> -->
            </td>
          </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <!-- Create User -->
  <div id="ajouter-user-popup" *ngIf="!loadingUsers"
    class="modal fade"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="callmodal-container">
        <form [formGroup]="userForm" class="new-user-form">
            <div class="form-group col-md-6">
              <label class="required-star" for="">Prénom</label>
              <input type="text" name="firstName" class="form-control" placeholder="Prénom" formControlName="firstName">
            </div>
            <div class="form-group col-md-6">
              <label class="required-star" for="">Nom</label>
              <input type="text" name="lastName" class="form-control" placeholder="Nom" formControlName="lastName">
            </div>
          <div class="form-group">
            <label class="required-star" for="">Email</label>
            <input type="email" name="email" class="form-control" placeholder="example@domain.com" formControlName="email" (keyup)="onKeyUpMail()">
            <span class="text-danger" *ngIf="userForm.get('email').touched && !userForm.get('email').valid">Email obligatoire</span>
            <span class="text-danger" *ngIf="userForm.get('email').touched && !mailExist && userForm.get('email').valid">Vous ne pouvez pas ajouter un utilisateur ayant déjà un compte AGLO. </span>
          </div>
          <div class="form-group">
            <label class="required-star" for="">Téléphone</label>
            <input type="number" name="phone" class="form-control" placeholder="06 66 55 44 33" formControlName="phone">
            <!-- data-dismiss="modal" data-toggle="modal" href="#ajouter-user-popup-2"  -->
          </div>
          <div class="form-group add-section"> 
            <button *ngIf="!creatingUser && !loadinglicence" (click)='createNewUser()' [disabled]="!userForm.valid || !mailExist" class="btn btn-add">Ajouter</button>
            <app-spinner *ngIf="creatingUser || loadinglicence" ></app-spinner>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Create User -->
  <div id="modify-user-password-popup" *ngIf="!loadingUsers"
    class="modal fade"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="callmodal-container">
        <div class="form-group">
          <label class="required-star" for="">Nouveau mot de passe</label>
          <input type="password" name="password" class="form-control" placeholder="Nouveau mot de pass" [(ngModel)]="password">
        </div>
        <div class="form-group">
          <label class="required-star" for="">Confirmer mot de passe</label>
          <input type="password" name="password" class="form-control" placeholder="Confirmer mot de pass" [(ngModel)]="passwordConfirmation">
          <span class="text-danger" *ngIf="passwordConfirmation && password && passwordConfirmation!=password">Veuillez entrer le même mot de passe que ci-dessus</span>
        </div>
        <div class="form-group">
          <button [disabled]="loadingPassword && !passwordConfirmation || !password || passwordConfirmation!=password" class="btn" (click)="changeUserPassword()">Changer le mot de passe</button>
          <app-spinner *ngIf="loadingPassword"></app-spinner>
        </div>
      </div>
    </div>
  </div>
  <p-confirmDialog key="user" #ca>
    <p-footer>
      <button type="button" pButton icon="fa fa-close" label="Non" (click)="ca.reject()"></button>
      <button type="button" pButton icon="fa fa-check" label="Oui" (click)="ca.accept()"></button>
    </p-footer>
  </p-confirmDialog>
  <p-confirmDialog key="plan" #cv>
    <p-footer>
      <button type="button" pButton icon="fa fa-close" label="Non" (click)="cv.reject()"></button>
      <button type="button" pButton icon="fa fa-check" label="Oui" (click)="cv.accept()"></button>
    </p-footer>
  </p-confirmDialog>
  <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
    <p-footer>
        <button type="button" pButton icon="fa fa-close" label="Non" (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" label="Oui" (click)="cd.accept()"></button>
    </p-footer>
  </p-confirmDialog>
  
  <div id="delete-user"
    class="modal fade upgrade-modal"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog no-padding">
      <div class="modal-container no-padding">
        <p class="upgrade-title">Êtes vous sur de vouloir supprimer cet utilisateur ?</p>
        <p class="upgrade-sub-title">Il sera définitivement supprimé et ne pourra plus accéder à l'application.</p>
        <div class="button-container">
          <button class="b1" data-dismiss="modal">Annuler</button>
          <ng-template [ngIf]='!deletingUser'>
            <button class="b2" (click)="removeCollaborateur()">Confirmer</button>
          </ng-template>
          <ng-template  [ngIf]='deletingUser'>
            <div class="margined">
              <app-spinner></app-spinner>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>