<div class="all">
    <app-header ></app-header>
    <div class="padding-left-40" style="padding-left: 160px;">
        <div style="padding-bottom: 40px;">
            <h1> Logos</h1> 
        </div>
        <div class="flex padding-left-40">
            <div class="padding-20" style="padding-right: 180px;">
            <p class="title">Logo du SGA</p> 
            <img [ngClass]="{'hidden': !isSgaLogoExist}" class="img-style" alt="your-image-description" [src]="sgaLogo||''">
            <br>
            <p-fileUpload  mode="basic" accept=".png, .jpg, .jpeg"  [chooseLabel]="isSgaLogoExist? 'Modifier' :'Ajouter un logo'" name="demo1" url="/api/v1/upload-file?type=logo&logos=SGA" [auto]="true" 
            (onUpload)="onUploadLogoSga($event)" ></p-fileUpload>
            <br>
            <!-- <div class="flex justify-center" *ngIf="!departement?.logo?.cdnUrl">
            <p class="cursor" (click)="selectDepartement(departement)">Ajouter une image</p>
            </div> -->
            <!-- <button>Modifier</button> -->
            </div>
            <div class="padding-20">
            <p class="title">Logo du Ministère des Armées</p>
            <img [ngClass]="{'hidden': !isMinarmLogoExist}" class="img-style" alt="your-image-description" [src]="minarmLogo|| ''">
            <br>
            <!-- <div class="flex ju stify-center" *ngIf="!departement?.logo?.cdnUrl">
                <p class="cursor" (click)="selectDepartement(departement)">Ajouter une image</p>
                </div> -->
            <!-- <button>Modifier</button> -->
            <p-fileUpload  mode="basic"  accept=".png, .jpg, .jpeg"  [chooseLabel]="isMinarmLogoExist? 'Modifier' :'Ajouter un logo'" name="demo1" url="/api/v1/upload-file?type=logo&logos=minarm" [auto]="true" 
            (onUpload)="onUploadLogoMinarm($event)"></p-fileUpload>

            </div>
        </div>
    </div>
</div>