<div class="modal fade" id="user-on-pro-license-post-project-modal" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="title">Limite de postes atteinte</h6>
        </div>
        <div class="modal-body">
          <p>
            Vous êtes actuellement limité à <span id="dpgfMaxPosts"></span> postes par projet avec votre abonnement.
          </p>
          <p>
            Souscrivez à une licence Max ou Intégral pour pouvoir créer un nombre illimité de postes.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-cancel ag-btn-tertiary-sm margin-right-16" data-dismiss="modal">Annuler</button>
          <button type="button" class="btn-accept ag-btn-tertiary-sm" data-dismiss="modal" (click)="redirectToLicensesPage()">
            Voir les licences
          </button>
        </div>
      </div>
    </div>
  </div>
  