import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
//Services
import { AuthService } from '../../_services/index';
import { ArchitectService } from '../../_services/index';
import { ArchitectThreeDSModalComponent } from '../architect-three-ds-modal/architect-three-ds-modal.component';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { NgSharedModule } from '../ngshared.module';
// environment
import { environment } from 'src/environments/environment'
@Component({
  standalone:true,
  selector: 'app-header',
  imports:[
    NgSharedModule,
    ArchitectThreeDSModalComponent,
    LoginModalComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()selected:string;
  @Input()quickLinks:any = [];
  @Input()guide:string = "none";
  @Input()cssClass : string = '';
  @Input()type : string = 'full';
  @Input()logoUrl : string = 'app';
  @Input()isFixed=false;
  public archiUrl:string = "";
  public hide:string = "false";
  public licence:string = "";
  public showNav=false;
  public diplayHelp:boolean = false;
  public hideWarningDemoDialog:boolean = true;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public guideBoxes:any = [{
    title : "Rénovation d'appartement avec un architecte",
    image:"//res.cloudinary.com/hrscywv4p/image/upload/c_limit,fl_lossy,h_1500,w_2000,f_auto,q_auto/v1/206926/trousseau-0589_orig_ndtzpv.jpg",
    url:"https://www.bam.archi/renovation-d-appartement"
  },{
    title : "Design et aménagement de bureaux",
    image:"//res.cloudinary.com/hrscywv4p/image/upload/c_limit,fl_lossy,h_1500,w_2000,f_auto,q_auto/v1/206926/05_dofnmg.jpg",
    url:"https://www.bam.archi/bureaux-startup-co-working"
  },{
    title : "La surélévation d'immeuble à Paris",
    image:"//res.cloudinary.com/hrscywv4p/image/upload/c_limit,fl_lossy,h_1500,w_2000,f_auto,q_auto/v1/206926/sur%C3%A9lev_vazistas_et_fay_kdmdxv.jpg",
    url:"https://www.bam.archi/surelevation-paris"
  },{
    title : "Extension de maison avec un architecte",
    image:"//res.cloudinary.com/hrscywv4p/image/upload/c_limit,fl_lossy,h_1500,w_2000,f_auto,q_auto/v1/206926/9_Cigalon_Photo_3_qjrygf.jpg",
    url:"https://www.bam.archi/extension-de-maison"
  }];
  private subscriptions: Array<Subscription> = [];
  url: string;
  initialDateEndTrial:any; /// no need to call Api to get this value (it's set in service)
  dateEndTrial: any;
  stripeUrl: any;
  constructor( public authService: AuthService,
               private router: Router,
               private archiService: ArchitectService,
               private activatedRoute: ActivatedRoute ) {
               }

  ngOnInit() {
    this.licence = this.authService.licence;
    // this.getUrl()
    // this.licence = this.authService.licence;
    // this.authService.licenceObserver.subscribe((licence)=>{this.licence = licence;console.log('licence',licence)})
    // combineLatest([
    //   interval(3000),
    //   this.authService.licenceObserver.pipe(takeUntil(this.ngUnsubscribe))
    // ])
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe)
    //   )
    //   .subscribe(([_, licenceValue]) => {
    //     console.log('Logging every 10 seconds');
    //     console.log('Received value from licenceObserver:', licenceValue);
    //     // Add your logic here
    //   });
    this.initialDateEndTrial = this.authService.dateEndTrial;
    this.url=this.router.url;
    if(this.url.includes('aglo-GPT')||this.url.includes('archi-db') || this.url.includes('my-consultations') || this.url.includes('carnet-adresses') || this.url.includes('parrainage')) this.showNav=true;
      this.getCurrentRoute();
      this.getUser();
    }
  //Get Bam URL
  public getUrl(){
  let archiId = this.authService.currentUser.archiId;
    if(this.archiUrl == "" && this.authService.currentUser.archiId != undefined && this.authService.currentUser.archiId != ""){
      this.subscriptions.push(
        this.archiService.getArchiUrl(this.authService.currentUser.archiId).subscribe(
            data => {
              this.archiUrl = data || "";
            },
            error =>{
              console.log("error" , error)
            }
          )
      )
    }
  }
  getUser(){
    if(!this.authService.currentEnvironment)
    this.authService.getUserLicenceStripeUser().subscribe(
      (res)=>{
        this.stripeUrl=res.data.stripe3dsUrl;
        this.dateEndTrial=res.data.dateEndTrial;
        // this.licence = res.data.licence
      }
    )
  }

  //LogOut function
  logout(){
    this.authService.logout();
    if(!environment.onPremise)window.analytics.track('log_out',{properties:{acValue: 'log_out'}});
    this.archiUrl = "";
    this.router.navigate(['login']);
  }

  public closeWarningDemo(){
    this.hideWarningDemoDialog = true;
    localStorage.setItem("hideWarningDemoDialog", JSON.stringify(true));
  }
  public showWarningDemo(){
    let showWarning = localStorage.getItem("hideWarningDemoDialog");
    showWarning = JSON.parse(showWarning);

    if(this.authService.isArchitect() && this.licence == 'base'){
      if(!showWarning){
        this.hideWarningDemoDialog = false;
      }else{
        this.hideWarningDemoDialog = true;
      }
    }
  }

  public getCurrentRoute(){
    if(this.router.url.includes('/architect/my-consultations')){
      this.showWarningDemo();
    }else{
      this.hideWarningDemoDialog = true;
    }
  }

  private checkBoosterFormIsCompleted(){
    let isCompleted = localStorage.getItem("boosterFormIsCompleted");

    return isCompleted == 'true' ? true : false;
  }
  public goToBoosterPage(){
    let boosterFormsRoute = "/artisan/booster-profile-forms";
    let boosterProfileRoute = "/artisan/booster-profile";
    let boosterFormIsCompleted = this.checkBoosterFormIsCompleted(); // if the artisan filled all booster forms
    let routeDestination = boosterFormIsCompleted ? boosterProfileRoute : boosterFormsRoute;

    this.router.navigate([routeDestination]);
  }

  public ngOnDestroy(): void {
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
