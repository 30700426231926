import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

// Modules
import { NgSharedModule } from 'src/app/all/ngshared.module';


declare var $:any;

@Component({
  selector: 'app-projects-searchbar',
  standalone: true,
  imports: [
    NgSharedModule
  ],
  templateUrl: './projects-searchbar.component.html',
  styleUrl: './projects-searchbar.component.scss'
})
export class ProjectsSearchbarComponent {

  @Input() isMinarm: boolean = false;
  @Input() minarmArchitectsList: [] = [];

  @Output() sortBy: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterProjects: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchProjects: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchProjectsByArchitectsName: EventEmitter<any> = new EventEmitter<any>();


  public inputSectionMenus: any = {
    typesDeTravaux: [
      {label: "Tous les types de travaux", value: "all"},
      {label: "Construction", value: "construction"},
      {label: "Rénovation", value: "renovation"},
      {label: "Extension", value: "extension"},
      {label: "Transformation", value: "transformation"},
      {label: "Travaux extérieurs", value: "travauxExterieurs", minarmOnly: true }
      
    ],   
    programmes: [
      {label: "Tous les programmes", value: "all"},
      {label: "Maison individuelle", minarmLabel: "Logement individuel", value: "maison"},
      {label: "Appartement", value: "appartement"},
      {label: "Logement collectif", value: "logement"},
      {label: "Café/Hôtel/Restaurant", minarmLabel: "Hôtellerie/restauration", value: "hotel"},
      {label: "Local commercial", minarmLabel: "Etablissement recevant du public", value: "commerce"},
      {label: "Bureaux/Tertiaire", value: "bureaux"},
      {label: "Agricole/Industriel", minarmLabel: "Local industriel", value: "agricole"},
      {label: "Local technique", value: "localTechnique"},
      {label: "Autre", value: "autre"},
    ],
    projectStatus: [
      {label: "Tous les projets", value: "all"},
      {label: "Projets en cours", value: "en_cours"},
      {label: "Projets archivés", value: "archived"},
    ],
    // MinArm
    minarmProjectStatus: [
      {label: "Tous les projets", value: "all"},
      {label: "Projets validés", value: "validated"},
      {label: "Projets en cours", value: "en_cours"},
      {label: "Projets archivés", value: "archived"},
    ],
    minarmArchitectsNames: [],

  }; 

  // Search Inputs
  public searchQuery: any = {
    textQuery: "",
    natureTravaux: ["all", "construction","renovation","extension","transformation", "travauxExterieurs"], // types de travaux
    type: ["all", "maison","appartement","logement","hotel","commerce","bureaux","agricole","autre", "localTechnique"], // programmes
    // projectStatus: "en_cours",
    // projectStatus: ["en_cours"],
    status: ["en_cours"],
    // minarm
    architectsNames: []
  }
  
  public optionsMainTitles: any = {
    "natureTravaux": "Tous les types de travaux",
    "type": "Tous les programmes",
    "status": "Tous les projets",
    "architectsNames": "Tous les groupes"
  }
  public typesDeTravauxTitle: string = "Tous les types de travaux";
  public programmesTitle: string = "Tous les programmes";

  // Filter Inputs
  public selectedProjectsFilter: string = "all";

  // sorting
  public selectedSortByLabel: string = "Date de création";
  public selectedSortByValue: string = "createdAt";
  public sortByDirection: number = 1;

  ngOnInit(){
    this.preventDropdownsFromClosingAfterClickingInside();

    // add "Projets validés" status on minarm
    this.AddMinarmProjectStatus();

    // remove "appartement" from programmes in minarm
    if(this.isMinarm){
      this.inputSectionMenus.programmes = this.inputSectionMenus.programmes.filter(item => item.value != "appartement");
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes?.minarmArchitectsList?.currentValue?.length){
      if(!this.isMinarm) return;

      this.setArchitectsList(changes.minarmArchitectsList.currentValue);
    }

  }

  public openDropdown(dropdownId){
    $(dropdownId).toggleClass('open');
  }

  private preventDropdownsFromClosingAfterClickingInside(){
    $('.dropdown-menu').on({"click": function(e){
        e.stopPropagation(); 
      }
    });
  }
  private AddMinarmProjectStatus(){
    if(!this.isMinarm) return; // if prod don't add the 'Projets validés'

    const INSERT_INDEX = 1;
    const ITEMS_TO_DELETE_NUMBER = 0;
    let validatedStatus = {label: "Projets validés", value: "validated"};
    // add the above object to index "1"
    this.inputSectionMenus.projectStatus.splice(INSERT_INDEX, ITEMS_TO_DELETE_NUMBER, validatedStatus);
  }

  public emptyTextQuery(){
    this.searchQuery.textQuery = "";
    
    this.emitSearchProjects();
  }
  public selectProjectStatus(value){

    // this.searchQuery.projectStatus = value;

    // this.emitSearchProjects();
  }

  public toggleDropdownItem(field, value){
    // check all or uncheck all
    if(value == "all"){
      this.toggleAllDropdownItems(field);
      this.emitSearchProjects();

      // set dropdown titles
      this.setDropdownItemsTitles(field);

      return;
    }

    const fieldsMapper = {
      "natureTravaux": "typesDeTravaux",
      "type": "programmes",
      "status": "projectStatus"
    };    

    const fieldExists = this.searchQuery[field];
    const valueAlreadySelected = this.searchQuery[field].includes(value);

    if(fieldExists && valueAlreadySelected){ // if already selected unselect
      this.searchQuery[field] = this.searchQuery[field].filter(item => item != value);
      
      /// if "all" is checked, uncheck "all"
      if(this.searchQuery[field].includes("all")){
        this.searchQuery[field] = this.searchQuery[field].filter(item => item != "all");
      }

    }else{
      // if not selected, select
      this.searchQuery[field].push(value);      

      // if "all" already unchecked and all of the options are checked manually by the user, it should be checked
      // original fields
      let fieldItems = fieldsMapper[field];
      // -1 without counting "all"
      let selectedFieldAllItemsNumber = this.inputSectionMenus[fieldItems].length - 1;
      
      if(this.searchQuery[field].length == selectedFieldAllItemsNumber){
        this.searchQuery[field].push("all");
      }
    }
    
    // set dropdown titles
    this.setDropdownItemsTitles(field);
    
    this.emitSearchProjects();
  }
  private toggleAllDropdownItems(field){
    let fieldsMapper = {
      "natureTravaux": "typesDeTravaux",
      "type": "programmes",
      "status": "projectStatus"
    };

    // original fields
    let fieldItems = fieldsMapper[field];
    let selectedFieldAllItems = this.inputSectionMenus[fieldItems];
    // selected fields
    let selectedFields = this.searchQuery[field];

    // if the same length, all fields selected then UNSELECT ALL
    if(selectedFieldAllItems.length == selectedFields.length){
      this.searchQuery[field] = [];
    }else{ // ELSE SELECT ALL
      this.searchQuery[field] = [];

      selectedFieldAllItems.forEach((item) => {
        this.searchQuery[field].push(item.value);
      });
    }
    
  }
  private setDropdownItemsTitles(field){

    if(!["natureTravaux", "type", "status", "architectsNames"].includes(field)) return;
    

    const fieldsMapper = {
      "natureTravaux": "typesDeTravaux",
      "type": "programmes",
      "status": "projectStatus",
      "architectsNames": "minarmArchitectsNames"
    };
    const allOptionsCheckedLabel = {
      "natureTravaux": "Tous les types de travaux",
      "type": "Tous les programmes",
      "status": "Tous les projets",
      "architectsNames": "Tous les groupes"
    }

    /// 
    let numberOfCheckedItems = this.searchQuery[field].length;
    let allIsChecked = this.searchQuery[field].includes("all");

    // if all is Checked
    if(allIsChecked){
      this.optionsMainTitles[field] = allOptionsCheckedLabel[field];
    }else if(numberOfCheckedItems == 1){ // if 1 item is checked, get the label and show it      
      let checkedItemValue = this.searchQuery[field][0];

      // in inputSectionMenus the objects we created they have label & value
      // but for architects names, they have _id and name
      // if the user selectes architects name use _id & name instead of label & value
      const fieldKey = fieldsMapper[field];
      let valueName = "value";
      let labelName = "label";

      if(field == "architectsNames"){
        valueName = "_id";
        labelName = "name";
      }

      let checkedItemObj = this.inputSectionMenus[fieldKey].find(item => item[valueName] == checkedItemValue);
      this.optionsMainTitles[field] = checkedItemObj[labelName];
      
    }else if(numberOfCheckedItems > 1){ // if more than 1 items is checked      
      this.optionsMainTitles[field] = "Plusieurs options";
    }

  }

  public submitSearchQuery(){
    this.emitSearchProjects();
  }

  private emitSearchProjects(){
    // remove empty string & empty arrays from the object
    let clonedSearchQuery = {...this.searchQuery};

    // remove empty fields from the query object
    for(let key in clonedSearchQuery){  
      if(typeof clonedSearchQuery[key] == 'string' && clonedSearchQuery[key] == ''){ // string
        delete clonedSearchQuery[key];
      }else if(Array.isArray(clonedSearchQuery[key]) && !clonedSearchQuery[key].length || clonedSearchQuery[key].includes("all")){ 
        // if array, and the array is empty remove from the search query
        // or if the array includes 'all value' then remove it, because we don't want to search by it
        delete clonedSearchQuery[key];
      }
    }

    this.searchProjects.emit(clonedSearchQuery);
  }

  public selectProjectFilter(filter){
    this.selectedProjectsFilter = filter;
    this.emitFilterProjects();
  }

  public emitFilterProjects(){
    this.filterProjects.emit(this.selectedProjectsFilter);
  }

  public emitSortBy(label, value){
    this.selectedSortByLabel = label;
    this.selectedSortByValue = value;

    let body = {sortBy: value, direction: this.sortByDirection};

    this.sortBy.emit(body);
  }
  public changeSortDirection(){
    const value = this.selectedSortByValue;
    const direction = this.sortByDirection == 1 ? -1 : 1;
    
    this.sortByDirection = direction;

    let body = {sortBy: value, direction: this.sortByDirection};
    
    this.sortBy.emit(body);
  }


  ///////////////////////////////////////////
  /// Minarm
  ///////////////////////////////////////////
  private setArchitectsList(architectsList){
    this.inputSectionMenus.minarmArchitectsNames = architectsList;

    // make all architects names checked by default
    for(let i = 0; i < architectsList.length; i++){
      this.searchQuery['architectsNames'].push(architectsList[i]._id);
    }
  }
  public minarmArchitectSelected(field, architectId){

    if(this.searchQuery[field].includes(architectId)){
      this.searchQuery[field] = this.searchQuery[field].filter(item => item != architectId);
    }else{
      this.searchQuery[field].push(architectId)
    }

    // set dropdown title
    this.setDropdownItemsTitles("architectsNames");
    
    this.emitSearchProjects();
  }

}
