import { Component, OnInit,Input, Output, EventEmitter, ElementRef, ChangeDetectorRef, SimpleChanges, ViewChild, NO_ERRORS_SCHEMA} from '@angular/core';
import { DpgfModule } from '../../dpgf.module';
import { NgSharedModule } from 'src/app/all/ngshared.module';
import { AuthService } from '../../../_services/index';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from 'src/app/dpgf/dce-edition/_store';

declare var $;

@Component({
  standalone:true,
  selector: 'app-unite-input-dpgf',
  imports:[DpgfModule,NgSharedModule],
  templateUrl: './unite-input-dpgf.component.html',
  styleUrls: ['./unite-input-dpgf.component.scss']
})
export class UniteInputDpgfComponent implements OnInit {
  @Input()unit:any;
  @Input()num:any;
  @Input()selected;
  @Input()selectedSousPostes;
  @Input()typeInput;
  @Input()placeHolder;
  @Input()autoSuggestOptions = [];
  @Input()color;
  @Input()sign:string = "";
  @Input()zeroDefault:string = "";
  @Input()disable;
  @Input()hideLock;
  @Input()id;
  @Input()style = {};
  @Input()tooltip;
  @Input() isLocalisation;
  @Output()changeText = new EventEmitter<boolean>();
  @Output()focused = new EventEmitter<boolean>();
  @Output()focusedOut = new EventEmitter<any>();
  @Output()sendNum = new EventEmitter<any>();

  public projectLicence: string='none';
  public dataValue;
  public lastEmit = 0;;
  public showInput = false;
  public results = [];
  public currentFocus = 0;
  constructor(
    private elRef: ElementRef,
    private cdRef : ChangeDetectorRef,
    private store: Store,
    public authService: AuthService) { }

  ngOnInit() {
    this.store.pipe(select(fromSelectors.getAny, {name : "projectLicence"} )).subscribe((licence)=>{
      this.projectLicence = licence;
    })
  }
  public onKeydownOnInput(event){
    if(this.showInput){
      switch(event.which) {
        case 40:
          this.currentFocus += 1;
          event.preventDefault();
        break;
        case 38:
          this.currentFocus -= 1;
          event.preventDefault();
        break;
        case 13:
          if(this.results[this.currentFocus]){
            event.preventDefault()
            this.choose(this.results[this.currentFocus]);
          }
        break;
        case 9:
          this.showInput = false;
          this.cdRef.detectChanges();
          this.results = [];
        break;
        case 27:
          this.showInput = false;
          this.cdRef.detectChanges();
          this.results = [];
        break;
      }
    }
  }
  public manageType(val){
    this.dataValue = val
    this.search(val)
    this.changeText.emit(val);
  }
  public search(val){
    if(this.autoSuggestOptions.length != 0 && this.typeInput == "text"){
      this.results = this.autoSuggestOptions.filter(sugg => (sugg && sugg.toLowerCase().indexOf(val.toLowerCase())!=-1 && sugg.indexOf(',')==-1)).slice(0,6)
    }else if(this.autoSuggestOptions.length != 0 && this.typeInput == "number"){
      this.results = this.autoSuggestOptions.slice()
    }
  }
  public getUnite(i){
    // console.log(i)
  }
  public focusedOn(){
    this.search("")
    this.focused.emit()
  }
  public focusedOutOn(){
    this.showInput = false;
    let now = new Date().getTime()
    if(now - this.lastEmit > 300){
      this.focusedOut.emit(this.dataValue)
      this.lastEmit = now;
    }
  }
  public editable(){
    if(this.authService.licence=='without' && this.projectLicence =='none'){
      $("#user-on-without-license-modal").modal('show');
      return;
    }
    if(!this.disable){
      this.showInput = true;
      this.dataValue = this.unit;
      this.sendNum.emit({num:this.num,choose:false});
      setTimeout(() => {
        this.elRef.nativeElement.getElementsByClassName("input-field")[0].focus()
        if(this.isLocalisation)this.elRef.nativeElement.getElementsByClassName("input-field")[0].select()
      }, 100)
    }
  }
  public choose(val){
    this.unit = val;
    this.results = [];
    // this.changeText.emit(val);
    this.sendNum.emit({num:-1,choose:true});
    this.focusedOut.emit(val)
    this.lastEmit = new Date().getTime()
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
}